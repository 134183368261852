/* eslint-disable react/no-unknown-property */
import {
  ActionIcon,
  ActionIconProps,
  Badge,
  Form,
  FormHandlers,
  HStack,
  ICON_ACCOUNT_CHECK_OUTLINE,
  ICON_ACCOUNT_OUTLINE,
  InputSize,
  Popover,
  PopoverProps,
} from '@doveit/bricks';
import React from 'react';
import { AgentStatus } from '../../domain/types';
import AgentAutoComplete from '../../containers/agent-autocomplete/AgentAutoComplete';
import { AgentFilterValue, useAgentFilter } from '../../hooks/agent-filter/useAgentFilter';
import * as styles from './AgentFilterAction.style';
import { Agent } from '../../providers/api/dtos/agent';
import { useCurrentAgentSync } from '../../hooks/use-agent/useAgent';
import useRBAC from '../../hooks/use-rbac/useRBAC';
import getShortAgentName from '../../utils/agent/getShortAgentName';
import getShortAgentNameFromEmail from '../../utils/agent/getShortAgentNameFromEmail';

export interface AgentFilterActionProps extends React.AriaAttributes {
  size?: InputSize,
  color?: ActionIconProps['color'],
  emphasis?: ActionIconProps['emphasis'],
  status?: AgentStatus,
  onSubmit?: (agents: AgentFilterValue[]) => void,
  children?: PopoverProps['trigger'],
}

const AgentFilterAction: React.FC<AgentFilterActionProps> = ({
  size = 'M',
  color = 'neutral',
  emphasis = 'low',
  status,
  children,
  onSubmit,
  ...rest
}) => {
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;

  const { isExplicit, agentEmails: agents } = useAgentFilter('page', getShortAgentName);

  const agentEmails = React.useMemo(() => agents.map((agent) => agent.email || [] as string[]).flat(), [agents]);
  const { data: currentAgent } = useCurrentAgentSync();
  const {
    userIsAdmin, userIsAreaManager, userIsCoordinator, userIsContentEditor,
  } = useRBAC();

  const canFilter = React.useMemo(
    () => userIsAdmin
      || userIsAreaManager
      || userIsCoordinator
      || userIsContentEditor
      || (currentAgent?.descendants?.length ?? 0) > 0,
    [currentAgent?.descendants?.length, userIsAdmin, userIsAreaManager, userIsContentEditor, userIsCoordinator],
  );

  const autocompleteStartFrom = React.useMemo(
    () => (userIsCoordinator ? undefined : currentAgent?.email.work),
    [currentAgent?.email.work, userIsCoordinator],
  );

  const coordinatedBy = React.useMemo(
    () => (userIsCoordinator ? currentAgent?.email.work : undefined),
    [currentAgent?.email.work, userIsCoordinator],
  );

  const [explicitAgentFilters, setExplicitAgentFilters] = React.useState<AgentFilterValue[]>(isExplicit ? agents : []);

  const actionIconPath = React.useMemo(
    () => ((agentEmails?.length ?? 0) > 0 && isExplicit ? ICON_ACCOUNT_CHECK_OUTLINE : ICON_ACCOUNT_OUTLINE),
    [agentEmails?.length, isExplicit],
  );

  const removeAgentEmail = React.useCallback((email: string) => () => {
    const filtered = explicitAgentFilters.filter((value) => value.email !== email);
    setExplicitAgentFilters(filtered);
  }, [explicitAgentFilters]);

  if (!canFilter) {
    return null;
  }

  return (
    <Popover
      aria-label="Azione filtra agenti"
      placement="bottom-end"
      trigger={children ?? (
        <ActionIcon
          icon={{ path: actionIconPath }}
          size={size}
          color={(agentEmails?.length ?? 0) > 0 && isExplicit ? 'primary' : color}
          emphasis={emphasis}
          label="Filtra agenti"
        />
      )}
      {...rest}
    >
      <div css={styles.agentFilterActionPopoverWrapper}>
        <HStack>
          {explicitAgentFilters.map((agentFilter) => (
            <Badge
              key={agentFilter.email}
              aria-label="Badge nome agente da filtrare"
              color={agents.some((element) => element.email === agentFilter.email) ? 'primary' : undefined}
              size="XS"
              label={agentFilter.name || getShortAgentNameFromEmail(agentFilter.email)}
              onRemove={removeAgentEmail(agentFilter.email)}
            />
          ))}
        </HStack>
        <Form
          initialValues={{} as any}
          innerRef={formRef}
          onSubmit={() => { onSubmit?.(explicitAgentFilters); }}
          enableReinitialize
        >
          {({ setFieldValue }) => (
            <>
              <Form.Item>
                <AgentAutoComplete
                  name="agent"
                  aria-label="Cerca agenti"
                  startFrom={autocompleteStartFrom}
                  coordinatedBy={coordinatedBy}
                  maxSuggestions={5}
                  status={status ?? AgentStatus.ACTIVE}
                  excludeAgentsByEmail={explicitAgentFilters.map((it) => it.email)}
                  size="S"
                  onSuggestionSelected={(value: Agent | null) => {
                    if (value?.email.work) {
                      setExplicitAgentFilters([...explicitAgentFilters, {
                        email: value?.email.work,
                        name: getShortAgentName(value?.name),
                      }]);
                      setFieldValue('agent', '');
                    }
                  }}
                />
              </Form.Item>
              {(explicitAgentFilters.length > 0 || (isExplicit && (agentEmails?.length ?? 0) > 0)) && (
                <Form.Item>
                  <Form.Submit
                    aria-label="Bottone per filtrare agenti"
                    label="Filtra agenti"
                    expanded
                  />
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </div>
    </Popover>
  );
};

export default AgentFilterAction;

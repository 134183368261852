import React from 'react';
import {
  ActionIcon, DetailStack, ICON_EYE_OUTLINE, UserPreview,
} from '@doveit/bricks';
import { Contact } from '../../../providers/api/dtos';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';

export interface ContactPreviewTableRowProps {
  contact: Contact,
}

const ContactPreviewTableRow: React.FC<ContactPreviewTableRowProps> = ({
  contact,
}) => {
  const goToContactPage = React.useCallback(() => {
    goToDetailPage(contact.id!, ReferenceType.CONTACT);
  }, [contact.id]);

  return (
    <SimpleTable.Row
      data-testid={`contact-preview-table-row-${contact.id}`}
      aria-label="Informazioni contatto"
    >
      <SimpleTable.Cell aria-label="Nome">
        <UserPreview
          name={contact.name || 'Nome assente'}
          size="S"
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Numero di telefono">
        <DetailStack label="Telefono">
          {contact?.phoneNumber || NO_VALUE_SYMBOL}
        </DetailStack>
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Email">
        <DetailStack label="Email">
          {contact?.email || NO_VALUE_SYMBOL}
        </DetailStack>
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Data di creazione">
        <DetailStack label="Aggiunto">
          <DateViewer
            stringDate={contact.createdAt!}
            checkIfToday
          />
        </DetailStack>
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Azioni"
        align="right"
      >
        <ActionIcon
          aria-label="Visualizza contatto"
          label="Visualizza"
          size="S"
          icon={{ path: ICON_EYE_OUTLINE }}
          onClick={goToContactPage}
        />
      </SimpleTable.Cell>
    </SimpleTable.Row>
  );
};

export default ContactPreviewTableRow;

/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Action, ActionIcon, FontWeight, HStack, Icon, ICON_CHECK_CIRCLE_OUTLINE, ICON_CONTENT_COPY, ICON_PROGRESS_CHECK, ICON_WHATSAPP, Spacing, Text,
} from '@doveit/bricks';
import React from 'react';
import { formatDate } from '@doveit/hammer';
import UpdateAssignmentEndDateAction from '../../assignment/containers/update-assignment-end-date-action/UpdateAssignmentEndDateAction';
import SimpleTable from '../../components/simple-table/SimpleTable';
import ManageDocumentAction from '../../document/containers/manage-document-action/ManageDocumentAction';
import { DocumentReferenceType } from '../../domain/types/documentReferenceType';
import ConfirmOfferDeedAction from '../../offer/containers/confirm-offer-deed-action/ConfirmOfferDeedAction';
import EditOfferDeedAction from '../../offer/containers/edit-offer-deed-action/EditOfferDeedAction';
import EditPropertyPrivacyAction from '../../property/containers/edit-property-privacy-action/EditPropertyPrivacyAction';
import NegotiationPropertyAction from '../../property/containers/negotiation-property-action/NegotiationPropertyAction';
import PublishPropertyAction from '../../property/containers/publish-property-action/PublishPropertyAction';
import RequestPropertyReviewsAction from '../../property/containers/request-property-reviews-action/RequestPropertyReviewsAction';
import ToggleHideRequestReviewsPropertyNoticeAction from '../../property/containers/toggle-hide-request-reviews-property-notice-action/ToggleHideRequestReviewsPropertyNoticeAction';
import {
  PropertyAssignmentExpiredNoticeData, Notice, Severity, PropertyAssignmentExpiringNoticeData, PropertyMissingMandatoryDocumentsNoticeData, PropertyMandatoryDocumentsAwaitingApprovalNoticeData, PropertyMandatoryDocumentsRejectedNoticeData, PropertyLiveWithAcceptedOfferNoticeData, PropertyInNegotiationWithInProgressOfferNoticeData, PropertyOfferDeedNotConfirmedNoticeData, PropertyReviewsToBeRequestedNoticeData, PropertyPrivacyHiddenInfoNoticeData, PropertyNoticeData,
  PropertyAssignmentSignatureOngoingNoticeData,
  PropertyContentAndShootingMissingNoticeData,
  PropertyMissingContentsAndExpiredShootingNoticeData,
  PropertyNoPublishedOnesNoticeData,
} from '../../providers/api/dtos/notice';
import { BuildPropertyNoticeCtx } from './types';
import CancelAssignmentSignatureRequestAction from '../../assignment/containers/cancel-assignment-signature-request-action/CancelAssignmentSignatureRequestAction';
import PreviewAssignmentWizardPDFAction from '../../assignment/containers/preview-assignment-wizard-pdf-action/PreviewAssignmentWizardPDFAction';
import CopyLinkAction from '../../components/copy-link-action/CopyLinkAction';
import SendWhatsappAction from '../../containers/send-whatsapp-action/SendWhatsappAction';
import ManageMultimediaContentsAction from '../../content/containers/manage-multimedia-contents-action/ManageMultimediaContentsAction';
import { ContentType } from '../../domain/types';
import UpsertPropertyShootingAction from '../../property/containers/upsert-property-shooting-action/UpsertPropertyShootingAction';
import ManagePublishedOnsAction from '../../property/published-on/containers/manage-published-ons-action/ManagePublishedOnsAction';
import { Signer, SingleSignatureStatus } from '../../providers/api/dtos';

export function buildPropertyAssignmentExpiredNotice(noticeData: PropertyAssignmentExpiredNoticeData, ctx: BuildPropertyNoticeCtx): Notice {
  const { type, data: { assignment } } = noticeData;
  const { onAssignmentUpdate } = ctx;

  const renewAssignment = () => {
    window.open(`/prospects/${assignment.prospectId}/assignment/renew`);
  };

  return {
    type,
    severity: Severity.CRITICAL,
    priority: 1,
    message: 'Il mandato è scaduto',
    children: `Il mandato di vendita dell'immobile è scaduto il giorno ${formatDate(new Date(assignment.endDate!))}. Contatta il venditore per concordare il rinnovo e generare un nuovo mandato.`,
    actions: [
      <Action
        emphasis="high"
        color="critical"
        size="S"
        label="Rinnova mandato"
        onClick={renewAssignment}
      />,
      <UpdateAssignmentEndDateAction
        assignment={assignment}
        onSuccess={onAssignmentUpdate}
      >
        {({ editEndDate, isSaving }) => (
          <Action
            label="Aggiorna data di scadenza"
            size="S"
            color="critical"
            onClick={editEndDate}
            loading={isSaving}
          />
        )}
      </UpdateAssignmentEndDateAction>,
    ],
  };
}

export function buildPropertyAssignmentExpiringNotice(noticeData: PropertyAssignmentExpiringNoticeData, ctx: BuildPropertyNoticeCtx): Notice {
  const { type, data: { assignment } } = noticeData;
  const { onAssignmentUpdate } = ctx;

  const renewAssignment = () => {
    window.open(`/prospects/${assignment.prospectId}/assignment/renew`);
  };

  return {
    type,
    severity: Severity.WARNING,
    priority: 3,
    message: 'Il mandato è in scadenza',
    children: `Il mandato di vendita dell'immobile scadrà il giorno ${formatDate(new Date(assignment.endDate!))}. Contatta il venditore per concordare il rinnovo e generare un nuovo mandato.`,
    actions: [
      <Action
        emphasis="high"
        color="warning"
        size="S"
        label="Rinnova mandato"
        onClick={renewAssignment}
      />,
      <UpdateAssignmentEndDateAction
        assignment={assignment}
        onSuccess={onAssignmentUpdate}
      >
        {({ editEndDate, isSaving }) => (
          <Action
            label="Aggiorna data di scadenza"
            size="S"
            color="warning"
            onClick={editEndDate}
            loading={isSaving}
          />
        )}
      </UpdateAssignmentEndDateAction>,
    ],
  };
}

export function buildPropertyMissingMandatoryDocumentsNotice(
  noticeData: PropertyMissingMandatoryDocumentsNoticeData,
  ctx: BuildPropertyNoticeCtx,
): Notice {
  const { type, referenceId, data } = noticeData;
  const { onDocumentCreate, onDocumentUpdate } = ctx;

  return ({
    type,
    severity: Severity.WARNING,
    priority: 1,
    message: 'Mancano alcuni documenti obbligatori',
    children: (
      <>
        Per pubblicare l&apos;immobile mancano i seguenti documenti:
        <Spacing margin={[200, 0, 0]}>
          <SimpleTable>
            <SimpleTable.Body>
              {data.missingDocuments.map((document) => (
                <SimpleTable.Row key={document.type}>
                  <SimpleTable.Cell>
                    <ManageDocumentAction
                      referenceId={referenceId.toString()}
                      referenceType={DocumentReferenceType.PROPERTY}
                      documentType={document.type}
                      referenceContext={DocumentReferenceType.PROPERTY}
                      document={document}
                      onCreate={onDocumentCreate}
                      onUpdate={onDocumentUpdate}
                    />
                  </SimpleTable.Cell>
                </SimpleTable.Row>
              ))}
            </SimpleTable.Body>
          </SimpleTable>
        </Spacing>
      </>
    ),
  });
}

export function buildPropertyMandatoryDocumentsAwaitingApprovalNotice(
  noticeData: PropertyMandatoryDocumentsAwaitingApprovalNoticeData,
  ctx: BuildPropertyNoticeCtx,
): Notice {
  const { type, referenceId, data } = noticeData;
  const { onDocumentCreate, onDocumentUpdate } = ctx;

  return ({
    type,
    severity: Severity.WARNING,
    priority: 1,
    message: 'Hai alcuni documenti obbligatori da revisionare',
    children: (
      <>
        Per pubblicare l&apos;immobile occorre approvare i seguenti documenti:
        <Spacing margin={[200, 0, 0]}>
          <SimpleTable>
            <SimpleTable.Body>
              {data.documentsAwaitingApproval.map((document) => (
                <SimpleTable.Row key={document.type}>
                  <SimpleTable.Cell>
                    <ManageDocumentAction
                      referenceId={referenceId.toString()}
                      referenceType={DocumentReferenceType.PROPERTY}
                      documentType={document.type}
                      document={document}
                      onCreate={onDocumentCreate}
                      onUpdate={onDocumentUpdate}
                    />
                  </SimpleTable.Cell>
                </SimpleTable.Row>
              ))}
            </SimpleTable.Body>
          </SimpleTable>
        </Spacing>
      </>
    ),
  });
}

export function buildPropertyMandatoryRejectedNotice(
  noticeData: PropertyMandatoryDocumentsRejectedNoticeData,
  ctx: BuildPropertyNoticeCtx,
): Notice {
  const { type, referenceId, data } = noticeData;
  const { onDocumentCreate, onDocumentUpdate } = ctx;

  return ({
    type,
    severity: Severity.CRITICAL,
    priority: 4,
    message: 'Alcuni documenti obbligatori sono da correggere',
    children: (
      <>
        Per pubblicare l&apos;immobile è necessario correggere i seguenti documenti:
        <Spacing margin={[200, 0, 0]}>
          <SimpleTable>
            <SimpleTable.Body>
              {data.documentsRejected.map((document) => (
                <SimpleTable.Row key={document.type}>
                  <SimpleTable.Cell>
                    <ManageDocumentAction
                      referenceId={referenceId.toString()}
                      referenceType={DocumentReferenceType.PROPERTY}
                      documentType={document.type}
                      document={document}
                      onCreate={onDocumentCreate}
                      onUpdate={onDocumentUpdate}
                    />
                  </SimpleTable.Cell>
                </SimpleTable.Row>
              ))}
            </SimpleTable.Body>
          </SimpleTable>
        </Spacing>
      </>
    ),
  });
}

export function buildPropertyLiveWithAcceptedOfferNotice(
  noticeData: PropertyLiveWithAcceptedOfferNoticeData,
  ctx: BuildPropertyNoticeCtx,
): Notice {
  const { type, data: { property } } = noticeData;
  const { onPropertyUpdate } = ctx;

  return {
    type,
    severity: Severity.WARNING,
    priority: 3,
    message: "L'immobile è in stato pubblicato con una proposta accettata",
    children: "L'immobile è visibile sui portali e sul sito Dove.it non compare il banner di trattativa. Metti in trattativa per rimuovere l'immobile dai portali e visualizzare il banner.",
    actions: [
      <NegotiationPropertyAction
        property={property}
        onSuccess={onPropertyUpdate}
      >
        {({ onNegotiation, isLoading }) => (
          <Action
            label="Metti in trattativa"
            size="S"
            color="warning"
            emphasis="high"
            onClick={onNegotiation}
            loading={isLoading}
          />
        )}
      </NegotiationPropertyAction>,
    ],
  };
}

export function buildPropertyInNegotiationWithInProgressOfferNotice(
  noticeData: PropertyInNegotiationWithInProgressOfferNoticeData,
  ctx: BuildPropertyNoticeCtx,
): Notice {
  const { type, data: { property } } = noticeData;
  const { onPropertyUpdate } = ctx;

  return {
    type,
    severity: Severity.WARNING,
    priority: 3,
    message: "L'immobile è in stato trattativa con una proposta presentata",
    children: "L'immobile non è visibile sui portali e sul sito Dove.it compare il banner di trattativa. Nascondi la trattativa per visualizzare l'immobile sui portali e nascondere il banner.",
    actions: [
      <PublishPropertyAction
        property={property}
        onSuccess={onPropertyUpdate}
      >
        {({ publish, loading }) => (
          <Action
            label="Nascondi trattativa"
            size="S"
            color="warning"
            emphasis="high"
            onClick={publish}
            loading={loading}
          />
        )}
      </PublishPropertyAction>,
    ],
  };
}

export function buildPropertyOfferDeedNotConfirmedNotice(
  noticeData: PropertyOfferDeedNotConfirmedNoticeData,
  ctx: BuildPropertyNoticeCtx,
): Notice {
  const { type, data: { offer } } = noticeData;
  const { onOfferUpdate } = ctx;

  return {
    type,
    severity: Severity.WARNING,
    priority: 1,
    message: 'Il rogito non è ancora stato confermato',
    children: `Il rogito${offer.deed?.date ? `, previsto in data ${formatDate(new Date(offer.deed.date))}, ` : ''} non è stato ancora confermato. Conferma il rogito per chiedere le recensioni verificate o modifica la data di rogito.`,
    actions: [
      <ConfirmOfferDeedAction
        offer={offer}
        onSuccess={onOfferUpdate}
      >
        {({ confirm, isSaving }) => (
          <Action
            label="Conferma rogito"
            size="S"
            color="warning"
            emphasis="high"
            onClick={confirm}
            loading={isSaving}
          />
        )}
      </ConfirmOfferDeedAction>,
      <EditOfferDeedAction
        offer={offer}
        onSuccess={onOfferUpdate}
      >
        {({ openEditOfferDeedModal }) => (
          <Action
            label="Modifica data"
            size="S"
            color="warning"
            onClick={openEditOfferDeedModal}
          />
        )}
      </EditOfferDeedAction>,
    ],
  };
}

export function buildPropertyReviewsToBeRequestedNotice(
  noticeData: PropertyReviewsToBeRequestedNoticeData,
  ctx: BuildPropertyNoticeCtx,
): Notice {
  const { type, data: { property } } = noticeData;
  const { onReviewRequest, onPropertyUpdate } = ctx;

  return {
    type,
    severity: Severity.INFO,
    priority: 1,
    message: 'Richiedi le recensioni verificate',
    children: 'Hai confermato la data di rogito, ma non hai ancora chiesto le recensioni verificate. Chiedi le recensioni o salta questo passaggio se non sei sicuro di ricevere delle recensioni positive.',
    actions: [
      <RequestPropertyReviewsAction
        property={property}
        onSuccess={onReviewRequest}
      >
        {({ request }) => (
          <Action
            label="Richiedi recensioni"
            size="S"
            emphasis="high"
            onClick={request}
          />
        )}
      </RequestPropertyReviewsAction>,
      <ToggleHideRequestReviewsPropertyNoticeAction
        property={property}
        onSuccess={onPropertyUpdate}
      >
        {({ toggle, isSaving }) => (
          <Action
            label="Salta richiesta"
            size="S"
            onClick={toggle}
            loading={isSaving}
          />
        )}
      </ToggleHideRequestReviewsPropertyNoticeAction>,
    ],
  };
}

export function buildPropertyPrivacyHiddenInfoNotice(
  noticeData: PropertyPrivacyHiddenInfoNoticeData,
  ctx: BuildPropertyNoticeCtx,
): Notice {
  const { type, data: { property } } = noticeData;
  const { onPropertyUpdate } = ctx;

  const hiddenInfo = Object.entries(property.privacy)
    .filter(([_, hidden]) => hidden)
    .map(([field, _]) => {
      switch (field) {
        case 'hidePrice':
          return 'il prezzo';
        case 'hideStreetNumber':
          return 'il numero civico';
        case 'hideRoute':
          return "l'indirizzo";
        default:
          throw new Error('Unknown hidden field');
      }
    });

  const stringifyHiddenInfo = hiddenInfo.length === 0
    ? ''
    : hiddenInfo.length === 1
      ? hiddenInfo[0]
      : `${hiddenInfo.slice(0, -1).join(', ')} e ${hiddenInfo[hiddenInfo.length - 1]}`;

  return {
    type,
    severity: Severity.INFO,
    priority: 2,
    message: 'Impostazioni di privacy attive',
    children: `Hai scelto di nascondere ${stringifyHiddenInfo}. L'annuncio potrebbe subire una penalizzazione sui portali.`,
    actions: [
      <EditPropertyPrivacyAction
        property={property}
        onSuccess={onPropertyUpdate}
      >
        {({ edit, isSaving }) => (
          <Action
            label="Modifica privacy"
            size="S"
            emphasis="high"
            onClick={edit}
            loading={isSaving}
          />
        )}
      </EditPropertyPrivacyAction>,
    ],
  };
}

export function buildPropertyAssignmentSignatureOngoingNotice(noticeData: PropertyAssignmentSignatureOngoingNoticeData, ctx: BuildPropertyNoticeCtx): Notice {
  const { data: { signers, assignmentWizardData }, type } = noticeData;
  const { onAssignmentWizardUpdate } = ctx;

  const buildMessage = (signer: Signer) => `Gentile ${signer.firstName} ${signer.lastName}, di seguito il link per firmare l'incarico di vendita: ${signer.signatureLink}`;

  return {
    message: 'Procedura di firma digitale in corso',
    children: (
      <>
        È in corso la procedura di firma digitale per il rinnovo o l&apos;aggiornamento del mandato di vendita.
        <Spacing margin={[200, 0]}>
          <SimpleTable>
            <SimpleTable.Body>
              {signers.map((signer) => (
                <SimpleTable.Row>
                  <SimpleTable.Cell width="1.5rem">
                    <Icon
                      path={signer.status === SingleSignatureStatus.SIGNED ? ICON_CHECK_CIRCLE_OUTLINE : ICON_PROGRESS_CHECK}
                      color={signer.status === SingleSignatureStatus.SIGNED ? 'success.default.low' : 'warning.default.low'}
                    />
                  </SimpleTable.Cell>
                  <SimpleTable.Cell>
                    <Text.Body fontWeight={FontWeight.MEDIUM}>
                      {signer.firstName} {signer.lastName}
                    </Text.Body>
                  </SimpleTable.Cell>
                  {signer.status === SingleSignatureStatus.NOTIFIED && signer.signatureLink && (
                    <SimpleTable.Cell align="right">
                      <HStack>
                        <CopyLinkAction
                          link={signer.signatureLink}
                          shortUrl
                        >
                          {({ onCopy }) => (
                            <ActionIcon
                              label="Copia negli appunti"
                              onClick={onCopy}
                              size="S"
                              icon={{ path: ICON_CONTENT_COPY }}
                              disabled={signer.status !== SingleSignatureStatus.NOTIFIED}
                              loading={signer.status === SingleSignatureStatus.INITIATED && !signer.signatureLink}

                            />
                          )}
                        </CopyLinkAction>
                        <SendWhatsappAction
                          phoneNumber={signer.phoneNumber}
                          name={`${signer.firstName} ${signer.lastName}`}
                          initialMessage={buildMessage(signer)}
                        >
                          {({ onClick }) => (
                            <ActionIcon
                              aria-label="Pulsante per condividere il link di firma di YouSign"
                              size="S"
                              icon={{ path: ICON_WHATSAPP }}
                              label="Condividi link su WhatsApp"
                              onClick={onClick}
                              disabled={signer.status !== SingleSignatureStatus.NOTIFIED}
                            />
                          )}
                        </SendWhatsappAction>
                      </HStack>
                    </SimpleTable.Cell>
                  )}
                </SimpleTable.Row>
              ))}
            </SimpleTable.Body>
          </SimpleTable>
        </Spacing>
      </>),
    priority: 0,
    actions: [
      <CancelAssignmentSignatureRequestAction
        assignmentWizardData={assignmentWizardData}
        onSuccess={onAssignmentWizardUpdate}
      >
        {({ cancelSignatureRequest }) => (
          <Action
            label="Annulla richiesta"
            onClick={cancelSignatureRequest}
            color="critical"
            emphasis="high"
            size="S"
          />
        )}
      </CancelAssignmentSignatureRequestAction>,
      <PreviewAssignmentWizardPDFAction
        prospectId={assignmentWizardData.prospectId}
      >
        {({ onClick, isLoading }) => (
          <Action
            label="Visualizza anteprima"
            onClick={onClick}
            color="critical"
            emphasis="low"
            size="S"
            loading={isLoading}
          />
        )}
      </PreviewAssignmentWizardPDFAction>,
    ],
    severity: Severity.CRITICAL,
    type,
  };
}

export function buildPropertyContentsAndShootingMissing(noticeData: PropertyContentAndShootingMissingNoticeData, ctx: BuildPropertyNoticeCtx): Notice {
  const { type, data: { property } } = noticeData;
  const { onPropertyShootingUpdate, onPropertyContentUpdate } = ctx;

  return {
    message: 'Mancano le foto e non è stata fissata una data per lo shooting',
    priority: 2,
    severity: Severity.WARNING,
    type,
    children: <>Per pubblicare l&apos;immobile è necessario caricare le foto. Fissa una data per lo shooting.</>,
    actions: [
      <UpsertPropertyShootingAction
        propertyId={property.id!}
        onSuccess={onPropertyShootingUpdate}
      >
        {({ upsert }) => (
          <Action
            label="Fissa data shooting"
            onClick={upsert}
            color="warning"
            size="S"
            emphasis="high"
          />
        )}
      </UpsertPropertyShootingAction>,
      <ManageMultimediaContentsAction
        propertyId={property.id!}
        contentType={ContentType.FOTO}
        canSort
        canAdd
        canDelete
        canEdit
        onContentChange={onPropertyContentUpdate}
      >
        {({ openManageModal }) => (
          <Action
            label="Carica le foto"
            onClick={() => openManageModal('upload')}
            color="warning"
            size="S"
            emphasis="low"
          />
        )}
      </ManageMultimediaContentsAction>,
    ],
  };
}

export function buildPropertyMissingContentsAndExpiredShooting(noticeData: PropertyMissingContentsAndExpiredShootingNoticeData, ctx: BuildPropertyNoticeCtx): Notice {
  const { type, data: { property, shooting } } = noticeData;
  const { onPropertyShootingUpdate, onPropertyContentUpdate } = ctx;

  return {
    message: 'Mancano le foto e la data dello shooting è scaduta',
    priority: 3,
    severity: Severity.CRITICAL,
    type,
    children: <>Per pubblicare l&apos;immobile è necessario caricare le foto. Carica le foto o aggiorna la data dello shooting.</>,
    actions: [
      <ManageMultimediaContentsAction
        propertyId={property.id!}
        contentType={ContentType.FOTO}
        canSort
        canAdd
        canDelete
        canEdit
        onContentChange={onPropertyContentUpdate}
      >
        {({ openManageModal }) => (
          <Action
            label="Carica le foto"
            onClick={() => openManageModal('upload')}
            color="critical"
            size="S"
            emphasis="high"
          />
        )}
      </ManageMultimediaContentsAction>,
      <UpsertPropertyShootingAction
        propertyId={property.id!}
        shooting={shooting}
        onSuccess={onPropertyShootingUpdate}
      >
        {({ upsert }) => (
          <Action
            label="Aggiorna data shooting"
            onClick={upsert}
            color="critical"
            size="S"
            emphasis="low"
          />
        )}
      </UpsertPropertyShootingAction>,
    ],
  };
}

export function buildPropertyNoPublishedOnsNotice(
  noticeData: PropertyNoPublishedOnesNoticeData,
  ctx: BuildPropertyNoticeCtx,
): Notice {
  const { type, data: { property } } = noticeData;
  const { onPropertyPublishedOnsUpdate } = ctx;

  return {
    message: 'Nessun portale selezionato',
    priority: 1,
    severity: Severity.INFO,
    type,
    children: <>L&apos;immobile non è visibile su nessun portale. Potresti aver fatto questa scelta nel caso di pubblicazione manuale di un immobile non residenziale.</>,
    actions: [
      <ManagePublishedOnsAction
        property={property}
        onSuccess={onPropertyPublishedOnsUpdate}
      >
        {({ openPublishedOnsModal, disabled }) => (
          <Action
            label="Modifica portali"
            onClick={openPublishedOnsModal}
            disabled={disabled}
            color="neutral"
            size="S"
            emphasis="high"
          />
        )}
      </ManagePublishedOnsAction>,
    ],
  };
}

export function buildPropertyNotice(noticeData: PropertyNoticeData, ctx: BuildPropertyNoticeCtx): Notice {
  switch (noticeData.type) {
    case 'PROPERTY_ASSIGNMENT_EXPIRED':
      return buildPropertyAssignmentExpiredNotice(noticeData, ctx);
    case 'PROPERTY_ASSIGNMENT_EXPIRING':
      return buildPropertyAssignmentExpiringNotice(noticeData, ctx);
    case 'PROPERTY_MISSING_MANDATORY_DOCUMENTS':
      return buildPropertyMissingMandatoryDocumentsNotice(noticeData, ctx);
    case 'PROPERTY_MANDATORY_DOCUMENTS_REJECTED':
      return buildPropertyMandatoryRejectedNotice(noticeData, ctx);
    case 'PROPERTY_MANDATORY_DOCUMENTS_AWAITING_APPROVAL':
      return buildPropertyMandatoryDocumentsAwaitingApprovalNotice(noticeData, ctx);
    case 'PROPERTY_LIVE_WITH_ACCEPTED_OFFER':
      return buildPropertyLiveWithAcceptedOfferNotice(noticeData, ctx);
    case 'PROPERTY_IN_NEGOTIATION_WITH_IN_PROGRESS_OFFER':
      return buildPropertyInNegotiationWithInProgressOfferNotice(noticeData, ctx);
    case 'PROPERTY_OFFER_DEED_NOT_CONFIRMED':
      return buildPropertyOfferDeedNotConfirmedNotice(noticeData, ctx);
    case 'PROPERTY_REVIEWS_TO_BE_REQUESTED':
      return buildPropertyReviewsToBeRequestedNotice(noticeData, ctx);
    case 'PROPERTY_PRIVACY_HIDDEN_INFO':
      return buildPropertyPrivacyHiddenInfoNotice(noticeData, ctx);
    case 'PROPERTY_ASSIGNMENT_SIGNATURE_ONGOING':
      return buildPropertyAssignmentSignatureOngoingNotice(noticeData, ctx);
    case 'PROPERTY_CONTENTS_AND_SHOOTING_MISSING':
      return buildPropertyContentsAndShootingMissing(noticeData, ctx);
    case 'PROPERTY_MISSING_CONTENTS_AND_EXPIRED_SHOOTING':
      return buildPropertyMissingContentsAndExpiredShooting(noticeData, ctx);
    case 'PROPERTY_NO_PUBLISHED_ONS':
      return buildPropertyNoPublishedOnsNotice(noticeData, ctx);
    default:
      throw new Error('Unknown property notice type');
  }
}

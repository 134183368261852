import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Action, Card, DetailItemList, Skeleton, theme, Spacing, HStack, FontWeight, Text,
  ActionIconDropdown,
  Dropdown,
} from '@doveit/bricks';
import { formatDate, pluralizeWord } from '@doveit/hammer';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import RightColumnPageLayout from '../../../layouts/right-column-page-layout/RightColumnPageLayout';
import useRumor from '../../hooks/use-rumor/useRumor';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import RightColumnPageSkeleton from '../../../components/page-skeleton/RightColumnPageSkeleton';
import CalendarWidget from '../../../components/calendar-widget/CalendarWidget';
import { ReferenceType } from '../../../domain/types';
import { ForbiddenErrorPage } from '../../../containers/require-auth/RequireAuth';
import AssociateContactToRumorWidget from '../../containers/associate-contact-to-rumor-widget/AssociateContactToRumorWidget';
import ViewNeighborhoodsAction from '../../../components/view-neighborhoods-action/ViewNeighborhoodsAction';
import NotesWidget from '../../../notes/containers/notes-widget/NotesWidget';
import { Rumor } from '../../../providers/api/dtos';
import LocationSellabilityWidget from '../../../containers/location-sellability-widget/LocationSellabilityWidget';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import { useRumorMatchCount } from '../../hooks/use-rumor-match-count/useRumorMatchCount';
import { RumorStatus } from '../../../domain/types/rumorStatus';
import { useRumorNoticesData } from '../../hooks/use-rumor-notices-data/useRumorNoticesData';
import { WithRequiredKeys } from '../../../types';
import { buildRumorNotice } from '../../../utils/notice/buildRumorNotice';
import NoticeBoard from '../../../notice/components/notice-board/NoticeBoard';
import ViewRumorPopoverPreview from '../../containers/view-rumor-popover-preview/ViewRumorPopoverPreview';
import RumorAvatar from '../../components/rumor-avatar/RumorAvatar';
import useContact from '../../../contact/hooks/use-contact/useContact';
import { goToDetailPage } from '../../../utils/navigate/utils';
import UpdateRumorStatus from '../../containers/update-rumor-status/UpdateRumorStatus';
import { useAgent, useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import EditAssignedAgentAction from '../../../agent/containers/edit-assigned-agent-action/EditAssignedAgentAction';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import RumorDocumentsWidget from '../../containers/rumor-documents-widget/RumorDocumentsWidget';
import { rumorMessagesProvider } from '../../../containers/send-whatsapp-action/messages/rumorMessagesProvider';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';
import RumorWidget from '../../components/rumor-widget/RumorWidget';

export const LOAD_RUMOR_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni della notizia';

const ViewRumorPage: React.FC = () => {
  const isMobile = useIsDevice('mobile');
  const params = useParams<'id'>();
  const rumorId = params?.id ? parseInt(params.id, 10) : undefined;
  const { userIsAdmin, userIsCallCenter } = useRBAC();

  const {
    data: rumor,
    isLoading: isRumorLoading,
    error: rumorError,
    mutate: mutateRumor,
  } = useRumor(rumorId);
  const { data: contact } = useContact(rumor?.contactId);

  const {
    data: rumorAgent,
    isLoading: isRumorAgentLoading,
  } = useAgent(rumor?.agentId);

  const {
    data: rumorOriginAgent,
    isLoading: isRumorOriginAgentLoading,
  } = useAgent(rumor?.originAgentId);

  const userIsRumorAgentOrManager = useCurrentAgentIsSameAgentOrManager(rumor?.agentId);
  const { data: noticesData, mutate: mutateRumorNotices } = useRumorNoticesData(rumor as WithRequiredKeys<Rumor, 'id'>);
  const currentAgent = useCurrentAgent();

  const { data: matchingCounters, isLoading: isMatchingCountersLoading } = useRumorMatchCount(rumorId);

  const matchingSearchCriteriaCount = React.useMemo(
    () => matchingCounters?.counters.SEARCH_CRITERIA || 0,
    [matchingCounters],
  );

  const canManageCalendarEvents = React.useMemo(
    () => userIsAdmin || userIsRumorAgentOrManager,
    [userIsAdmin, userIsRumorAgentOrManager],
  );

  const canAddReminder = React.useMemo(
    () => canManageCalendarEvents && rumor?.status === RumorStatus.IN_PROGRESS,
    [canManageCalendarEvents, rumor?.status],
  );

  const canManageContactAdditionalServicesAndConcessions = React.useMemo(
    () => userIsAdmin || userIsCallCenter || userIsRumorAgentOrManager,
    [userIsRumorAgentOrManager, userIsAdmin, userIsCallCenter],
  );

  const canManageContactOptIns = React.useMemo(
    () => userIsAdmin || userIsCallCenter,
    [userIsAdmin, userIsCallCenter],
  );

  const canManageNotes = React.useMemo(
    () => userIsRumorAgentOrManager || userIsCallCenter || userIsAdmin,
    [userIsAdmin, userIsCallCenter, userIsRumorAgentOrManager],
  );

  const canViewSellabilitySection = React.useMemo(
    () => (userIsAdmin || userIsRumorAgentOrManager) && !!rumor?.isGeoComplete,
    [rumor?.isGeoComplete, userIsAdmin, userIsRumorAgentOrManager],
  );

  const canViewMatchingSearchCriteria = React.useMemo(
    () => userIsAdmin || userIsRumorAgentOrManager,
    [userIsAdmin, userIsRumorAgentOrManager],
  );

  const showRumorDocumentsWidget = React.useMemo(
    () => userIsAdmin || userIsRumorAgentOrManager,
    [userIsAdmin, userIsRumorAgentOrManager],
  );

  const canSendWhatsapp = React.useMemo(
    () => contact?.phoneNumber && parsePhoneNumber(contact.phoneNumber, 'IT').getType() === 'MOBILE' && ((currentAgent && userIsRumorAgentOrManager) || userIsAdmin),
    [contact?.phoneNumber, currentAgent, userIsRumorAgentOrManager, userIsAdmin],
  );

  const canEditRumor = React.useMemo(() => userIsAdmin || userIsRumorAgentOrManager, [userIsAdmin, userIsRumorAgentOrManager]);

  const notices = React.useMemo(() => {
    if (!rumor) {
      return [];
    }

    return (noticesData || []).map((noticeData) => buildRumorNotice(noticeData, {
      onRumorNudgeSent: mutateRumorNotices,
      onReminderCreate: mutateRumorNotices,
      onRumorEdit: () => {
        mutateRumor();
        mutateRumorNotices();
      },
      onContactAssociated: () => {
        mutateRumor();
        mutateRumorNotices();
      },
    }));
  }, [mutateRumor, mutateRumorNotices, noticesData, rumor]);

  const onContactChange = React.useCallback(
    () => { mutateRumor(); },
    [mutateRumor],
  );

  const goToMatchingSearchCriteriaPage = React.useCallback(() => {
    const matchingPage = `/rumors/${rumorId}/matching`;
    if (isMobile) {
      window.location.href = matchingPage;
    } else {
      window.open(matchingPage, '_blank');
    }
  }, [rumorId, isMobile]);

  const showNeighborhoodsSection = React.useMemo(
    () => userIsAdmin || userIsRumorAgentOrManager,
    [userIsAdmin, userIsRumorAgentOrManager],
  );

  const canChangeAssignedAgent = React.useMemo(
    () => (userIsAdmin || userIsRumorAgentOrManager) && rumor?.status === RumorStatus.IN_PROGRESS,
    [rumor?.status, userIsAdmin, userIsRumorAgentOrManager],
  );

  const showOriginAgentPreview = React.useMemo(
    () => rumor?.originAgentId && rumor.originAgentId !== rumor.agentId,
    [rumor?.agentId, rumor?.originAgentId],
  );

  const onNotesUpdate = React.useCallback(async () => {
    await mutateRumor();
  }, [mutateRumor]);

  const goToProspectPage = React.useCallback(
    () => rumor?.prospectId && goToDetailPage(rumor.prospectId, ReferenceType.PROSPECT),
    [rumor],
  );

  if (isRumorLoading) {
    return <RightColumnPageSkeleton />;
  }

  if (!rumor || rumorError) {
    if (rumorError?.response?.status === 403) {
      return <ForbiddenErrorPage />;
    }

    return <GenericErrorPage title={LOAD_RUMOR_ERROR_MESSAGE} />;
  }

  return rumor! && (
    <RightColumnPageLayout
      title={(
        <HStack
          aria-label="rumor-preview"
          gap={200}
          wrap="nowrap"
        >
          <ViewRumorPopoverPreview
            rumorId={rumor.id!}
            trigger={(
              <RumorAvatar
                aria-label="Avatar notizia"
                size="M"
              />
            )}
          />
          <div>
            <div>
              <Text.H4
                fontWeight={FontWeight.REGULAR}
                aria-label="page-title"
                as="div"
              >
                {rumor.propertyAddress || 'Notizia'}
              </Text.H4>
            </div>
            {contact && (
              <Text.Body
                aria-label="page-title-details"
                as="div"
              >
                {contact.name || contact.phoneNumber || contact.email}
              </Text.Body>
            )}
          </div>
        </HStack>
      )}
      actions={(
        <>
          {(!isMobile && canSendWhatsapp) && (
            <SendWhatsappAction
              phoneNumber={contact!.phoneNumber!}
              name={contact!.name}
              fetchMessages={rumorMessagesProvider(currentAgent)}
            >
              {({ onClick }) => (
                <Action
                  label="Invia whatsapp"
                  aria-label="Pulsante per inviare un messaggio Whatsapp"
                  onClick={onClick}
                  emphasis="high"
                />
              )}
            </SendWhatsappAction>
          )}
          {(rumor.prospectId || (isMobile && canSendWhatsapp)) && (
            <ActionIconDropdown
              label="Azioni sulla notizia"
              aria-label="Azioni sulla notizia"
            >
              {(isMobile && canSendWhatsapp) && (
                <SendWhatsappAction
                  phoneNumber={contact!.phoneNumber!}
                  name={contact!.name}
                  fetchMessages={rumorMessagesProvider(currentAgent)}
                >
                  {({ onClick }) => (
                    <Dropdown.Option
                      label="Invia whatsapp"
                      aria-label="Pulsante per inviare un messaggio Whatsapp"
                      onClick={onClick}
                    />
                  )}
                </SendWhatsappAction>
              )}
              {rumor.prospectId && (
                <Dropdown.Option
                  aria-label="Pulsante per visualizzare la valutazione"
                  label="Visualizza valutazione"
                  onClick={goToProspectPage}
                />
              )}
            </ActionIconDropdown>
          )}
        </>
      )}
      topContent={(
        <NoticeBoard notices={notices} />
      )}
      primarySide={(
        <Card>
          <Card.Header isSecondary>
            <UpdateRumorStatus
              rumor={rumor}
              onSuccess={() => mutateRumor()}
              emphasis="high"
              showIcon
            />
          </Card.Header>
          <Card.Content>
            <DetailItemList>
              {rumor.createdAt && (
                <DetailItemList.Item label="Data creazione">
                  {formatDate(new Date(rumor.createdAt))}
                </DetailItemList.Item>
              )}
              {showOriginAgentPreview && (
                <DetailItemList.Item
                  label="Aggiunta da"
                  loading={isRumorOriginAgentLoading}
                  fullWidth
                  skeleton={(
                    <Skeleton
                      aria-label="Informazioni agente in caricamento"
                      width="12rem"
                      height={theme.remHeight.S}
                    />
                  )}
                >
                  {rumorOriginAgent && (
                    <AgentPreview
                      {...toAgentPreviewProps(rumorOriginAgent)}
                      size="S"
                      expanded
                    />
                  )}
                </DetailItemList.Item>
              )}

              {canChangeAssignedAgent && rumorAgent && (
                <DetailItemList.Item
                  label="Agente"
                  withoutVPadding
                  fullWidth
                  loading={isRumorAgentLoading}
                  withoutHPadding
                  skeleton={(
                    <Skeleton
                      aria-label="Informazioni agente in caricamento"
                      width="12rem"
                      height={theme.remHeight.S}
                    />
                  )}
                >
                  <EditAssignedAgentAction
                    reference={rumor}
                    referenceType={ReferenceType.RUMOR}
                    onSuccess={() => mutateRumor()}
                  >
                    {({ openEditModal }) => (
                      <AgentPreview
                        {...toAgentPreviewProps(rumorAgent)}
                        size="S"
                        onClick={openEditModal}
                        expanded
                      />
                    )}
                  </EditAssignedAgentAction>
                </DetailItemList.Item>
              )}

              {!canChangeAssignedAgent && (
                <DetailItemList.Item
                  label="Agente"
                  loading={isRumorOriginAgentLoading}
                  aria-label="Agente incaricato"
                  fullWidth
                  skeleton={(
                    <Skeleton
                      aria-label="Informazioni agente in caricamento"
                      width="12rem"
                      height={theme.remHeight.S}
                    />
                  )}
                >
                  {rumorAgent && (
                    <AgentPreview
                      {...toAgentPreviewProps(rumorAgent)}
                      size="S"
                      expanded
                    />
                  )}
                </DetailItemList.Item>
              )}
            </DetailItemList>
          </Card.Content>

          <AssociateContactToRumorWidget
            rumor={rumor}
            onDisassociateSuccess={onContactChange}
            onAssociateSuccess={onContactChange}
            asNestedComponent
            canEdit
            canAddAdditionalServices={canManageContactAdditionalServicesAndConcessions}
            canDeleteAdditionalServices={canManageContactAdditionalServicesAndConcessions}
            canAddOptIns={canManageContactOptIns}
            canDeleteOptIns={canManageContactOptIns}
            canAddConcessions={canManageContactAdditionalServicesAndConcessions}
            canDeleteConcessions={canManageContactAdditionalServicesAndConcessions}
          />
          {canViewSellabilitySection && (
            <>
              <Card.Divider />
              <LocationSellabilityWidget
                latitude={rumor.latitude!.toString()}
                longitude={rumor.longitude!.toString()}
                propertySize={rumor.propertySize}
                asNestedComponent
              />
            </>
          )}

          {canViewMatchingSearchCriteria && rumor.status === RumorStatus.IN_PROGRESS && rumor?.isGeoComplete && (matchingSearchCriteriaCount > 0 || isMatchingCountersLoading) && (
            <>
              <Card.Divider />
              <Card.Content aria-label="Sezione matching">
                <DetailItemList>
                  <DetailItemList.Item
                    label="Matching"
                    aria-label="Matching"
                    withoutVPadding
                    loading={isMatchingCountersLoading}
                    skeleton={(
                      <Skeleton
                        aria-label="skeleton-matching-search-criteria-loading"
                        width="10rem"
                        height={theme.remHeight.S}
                      />
                    )}
                  >
                    {matchingSearchCriteriaCount > 0 && (
                      <Action
                        label={`${matchingSearchCriteriaCount} ${pluralizeWord('potenzial', 'e', 'i', matchingSearchCriteriaCount)} ${pluralizeWord('acquirent', 'e', 'i', matchingSearchCriteriaCount)}`}
                        aria-label="Visualizza i criteri di ricerca che matchano la notizia"
                        onClick={goToMatchingSearchCriteriaPage}
                        size="S"
                      />
                    )}
                  </DetailItemList.Item>
                </DetailItemList>
              </Card.Content>
            </>
          )}
        </Card>

      )}
      secondarySide={showNeighborhoodsSection && rumor.latitude && rumor.longitude && (
        <Card aria-label="Sezione nelle vicinanze">
          <Card.Header title="Nelle vicinanze" />
          <Card.Content>
            <Spacing padding={[0, 150]}>
              <HStack
                alignItems="center"
                justifyContent="space-between"
              >
                <Text.Body fontWeight={FontWeight.MEDIUM}>Immobiliare.it</Text.Body>
                <ViewNeighborhoodsAction latitude={rumor.latitude} longitude={rumor.longitude} />
              </HStack>
            </Spacing>
          </Card.Content>
        </Card>
      )}
    >
      <RumorWidget
        rumorId={rumor.id!}
        canEdit={canEditRumor}
      />

      <CalendarWidget
        contactId={rumor.contactId}
        agentId={rumor.agentId}
        filters={{
          referenceId: rumorId!.toString(),
          referenceType: ReferenceType.RUMOR,
        }}
        canAddReminder={canAddReminder}
        canEditReminder={canManageCalendarEvents}
        canDeleteReminder={canManageCalendarEvents}
      />
      <NotesWidget
        reference={rumor}
        referenceType={ReferenceType.RUMOR}
        canCreate={canManageNotes}
        canEdit={canManageNotes}
        canDelete={canManageNotes}
        onNotesUpdate={onNotesUpdate}
      />

      {showRumorDocumentsWidget && (
        <RumorDocumentsWidget
          rumorId={rumorId!}
          canManage={rumor.status === RumorStatus.IN_PROGRESS || rumor.status === RumorStatus.APPOINTMENT_SCHEDULED}
        />
      )}

    </RightColumnPageLayout>
  );
};

export default ViewRumorPage;

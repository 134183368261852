import React from 'react';
import {
  Action, ConfirmModal, Form, FormActionsAlign, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Rumor } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { useComputedRumorStatus } from '../../hooks/use-computed-rumor-status/useComputedRumorStatus';
import { RumorStatus } from '../../../domain/types';
import {
  ARCHIVE_RUMOR_ERROR_MESSAGE, ARCHIVE_RUMOR_FORM_DEFAULT_VALUES, ARCHIVE_RUMOR_SUCCESS_MESSAGE, archiveRumorFormValidationSchema, RUMOR_KO_STATUS_TO_ARCHIVE_RUMOR_ACTION_LABELS,
} from './constants';
import { updateRumor } from '../../../providers/api/rumor/rumorProvider';
import { RUMOR_KO_STATUSES, rumorStatusLabels } from '../../constants';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';

const { KO_DUPLICATE_LEAD, KO_NOT_CONFIRMED, KO_NOT_INTERESTED } = rumorStatusLabels;

type ChildFnProps = {
  isLoading: boolean,
  archiveRumor: VoidFunction,
};

export interface ArchiveRumorActionProps {
  rumor: Rumor,
  toStatus?: typeof RUMOR_KO_STATUSES[number],
  onSuccess?: (archivedRumor: Rumor) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const ArchiveRumorAction: React.FC<ArchiveRumorActionProps> = ({
  rumor,
  toStatus,
  onSuccess,
  children,
}) => {
  const modal = useModal<{ toStatus: typeof RUMOR_KO_STATUSES[number] }>();
  const [isSaving, setIsSaving] = React.useState(false);
  const { canBeUpdatedTo } = useComputedRumorStatus(rumor);
  const { addSuccess, addError } = useNotifications();

  const openArchiveRumorModal = React.useCallback(async () => {
    modal.open(toStatus ? { toStatus } : undefined);
  }, [modal, toStatus]);

  const archiveRumor = React.useCallback(async ({ status }: { status: RumorStatus }) => {
    try {
      setIsSaving(true);

      const rumorToArchive: Rumor = {
        ...rumor,
        status,
      };

      const archivedRumor = await updateRumor(rumor.id!, rumorToArchive);

      setIsSaving(false);
      addSuccess(ARCHIVE_RUMOR_SUCCESS_MESSAGE);
      modal.close();

      onSuccess?.(archivedRumor);
    } catch (_) {
      setIsSaving(false);
      addError(ARCHIVE_RUMOR_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, modal, onSuccess, rumor]);

  if (!canBeUpdatedTo(toStatus || 'ARCHIVED')) {
    return null;
  }

  return (
    <>
      {children
        ? children({ archiveRumor: openArchiveRumorModal, isLoading: isSaving })
        : (
          <Action
            label="Archivia"
            aria-label="Pulsante di archiviazione notizia"
            size="S"
            disabled={isSaving}
            onClick={openArchiveRumorModal}
          />
        )}
      <Portal>
        {!modal.data?.toStatus && (
          <SimpleModal
            {...modal}
            aria-label="Modale di archiviazione notizia"
            title="Archivia notizia"
          >
            <Form
              initialValues={ARCHIVE_RUMOR_FORM_DEFAULT_VALUES}
              loading={isSaving}
              onSubmit={archiveRumor}
              validationSchema={archiveRumorFormValidationSchema}
            >
              <Form.Item>
                <Form.Select
                  name="status"
                  label="Motivazione archiviazione"
                  aria-label="Campo per selezionare il motivo dell'archiviazione"
                  options={asSelectOptions({ KO_DUPLICATE_LEAD, KO_NOT_CONFIRMED, KO_NOT_INTERESTED })}
                />
              </Form.Item>
              <Form.Actions align={FormActionsAlign.RIGHT}>
                <Form.Submit label="Archivia" />
              </Form.Actions>
            </Form>
          </SimpleModal>
        )}
        {modal.data?.toStatus && (
          <ConfirmModal
            variant="critical"
            isOpen={modal.isOpen}
            saving={isSaving}
            aria-label={RUMOR_KO_STATUS_TO_ARCHIVE_RUMOR_ACTION_LABELS[modal.data.toStatus].modalTitle}
            title={RUMOR_KO_STATUS_TO_ARCHIVE_RUMOR_ACTION_LABELS[modal.data.toStatus].modalTitle}
            onConfirm={() => archiveRumor({ status: modal.data!.toStatus })}
            onAbort={() => modal.close()}
          >
            {RUMOR_KO_STATUS_TO_ARCHIVE_RUMOR_ACTION_LABELS[modal.data.toStatus].modalBody}
          </ConfirmModal>
        )}
      </Portal>
    </>
  );
};

export default ArchiveRumorAction;

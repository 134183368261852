import React from 'react';
import {
  Card, Grid, UserPreview,
  ActionIcon,
  ICON_EYE_OUTLINE,
  DetailStack,
} from '@doveit/bricks';
import { Contact } from '../../../providers/api/dtos';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { ReferenceType } from '../../../domain/types';
import { goToDetailPage } from '../../../utils/navigate/utils';

export interface ContactCardProps extends React.AriaAttributes {
  contact: Contact,
}

const ContactCard: React.FC<ContactCardProps> = ({
  contact,
  'aria-label': ariaLabel = 'Card con informazioni contatto',
  ...rest
}) => {
  const goToContactPage = React.useCallback(() => {
    goToDetailPage(contact.id!, ReferenceType.CONTACT, true);
  }, [contact.id]);

  return (
    <Card
      {...rest}
      aria-label={ariaLabel}
    >
      <Card.Header
        primaryActions={[
          <ActionIcon
            aria-label="Visualizza contatto"
            label="Visualizza"
            size="S"
            icon={{ path: ICON_EYE_OUTLINE }}
            onClick={goToContactPage}
          />,
        ]}
      >
        <UserPreview
          name={contact.name || NO_VALUE_SYMBOL}
          size="S"
        />
      </Card.Header>

      <Card.Content>
        <Grid gutter={150}>
          <Grid.Unit size={1 / 2}>
            <DetailStack label="Telefono">
              {contact.phoneNumber || NO_VALUE_SYMBOL}
            </DetailStack>
          </Grid.Unit>
          <Grid.Unit size={1 / 2}>
            <DetailStack label="Aggiunto">
              <DateViewer
                stringDate={contact.createdAt!}
                checkIfToday
              />
            </DetailStack>
          </Grid.Unit>
          <Grid.Unit>
            <DetailStack label="Email">
              {contact.email || NO_VALUE_SYMBOL}
            </DetailStack>
          </Grid.Unit>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default ContactCard;

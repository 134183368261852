import {
  endOfDay, startOfDay, addMonths,
} from 'date-fns';
import {
  ExtraPackageDoveIt, ExtraPackageSPF, ShootingStatus, Status,
} from '../../../domain/types';
import { FiltersMapper } from '../../../hooks/use-filters/useFilters';
import { SortOrder } from '../../../providers/pagination';
import { UserRole } from '../../../types';
import { removeNullAndUndefinedValues } from '../../../utils/array';
import {
  PropertySerpPageFilterKey, PropertySerpPageFilterOption, PropertySerpPageFilterOptionCtx, PropertySerpPageFilters, PropertySerpSortOption,
} from './types';

export const LOAD_SERP_PROPERTIES_ERROR_MESSAGE = 'Non è stato possibile recuperare gli immobili.';
export const NO_SERP_PROPERTIES = 'Non sono presenti immobili.';

export const DEFAULT_FILTER_OPTION = ({
  userIsAdmin,
  userIsAgent,
  userIsContentEditor,
}: PropertySerpPageFilterOptionCtx): PropertySerpPageFilterOption['key'] => ((userIsAdmin || userIsAgent || userIsContentEditor) ? 'toBeManaged' : 'all');

export const TO_BE_MANAGED_FILTER_OPTION = ({ mainUserRole }: PropertySerpPageFilterOptionCtx): PropertySerpPageFilterOption => ({
  key: 'toBeManaged',
  label: {
    short: 'Da gestire',
    long: 'Da gestire',
  },
  value: {
    toBeManagedBy: mainUserRole,
  },
});

export const ALL_PROPERTIES_FILTER_OPTION: PropertySerpPageFilterOption = {
  key: 'all',
  label: {
    short: 'Tutti',
    long: 'Tutti gli immobili',
  },
  value: {},
};

export const PORTFOLIO_FILTER_OPTION: PropertySerpPageFilterOption = {
  key: 'portfolio',
  label: {
    short: 'Portafoglio',
    long: 'Portafoglio',
  },
  value: {},
};

export const SHOOTING_TO_REVIEW_FILTER_OPTION: PropertySerpPageFilterOption = {
  key: 'shootingToReview',
  label: {
    short: 'Shooting da revisionare',
    long: 'Con shooting da revisionare',
  },
  value: {
    toBeManagedBy: UserRole.CONTENT_EDITOR,
    shootingStatus: ShootingStatus.BOOKED,
    status: Status.DRAFT,
  },
};

export const TO_BE_PROCESSED_FILTER_OPTION: PropertySerpPageFilterOption = {
  key: 'toBeProcessed',
  label: {
    short: 'Foto disponibili',
    long: 'Con foto disponibili',
  },
  value: {
    toBeManagedBy: UserRole.CONTENT_EDITOR,
    shootingStatus: [ShootingStatus.DELIVERED, ShootingStatus.COMPLETED],
    status: Status.DRAFT,
  },
};

export const DOCUMENT_TO_REVIEW_FILTER_OPTION: PropertySerpPageFilterOption = {
  key: 'documentToReview',
  label: {
    short: 'Documenti da revisionare',
    long: 'Con documenti da revisionare',
  },
  value: {
    status: Status.DRAFT,
    withInReviewMandatoryDocuments: true,
  },
};

export const ALL_TO_BE_MANAGED_FILTER_OPTION: PropertySerpPageFilterOption = {
  key: 'toBeManaged',
  label: {
    short: 'Tutti',
    long: 'Tutti da gestire',
  },
  value: {
    toBeManagedBy: UserRole.CONTENT_EDITOR,
  },
};

export const STATUS_FILTERS = ({
  userIsContentEditor,
  userIsAdmin,
}: PropertySerpPageFilterOptionCtx): PropertySerpPageFilterOption[] => {
  if (userIsContentEditor || userIsAdmin) {
    return [
      {
        key: 'draft',
        label: {
          short: 'In bozza',
          long: 'In bozza',
        },
        value: {
          status: Status.DRAFT,
        },
      },
      {
        key: 'anteprima',
        label: {
          short: 'Anteprima',
          long: 'Anteprima',
        },
        value: {
          status: Status.ANTEPRIMA,
        },
      },
      {
        key: 'live',
        label: {
          short: 'Pubblicato',
          long: 'Pubblicato',
        },
        value: {
          status: Status.LIVE,
        },
      },
      {
        key: 'trattativa',
        label: {
          short: 'Trattativa',
          long: 'Trattativa',
        },
        value: {
          status: Status.PROPOSTA,
        },
      },
      {
        key: 'ritirato',
        label: {
          short: 'Ritirato',
          long: 'Ritirato',
        },
        value: {
          status: Status.RITIRATO,
        },
      },
      {
        key: 'venduto',
        label: {
          short: 'Venduto',
          long: 'Venduto',
        },
        value: {
          status: Status.VENDUTO,
        },
      },
    ];
  }

  return [
    {
      key: 'active',
      label: {
        short: 'Attivi',
        long: 'Attivi',
      },
      value: {
        status: [Status.DRAFT, Status.ANTEPRIMA, Status.PROPOSTA, Status.LIVE],
      },
    },
    {
      key: 'inactive',
      label: {
        short: 'Non attivi',
        long: 'Non attivi',
      },
      value: {
        status: [Status.RITIRATO, Status.VENDUTO],
      },
    },
  ];
};

export const EXPIRE_FILTERS = (): PropertySerpPageFilterOption[] => [
  {
    key: 'expiredAssignment',
    label: {
      short: 'Scaduti',
      long: 'Scaduti',
    },
    value: {
      status: Status.LIVE,
      assignmentExpirationLt: startOfDay(new Date()),
    },
  },
  {
    key: 'expiringAssignment',
    label: {
      short: 'In scadenza',
      long: 'In scadenza',
    },
    value: {
      status: Status.LIVE,
      assignmentExpirationGt: startOfDay(new Date()),
      assignmentExpirationLt: addMonths(endOfDay(new Date()), 1),
    },
  },
];

export const PROPERTY_FILTERS = (): PropertySerpPageFilterOption[] => [
  PORTFOLIO_FILTER_OPTION,
  ALL_PROPERTIES_FILTER_OPTION,
];

export const TO_BE_MANAGED_FILTERS = (): PropertySerpPageFilterOption[] => [
  ALL_TO_BE_MANAGED_FILTER_OPTION,
  DOCUMENT_TO_REVIEW_FILTER_OPTION,
  SHOOTING_TO_REVIEW_FILTER_OPTION,
  TO_BE_PROCESSED_FILTER_OPTION,
];

export const EXTRA_PACKAGES_FILTERS = (): PropertySerpPageFilterOption[] => [
  {
    key: 'activeWithExtraVisibility',
    label: {
      short: 'Attivi con visibilità extra',
      long: 'Attivi con visibilità extra',
    },
    value: {
      status: [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE, Status.PROPOSTA],
      extraPackages: ExtraPackageDoveIt.EXTRA_VISIBILITY,
    },
  },
  {
    key: 'activeWithInteriorRestyling',
    label: {
      short: 'Attivi con restyling interno',
      long: 'Attivi con restyling interno',
    },
    value: {
      status: [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE, Status.PROPOSTA],
      extraPackages: ExtraPackageDoveIt.INTERIOR_RESTYLING,
    },
  },
  {
    key: 'allWithExtraVisibility',
    label: {
      short: 'Tutti con visibilità extra',
      long: 'Con visibilità extra',
    },
    value: {
      extraPackages: ExtraPackageDoveIt.EXTRA_VISIBILITY,
    },
  },
  {
    key: 'allWithInteriorRestyling',
    label: {
      short: 'Tutti con restyling interno',
      long: 'Con restyling interno',
    },
    value: {
      extraPackages: ExtraPackageDoveIt.INTERIOR_RESTYLING,
    },
  },
  {
    key: 'allWithExtraPackages',
    label: {
      short: 'Tutti con pacchetti',
      long: 'Con pacchetti',
    },
    value: {
      extraPackages: [...Object.values(ExtraPackageDoveIt), ...Object.values(ExtraPackageSPF)],
    },
  },
];

export const FILTER_OPTIONS = (ctx: PropertySerpPageFilterOptionCtx): PropertySerpPageFilterOption[] => removeNullAndUndefinedValues([
  ...(ctx.userIsContentEditor ? TO_BE_MANAGED_FILTERS() : []),
  ...(ctx.userIsAdmin || ctx.userIsAgent ? [TO_BE_MANAGED_FILTER_OPTION(ctx)] : []),
  ...STATUS_FILTERS(ctx),
  ...(ctx.userIsAdmin || ctx.userIsContentEditor ? EXTRA_PACKAGES_FILTERS() : []),
  ...EXPIRE_FILTERS(),
  // eslint-disable-next-line no-nested-ternary
  ...(ctx.userIsAgent ? (!ctx.isTrial ? PROPERTY_FILTERS() : [PORTFOLIO_FILTER_OPTION]) : [ALL_PROPERTIES_FILTER_OPTION]),
]);

export const GROUPED_FILTER_OPTIONS = ({
  userIsContentEditor,
  userIsAgent,
  userIsAdmin,
  isTrial,
}: PropertySerpPageFilterOptionCtx) => removeNullAndUndefinedValues([
  userIsContentEditor ? {
    label: 'Da gestire',
    options: TO_BE_MANAGED_FILTERS,
  } : undefined,
  {
    label: 'Stato',
    options: STATUS_FILTERS,
  },
  (userIsAdmin || userIsContentEditor) ? {
    label: 'Pacchetti',
    options: EXTRA_PACKAGES_FILTERS,
  } : undefined,
  {
    label: 'Scadenza',
    options: EXPIRE_FILTERS,
  },
  (userIsAgent && !isTrial) ? {
    label: 'Immobili',
    options: PROPERTY_FILTERS,
  } : undefined,
]);

export const DEFAULT_SORT_OPTION: PropertySerpSortOption = {
  key: 'default',
  label: 'In evidenza',
} as const;

export const MOST_RECENT_CREATION_SORT_OPTION: PropertySerpSortOption = {
  key: 'mostRecentPropertyCreation',
  label: 'Creazione più recente',
  value: {
    createdAt: SortOrder.DESC,
  },
} as const;

export const LEAST_RECENT_CREATION_SORT_OPTION: PropertySerpSortOption = {
  key: 'leastRecentPropertyCreation',
  label: 'Creazione meno recente',
  value: {
    createdAt: SortOrder.ASC,
  },
} as const;

export const MOST_RECENT_UPDATE_SORT_OPTION: PropertySerpSortOption = {
  key: 'mostRecentPropertyUpdate',
  label: 'Aggiornamento più recente',
  value: {
    updatedAt: SortOrder.DESC,
  },
};

export const LEAST_RECENT_UPDATE_SORT_OPTION: PropertySerpSortOption = {
  key: 'leastRecentPropertyUpdate',
  label: 'Aggiornamento meno recente',
  value: {
    updatedAt: SortOrder.ASC,
  },
};

export const MOST_RECENT_EXPIRATION_SORT_OPTION: PropertySerpSortOption = {
  key: 'mostRecentExpiration',
  label: 'Scadenza decrescente',
  value: {
    assignmentEndDate: SortOrder.DESC,
  },
};

export const LEAST_RECENT_EXPIRATION_SORT_OPTION: PropertySerpSortOption = {
  key: 'leastRecentExpiration',
  label: 'Scadenza crescente',
  value: {
    assignmentEndDate: SortOrder.ASC,
  },
};

export const HIGHEST_PRICE_SORT_OPTION: PropertySerpSortOption = {
  key: 'highestPrice',
  label: 'Prezzo più alto',
  value: {
    price: SortOrder.DESC,
  },
};

export const LOWEST_PRICE_SORT_OPTION: PropertySerpSortOption = {
  key: 'lowestPrice',
  label: 'Prezzo più basso',
  value: {
    price: SortOrder.ASC,
  },
};

export const SORT_OPTIONS_PER_FILTER: Record<PropertySerpPageFilterKey, PropertySerpSortOption> = {
  toBeManaged: MOST_RECENT_UPDATE_SORT_OPTION,
  all: MOST_RECENT_CREATION_SORT_OPTION,
  portfolio: MOST_RECENT_CREATION_SORT_OPTION,
  active: MOST_RECENT_CREATION_SORT_OPTION,
  inactive: MOST_RECENT_UPDATE_SORT_OPTION,
  live: MOST_RECENT_CREATION_SORT_OPTION,
  draft: MOST_RECENT_CREATION_SORT_OPTION,
  trattativa: MOST_RECENT_CREATION_SORT_OPTION,
  anteprima: MOST_RECENT_CREATION_SORT_OPTION,
  venduto: MOST_RECENT_UPDATE_SORT_OPTION,
  ritirato: MOST_RECENT_UPDATE_SORT_OPTION,
  expiredAssignment: LEAST_RECENT_EXPIRATION_SORT_OPTION,
  expiringAssignment: LEAST_RECENT_EXPIRATION_SORT_OPTION,
  shootingToReview: MOST_RECENT_UPDATE_SORT_OPTION,
  toBeProcessed: MOST_RECENT_UPDATE_SORT_OPTION,
  documentToReview: MOST_RECENT_UPDATE_SORT_OPTION,
  activeWithExtraVisibility: MOST_RECENT_CREATION_SORT_OPTION,
  activeWithInteriorRestyling: MOST_RECENT_CREATION_SORT_OPTION,
  allWithExtraPackages: MOST_RECENT_CREATION_SORT_OPTION,
  allWithExtraVisibility: MOST_RECENT_CREATION_SORT_OPTION,
  allWithInteriorRestyling: MOST_RECENT_CREATION_SORT_OPTION,
};

export const SORT_OPTIONS: PropertySerpSortOption[] = [
  DEFAULT_SORT_OPTION,
  MOST_RECENT_CREATION_SORT_OPTION,
  LEAST_RECENT_CREATION_SORT_OPTION,
  MOST_RECENT_UPDATE_SORT_OPTION,
  LEAST_RECENT_UPDATE_SORT_OPTION,
  MOST_RECENT_EXPIRATION_SORT_OPTION,
  LEAST_RECENT_EXPIRATION_SORT_OPTION,
  HIGHEST_PRICE_SORT_OPTION,
  LOWEST_PRICE_SORT_OPTION,
] as const;

export const FILTERS_MAPPER = (ctx: PropertySerpPageFilterOptionCtx): FiltersMapper<PropertySerpPageFilters> => ({
  q: (v) => v,
  filter: (v) => FILTER_OPTIONS(ctx).find(({
    key,
  }) => key === v)?.key || DEFAULT_FILTER_OPTION(ctx),
  sort: (v) => SORT_OPTIONS.find(({
    key,
  }) => key === v)?.key || DEFAULT_SORT_OPTION.key,
});

import {
  ICON_ALERT_CIRCLE_OUTLINE, ICON_CHECK_CIRCLE_OUTLINE, ICON_PROGRESS_CHECK,
} from '@doveit/bricks';
import { BadgeColor } from '@doveit/bricks/dist/typings/components/badge/types';
import { ComputedOfferStatus, DigitalOfferStatus, OfferStatus } from '../../../domain/types';
import { SignatureStatus } from '../../../providers/api/dtos';

export enum UpdateOfferStatusAction {
  CONFIRM_OFFER = 'CONFIRM_OFFER',
  CONFIRM_DEED = 'CONFIRM_DEED',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  DELETE_REQUEST = 'DELETE_REQUEST',
  REFUSED_OFFER = 'REFUSED_OFFER',
  WITHDRAW_OFFER = 'WITHDRAW_OFFER',
  ARCHIVE_FOR_MORTGAGE = 'ARCHIVE_FOR_MORTGAGE',
  ARCHIVE_FOR_CONSTRAINT = 'ARCHIVE_FOR_CONSTRAINT',
}

export const computedOfferStatusActionIcon: Record<ComputedOfferStatus, string> = {
  WAITING_FOR_SIGNATURE: ICON_PROGRESS_CHECK,
  IN_PROGRESS: ICON_PROGRESS_CHECK,
  WAITING_FOR_ACCEPTANCE: ICON_PROGRESS_CHECK,
  ACCEPTED: ICON_PROGRESS_CHECK,
  CONFIRMED: ICON_PROGRESS_CHECK,
  DEED_DONE: ICON_CHECK_CIRCLE_OUTLINE,
  REFUSED: ICON_ALERT_CIRCLE_OUTLINE,
  WITHDRAWN: ICON_ALERT_CIRCLE_OUTLINE,
  MORTGAGE_REFUSED: ICON_ALERT_CIRCLE_OUTLINE,
  OTHER_CONSTRAINTS: ICON_ALERT_CIRCLE_OUTLINE,
};

export const computedOfferStatusActionColors: Record<ComputedOfferStatus, BadgeColor> = {
  WAITING_FOR_SIGNATURE: 'info',
  IN_PROGRESS: 'warning',
  WAITING_FOR_ACCEPTANCE: 'success',
  ACCEPTED: 'success',
  CONFIRMED: 'success',
  DEED_DONE: 'success',
  REFUSED: 'critical',
  WITHDRAWN: 'critical',
  MORTGAGE_REFUSED: 'critical',
  OTHER_CONSTRAINTS: 'critical',
};

export const computedOfferStatusAction: (signatureStatus: SignatureStatus | undefined) => Record<ComputedOfferStatus, UpdateOfferStatusAction[]> = (signatureStatus) => ({
  [DigitalOfferStatus.WAITING_FOR_SIGNATURE]: [signatureStatus === SignatureStatus.ONGOING ? UpdateOfferStatusAction.CANCEL_REQUEST : UpdateOfferStatusAction.DELETE_REQUEST],
  [OfferStatus.IN_PROGRESS]: [UpdateOfferStatusAction.REFUSED_OFFER, UpdateOfferStatusAction.WITHDRAW_OFFER],
  [DigitalOfferStatus.WAITING_FOR_ACCEPTANCE]: [UpdateOfferStatusAction.REFUSED_OFFER, UpdateOfferStatusAction.WITHDRAW_OFFER],
  [OfferStatus.ACCEPTED]: [UpdateOfferStatusAction.CONFIRM_OFFER, UpdateOfferStatusAction.ARCHIVE_FOR_MORTGAGE, UpdateOfferStatusAction.ARCHIVE_FOR_CONSTRAINT],
  [OfferStatus.CONFIRMED]: [UpdateOfferStatusAction.CONFIRM_DEED],
  [OfferStatus.REFUSED]: [],
  [OfferStatus.WITHDRAWN]: [],
  [OfferStatus.MORTGAGE_REFUSED]: [],
  [OfferStatus.OTHER_CONSTRAINTS]: [],
  DEED_DONE: [],
});

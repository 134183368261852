/* eslint-disable react/no-unknown-property */
import {
  ActionIcon, Popover, Card, Dropdown, FontWeight, HStack, ICON_EYE_OUTLINE, Stack, Text, UserPreview, DetailStack, ICON_BELL_OUTLINE, ICON_CALENDAR_OUTLINE,
} from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import React from 'react';
import { isFuture, isSameMinute } from 'date-fns';
import { AgentPreview } from '../../../agent/components';
import UpdateIntentStatus from '../update-intent-status/UpdateIntentStatus';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { IntentStatus, ReferenceType } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import UpdateOfferStatus from '../../../offer/containers/update-offer-status/UpdateOfferStatus';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import {
  Appointment, Intent, Reminder, SerpIntent,
} from '../../../providers/api/dtos';
import UpdateAppointmentStatus from '../../../reminders/components/update-appointment-status/UpdateAppointmentStatus';
import { goToDetailPage } from '../../../utils/navigate/utils';
import * as styles from './ViewSerpIntentCard.style';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import ViewContactPopoverPreview from '../../../contact/containers/view-contact-popover-preview/ViewContactPopoverPreview';
import { ReferenceEntityWithNotes, UserRole } from '../../../types';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import UpsertReminderAction from '../../../reminders/containers/upsert-reminder-action/UpsertReminderAction';
import UpsertNoteAction from '../../../notes/containers/upsert-note-action-v2/UpsertNoteAction';
import { updateIntent } from '../../../providers/api/intent/intentProvider';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';
import { intentMessagesProvider } from '../../../containers/send-whatsapp-action/messages/intentMessagesProvider';
import { buildWhatsappMessageSentNote } from '../../utils/buildWhatsappMessageSentNote';
import useMarkIntentAsSeen from '../../../hooks/use-mark-intent-as-seen/useMarkIntentAsSeen';
import Dot from '../../../components/dot/Dot';
import { buildOfferDateToDisplay } from '../../../offer/utils';
import PropertyPreview from '../../../property/components/property-preview/PropertyPreview';

const MAX_NOTE_LENGTH = 100;

export interface ViewSerpIntentCardProps extends React.AriaAttributes {
  serpIntent: SerpIntent,
  onIntentUpdate?: (updatedIntent: Intent) => void,
  onAppointmentUpdate?: (updatedAppointment: Appointment) => void,
  onOfferUpdate?: VoidFunction,
  onReminderCreated?: (reminder: Reminder) => void,
}

const ViewSerpIntentCard: React.FC<ViewSerpIntentCardProps> = ({
  serpIntent,
  onIntentUpdate,
  onAppointmentUpdate,
  onOfferUpdate,
  onReminderCreated,
  ...rest
}) => {
  const {
    intent, property, agent, contact, appointment, offer,
  } = serpIntent;

  const {
    user, mainUserRole, userIsAdmin, userIsCallCenter,
  } = useRBAC();

  const { markAsSeen } = useMarkIntentAsSeen(serpIntent.agent?.id);

  const userIsIntentAgentOrManager = useCurrentAgentIsSameAgentOrManager(agent?.id);
  const userIsAdminOrOwnerOrManager = React.useMemo(
    () => userIsAdmin || userIsIntentAgentOrManager,
    [userIsAdmin, userIsIntentAgentOrManager],
  );
  const userIsAdminOrCallCenterOrOwnerOrManager = React.useMemo(
    () => userIsCallCenter || userIsAdminOrOwnerOrManager,
    [userIsAdminOrOwnerOrManager, userIsCallCenter],
  );

  const showUpdatedAt = React.useMemo(
    () => !isSameMinute(intent.createdAt!, intent.updatedAt!),
    [intent.createdAt, intent.updatedAt],
  );

  const reminderToCreate: Reminder = React.useMemo(() => ({
    agentId: serpIntent.agent!.id!,
    referenceId: intent.id!,
    referenceType: ReferenceType.INTENT,
    resolved: false,
  } as Reminder), [intent.id, serpIntent.agent]);

  const goToIntentPage = React.useCallback(() => {
    goToDetailPage(intent.id!, ReferenceType.INTENT, true);
  }, [intent.id]);

  const dateToDisplayWithOffer = React.useMemo(() => {
    if (!offer) return undefined;

    return buildOfferDateToDisplay(offer);
  }, [offer]);

  const showReminder = React.useMemo(
    () => intent.status === IntentStatus.IN_PROGRESS && !!serpIntent.reminder,
    [intent.status, serpIntent.reminder],
  );

  const noteToDisplay = React.useMemo(
    () => intent.notes
      ?.filter(({ role }) => role !== UserRole.CUSTOMER)
      .at(0),
    [intent.notes],
  );

  const showNote = React.useMemo(
    () => intent.status === IntentStatus.IN_PROGRESS && !showReminder && !!noteToDisplay,
    [intent.status, noteToDisplay, showReminder],
  );
  const handleReminderCreated = React.useCallback(async (reminder: Reminder) => {
    await markAsSeen(intent, { onSuccess: onIntentUpdate });

    onReminderCreated?.(reminder);
  }, [intent, markAsSeen, onIntentUpdate, onReminderCreated]);

  const onNoteCreated = React.useCallback(async (intentWithNote: ReferenceEntityWithNotes) => {
    try {
      const updatedIntent = await markAsSeen(intentWithNote as Intent);
      onIntentUpdate?.(updatedIntent);
    } catch (err) { /* empty */ }
  }, [markAsSeen, onIntentUpdate]);

  const onMessageSent = React.useCallback(async (message: string) => {
    if (!intent) return;

    let updatedIntent;

    updatedIntent = await updateIntent(intent.id!, {
      ...intent,
      notes: [{
        text: buildWhatsappMessageSentNote(message),
        author: user.name,
        role: mainUserRole,
      }, ...(intent.notes || [])],
    });

    updatedIntent = await markAsSeen(updatedIntent);

    onIntentUpdate?.(updatedIntent);
  }, [intent, mainUserRole, markAsSeen, onIntentUpdate, user.name]);

  return (
    <Card {...rest}>
      <Card.Header
        primaryActions={[
          !intent.seen && (
            <Dot size="S" aria-label="Interesse segnato come non visto" />
          ),
          userIsAdminOrCallCenterOrOwnerOrManager && (
            <ActionIcon
              aria-label="Visualizza interesse"
              label="Visualizza"
              size="S"
              icon={{ path: ICON_EYE_OUTLINE }}
              onClick={goToIntentPage}
            />
          )]}
        secondaryActions={[
          userIsAdminOrOwnerOrManager && contact.phoneNumber && (
            <SendWhatsappAction
              phoneNumber={contact.phoneNumber}
              name={contact.name}
              fetchMessages={intentMessagesProvider(intent, agent)}
              onMessageSent={onMessageSent}
            >
              {({ onClick }) => (
                <Dropdown.Option
                  label="Invia whatsapp"
                  onClick={onClick}
                />
              )}
            </SendWhatsappAction>
          ),
          userIsAdminOrOwnerOrManager && (
            <UpsertReminderAction
              reminder={reminderToCreate}
              onSuccess={handleReminderCreated}
              aria-label="Pulsante per aggiungere un promemoria"
            >
              {({ upsert }) => (
                <Dropdown.Option
                  label="Aggiungi promemoria"
                  onClick={upsert}
                />
              )}
            </UpsertReminderAction>
          ),
          <UpsertNoteAction
            reference={intent}
            referenceType={ReferenceType.INTENT}
            onSuccess={onNoteCreated}
            aria-label="Pulsante per aggiungere una nota"
          >
            {({ upsert }) => (
              <Dropdown.Option
                label="Aggiungi nota"
                onClick={upsert}
              />
            )}
          </UpsertNoteAction>,
        ]}
      >
        <PropertyPreview
          property={property}
          showMatchingAction={userIsAdminOrOwnerOrManager}
        />
      </Card.Header>

      <Card.Content aria-label="Informazioni interesse">
        <Stack gap={150}>
          <div>
            <UpdateIntentStatus
              intent={intent}
              size="XS"
              showAsBadge={!userIsAdminOrCallCenterOrOwnerOrManager}
              onSuccess={onIntentUpdate}
            />
          </div>

          <HStack gap={150}>
            <HStack
              gap={100}
              wrap="nowrap"
              css={styles.firstRowWrapper}
            >
              <HStack gap={150} wrap="nowrap">
                <ViewContactPopoverPreview
                  contact={contact}
                  trigger={(
                    <div css={styles.userPreview}>
                      <UserPreview
                        name={contact.name || NO_VALUE_SYMBOL}
                        size="S"
                      >
                        {contact.phoneNumber}
                      </UserPreview>
                    </div>
                  )}
                />
                <DetailStack label={showUpdatedAt ? 'Aggiornato' : 'Aggiunto'}>
                  <DateViewer
                    stringDate={showUpdatedAt ? intent.updatedAt! : intent.createdAt!}
                    checkIfToday
                    checkIfTomorrow
                  />
                </DetailStack>
              </HStack>

              {agent && (
                <ViewAgentPopoverPreview
                  agent={agent}
                  trigger={(
                    <AgentPreview
                      {...toAgentPreviewProps(agent, { omit: { name: true } })}
                      size="S"
                    />
                  )}
                />
              )}
            </HStack>

            {(!appointment && !offer && showReminder && (
              <HStack
                gap={150}
                css={styles.secondRowWrapper}
                aria-label="Dettagli promemoria"
              >
                <div css={styles.dateWrapper}>
                  <Stack gap={25}>
                    <DetailStack
                      label="Promemoria"
                      icon={ICON_BELL_OUTLINE}
                    >
                      <DateViewer
                        stringDate={serpIntent.reminder!.startDate}
                        checkIfToday
                        checkIfTomorrow
                        withHour={isFuture(serpIntent.reminder!.startDate)}
                      />
                    </DetailStack>
                  </Stack>
                </div>
                <Popover
                  aria-label="Visualizza dettagli promemoria"
                  trigger={(
                    <Text.Caption>
                      {truncateTextWithSuffix(serpIntent.reminder!.notes, MAX_NOTE_LENGTH)}
                    </Text.Caption>
                  )}
                  hasArrow
                >
                  <div css={styles.noteAndReminderPopoverWrapper}>
                    <Text.Caption>{serpIntent.reminder!.notes}</Text.Caption>
                  </div>
                </Popover>
              </HStack>
            ))}

            {(!appointment && !offer && showNote && (
              <Popover
                aria-label="Visualizza dettagli note"
                css={styles.secondRowWrapper}
                trigger={(
                  <Text.Caption>
                    {truncateTextWithSuffix(noteToDisplay!.text, MAX_NOTE_LENGTH)}
                  </Text.Caption>
                )}
                hasArrow
              >
                <div css={styles.noteAndReminderPopoverWrapper}>
                  <Text.Body fontWeight={FontWeight.REGULAR}>{noteToDisplay?.author}</Text.Body>
                  <Text.BodySmall>{noteToDisplay!.text}</Text.BodySmall>
                </div>
              </Popover>
            ))}

            {(appointment || offer) && (
              <HStack gap={150} css={styles.secondRowWrapper}>
                {!offer && appointment && (
                  <div css={styles.dateWrapper}>
                    <DetailStack
                      label="Appuntamento"
                      icon={ICON_CALENDAR_OUTLINE}
                    >
                      <DateViewer
                        stringDate={appointment.startDate}
                        checkIfToday
                        checkIfTomorrow
                        withHour={isFuture(appointment.startDate)}
                      />
                    </DetailStack>
                  </div>
                )}

                {!offer && appointment && (
                  <UpdateAppointmentStatus
                    appointment={appointment}
                    onSuccess={onAppointmentUpdate}
                    showAsBadge={!userIsAdminOrCallCenterOrOwnerOrManager}
                  />
                )}

                {offer && dateToDisplayWithOffer && (
                  <div css={styles.dateWrapper}>
                    <DetailStack
                      aria-label="Data"
                      label={dateToDisplayWithOffer.label}
                    >
                      <DateViewer
                        stringDate={dateToDisplayWithOffer.value}
                        checkIfToday
                        checkIfTomorrow
                      />
                    </DetailStack>
                  </div>
                )}

                {offer && (
                  <>
                    <UpdateOfferStatus
                      lastOffer={offer}
                      intentId={offer.intentId}
                      size="XS"
                      onSuccess={onOfferUpdate}
                      showAsBadge={!userIsAdminOrCallCenterOrOwnerOrManager}
                    />
                    <DetailStack
                      aria-label="Valore della proposta"
                      label="Proposta"
                    >
                      {formatEuro(offer.value)}
                    </DetailStack>
                  </>
                )}
              </HStack>
            )}
          </HStack>
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default ViewSerpIntentCard;

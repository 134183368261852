/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Popover, ActionIcon, ActionIconDropdown, Avatar, Dropdown, FontWeight, HStack, ICON_EYE_OUTLINE, Text, UserPreview,
  DetailStack,
  ICON_CALENDAR_OUTLINE,
  ICON_BELL_OUTLINE,
} from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import { isFuture, isSameMinute } from 'date-fns';
import {
  Appointment, Intent, Reminder, SerpIntent,
} from '../../../providers/api/dtos';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import UpdateIntentStatus from '../update-intent-status/UpdateIntentStatus';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { IntentStatus, ReferenceType } from '../../../domain/types';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import DateViewer from '../../../components/date-viewer/DateViewer';
import UpdateOfferStatus from '../../../offer/containers/update-offer-status/UpdateOfferStatus';
import UpdateAppointmentStatus from '../../../reminders/components/update-appointment-status/UpdateAppointmentStatus';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import * as styles from './ViewSerpIntentRow.style';
import ViewContactPopoverPreview from '../../../contact/containers/view-contact-popover-preview/ViewContactPopoverPreview';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import { ReferenceEntityWithNotes, UserRole } from '../../../types';
import UpsertReminderAction from '../../../reminders/containers/upsert-reminder-action/UpsertReminderAction';
import UpsertNoteAction from '../../../notes/containers/upsert-note-action-v2/UpsertNoteAction';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';
import { intentMessagesProvider } from '../../../containers/send-whatsapp-action/messages/intentMessagesProvider';
import { buildWhatsappMessageSentNote } from '../../utils/buildWhatsappMessageSentNote';
import useMarkIntentAsSeen from '../../../hooks/use-mark-intent-as-seen/useMarkIntentAsSeen';
import { updateIntent } from '../../../providers/api/intent/intentProvider';
import Dot from '../../../components/dot/Dot';
import { buildOfferDateToDisplay } from '../../../offer/utils';
import PropertyPreview from '../../../property/components/property-preview/PropertyPreview';
import { CELL_DATE_HOUR_WIDTH, CELL_DATE_WIDTH, CELL_PRICE_WIDTH } from '../../../constants';

const MAX_NOTE_LENGTH = 100;
const MAX_REMINDER_NOTE_LENGTH = 90;

export interface ViewSerpIntentRowProps extends React.AriaAttributes {
  serpIntent: SerpIntent,
  onIntentUpdate?: (updatedIntent: Intent) => void,
  onAppointmentUpdate?: (updatedAppointment: Appointment) => void,
  onOfferUpdate?: VoidFunction,
  onReminderCreated?: (reminder: Reminder) => void,
}

const ViewSerpIntentRow: React.FC<ViewSerpIntentRowProps> = ({
  serpIntent,
  onIntentUpdate,
  onAppointmentUpdate,
  onOfferUpdate,
  onReminderCreated,
  ...rest
}) => {
  const {
    intent, contact, property, offer, appointment, agent,
  } = serpIntent;

  const {
    user, mainUserRole, userIsAdmin, userIsCallCenter,
  } = useRBAC();
  const { markAsSeen } = useMarkIntentAsSeen(serpIntent.agent?.id);

  const userIsIntentAgentOrManager = useCurrentAgentIsSameAgentOrManager(serpIntent.agent?.id);
  const userIsAdminOrOwnerOrManager = React.useMemo(
    () => userIsAdmin || userIsIntentAgentOrManager,
    [userIsIntentAgentOrManager, userIsAdmin],
  );
  const userIsAdminOrCallCenterOrOwnerOrManger = React.useMemo(
    () => userIsCallCenter || userIsAdminOrOwnerOrManager,
    [userIsAdminOrOwnerOrManager, userIsCallCenter],
  );

  const showUpdatedAt = React.useMemo(
    () => !isSameMinute(intent.updatedAt!, intent.createdAt!),
    [intent.createdAt, intent.updatedAt],
  );

  const reminderToCreate: Reminder = React.useMemo(() => ({
    agentId: serpIntent.agent!.id!,
    referenceId: intent.id!,
    referenceType: ReferenceType.INTENT,
    resolved: false,
  } as Reminder), [intent.id, serpIntent.agent]);

  const goToIntentPage = React.useCallback(() => {
    goToDetailPage(intent.id!, ReferenceType.INTENT);
  }, [intent]);

  const dateToDisplayWithOffer = React.useMemo(() => {
    if (!offer) return undefined;

    return buildOfferDateToDisplay(offer);
  }, [offer]);

  const showReminder = React.useMemo(
    () => intent.status === IntentStatus.IN_PROGRESS && !!serpIntent.reminder,
    [intent.status, serpIntent.reminder],
  );

  const noteToDisplay = React.useMemo(
    () => intent.notes
      ?.filter(({ role }) => role !== UserRole.CUSTOMER)
      .at(0),
    [intent.notes],
  );

  const showNote = React.useMemo(
    () => intent.status === IntentStatus.IN_PROGRESS && !showReminder && !!noteToDisplay,
    [intent.status, noteToDisplay, showReminder],
  );
  const handleReminderCreated = React.useCallback(async (reminder: Reminder) => {
    await markAsSeen(intent, { onSuccess: onIntentUpdate });

    onReminderCreated?.(reminder);
  }, [intent, markAsSeen, onIntentUpdate, onReminderCreated]);

  const onNoteCreated = React.useCallback(async (intentWithNote: ReferenceEntityWithNotes) => {
    try {
      const updatedIntent = await markAsSeen(intentWithNote as Intent);
      onIntentUpdate?.(updatedIntent);
    } catch (err) { /* empty */ }
  }, [markAsSeen, onIntentUpdate]);

  const onMessageSent = React.useCallback(async (message: string) => {
    if (!intent) return;

    let updatedIntent;

    updatedIntent = await updateIntent(intent.id!, {
      ...intent,
      notes: [{
        text: buildWhatsappMessageSentNote(message),
        author: user.name,
        role: mainUserRole,
      }, ...(intent.notes || [])],
    });

    updatedIntent = await markAsSeen(updatedIntent);

    onIntentUpdate?.(updatedIntent);
  }, [intent, mainUserRole, markAsSeen, onIntentUpdate, user.name]);

  return (
    <SimpleTable.Row {...rest}>
      <SimpleTable.Cell
        aria-label="Contatto"
        width="9rem"
      >
        <ViewContactPopoverPreview
          contact={contact}
          trigger={(
            <div css={styles.userWrapper}>
              <UserPreview
                name={contact.name || NO_VALUE_SYMBOL}
                size="S"
              >
                {contact.phoneNumber}
              </UserPreview>
            </div>
          )}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Immobile"
        width="12rem"
      >
        <PropertyPreview
          property={property}
          showMatchingAction={userIsAdminOrOwnerOrManager}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Stato dell'interesse"
        width="12rem"
      >
        <UpdateIntentStatus
          showAsBadge={!userIsAdminOrCallCenterOrOwnerOrManger}
          intent={intent}
          size="XS"
          onSuccess={onIntentUpdate}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Agente"
        width="2rem"
      >
        {serpIntent.agent && (
          <ViewAgentPopoverPreview
            agent={serpIntent.agent}
            trigger={(
              <AgentPreview
                {...toAgentPreviewProps(serpIntent.agent, { omit: { name: true } })}
                size="S"
                expanded
              />
            )}
          />
        )}
        {!serpIntent.agent && (
          <Avatar size="S" />
        )}
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Data"
        width={CELL_DATE_WIDTH}
      >
        <DetailStack
          label={showUpdatedAt ? 'Aggiornato' : 'Aggiunto'}
        >
          <DateViewer
            stringDate={showUpdatedAt ? intent.updatedAt! : intent.createdAt!}
            checkIfToday
          />
        </DetailStack>
      </SimpleTable.Cell>
      {!offer && appointment && (
        <SimpleTable.Cell
          aria-label="Appuntamento"
          withDivider
        >
          <HStack gap={200}>
            <DetailStack
              label="Appuntamento"
              icon={ICON_CALENDAR_OUTLINE}
              width={CELL_DATE_HOUR_WIDTH}
            >
              <DateViewer
                stringDate={appointment.startDate}
                checkIfToday
                checkIfTomorrow
                withHour={isFuture(appointment.startDate)}
              />
            </DetailStack>
            <UpdateAppointmentStatus
              appointment={appointment}
              onSuccess={onAppointmentUpdate}
              showAsBadge={!userIsAdminOrCallCenterOrOwnerOrManger}
            />
          </HStack>
        </SimpleTable.Cell>
      )}

      {offer && (
        <SimpleTable.Cell
          aria-label="Proposta"
          withDivider
        >
          <HStack gap={200}>
            {dateToDisplayWithOffer && (
              <DetailStack
                label={dateToDisplayWithOffer.label}
                aria-label="Data"
                color={dateToDisplayWithOffer.color}
                width={CELL_DATE_WIDTH}
              >
                <DateViewer
                  stringDate={dateToDisplayWithOffer.value!}
                  checkIfToday
                  checkIfTomorrow
                />
              </DetailStack>
            )}
            <UpdateOfferStatus
              intentId={intent.id!}
              lastOffer={offer}
              onSuccess={onOfferUpdate}
              showAsBadge={!userIsAdminOrOwnerOrManager}
              showShortLabel
              size="XS"
            />
            <DetailStack
              label="Proposta"
              aria-label="Valore della proposta"
              width={CELL_PRICE_WIDTH}
            >
              {formatEuro(offer.value)}
            </DetailStack>
          </HStack>
        </SimpleTable.Cell>
      )}

      {!offer && !appointment && (
        <SimpleTable.Cell withDivider={showNote || showReminder}>
          {showReminder && (
            <HStack
              gap={200}
              wrap="nowrap"
              aria-label="Dettagli promemoria"
            >
              <DetailStack
                label="Promemoria"
                icon={ICON_BELL_OUTLINE}
                width={CELL_DATE_HOUR_WIDTH}
              >
                <DateViewer
                  stringDate={serpIntent.reminder!.startDate}
                  checkIfToday
                  checkIfTomorrow
                  withHour={isFuture(serpIntent.reminder!.startDate)}
                />
              </DetailStack>
              <Popover
                aria-label="Visualizza dettagli promemoria"
                trigger={(
                  <Text.Caption>
                    {truncateTextWithSuffix(serpIntent.reminder!.notes, MAX_REMINDER_NOTE_LENGTH)}
                  </Text.Caption>
                )}
                hasArrow
              >
                <div css={styles.noteAndReminderPopoverWrapper}>
                  <Text.BodySmall>{serpIntent.reminder!.notes}</Text.BodySmall>
                </div>
              </Popover>
            </HStack>
          )}

          {showNote && (
            <Popover
              aria-label="Visualizza dettagli note"
              trigger={(
                <Text.Caption>
                  {truncateTextWithSuffix(noteToDisplay!.text, MAX_NOTE_LENGTH)}
                </Text.Caption>
              )}
              hasArrow
            >
              <div css={styles.noteAndReminderPopoverWrapper}>
                <Text.Body fontWeight={FontWeight.REGULAR}>{noteToDisplay?.author}</Text.Body>
                <Text.BodySmall>{noteToDisplay!.text}</Text.BodySmall>
              </div>
            </Popover>
          )}
        </SimpleTable.Cell>
      )}

      <SimpleTable.Cell
        aria-label="Azioni"
        align="right"
      >
        <HStack gap={75} wrap="nowrap">
          {!intent.seen && (
            <Dot size="S" aria-label="Interesse segnato come non visto" />
          )}
          {userIsAdminOrCallCenterOrOwnerOrManger && (
            <ActionIcon
              aria-label="Visualizza interesse"
              label="Visualizza"
              size="S"
              icon={{ path: ICON_EYE_OUTLINE }}
              onClick={goToIntentPage}
            />
          )}
          <ActionIconDropdown
            label="Azioni rapide sull'interesse"
            aria-label="Azioni rapide sull'interesse"
            size="S"
            emphasis="low"
          >
            {userIsAdminOrOwnerOrManager && contact.phoneNumber && (
              <SendWhatsappAction
                phoneNumber={contact.phoneNumber}
                name={contact.name}
                fetchMessages={intentMessagesProvider(intent, agent)}
                onMessageSent={onMessageSent}
              >
                {({ onClick }) => (
                  <Dropdown.Option
                    label="Invia whatsapp"
                    onClick={onClick}
                  />
                )}
              </SendWhatsappAction>
            )}
            {userIsAdminOrOwnerOrManager && (
              <UpsertReminderAction
                reminder={reminderToCreate}
                onSuccess={handleReminderCreated}
                aria-label="Pulsante per aggiungere un promemoria"
              >
                {({ upsert }) => (
                  <Dropdown.Option
                    label="Aggiungi promemoria"
                    onClick={upsert}
                  />
                )}
              </UpsertReminderAction>
            )}
            <UpsertNoteAction
              reference={intent}
              referenceType={ReferenceType.INTENT}
              aria-label="Pulsante per aggiungere una nota"
              onSuccess={onNoteCreated}
            >
              {({ upsert }) => (
                <Dropdown.Option
                  label="Aggiungi nota"
                  onClick={upsert}
                />
              )}
            </UpsertNoteAction>
          </ActionIconDropdown>
        </HStack>
      </SimpleTable.Cell>
    </SimpleTable.Row>
  );
};

export default ViewSerpIntentRow;

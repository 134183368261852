/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  ActionIcon,
  ActionIconDropdown,
  DetailStack,
  Dropdown, FontWeight, HStack, ICON_EYE_OUTLINE, Popover, Text,
} from '@doveit/bricks';
import { formatEuro, pluralizeWord } from '@doveit/hammer';
import { isPast, isToday } from 'date-fns';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import { SerpProperty } from '../../../providers/api/dtos/serpProperty';
import {
  Intent, Property, Shooting,
} from '../../../providers/api/dtos';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import UpdatePropertyStatus from '../update-property-status/UpdatePropertyStatus';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { NO_VALUE_SYMBOL } from '../../utils';
import {
  Aggregator, ReferenceType, Status,
} from '../../../domain/types';
import UpdateShootingStatus from '../../../shooting/containers/update-shooting-status/UpdateShootingStatus';
import PublishedOnList from '../../published-on/containers/published-on-list/PublishedOnList';
import * as styles from './ViewSerpPropertyTableRow.style';
import UpdateOfferStatus from '../../../offer/containers/update-offer-status/UpdateOfferStatus';
import { goToDetailPage } from '../../../utils/navigate/utils';
import CreateIntentAction from '../../../intent/containers/create-intent-action/CreateIntentAction';
import UpsertNoteAction from '../../../notes/containers/upsert-note-action-v2/UpsertNoteAction';
import EntityReferenceBadge from '../../../components/entity-reference-badge/EntityReferenceBadge';
import { ReferenceEntityWithNotes } from '../../../types';
import { buildOfferDateToDisplay } from '../../../offer/utils';
import PropertyPreview from '../../components/property-preview/PropertyPreview';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import { CELL_DATE_WIDTH, CELL_PRICE_WIDTH } from '../../../constants';

const MAX_NOTE_LENGTH = 85;
export interface ViewSerpPropertyTableRowProps extends React.AriaAttributes {
  serpProperty: SerpProperty,
  onPropertyUpdate?: (updatedProperty: Property) => void;
  onShootingUpdate?: (updatedShooting: Shooting) => void;
  onIntentCreate?: (createdintent: Intent) => void,
  onOfferUpdate?: VoidFunction;
}
const ViewSerpPropertyTableRow: React.FC<ViewSerpPropertyTableRowProps> = ({
  serpProperty,
  onPropertyUpdate,
  onShootingUpdate,
  onIntentCreate,
  onOfferUpdate,
  ...rest
}) => {
  const {
    property, agent, assignment, shooting, offer,
  } = serpProperty;
  const { userIsContentEditor, userIsAdmin, userIsCallCenter } = useRBAC();
  const userIsPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(assignment?.agentId);

  const canManageShooting = React.useMemo(
    () => property?.status
      && [Status.DRAFT, Status.ANTEPRIMA].includes(property.status)
      && (userIsAdmin || userIsContentEditor || userIsPropertyAgentOrManager),
    [property?.status, userIsAdmin, userIsPropertyAgentOrManager, userIsContentEditor],
  );

  const canManageOffer = React.useMemo(
    () => userIsPropertyAgentOrManager || userIsAdmin,
    [userIsPropertyAgentOrManager, userIsAdmin],
  );

  const canAddIntent = React.useMemo(
    () => userIsAdmin || userIsCallCenter || userIsPropertyAgentOrManager,
    [userIsAdmin, userIsCallCenter, userIsPropertyAgentOrManager],
  );

  const publishedAt = React.useMemo(() => {
    const doveItAggregator = property.publishedOn.find((element) => element.aggregator === Aggregator.DOVE_IT);

    return doveItAggregator?.publishedAt;
  }, [property.publishedOn]);

  const noteToDisplay = React.useMemo(
    () => property.notes?.[0],
    [property.notes],
  );

  const dateToDisplayWithOffer = React.useMemo(() => {
    if (!offer) return undefined;

    return buildOfferDateToDisplay(offer);
  }, [offer]);

  const goToPropertyPage = React.useCallback(() => {
    goToDetailPage(property.id!, ReferenceType.PROPERTY);
  }, [property]);

  const onNoteCreated = React.useCallback((updatedProperty: ReferenceEntityWithNotes) => {
    onPropertyUpdate?.(updatedProperty as Property);
  }, [onPropertyUpdate]);

  return (
    <SimpleTable.Row {...rest}>
      <SimpleTable.Cell
        aria-label="Immobile"
        width="12rem"
      >
        <PropertyPreview
          property={property}
          showMatchingAction={userIsPropertyAgentOrManager || userIsAdmin}
        />
      </SimpleTable.Cell>
      <SimpleTable.Cell
        aria-label="ID riferimento"
        width="5rem"
      >
        <EntityReferenceBadge
          id={property.id!}
          label={property.referenceId!}
          entity={ReferenceType.PROPERTY}
        />
      </SimpleTable.Cell>
      <SimpleTable.Cell
        aria-label="Stato"
        width="6.5rem"
      >
        <UpdatePropertyStatus
          property={property}
          size="XS"
          onSuccess={onPropertyUpdate}
        />
      </SimpleTable.Cell>
      <SimpleTable.Cell
        aria-label="Agente"
        width="2rem"
      >
        <ViewAgentPopoverPreview
          agent={agent}
          trigger={(
            <AgentPreview
              {...toAgentPreviewProps(agent, { omit: { name: true } })}
              size="S"
              expanded
            />
          )}
        />
      </SimpleTable.Cell>
      <SimpleTable.Cell width={CELL_DATE_WIDTH}>
        <DetailStack
          label="Aggiunto"
          aria-label="Data di creazione immobile"
        >
          <DateViewer
            stringDate={property.createdAt!}
            checkIfToday
          />
        </DetailStack>
      </SimpleTable.Cell>
      <SimpleTable.Cell width={CELL_DATE_WIDTH}>
        <DetailStack
          label="Prezzo"
          aria-label="Prezzo minimo accettato"
        >
          {property.minimumPrice ? formatEuro(property.minimumPrice) : NO_VALUE_SYMBOL}
        </DetailStack>
      </SimpleTable.Cell>
      <SimpleTable.Cell withDivider={!!shooting || Object.keys(Status).filter((s) => s !== Status.DRAFT).includes(property.status) || !!noteToDisplay}>
        <HStack gap={200} wrap="nowrap">

          {(property.status === Status.DRAFT && shooting) && (
            <>
              <DetailStack
                label="Shooting"
                aria-label="Data shooting"
                width={CELL_DATE_WIDTH}
              >
                <DateViewer
                  stringDate={shooting.date}
                  checkIfToday
                />
              </DetailStack>
              <div css={styles.shootingStatusWrapper}>
                <UpdateShootingStatus
                  shooting={shooting}
                  showAsBadge={!canManageShooting}
                  onSuccess={onShootingUpdate}
                  size="XS"
                />
              </div>
            </>
          )}

          {(property.status === Status.ANTEPRIMA) && (
            <>
              <DetailStack
                label="Scad. mand."
                aria-label="Scadenza mandato"
                color={(isPast(assignment.endDate!) && !isToday(assignment.endDate!))
                  ? 'critical.default.low'
                  : 'neutral.default.low'}
                width={CELL_DATE_WIDTH}
              >
                <DateViewer
                  stringDate={assignment.endDate!}
                  checkIfToday
                />
              </DetailStack>
              <DetailStack
                label="Aggiornato"
                aria-label="Data di aggiornamento immobile"
                width={CELL_DATE_WIDTH}
              >
                <DateViewer
                  stringDate={property.updatedAt!}
                  checkIfToday
                />
              </DetailStack>
              <DetailStack
                label="In vendita a"
                aria-label="Prezzo di pubblicazione"
              >
                {property.price ? formatEuro(property.price) : NO_VALUE_SYMBOL}
              </DetailStack>
            </>
          )}

          {(property.status === Status.LIVE) && (
            <>
              <DetailStack
                label="Scad. mand."
                aria-label="Scadenza mandato"
                color={(isPast(assignment.endDate!) && !isToday(assignment.endDate!))
                  ? 'critical.default.low'
                  : 'neutral.default.low'}
                width={CELL_DATE_WIDTH}
              >
                <DateViewer
                  stringDate={assignment.endDate!}
                  checkIfToday
                />
              </DetailStack>
              <DetailStack
                label="Aggiornato"
                aria-label="Data di aggiornamento immobile"
                width={CELL_DATE_WIDTH}
              >
                <DateViewer
                  stringDate={property.updatedAt!}
                  checkIfToday
                />
              </DetailStack>
              {publishedAt && (
                <DetailStack
                  label="Pubblicato"
                  aria-label="Data pubblicazione immobile"
                  width={CELL_DATE_WIDTH}
                >
                  <DateViewer
                    stringDate={publishedAt}
                    checkIfToday
                  />
                </DetailStack>
              )}
              <Popover
                aria-label="Visualizza dettagli portali"
                trigger={(
                  <DetailStack
                    label="Visibile su"
                    aria-label="Portali pubblicati"
                    color={publishedAt ? 'neutral.default.low' : 'critical.default.low'}
                  >
                    {property.publishedOn.length} {pluralizeWord('portal', 'e', 'i', property.publishedOn.length)}
                  </DetailStack>
                )}
                hasArrow
              >
                <div css={styles.publishedOnsPopoverWrapper}>
                  <Text.BodySmall>Portali</Text.BodySmall>
                  <PublishedOnList property={property} />
                </div>
              </Popover>
              <DetailStack
                label="In vendita a"
                aria-label="Prezzo di pubblicazione"
              >
                {property.price ? formatEuro(property.price) : NO_VALUE_SYMBOL}
              </DetailStack>
            </>
          )}

          {((property.status === Status.PROPOSTA) && offer) && (
            <>
              {dateToDisplayWithOffer && (
                <DetailStack
                  label={dateToDisplayWithOffer.label}
                  aria-label="Data della proposta"
                  color={dateToDisplayWithOffer.color}
                  width={CELL_DATE_WIDTH}
                >
                  <DateViewer
                    stringDate={dateToDisplayWithOffer.value}
                    checkIfToday
                    checkIfTomorrow
                  />
                </DetailStack>
              )}
              <DetailStack
                label="Proposta"
                aria-label="Valore della proposta"
                width={CELL_PRICE_WIDTH}
              >
                {formatEuro(offer.value)}
              </DetailStack>
              <UpdateOfferStatus
                intentId={offer.intentId}
                lastOffer={offer}
                onSuccess={onOfferUpdate}
                showAsBadge={!canManageOffer}
                showShortLabel
                size="XS"
              />
            </>
          )}

          {(property.status === Status.VENDUTO && property.soldAt) && (
            <>
              <DetailStack
                label="Vendita"
                aria-label="Data vendita"
                width={CELL_DATE_WIDTH}
              >
                <DateViewer
                  stringDate={property.soldAt}
                  checkIfToday
                />
              </DetailStack>
              <DetailStack
                label="Venduto a"
                aria-label="Prezzo di vendita"
              >
                {property.soldPrice ? formatEuro(property.soldPrice) : NO_VALUE_SYMBOL}
              </DetailStack>
            </>
          )}

          {(property.status === Status.RITIRATO && property.retiredAt) && (
            <DetailStack
              label="Ritiro"
              aria-label="Data di ritiro immobile"
              width={CELL_DATE_WIDTH}
            >
              <DateViewer
                stringDate={property.retiredAt}
                checkIfToday
              />
            </DetailStack>
          )}
          {(noteToDisplay && [Status.DRAFT, Status.ANTEPRIMA].includes(property.status)) && (
            <Popover
              aria-label="Visualizza dettagli note"
              trigger={(
                <Text.Caption>
                  {truncateTextWithSuffix(noteToDisplay!.text, MAX_NOTE_LENGTH)}
                </Text.Caption>
              )}
              hasArrow
            >
              <div css={styles.notePopoverWrapper}>
                <Text.Body fontWeight={FontWeight.REGULAR}>{noteToDisplay?.author}</Text.Body>
                <Text.BodySmall>{noteToDisplay!.text}</Text.BodySmall>
              </div>
            </Popover>
          )}
        </HStack>
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Azioni"
        align="right"
      >
        <HStack gap={75} wrap="nowrap">
          <ActionIcon
            aria-label="Visualizza immobile"
            label="Visualizza"
            size="S"
            icon={{ path: ICON_EYE_OUTLINE }}
            onClick={goToPropertyPage}
          />
          <ActionIconDropdown
            label="Azioni rapide sull'immobile"
            aria-label="Azioni rapide sull'immobile"
            size="S"
            emphasis="low"
          >
            {canAddIntent && (
              <CreateIntentAction
                aria-label="Pulsante per aggiungere un nuovo interesse d'acquisto"
                propertyId={property.id}
                onSuccess={onIntentCreate}
              >
                {({ createIntent }) => (
                  <Dropdown.Option
                    label="Aggiungi interesse"
                    aria-label="Aggiungi un nuovo interesse d'acquisto"
                    onClick={createIntent}
                  />
                )}
              </CreateIntentAction>
            )}
            <UpsertNoteAction
              reference={property}
              referenceType={ReferenceType.PROPERTY}
              aria-label="Pulsante per aggiungere una nota"
              onSuccess={onNoteCreated}
            >
              {({ upsert }) => (
                <Dropdown.Option
                  label="Aggiungi nota"
                  onClick={upsert}
                />
              )}
            </UpsertNoteAction>
          </ActionIconDropdown>
        </HStack>
      </SimpleTable.Cell>
    </SimpleTable.Row>
  );
};

export default ViewSerpPropertyTableRow;

import {
  ActionIcon, ICON_NOTE_PLUS_OUTLINE, ICON_PENCIL_OUTLINE, Portal, useModal,
  useNotifications,
} from '@doveit/bricks';
import React from 'react';
import NoteForm, { NoteFormModel } from '../../components/note-form/NoteForm';
import { Note } from '../../../providers/api/dtos';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { ReferenceType } from '../../../domain/types';
import { UpdateProviderFunction } from '../../../providers/api/types';
import { ReferenceEntityWithNotes, ReferenceWithNotes } from '../../../types';
import { uniqueByField } from '../../../utils/array';
import { mapUpdateFunctionToReferenceType } from '../../../constants';

interface ChildFnProps {
  upsert: VoidFunction,
}
export interface UpsertNotesActionBaseProps {
  reference: ReferenceEntityWithNotes,
  referenceType: ReferenceType,
  note?: Note,
  showNudgeCheckbox?: boolean,
  onSuccess?: (updatedReference: ReferenceEntityWithNotes) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export type UpsertNotesActionProps = UpsertNotesActionBaseProps & ReferenceWithNotes;

export const UPSERT_NOTE_ERROR_MESSAGE = 'Non è stato possibile aggiungere la nota.';
export const UPSERT_NOTE_SUCCESS_MESSAGE = 'La nota è stata salvata con successo.';
const UpsertNoteAction: React.FC<UpsertNotesActionProps> = ({
  note,
  reference,
  referenceType,
  showNudgeCheckbox = false,
  onSuccess,
  children,
}) => {
  const { user, userIsCallCenter, mainUserRole } = useRBAC();
  const [isSaving, setIsSaving] = React.useState(false);
  const modal = useModal<{ note?: Note }>();
  const { addSuccess, addError } = useNotifications();

  const openModal = React.useCallback(
    () => modal.open(),
    [modal],
  );

  const handleUpdateNote = React.useCallback(async (updatedNote: NoteFormModel) => {
    setIsSaving(true);
    note!.nudge = updatedNote.nudge;
    note!.text = updatedNote.text!;

    try {
      const updateReference = await (mapUpdateFunctionToReferenceType[referenceType] as UpdateProviderFunction<typeof reference>)(reference.id!, {
        ...reference,
        notes: uniqueByField([note!, ...(reference.notes || [])], 'date'),
      });

      setIsSaving(false);
      modal.close();

      addSuccess(UPSERT_NOTE_SUCCESS_MESSAGE);
      onSuccess?.(updateReference);
    } catch (error) {
      addError(UPSERT_NOTE_ERROR_MESSAGE);
    }
  }, [note, referenceType, reference, modal, addSuccess, onSuccess, addError]);

  const createNote = React.useCallback(async ({ nudge, text }: Partial<Note>) => {
    setIsSaving(true);

    try {
      const newNote: Note = {
        date: new Date().toISOString(),
        role: mainUserRole,
        author: user.name,
        text: text?.trim() || '',
        nudge: nudge || false,
      };
      const updateReference = await (mapUpdateFunctionToReferenceType[referenceType] as UpdateProviderFunction<typeof reference>)(reference.id!, {
        ...reference,
        notes: [newNote, ...(reference.notes || [])],
      });

      setIsSaving(false);
      modal.close();

      addSuccess(UPSERT_NOTE_SUCCESS_MESSAGE);
      onSuccess?.(updateReference);
    } catch (error) {
      addError(UPSERT_NOTE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, mainUserRole, modal, onSuccess, reference, referenceType, user.name]);

  return (
    <>
      {children
        ? children({
          upsert: openModal,
        })
        : (
          <ActionIcon
            icon={note ? { path: ICON_PENCIL_OUTLINE } : { path: ICON_NOTE_PLUS_OUTLINE }}
            label={note ? 'Modifica' : 'Aggiungi'}
            aria-label={note ? 'Pulsante per modificare la nota' : 'Pulsante per aggiungere una nota'}
            size="S"
            onClick={openModal}
          />
        )}
      <Portal>
        <SimpleModal
          isOpen={modal.isOpen}
          close={modal.close}
          title={note ? 'Modifica nota' : 'Aggiungi nota'}
          aria-label={note ? 'Modale per modificare la nota' : 'Modale per aggiungere una nota'}
        >
          <NoteForm
            initialValues={note}
            loading={isSaving}
            showNudgeCheckbox={showNudgeCheckbox && userIsCallCenter && !note?.date}
            onSubmit={note ? handleUpdateNote : createNote}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default UpsertNoteAction;

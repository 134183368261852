import hash from 'object-hash';
import useSWR from 'swr';
import { SWRCustomResponse } from '../../../hooks/types';
import { SerpProperty } from '../../../providers/api/dtos';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import { API_PROPERTIES_SERP_PATH } from '../../../providers/api/api';
import { getSerpProperties, SerpPropertiesFilters } from '../../../providers/api/serp-properties/getSerpProperties';

export function useSerpProperties(
  filters?: SerpPropertiesFilters,
  pagination: PaginationParams = { size: 20 },
): SWRCustomResponse<PaginatedResult<SerpProperty>, Error> {
  const matchMutate = useMatchMutate();
  const key = filters ? [API_PROPERTIES_SERP_PATH, hash({ ...filters, ...pagination })] : null;
  const result = useSWR(key, () => getSerpProperties(filters, pagination));

  return {
    ...result,
    mutate: () => matchMutate(API_PROPERTIES_SERP_PATH),
  };
}

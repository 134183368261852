import React from 'react';
import { useNotifications } from '@doveit/bricks';
import RumorForm, { RumorFormModel } from '../../components/rumor-form/RumorForm';
import { Contact, Rumor } from '../../../providers/api/dtos';
import useContact from '../../../contact/hooks/use-contact/useContact';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import useAddressSuggestions from '../../../hooks/use-address-suggestion/useAddressSuggestions';
import { SearchContactFormModel } from '../../../contact/components/search-contact-form/SearchContactForm';
import { getPlaceDetails } from '../../../providers/geo/places/places';
import { geolocate } from '../../../providers/public-api/location/locationProvider';
import { updateRumor } from '../../../providers/api/rumor/rumorProvider';
import { createContact } from '../../../providers/api/contact/contactProvider';

export const UPDATE_RUMOR_SUCCESS_MESSAGE = 'La notizia è stata aggiornata correttamente.';
export const UPDATE_RUMOR_ERROR_MESSAGE = 'Non è stato possibile aggiornare la notizia.';

export interface EditRumorProps {
  rumor: Rumor,
  onSuccess?: (updatedRumor: Rumor) => void,
}

const EditRumor: React.FunctionComponent<EditRumorProps> = ({
  rumor,
  onSuccess,
}) => {
  const { data: contact } = useContact(rumor.contactId);
  const { data: agent } = useAgent(rumor.agentId);
  const { data: addressSuggestion } = useAddressSuggestions(rumor?.propertyAddress);

  const { addSuccess, addError } = useNotifications();

  const [isSaving, setIsSaving] = React.useState(false);
  const [selectedContact, setSelectedContact] = React.useState(contact);

  const initialValues: RumorFormModel = React.useMemo(() => ({
    contact,
    agent,
    description: rumor.description || '',
    propertyAddress: addressSuggestion ? addressSuggestion[0] : undefined,
    propertyFloor: rumor.propertyFloor?.toString() || '',
    propertySize: rumor.propertySize?.toString() || '',
    propertyStatus: rumor.propertyStatus,
    propertyType: rumor.propertyType,
  }), [addressSuggestion, agent, contact, rumor.description, rumor.propertyFloor, rumor.propertySize, rumor.propertyStatus, rumor.propertyType]);

  const onContactChange = React.useCallback(async (contactFormValues?: Contact, searchContact?: SearchContactFormModel) => {
    setSelectedContact(searchContact || contactFormValues);
  }, []);

  const onSubmit = React.useCallback(async (values: RumorFormModel) => {
    let contactToSave = selectedContact;
    setIsSaving(true);

    if (selectedContact && !selectedContact?.id) {
      contactToSave = await createContact(selectedContact);
    }

    try {
      const place = values.propertyAddress
        ? await getPlaceDetails(values.propertyAddress.placeId)
          .catch((_) => undefined)
        : undefined;

      const { suburb } = (place && place.latitude && place.longitude)
        ? await geolocate({
          latitude: `${place.latitude}`,
          longitude: `${place.longitude}`,
        }).catch((_) => ({ suburb: undefined }))
        : { suburb: undefined };

      const updatedRumor = await updateRumor(rumor.id!, {
        ...rumor,
        contactId: contactToSave?.id,
        agentId: values.agent?.id!,
        description: values.description!,
        propertyAddress: values.propertyAddress?.description,
        propertyType: values.propertyType,
        propertyStatus: values.propertyStatus,
        propertySize: values.propertySize ? parseInt(values.propertySize, 10) : undefined,
        propertyFloor: values.propertyFloor ? parseFloat(values.propertyFloor) : undefined,
        locality: place?.locality,
        area: suburb?.area,
        latitude: place?.latitude,
        longitude: place?.longitude,
      });

      setIsSaving(false);
      addSuccess(UPDATE_RUMOR_SUCCESS_MESSAGE);

      onSuccess?.(updatedRumor);
    } catch (error) {
      addError(UPDATE_RUMOR_ERROR_MESSAGE);
      setIsSaving(false);
    }
  }, [addError, addSuccess, onSuccess, rumor, selectedContact]);

  return (
    <RumorForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      onContactChange={onContactChange}
      loading={isSaving}
    />
  );
};

export default EditRumor;

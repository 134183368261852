import React from 'react';
import {
  ActionDropDown, Action, ActionIconDropdown, CardSkeleton, HStack, ICON_CHECK, ICON_CUSTOM_SORT_PLUS, ICON_SORT, Message, Select, Spacing, Stack,
  Text,
} from '@doveit/bricks';
import { useSerpRumors } from '../../hooks/use-serp-rumors/useSerpRumors';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import usePagination from '../../../hooks/use-pagination/usePagination';
import {
  ALL_RUMORS_FILTER_OPTION,
  DEFAULT_FILTER_OPTION,
  DEFAULT_SORT_OPTION,
  FILTER_OPTIONS,
  FILTERS_MAPPER, GROUPED_FILTER_OPTIONS, LOAD_SERP_RUMORS_ERROR_MESSAGE, MOST_RECENT_CREATION_SORT_OPTION, NO_SERP_RUMORS, SORT_OPTIONS,
  SORT_OPTIONS_PER_FILTER,
} from './constants';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import ViewSerpRumorTableRow from '../../containers/view-serp-rumor-table-row/ViewSerpRumorTableRow';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import ViewSerpRumorCard from '../../containers/view-serp-rumor-card/ViewSerpRumorCard';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import CreateRumorAction from '../../containers/create-rumor-action/CreateRumorAction';
import { RumorSerpPageFilterKeys } from './types';
import SearchByQueryAction from '../../../components/search-by-query-action/SearchByQueryAction';
import useFilters from '../../../hooks/use-filters/useFilters';
import AgentFilterAction from '../../../components/agent-filter-action/AgentFilterAction';
import { useAgentFilter } from '../../../hooks/agent-filter/useAgentFilter';
import getShortAgentName from '../../../utils/agent/getShortAgentName';
import SerpFiltersSummary from '../../../components/serp-filters-summary/SerpFiltersSummary';

const RumorSerpPage: React.FC = () => {
  const isDesktop = useIsDevice('desktop');
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const { page, paginationQueryParam, goToPage } = usePagination('page', scrollRef);

  const {
    filters,
    addFilters,
    removeFilter,
    removeFilters,
  } = useFilters(FILTERS_MAPPER);

  const sortOption = React.useMemo(() => {
    if (filters.sort && filters.sort !== DEFAULT_SORT_OPTION.key) {
      return SORT_OPTIONS.find(({ key }) => key === filters.sort);
    }
    if (filters.filter) {
      return SORT_OPTIONS_PER_FILTER[filters.filter!];
    }
    return MOST_RECENT_CREATION_SORT_OPTION;
  }, [filters.filter, filters.sort]);

  const {
    agentEmails: agentFilters,
    isExplicit: isAgentFilterExplicit,
    replaceFilter: replaceAgentFilter,
    isLoading: isAgentFilterLoading,
  } = useAgentFilter(paginationQueryParam, getShortAgentName);

  const agentEmail = React.useMemo(() => agentFilters.map(({ email }) => email || ''), [agentFilters]);

  const {
    data: serpRumors,
    isLoading: areSerpRumorsLoading,
    error: serpRumorsError,
    mutate: mutateSerpRumors,
  } = useSerpRumors(isAgentFilterLoading
    ? undefined
    : {
      q: filters.q,
      agentEmail,
      ...(FILTER_OPTIONS.find(({
        key,
      }) => key === filters.filter)?.value),
    }, {
    page,
    sort: sortOption?.value,
  });

  const handleBadgeClick = React.useCallback((badgeFilter: RumorSerpPageFilterKeys) => () => {
    removeFilter(paginationQueryParam);
    addFilters({ filter: badgeFilter });
  }, [addFilters, paginationQueryParam, removeFilter]);

  const mutateRumors = React.useCallback(async () => {
    await mutateSerpRumors();
  }, [mutateSerpRumors]);

  const handleSearch = React.useCallback((query: string) => {
    if (query) {
      addFilters({ q: query });
      removeFilter(paginationQueryParam);
    } else {
      removeFilters(['q', paginationQueryParam]);
    }
  }, [addFilters, paginationQueryParam, removeFilter, removeFilters]);

  const filterLabel = React.useMemo(() => {
    if (filters.filter === ALL_RUMORS_FILTER_OPTION.key) {
      return '';
    }

    const filterObject = FILTER_OPTIONS.find(({ key }) => key === filters.filter);

    return filterObject?.label.long.toLowerCase();
  }, [filters.filter]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Bar>
        <HStack
          alignItems="center"
          justifyContent="space-between"
          gap={100}
        >
          <div style={{ flexGrow: '1' }}>
            {isDesktop && (
              <HStack aria-label="Filtri (desktop)" gap={150}>
                <Action
                  label={DEFAULT_FILTER_OPTION.label.short}
                  aria-label="Da gestire"
                  onClick={filters.filter !== DEFAULT_FILTER_OPTION.key
                    ? handleBadgeClick(DEFAULT_FILTER_OPTION.key)
                    : undefined}
                  color={filters.filter !== DEFAULT_FILTER_OPTION.key
                    ? 'neutral'
                    : 'primary'}
                />
                {GROUPED_FILTER_OPTIONS.map(({ label, options }) => (
                  <ActionDropDown
                    key={label}
                    label={label}
                    aria-label={`Filtra notizie per ${label}`}
                    color={options.some(({ key }) => key === filters.filter) ? 'primary' : 'neutral'}
                    emphasis="low"
                    options={options.map(({ key, label: optionLabel }) => ({
                      key,
                      label: optionLabel.short,
                      color: filters.filter !== key ? 'neutral' : 'primary',
                      iconRight: filters.filter !== key ? undefined : { path: ICON_CHECK },
                      onClick: filters.filter !== key ? handleBadgeClick(key) : undefined,
                    }))}
                  />
                ))}
                <Action
                  label={ALL_RUMORS_FILTER_OPTION.label.short}
                  aria-label="Tutte le notizie"
                  onClick={filters.filter !== ALL_RUMORS_FILTER_OPTION.key
                    ? handleBadgeClick(ALL_RUMORS_FILTER_OPTION.key)
                    : undefined}
                  color={filters.filter !== ALL_RUMORS_FILTER_OPTION.key
                    ? 'neutral'
                    : 'primary'}
                />
              </HStack>
            )}
            {!isDesktop && (
              <Select
                value={filters.filter}
                aria-label="Seleziona un filtro della notizia (mobile)"
                options={FILTER_OPTIONS.map(({ label, key }) => ({
                  label: label.long,
                  value: key,
                }))}
                size="S"
                onChange={({ target }) => handleBadgeClick(target.value as RumorSerpPageFilterKeys)()}
              />
            )}
          </div>

          <HStack
            alignItems="center"
            gap={100}
          >
            <SearchByQueryAction
              initialQuery={filters.q}
              onSearch={handleSearch}
              placeholder="Cerca per contatto, immobile o notizia"
              size={isDesktop ? 'M' : 'S'}
              variant={isDesktop ? 'input' : 'action'}
            />
            <AgentFilterAction
              size={isDesktop ? 'M' : 'S'}
              onSubmit={(emails) => replaceAgentFilter(emails)()}
            />
            <ActionIconDropdown
              label="Ordina"
              aria-label="Pulsante per selezionare l'ordinamento delle notizie"
              placement="bottom-end"
              icon={{ path: !filters.sort || filters.sort !== DEFAULT_SORT_OPTION.key ? ICON_CUSTOM_SORT_PLUS : ICON_SORT }}
              size={isDesktop ? 'M' : 'S'}
              color={filters.sort && filters.sort !== DEFAULT_SORT_OPTION.key ? 'primary' : 'neutral'}
              emphasis="low"
              options={SORT_OPTIONS.map(({ key, label }) => ({
                key,
                label,
                iconRight:
                  // ICON_CHECK on "default" if no sort option has been selected
                  (!filters.sort && key === DEFAULT_SORT_OPTION.key)
                    // or the sort option is the current one
                    || filters.sort === key ? { path: ICON_CHECK } : undefined,
                color:
                  // Apply neutral color if "default" sort option has been selected
                  key !== DEFAULT_SORT_OPTION.key
                    // Apply the primary color if the sort option is the current one
                    && filters.sort === key
                    ? 'primary'
                    : 'neutral',
                onClick: () => addFilters({ sort: key }),
              }))}
            />
          </HStack>
        </HStack>
      </GenericPageLayout.Bar>
      <GenericPageLayout.Content>
        <HStack justifyContent="space-between" alignItems="center">
          <Stack gap={100}>
            <Text.H4 aria-label="Titolo pagina">
              {`Notizie${filterLabel ? ` ${filterLabel}` : ''}${(serpRumors && serpRumors.totalElements > 0) ? ` (${serpRumors.totalElements})` : ''}`}
            </Text.H4>
            <SerpFiltersSummary
              aria-label="Sommario filtri attivi"
              filters={{
                ...filters,
                agentFilters: {
                  values: agentFilters,
                  isExplicit: isAgentFilterExplicit,
                },
              }}
              sortOptions={SORT_OPTIONS}
            />
          </Stack>

          <CreateRumorAction onSuccess={mutateRumors}>
            {({ createRumor }) => (
              <Action
                label="Aggiungi notizia"
                emphasis="high"
                size="S"
                onClick={createRumor}
              />
            )}
          </CreateRumorAction>
        </HStack>

        <Spacing
          margin={[300, 0, 0]}
          aria-label="Lista notizie"
        >
          {areSerpRumorsLoading && isDesktop && (
            <SimpleTableSkeleton aria-label="Notizie in caricamento" />
          )}
          {areSerpRumorsLoading && !isDesktop && (
            <Stack aria-label="Notizie in caricamento">
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </Stack>
          )}
          {serpRumorsError && !areSerpRumorsLoading && (
            <Message
              message={LOAD_SERP_RUMORS_ERROR_MESSAGE}
              type="critical"
              boxed
            />
          )}
          {(!areSerpRumorsLoading && (serpRumors && serpRumors.totalElements > 0)) && (
            <PaginatedList
              {...serpRumors}
              hideResultCount
              goToPage={goToPage}
            >
              {!isDesktop && serpRumors.content.map((serpRumor) => (
                <ViewSerpRumorCard
                  key={serpRumor.rumor.id}
                  serpRumor={serpRumor}
                  onRumorUpdate={mutateRumors}
                  onReminderCreate={mutateRumors}
                />
              ))}
              {isDesktop && (
                <SimpleTable>
                  <SimpleTable.Body>
                    {serpRumors.content.map((serpRumor) => (
                      <SimpleTable.Row
                        asChild
                        key={serpRumor.rumor.id}
                      >
                        <ViewSerpRumorTableRow
                          serpRumor={serpRumor}
                          onRumorUpdate={mutateRumors}
                          onReminderCreate={mutateRumors}
                        />
                      </SimpleTable.Row>
                    ))}
                  </SimpleTable.Body>
                </SimpleTable>
              )}
            </PaginatedList>
          )}
          {(serpRumors && serpRumors.totalElements === 0) && (
            <Message message={NO_SERP_RUMORS} boxed />
          )}
        </Spacing>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default RumorSerpPage;

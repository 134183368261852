import { BreakpointQueryName, ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const firstRowWrapper: ThemedFunctor = ({ theme }) => css`
  ${theme.breakpoints.down(BreakpointQueryName.MD)} {
    width: 100%;
    justify-content: space-between;
  };
`;

export const secondRowWrapper: ThemedFunctor = ({ theme }) => css`
  ${theme.breakpoints.down(BreakpointQueryName.MD)} {
    width: 100%;
    padding-top: ${theme.remSize[150]};
    border-top: 1px solid ${theme.color.border.neutral.default.low};
  };
  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    padding-left: ${theme.remSize[150]};
    border-left: 1px solid ${theme.color.border.neutral.default.low};
  }
`;

export const userPreview = () => css`
  width: 9rem;
`;

export const dateWrapper = () => css`
  width: 7rem;
`;

export const noteAndReminderPopoverWrapper: ThemedFunctor = ({ theme }) => css`
  min-width: 20rem;
  padding: ${theme.remSize[150]};
`;

import {
  API_PROPERTIES_PATH, API_ASSIGNMENTS_PATH, getClient, API_PROSPECTS,
} from '../api';
import {
  Assignment, Signature, AssignmentWizardData, Property, Prospect,
} from '../dtos';

export async function getAssignmentByPropertyId(propertyId: number): Promise<Assignment> {
  const client = await getClient();
  const { data } = await client.get(`${API_PROPERTIES_PATH}/${propertyId}/assignment`);

  return data;
}

export async function getAssignmentByProspectId(prospectId: string): Promise<Assignment> {
  const client = await getClient();
  const { data } = await client.get(`${API_PROSPECTS}/${prospectId}/assignment`);

  return data;
}

export async function updateAssignment(assignment: Assignment): Promise<Assignment> {
  const client = await getClient();
  const { data } = await client.put<Assignment>(`${API_ASSIGNMENTS_PATH}/${assignment.id}`, assignment);

  return data;
}

export async function createManualAssignmentFromProspect(data: AssignmentWizardData): Promise<Property> {
  const client = await getClient();
  const { data: res } = await client.post<Property>(`${API_ASSIGNMENTS_PATH}/from-prospect/manual`, data);

  return res;
}

export async function updateManualAssignmentFromProspect(prospectId: NonNullable<Prospect['id']>, assignment: AssignmentWizardData): Promise<Property> {
  const client = await getClient();
  const { data } = await client.put<Property>(`${API_ASSIGNMENTS_PATH}/from-prospect/${prospectId}/manual`, assignment);

  return data;
}

export async function saveAssignmentWizardData(
  data: AssignmentWizardData,
): Promise<AssignmentWizardData> {
  const client = await getClient();

  const res = await client.put<AssignmentWizardData>(`${API_ASSIGNMENTS_PATH}/from-prospect/wizard`, data);

  return res.data;
}

export async function getAssignmentWizardData(prospectId: NonNullable<Prospect['id']>): Promise<AssignmentWizardData> {
  const client = await getClient();

  const { data } = await client.get<AssignmentWizardData>(`${API_ASSIGNMENTS_PATH}/from-prospect/wizard/${prospectId}`);

  return data;
}

export async function cancelAssignmentSignatureRequest(prospectId: NonNullable<Prospect['id']>): Promise<void> {
  const client = await getClient();

  await client.post<void>(`${API_ASSIGNMENTS_PATH}/from-prospect/wizard/${prospectId}/cancel`);
}

export async function deleteAssignmentSignatureRequest(prospectId: NonNullable<Prospect['id']>): Promise<void> {
  const client = await getClient();

  await client.delete<void>(`${API_ASSIGNMENTS_PATH}/from-prospect/wizard/${prospectId}`);
}

export async function getAssignmentSignatureStatus(prospectId: NonNullable<Prospect['id']>): Promise<Signature> {
  const client = await getClient();

  const { data } = await client.get<Signature>(`${API_ASSIGNMENTS_PATH}/from-prospect/wizard/${prospectId}/signature-status`);

  return data;
}

export async function getAssignmentWizardPDFPreview(prospectId: string): Promise<ArrayBuffer> {
  const client = await getClient();
  const { data } = await client.get<ArrayBuffer>(`${API_ASSIGNMENTS_PATH}/from-prospect/wizard/${prospectId}/preview`, {
    responseType: 'arraybuffer',
  });
  return data;
}

export async function reactivateAssignmentSignatureRequest(prospectId: string): Promise<AssignmentWizardData> {
  const client = await getClient();
  const { data } = await client.post<AssignmentWizardData>(`${API_ASSIGNMENTS_PATH}/from-prospect/wizard/${prospectId}/reactivate`);
  return data;
}

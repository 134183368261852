import { getClient, API_PROSPECTS } from '../api';
import {
  Prospect, ProspectSearchParams, GetProspectsFilters, GetTodoProspectsFilters, GetPendingProspectsFilters, MatchingSearchCriteria, MatchCount, Document,
} from '../dtos';
import { PaginatedResult, PaginationParams, serializePagination } from '../../pagination';
import { serializeParams } from '../utils/serializer';
import { CreateProviderFunction, UpdateProviderFunction } from '../types';

export async function getProspects(
  filters: GetProspectsFilters = {},
  pagination: PaginationParams = { size: 50 },
): Promise<PaginatedResult<Prospect>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Prospect>>(API_PROSPECTS, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function getTodoProspects(
  filters: GetTodoProspectsFilters = {},
  pagination: PaginationParams = { size: 50 },
): Promise<PaginatedResult<Prospect>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Prospect>>(`${API_PROSPECTS}/todo`, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function getPendingProspects(
  filters: GetPendingProspectsFilters = {},
  pagination: PaginationParams = { size: 50 },
): Promise<PaginatedResult<Prospect>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Prospect>>(`${API_PROSPECTS}/pending`, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function getProspect(id: string): Promise<Prospect> {
  const client = await getClient();
  const { data } = await client.get<Prospect>(`${API_PROSPECTS}/${id}`);

  return data;
}

export async function searchProspect(searchParams: ProspectSearchParams, pagination: PaginationParams = { size: 50 }): Promise<PaginatedResult<Prospect>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Prospect>>(`${API_PROSPECTS}/search`, {
    params: {
      ...searchParams,
      ...serializePagination(pagination),
    },
  });

  return data;
}

export const createProspect: CreateProviderFunction<Prospect> = async (prospect) => {
  const client = await getClient();
  const { data } = await client.post<Prospect & { id: NonNullable<Prospect['id']> }>(API_PROSPECTS, prospect);

  return data;
};

export const updateProspect: UpdateProviderFunction<Prospect> = async (prospectId, prospect) => {
  const client = await getClient();
  const { data } = await client.put<Prospect & { id: NonNullable<Prospect['id']> }>(`${API_PROSPECTS}/${prospectId}`, prospect);

  return data;
};

export async function getProspectMatchingSearchCriteria(
  prospectId: string,
  pagination: PaginationParams,
): Promise<PaginatedResult<MatchingSearchCriteria>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<MatchingSearchCriteria>>(`${API_PROSPECTS}/${prospectId}/match/search-criteria`, {
    params: {
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function getProspectMatchCount(prospectId: string): Promise<MatchCount> {
  const client = await getClient();
  const { data } = await client.get<MatchCount>(`${API_PROSPECTS}/${prospectId}/match-count`);

  return data;
}

export async function getProspectDocuments(prospectId: NonNullable<Prospect['id']>): Promise<Document[]> {
  const client = await getClient();

  const { data } = await client.get<Document[]>(`${API_PROSPECTS}/${prospectId}/documents`);

  return data;
}

import React, { FunctionComponent, useCallback, useState } from 'react';
import { useNotifications } from '@doveit/bricks';
import ProspectFormByLead, { ProspectFormByLeadProps } from '../../components/prospect-form-by-lead/ProspectFormByLead';
import { updateLead } from '../../../providers/api/lead/leadProvider';
import {
  ProspectStatus, LeadStatus, AppointmentStatus, LeadWillingness, EvaluationType, ReferenceType,
} from '../../../domain/types';
import { createProspect } from '../../../providers/api/prospect/prospectProvider';
import { Prospect } from '../../../providers/api/dtos/prospect';
import { createAppointment } from '../../../providers/api/appointment/appointmentProvider';
import { formDateTimeToDate } from '../../../utils/form';
import useCoveredAgentArea from '../../../agent/hooks/use-covered-agent-area/useCoveredAgentArea';
import { ProspectFormByLeadModel } from '../../models/ProspectFormByLeadModel';
import { geolocate } from '../../../providers/public-api/location/locationProvider';
import { Agent, Appointment, Lead } from '../../../providers/api/dtos';
import { getAppointmentEndDate } from '../../../appointment/utils/getAppointmentEndDate';
import { UserRole } from '../../../types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { getAgentName } from '../../../agent/utils/utils';

const toProspectWillingnessMap: { [key in LeadWillingness]: number } = {
  NOW: 5,
  IN_6_MONTHS: 3,
  UNKNOWN: 1,
};

export interface CreateProspectByLeadProps {
  lead: Lead,
  correlationId?: string,
  agent?: Agent,
  hideFormSubmit?: boolean,
  formRef?: ProspectFormByLeadProps['innerRef'],
  onSuccess?: (prospect: Prospect, appointment: Appointment) => void,
}

export const SAVE_PROSPECT_SUCCESS_MESSAGE = 'La valutazione è stata salvata con successo.';
export const SAVE_PROSPECT_ERROR_MESSAGE = 'Non è stato possibile creare la valutazione.';

const CreateProspectByLead: FunctionComponent<CreateProspectByLeadProps> = ({
  lead,
  correlationId,
  agent,
  formRef,
  hideFormSubmit,
  onSuccess,
}) => {
  const [saving, setSaving] = useState(false);
  const { user, mainUserRole } = useRBAC();
  const { addSuccess, addError } = useNotifications();
  const { data: coveredAgentArea } = useCoveredAgentArea(
    lead?.postalCode && lead?.locality
      ? { postalCode: lead.postalCode, locality: lead.locality }
      : null,
  );

  const initialValues = React.useMemo(() => (
    {
      agent,
      willingness: toProspectWillingnessMap[lead.willingness].toString(),
      virtual: coveredAgentArea?.covered?.evaluationType === EvaluationType.REMOTE
        || coveredAgentArea?.covered?.evaluationType === EvaluationType.VIDEO,
    }
  ), [agent, coveredAgentArea?.covered?.evaluationType, lead.willingness]);

  const onSubmit = useCallback(async (values: ProspectFormByLeadModel) => {
    setSaving(true);

    const prospect: Prospect = {
      leadId: lead.id!,
      contactId: lead.contactId,
      correlationId,
      status: ProspectStatus.IN_PROGRESS,
      agentId: values.agent?.id!,
      willingness: +values.willingness,
      sellability: 3,
      notes: lead!.notes ? [{
        author: agent ? getAgentName(agent)! : user.name,
        role: agent ? UserRole.AGENT : mainUserRole,
        text: lead!.notes,
        date: new Date().toISOString(),
      }] : undefined,
    };

    try {
      const prospectCreated = await createProspect(prospect);
      const startDate = formDateTimeToDate(values.date, values.time);

      const appointmentCreated = await createAppointment({
        contactId: lead.contactId,
        agentId: values.agent?.id!,
        referenceId: prospectCreated.id!,
        referenceType: ReferenceType.PROSPECT,
        startDate: startDate.toISOString(),
        endDate: getAppointmentEndDate(ReferenceType.PROSPECT, startDate, values.virtual).toISOString(),
        status: AppointmentStatus.TODO,
        virtual: values.virtual,
        notes: values.notes,
      });

      const { suburb } = (lead?.latitude && lead?.longitude)
        ? await geolocate({
          latitude: `${lead.latitude}`,
          longitude: `${lead.longitude}`,
        }).catch((_) => ({ suburb: undefined }))
        : { suburb: undefined };

      await updateLead(lead!.id!, {
        ...lead!,
        area: suburb?.area,
        status: LeadStatus.APPOINTMENT_SCHEDULED,
      });

      setSaving(false);
      addSuccess(SAVE_PROSPECT_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(prospectCreated, appointmentCreated);
      }
    } catch (error) {
      setSaving(false);
      addError(SAVE_PROSPECT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agent, correlationId, lead, mainUserRole, onSuccess, user.name]);

  return (
    <ProspectFormByLead
      loading={saving}
      onSubmit={onSubmit}
      initialValues={initialValues}
      hideSubmit={hideFormSubmit}
      innerRef={formRef}
    />
  );
};

export default CreateProspectByLead;

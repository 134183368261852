import React from 'react';
import {
  ActionIcon, Card, DetailStack, FontWeight, Grid, HStack, ICON_EYE_OUTLINE, Skeleton, Stack, Text,
} from '@doveit/bricks';
import { pluralizeWord } from '@doveit/hammer';
import { SearchCriteria } from '../../../providers/api/dtos';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { setVerticalEllipsis } from '../../../utils/styles/verticalEllipsis.style';
import useMatchProperties from '../../../property/hooks/use-match-properties/useMatchProperties';
import { residentialPropertyTypeLabels } from '../../../labels';
import { getGeoCriterionLabel } from '../../utils/getGeoCriterionLabel';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import ViewSearchCriteriaPopoverPreview from '../view-search-criteria-popover-preview/ViewSearchCriteriaPopoverPreview';
import SearchCriteriaAvatar from '../../component/search-criteria-avatar/SearchCriteriaAvatar';
import UpdateSearchCriteriaStatusAction from '../update-search-criteria-status-action/UpdateSearchCriteriaStatusAction';
import useRBAC from '../../../hooks/use-rbac/useRBAC';

export interface ViewSearchCriteriaCardProps {
  searchCriteria: SearchCriteria,
  onSearchCriteriaUpdate?: (updatedSearchCriteria: SearchCriteria) => void,
}

const ViewSearchCriteriaCard: React.FC<ViewSearchCriteriaCardProps> = ({
  searchCriteria,
  onSearchCriteriaUpdate,
}) => {
  const {
    userIsAdmin, userIsAgent, userIsContentEditor, userIsCallCenter,
  } = useRBAC();

  const {
    data: matchingProperties,
    isLoading: areMatchingPropertiesLoading,
    error: matchingPropertiesError,
  } = useMatchProperties(searchCriteria, {
    size: 0,
  });

  const propertyTypes = React.useMemo(() => searchCriteria?.propertyTypes
    .map((propertyType) => residentialPropertyTypeLabels[propertyType])
    .join(', '), [searchCriteria?.propertyTypes]);

  const goToSearchCriteriaPage = React.useCallback(() => {
    goToDetailPage(searchCriteria.id!, ReferenceType.SEARCH_CRITERIA, true);
  }, [searchCriteria.id]);

  const showAsBadge = React.useMemo(() => !(userIsAdmin || userIsAgent || userIsContentEditor || userIsCallCenter), [userIsAdmin, userIsAgent, userIsCallCenter, userIsContentEditor]);

  return (
    <Card aria-label="Card della ricerca">
      <Card.Header
        primaryActions={[
          <ActionIcon
            label="Visualizza"
            icon={{ path: ICON_EYE_OUTLINE }}
            size="XS"
            onClick={goToSearchCriteriaPage}
            aria-label="Visualizza ricerca"
          />,
        ]}
      >
        <ViewSearchCriteriaPopoverPreview
          searchCriteria={searchCriteria}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <SearchCriteriaAvatar size="S" />
              <Stack gap={0}>
                {propertyTypes && (
                  <Text.BodySmall
                    css={setVerticalEllipsis(1)}
                    fontWeight={FontWeight.LIGHT}
                  >
                    {propertyTypes}
                  </Text.BodySmall>
                )}
                <Text.BodySmall
                  fontWeight={FontWeight.MEDIUM}
                  css={setVerticalEllipsis(1)}
                >
                  {searchCriteria.geo.map(getGeoCriterionLabel).join(', ')}
                </Text.BodySmall>
              </Stack>
            </HStack>

          )}
        />
      </Card.Header>
      <Card.Content>
        <Grid gutter={150}>
          <Grid.Unit>
            <UpdateSearchCriteriaStatusAction
              searchCriteria={searchCriteria}
              showAsBadge={showAsBadge}
              size="XS"
              onSuccess={onSearchCriteriaUpdate}
            />
          </Grid.Unit>

          <Grid.Unit size={1 / 2}>
            {areMatchingPropertiesLoading && (
              <Skeleton
                aria-label="Risultati matching in caricamento"
                width="3rem"
              />
            )}
            {matchingPropertiesError && (
              <DetailStack label="Risultati">
                Non disponibili
              </DetailStack>
            )}
            {!areMatchingPropertiesLoading && (
              <DetailStack label="Risultati">
                {matchingProperties?.totalElements
                  ? `
                  ${matchingProperties.totalElements}
                  ${pluralizeWord('immobil', 'e', 'i', matchingProperties.totalElements)}
                  ${pluralizeWord('trovat', 'o', 'i', matchingProperties.totalElements)}
                  `
                  : NO_VALUE_SYMBOL}
              </DetailStack>
            )}
          </Grid.Unit>

          <Grid.Unit size={1 / 2}>
            <DetailStack label="Aggiunta">
              <Text.Caption
                fontWeight={FontWeight.REGULAR}
                lineHeight="1"
              >
                <DateViewer
                  stringDate={searchCriteria.createdAt!}
                  checkIfToday
                />
              </Text.Caption>
            </DetailStack>
          </Grid.Unit>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default ViewSearchCriteriaCard;

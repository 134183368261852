import { AxiosInstance, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { getClient as getAuth0Client } from '../clients/auth0Client';

import { createClient as createAxiosClient } from '../clients/axiosClient';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
export const API_CONTENT_PROCESSING_PATH = '/api/v1/content-processing';
export const API_AGENCIES_PATH = '/api/v1/agencies';
export const API_AGENTS_PATH = '/api/v5/agents';
export const API_ASSIGNMENTS_PATH = '/api/v2/assignments';
export const API_BALCONIES_PATH = '/api/v2/balconies';
export const API_CELLARS_PATH = '/api/v2/cellars';
export const API_CONTENTS_PATH = '/api/v2/contents';
export const API_GARAGES_PATH = '/api/v2/garages';
export const API_PERSONS_PATH = '/api/v2/persons';
export const API_PROPERTIES_PATH = '/api/v3/properties';
export const API_PUBLISHED_ON_PATH = '/api/v3/published-on';
export const API_ROOMS_PATH = '/api/v2/rooms';
export const API_TERRACE_PATH = '/api/v2/terraces';
export const API_INTENTS_PATH = '/api/v2/intents';
export const API_CALL_CENTER = '/api/v1/call-center';
export const API_CONCESSIONS_PATH = '/api/v1/concessions';
export const API_CONTACTS = '/api/v1/contacts';
export const API_CONTACTS_V2 = '/api/v2/contacts';
export const API_LEADS = '/api/v1/leads';
export const API_CALLS_PATH = '/api/v1/calls';
export const API_PROSPECTS = '/api/v2/prospects';
export const API_INVOICES_PATH = '/api/v3/invoices';
export const API_SCRAPED_LISTING = '/api/v1/scraped-listing';
export const API_PROPERTIES_TRANSACTIONS_PATH = '/api/v1/properties-transactions';
export const API_CRAWLED_PROPERTIES = '/api/v1/crawled-properties';
export const API_OFFERS_PATH = '/api/v2/offers';
export const API_REMINDERS_PATH = '/api/v2/reminders';
export const API_REMINDERS_PATH_V3 = '/api/v3/reminders';
export const API_APPOINTMENTS_PATH = '/api/v2/appointments';
export const API_EVENTS_PATH = '/api/v1/events';
export const API_SEARCH_CRITERIA_PATH = '/api/v1/search-criteria';
export const API_RUMORS_PATH = '/api/v1/rumors';
export const API_OPT_INS_PATH = '/api/v1/opt-ins';
export const API_ADDITIONAL_SERVICES_PATH = '/api/v1/additional-services';
export const API_APPLICATION_NOTIFICATIONS_PATH = '/api/v1/application-notifications';
export const API_APPLICATION_NOTIFICATIONS_COUNT_PATH = `${API_APPLICATION_NOTIFICATIONS_PATH}/count`;
export const API_APPLICATION_NOTIFICATIONS_MARK_ALL_AS_READ_PATH = `${API_APPLICATION_NOTIFICATIONS_PATH}/mark-all-read`;
export const API_NOTIFICATIONS_PATH = '/api/v1/notifications';
export const API_JWT_SIGNATURE_PATH = '/api/v1/jwt-signature';
export const API_APPLICANTS_PATH = '/api/v1/applicants';
export const API_JOB_APPLICATIONS_PATH = '/api/v1/job-applications';
export const API_JOB_POSITIONS_PATH = '/api/v1/job-positions';
export const API_TRANSITIONS_PROSPECT_TO_PROPERTY_PATH = '/api/v1/transitions/prospect-to-property';
export const API_PROPERTIES_CONTACTS_PATH = '/api/v1/properties-contacts';
export const API_DOCUMENTS_PATH = '/api/v1/documents';
export const API_DOCUMENT_FILES_PATH = '/api/v1/document-files';
export const API_REVIEWS_PATH = '/api/v1/reviews';
export const API_CONVERSATION_PATH = '/api/v1/conversations';
export const API_NOTARIES_PATH = '/api/v1/notaries';
export const API_SHOOTING_PATH = '/api/v1/shootings';
export const API_INTENT_SERP_PATH = '/api/v1/serp/intents';
export const API_PROSPECTS_SERP_PATH = '/api/v1/serp/prospects';
export const API_RUMORS_SERP_PATH = '/api/v1/serp/rumors';
export const API_PROPERTIES_SERP_PATH = '/api/v1/serp/properties';
export const HYGRAPH_ASSET_BASE_URL = 'https://media.graphassets.com';

export const paramsSerializer = (params: any): string => qs.stringify(params, { arrayFormat: 'repeat' });

let client: AxiosInstance;

export async function getClient() {
  if (client) {
    return client;
  }

  const options: AxiosRequestConfig = {
    baseURL: API_BASE_URL,
    paramsSerializer,
  };

  if (process.env.REACT_APP_ENV !== 'development') {
    const auth0 = await getAuth0Client();

    options.headers = {
      Authorization: `Bearer ${await auth0.getTokenSilently()}`,
    };
  }

  client = createAxiosClient(options);
  return client;
}

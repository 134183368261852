import {
  BadgeProps, ICON_ALERT_CIRCLE_OUTLINE, ICON_CHECK_CIRCLE_OUTLINE, ICON_PROGRESS_CHECK,
} from '@doveit/bricks';
import { LeadStatus, ReferenceType } from '../domain/types';
import { ReferenceWithNotes, ReferenceWithNotesAndId } from '../types';
import { UpdateProviderFunction } from '../providers/api/types';
import { updateProperty } from '../providers/api/property/propertyProvider';
import { updateProspect } from '../providers/api/prospect/prospectProvider';
import { updateRumor } from '../providers/api/rumor/rumorProvider';
import { updateIntent } from '../providers/api/intent/intentProvider';

export const leadStatusLabels: Record<LeadStatus, string> = {
  [LeadStatus.IN_PROGRESS]: 'In lavorazione',
  [LeadStatus.KO_ONLY_EVALUATION]: 'Solo valutazione',
  [LeadStatus.APPOINTMENT_SCHEDULED]: 'Appuntamento',
  [LeadStatus.KO_GEO]: 'Geo',
  [LeadStatus.KO_BUY]: 'Compra',
  [LeadStatus.KO_RENT]: 'Affitto',
  [LeadStatus.KO_NOT_INTERESTED]: 'Non interessato',
  [LeadStatus.KO_NOT_RESIDENTIAL]: 'Non residenziale',
  [LeadStatus.KO_GARAGE]: 'Garage',
  [LeadStatus.KO_CANNOT_CONTACT]: 'irraggiungibile',
  [LeadStatus.KO_OTHER]: 'Altro',
  [LeadStatus.KO_WRONG_PHONE_NUMBER]: 'Telefono errato',
  [LeadStatus.KO_TEST]: 'Test',
  [LeadStatus.KO_DUPLICATE]: 'Duplicato',
  [LeadStatus.PRIVATE]: 'Privato',
};

export const leadStatusColors: Record<LeadStatus, BadgeProps['color']> = {
  [LeadStatus.IN_PROGRESS]: 'info',
  [LeadStatus.KO_ONLY_EVALUATION]: 'info',
  [LeadStatus.APPOINTMENT_SCHEDULED]: 'success',
  [LeadStatus.KO_BUY]: 'critical',
  [LeadStatus.KO_CANNOT_CONTACT]: 'critical',
  [LeadStatus.KO_DUPLICATE]: 'critical',
  [LeadStatus.KO_GARAGE]: 'critical',
  [LeadStatus.KO_GEO]: 'critical',
  [LeadStatus.KO_NOT_INTERESTED]: 'critical',
  [LeadStatus.KO_NOT_RESIDENTIAL]: 'critical',
  [LeadStatus.KO_WRONG_PHONE_NUMBER]: 'critical',
  [LeadStatus.KO_OTHER]: 'critical',
  [LeadStatus.KO_RENT]: 'critical',
  [LeadStatus.KO_TEST]: 'critical',
  [LeadStatus.PRIVATE]: 'critical',
};

export const leadStatusIcons: Record<LeadStatus, string> = {
  [LeadStatus.IN_PROGRESS]: ICON_PROGRESS_CHECK,
  [LeadStatus.KO_ONLY_EVALUATION]: ICON_PROGRESS_CHECK,
  [LeadStatus.APPOINTMENT_SCHEDULED]: ICON_CHECK_CIRCLE_OUTLINE,
  [LeadStatus.KO_BUY]: ICON_ALERT_CIRCLE_OUTLINE,
  [LeadStatus.KO_CANNOT_CONTACT]: ICON_ALERT_CIRCLE_OUTLINE,
  [LeadStatus.KO_DUPLICATE]: ICON_ALERT_CIRCLE_OUTLINE,
  [LeadStatus.KO_GARAGE]: ICON_ALERT_CIRCLE_OUTLINE,
  [LeadStatus.KO_GEO]: ICON_ALERT_CIRCLE_OUTLINE,
  [LeadStatus.KO_NOT_INTERESTED]: ICON_ALERT_CIRCLE_OUTLINE,
  [LeadStatus.KO_NOT_RESIDENTIAL]: ICON_ALERT_CIRCLE_OUTLINE,
  [LeadStatus.KO_WRONG_PHONE_NUMBER]: ICON_ALERT_CIRCLE_OUTLINE,
  [LeadStatus.KO_OTHER]: ICON_ALERT_CIRCLE_OUTLINE,
  [LeadStatus.KO_RENT]: ICON_ALERT_CIRCLE_OUTLINE,
  [LeadStatus.KO_TEST]: ICON_ALERT_CIRCLE_OUTLINE,
  [LeadStatus.PRIVATE]: ICON_ALERT_CIRCLE_OUTLINE,
};

export const mapUpdateFunctionToReferenceType: {
  [K in ReferenceWithNotes['referenceType']]?: UpdateProviderFunction<ReferenceWithNotesAndId<K>>;
} = {
  [ReferenceType.PROPERTY]: updateProperty as UpdateProviderFunction<ReferenceWithNotesAndId<typeof ReferenceType.PROPERTY>>,
  [ReferenceType.PROSPECT]: updateProspect as UpdateProviderFunction<ReferenceWithNotesAndId<typeof ReferenceType.PROSPECT>>,
  [ReferenceType.RUMOR]: updateRumor as UpdateProviderFunction<ReferenceWithNotesAndId<typeof ReferenceType.RUMOR>>,
  [ReferenceType.INTENT]: updateIntent as UpdateProviderFunction<ReferenceWithNotesAndId<typeof ReferenceType.INTENT>>,
};

/* eslint-disable react/no-unknown-property */
import {
  Badge, FontWeight, HStack, Icon, ICON_CASH_MULTIPLE, ICON_CHECK, Skeleton, Stack, Text,
} from '@doveit/bricks';
import React from 'react';
import { formatEuro, formatSquareMeters } from '@doveit/hammer';
import { Prospect } from '../../../providers/api/dtos';
import useProspect from '../../hooks/use-prospect/useProspect';
import { LeadPropertyFeature } from '../../../domain/types';
import * as styles from './ViewProspectPopoverContent.style';
import useLead from '../../../lead/hooks/use-lead/useLead';
import { leadFeatureLabels, propertyTypeLabels } from '../../../labels';
import { formatFloor, NO_VALUE_SYMBOL } from '../../../property/utils';

export interface ViewProspectPopoverContentProps {
  prospectId: NonNullable<Prospect['id']>,
  prospect?: Prospect,
}

const ViewProspectPopoverContent: React.FC<ViewProspectPopoverContentProps> = ({
  prospectId,
  prospect: externalProspect,
}) => {
  const {
    data: loadedProspect,
    isLoading: isProspectLoading,
    error: prospectError,
  } = useProspect(!externalProspect ? prospectId : undefined);

  const prospect = externalProspect || loadedProspect;

  const { data: lead, isLoading: isLeadLoading, error: leadError } = useLead(prospect?.leadId);

  const features = React.useMemo(() => ([
    {
      key: 'propertySize',
      label: 'Superficie',
      value: lead?.propertySize ? formatSquareMeters(lead.propertySize) : NO_VALUE_SYMBOL,
    },
    {
      key: 'propertyFloor',
      label: 'Piano',
      value: lead?.propertyFloor ? formatFloor(lead.propertyFloor) : NO_VALUE_SYMBOL,
    },
  ]), [lead?.propertyFloor, lead?.propertySize]);

  if (prospectError || leadError) return null;

  return (
    <>
      {(isProspectLoading || isLeadLoading) && (
        <div css={styles.loadingContent} aria-label="Caricamento informazioni valutazione in corso">
          <Skeleton />
          <Skeleton width="60%" />
          <Skeleton />
        </div>
      )}

      {prospect && lead && (
        <Stack gap={150}>
          {prospect.agentEvaluation !== undefined && (
          <HStack wrap="nowrap" gap={75}>
            <Icon path={ICON_CASH_MULTIPLE} size={20} />
            <Text.Body fontWeight={FontWeight.MEDIUM}>{formatEuro(prospect.agentEvaluation!)}</Text.Body>
          </HStack>
          )}
          <HStack gap={150} wrap="nowrap">
            <Text.Body fontWeight={FontWeight.MEDIUM}>
              {propertyTypeLabels[lead.propertyType]}
            </Text.Body>

            {features.length > 0 && (
            <div css={styles.features}>
              {features.map(({ key, label, value }) => (
                <div
                  key={key}
                  css={styles.feature}
                >
                  <Text.BodySmall
                    fontWeight={FontWeight.MEDIUM}
                    lineHeight="1"
                    as="div"
                  >
                    {value}
                  </Text.BodySmall>
                  <Text.Mini
                    transform="uppercase"
                    as="div"
                  >
                    {label}
                  </Text.Mini>
                </div>
              ))}
            </div>
            )}
          </HStack>

          <HStack>
            {Object.keys(lead.propertyFeatures)
              .filter((feature) => feature)
              .map((feature) => (
                <Badge
                  key={feature}
                  icon={ICON_CHECK}
                  label={leadFeatureLabels[feature as LeadPropertyFeature]}
                  size="XS"
                />
              ))}
          </HStack>
        </Stack>
      )}
    </>
  );
};

export default ViewProspectPopoverContent;

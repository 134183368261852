import { RegistryCategory } from '../domain/types/registryCategory';

export const registryCategoryLabels: Record<RegistryCategory, string> = {
  A1: 'A1',
  A2: 'A2',
  A3: 'A3',
  A4: 'A4',
  A5: 'A5',
  A6: 'A6',
  A7: 'A7',
  A8: 'A8',
  A9: 'A9',
  A10: 'A10',
  A11: 'A11',
  C1: 'C1',
  C2: 'C2',
  C3: 'C3',
  C6: 'C6',
  F1: 'F1',
  F2: 'F2',
  F3: 'F3',
  F5: 'F5',
};

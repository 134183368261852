import hash from 'object-hash';
import useSWR from 'swr';
import { SWRCustomResponse } from '../../../hooks/types';
import { SerpIntent } from '../../../providers/api/dtos';
import { getSerpIntent, SerpIntentProviderFilters } from '../../../providers/api/serp-intent/getSerpIntent';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';

export const INTENT_SERP_RID = '/api/v1/serp/intent';

export function useSerpIntent(
  filters?: SerpIntentProviderFilters,
  pagination: PaginationParams = { size: 20 },
): SWRCustomResponse<PaginatedResult<SerpIntent>, Error> {
  const matchMutate = useMatchMutate();
  const key = filters ? [INTENT_SERP_RID, hash({ ...filters, ...pagination })] : null;
  const result = useSWR(key, () => getSerpIntent(filters, pagination));

  return {
    ...result,
    mutate: () => matchMutate(INTENT_SERP_RID),
  };
}

import React from 'react';
import {
  Action, ConfirmModal, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { OfferWizardData } from '../../../providers/api/dtos';
import { deleteOfferSignatureRequest } from '../../../providers/api/offer/offerProvider';

export const DELETE_OFFER_SIGNATURE_REQUEST_SUCCESS_MESSAGE = 'La richiesta di firma digitale della proposta è stata cancellata con successo.';
export const DELETE_OFFER_SIGNATURE_REQUEST_ERROR_MESSAGE = 'Non è stato possibile cancellare la richiesta di firma digitale della proposta.';

interface ChildFnProps {
  deleteSignatureRequest: VoidFunction,
  isLoading: boolean,
}

export interface DeleteOfferSignatureRequestActionProps {
  offerWizardData: OfferWizardData,
  onSuccess?: (offerWizardData: OfferWizardData) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const DeleteOfferSignatureRequestAction: React.FC<DeleteOfferSignatureRequestActionProps> = (props) => {
  const { offerWizardData, onSuccess, children } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const confirmDeleteSignatureRequestModal = useModal();

  const openConfirmModal = React.useCallback(() => {
    confirmDeleteSignatureRequestModal.open();
  }, [confirmDeleteSignatureRequestModal]);

  const deleteSignatureRequest = React.useCallback(async () => {
    try {
      setIsLoading(true);

      await deleteOfferSignatureRequest(offerWizardData.intentId);

      setIsLoading(false);
      confirmDeleteSignatureRequestModal.close();

      addSuccess(DELETE_OFFER_SIGNATURE_REQUEST_SUCCESS_MESSAGE);
      onSuccess?.(offerWizardData);
    } catch (_) {
      setIsLoading(false);
      addError(DELETE_OFFER_SIGNATURE_REQUEST_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, confirmDeleteSignatureRequestModal, offerWizardData, onSuccess]);

  if (!offerWizardData.isDigitallySigned) {
    return null;
  }

  return (
    <>
      {
      children
        ? children({ deleteSignatureRequest: openConfirmModal, isLoading })
        : (
          <Action
            aria-label="Cancella la richiesta di firma digitale della proposta"
            label="Cancella richiesta"
            onClick={openConfirmModal}
            size="S"
            loading={isLoading}
          />
        )
    }
      <Portal>
        <ConfirmModal
          isOpen={confirmDeleteSignatureRequestModal.isOpen}
          title="Conferma cancellazione richiesta di firma"
          aria-label="Conferma cancellazione richiesta di firma digitale della proposta"
          onConfirm={deleteSignatureRequest}
          variant="critical"
          onAbort={confirmDeleteSignatureRequestModal.close}
        >
          Confermando l&apos;operazione, la richiesta di firma digitale della proposta sarà cancellata.
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default DeleteOfferSignatureRequestAction;

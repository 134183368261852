import {
  ActionDropDown, Badge, Dropdown, Skeleton,
} from '@doveit/bricks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import UpsertAppointmentAction from '../../../appointment/containers/upsert-appointment-action/UpsertAppointmentAction';
import CancelAppointmentAction from '../../../containers/cancel-appointment-action/CancelAppointmentAction';
import {
  OfferStatus, ReferenceType,
} from '../../../domain/types';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import ArchiveIntent from '../archive-intent/ArchiveIntent';
import RecoveryIntent from '../recovery-intent/RecoveryIntent';
import useIntentsWithOffer from '../../hooks/use-intents-with-offer/useIntentsWithOffer';
import { Appointment, Intent } from '../../../providers/api/dtos';
import {
  updateIntentActionStatusColors, updateIntentActionStatusIcon, computedIntentStatusLabels,
} from './constants';
import { useComputedIntentStatus } from '../../hooks/use-computed-intent-status/useComputedIntentStatus';
import useOffersByIntentId from '../../../offer/hooks/use-offers-by-intent-id/useOffersByIntentId';
import { useMarkIntentAsSeen } from '../../../hooks/use-mark-intent-as-seen/useMarkIntentAsSeen';

export interface UpdateIntentStatusProps {
  showAsBadge: boolean,
  showIcon?: boolean,
  intent: Intent,
  emphasis?: 'high' | 'low',
  size?: 'XS' | 'S',
  onSuccess?: (updatedIntent: Intent) => void,
}

const UpdateIntentStatus: React.FC<UpdateIntentStatusProps> = ({
  showAsBadge,
  showIcon = false,
  intent,
  emphasis = 'low',
  size = 'S',
  onSuccess,
}) => {
  const { userIsCallCenter } = useRBAC();
  const navigate = useNavigate();
  const theme = useTheme();
  const { data: computedIntentStatus, mutate: mutateIntentStatus } = useComputedIntentStatus(intent);
  const { data: pendingOffers, isLoading: arePendingOffersLoading } = useOffersByIntentId(intent.id, [OfferStatus.ACCEPTED, OfferStatus.IN_PROGRESS]);

  const { data: agent, isLoading: isAgentLoading } = useAgentByPropertyId(intent.propertyId);

  const { data: propertyIntentsWithAcceptedOffer } = useIntentsWithOffer({
    propertyId: intent?.propertyId,
    offerStatus: OfferStatus.ACCEPTED,
  });
  const { forceAsSeen } = useMarkIntentAsSeen(agent?.id);

  const intentWithAcceptedOfferExists = React.useMemo(
    () => !!propertyIntentsWithAcceptedOffer?.content.find(({ id }) => id !== intent.id),
    [intent.id, propertyIntentsWithAcceptedOffer?.content],
  );

  const appointmentToCreate: Appointment = React.useMemo(() => ({
    agentId: agent?.id,
    contactId: intent.contactId,
    referenceId: intent.id,
    referenceType: ReferenceType.INTENT,
  } as Appointment), [agent?.id, intent.contactId, intent.id]);

  const goToOfferPage = React.useCallback(() => {
    navigate(`/intents/${intent.id!}/offer`);
  }, [intent.id, navigate]);

  const onInternalSuccess = React.useCallback(async (updatedIntent: Intent) => {
    const updatedIntentWithSeen = await forceAsSeen(updatedIntent);
    onSuccess?.(updatedIntentWithSeen);
  }, [forceAsSeen, onSuccess]);

  const onChangeOnAppointments = React.useCallback(() => {
    mutateIntentStatus();
    onInternalSuccess(intent);
  }, [intent, mutateIntentStatus, onInternalSuccess]);

  if (isAgentLoading) {
    return <Skeleton width="10rem" height={theme.remHeight[size]} />;
  }

  if (!computedIntentStatus) {
    return null;
  }

  return (
    <>
      {(showAsBadge || !computedIntentStatus.canBeUpdated) && (
        <Badge
          label={computedIntentStatusLabels[computedIntentStatus.status]}
          icon={showIcon ? updateIntentActionStatusIcon[computedIntentStatus.status] : undefined}
          color={updateIntentActionStatusColors[computedIntentStatus.status]}
          emphasis={emphasis}
          size={size}
        />
      )}

      {!showAsBadge && computedIntentStatus.canBeUpdated && (
        <ActionDropDown
          aria-label="Selezione dello stato interesse"
          label={computedIntentStatusLabels[computedIntentStatus.status]}
          iconLeft={showIcon ? { path: updateIntentActionStatusIcon[computedIntentStatus.status] } : undefined}
          emphasis={emphasis}
          size={size}
          color={updateIntentActionStatusColors[computedIntentStatus.status]}
        >
          {computedIntentStatus.canBeUpdatedTo('WITH_FUTURE_APPOINTMENT') && agent && (
            <UpsertAppointmentAction
              appointment={appointmentToCreate}
              onSuccess={onChangeOnAppointments}
              aria-label="Pulsante per aggiungere un appuntamento"
            >
              {({ upsert }) => (
                <Dropdown.Option
                  label="Aggiungi appuntamento"
                  onClick={upsert}
                />
              )}
            </UpsertAppointmentAction>
          )}

          {computedIntentStatus.canBeUpdatedTo('WITH_CANCELLED_APPOINTMENT') && (
            <CancelAppointmentAction
              contactId={intent.contactId}
              referenceId={intent.id!}
              referenceType={ReferenceType.INTENT}
              onSuccess={onChangeOnAppointments}
            >
              {({ cancel }) => (
                <Dropdown.Option
                  label="Annulla appuntamento"
                  onClick={cancel}
                />
              )}
            </CancelAppointmentAction>
          )}

          {computedIntentStatus.canBeUpdatedTo('WITH_OFFER') && !userIsCallCenter && !intentWithAcceptedOfferExists && (
            <Dropdown.Option
              label="Aggiungi proposta"
              onClick={goToOfferPage}
            />
          )}

          {computedIntentStatus.canBeUpdatedTo('ARCHIVED') && !arePendingOffersLoading && pendingOffers && (
            <ArchiveIntent
              intent={intent}
              intentOffers={pendingOffers}
              onSuccess={onInternalSuccess}
            >
              {({ openArchiveModal }) => (
                <Dropdown.Option
                  label="Archivia interesse"
                  onClick={openArchiveModal}
                />
              )}
            </ArchiveIntent>
          )}

          {computedIntentStatus.canBeUpdatedTo('RECOVERED') && (
            <RecoveryIntent
              intent={intent}
              onSuccess={onInternalSuccess}
            >
              {({ recover }) => (
                <Dropdown.Option
                  label="Ripristina interesse"
                  onClick={recover}
                />
              )}
            </RecoveryIntent>
          )}
        </ActionDropDown>
      )}
    </>
  );
};

export default UpdateIntentStatus;

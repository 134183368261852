import { AppointmentStatus, IntentStatus } from '../../../domain/types';
import { OfferStatus } from '../../../domain/types/offerStatus';
import {
  PaginatedResult, PaginationParams, serializePagination,
} from '../../pagination';
import {
  getClient, API_INTENTS_PATH,
} from '../api';
import { EventV2, Intent } from '../dtos';
import { EventType } from '../../../domain/types/eventType';
import { serializeParams } from '../utils/serializer';
import { CreateProviderFunction, UpdateProviderFunction } from '../types';

export interface IntentsProviderFilters {
  agentEmail?: string | string[],
  propertyId?: number,
  contactId?: string,
  status?: IntentStatus | IntentStatus[],
  excludeStatus?: IntentStatus | IntentStatus[],
  withDoneAppointments?: boolean,
  ids?: string[],
}

export interface ScheduledIntentsProviderFilters {
  agentEmail?: string | string[],
  appointmentStatus?: AppointmentStatus | AppointmentStatus[];
  excludeAppointmentStatus?: AppointmentStatus | AppointmentStatus[];
  appointmentStartDate?: string;
  appointmentEndDate?: string;
}

export interface IntentsWithOfferProviderFilters {
  propertyId?: number,
  agentEmail?: string | string[],
  offerStatus?: OfferStatus | OfferStatus[],
  excludeOfferStatus?: OfferStatus | OfferStatus[]
}

export interface IntentEventsProviderFilters {
  type?: EventType,
}

export const createIntent: CreateProviderFunction<Intent> = async (intent) => {
  const client = await getClient();
  const { data } = await client.post<Intent & { id: NonNullable<Intent['id']> }>(API_INTENTS_PATH, intent);
  return data;
};

export async function getIntent(id: string): Promise<Intent> {
  const client = await getClient();
  const { data } = await client.get<Intent>(`${API_INTENTS_PATH}/${id}`);
  return data;
}

export const updateIntent: UpdateProviderFunction<Intent> = async (intentId, intent) => {
  const client = await getClient();
  const { data } = await client.put<Intent & { id: NonNullable<Intent['id']> }>(`${API_INTENTS_PATH}/${intentId}`, intent);
  return data;
};

export async function getNewIntents(filter: IntentsProviderFilters = {}, pagination: PaginationParams = { size: 50 }): Promise<PaginatedResult<Intent>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Intent>>(`${API_INTENTS_PATH}/new`, {
    params: {
      ...serializeParams(filter),
      ...serializePagination(pagination),
    },
  });
  return data;
}

export async function getRecallIntents(filter: IntentsProviderFilters = {}, pagination: PaginationParams = { size: 50 }): Promise<PaginatedResult<Intent>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Intent>>(`${API_INTENTS_PATH}/recall`, {
    params: {
      ...serializeParams(filter),
      ...serializePagination(pagination),
    },
  });
  return data;
}

export async function getScheduledIntents(filter: ScheduledIntentsProviderFilters = {}, pagination: PaginationParams = { size: 50 }): Promise<PaginatedResult<Intent>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Intent>>(`${API_INTENTS_PATH}/scheduled`, {
    params: {
      ...serializeParams(filter),
      ...serializePagination(pagination),
    },
  });
  return data;
}

export async function getIntentsWithOffer(filter: IntentsWithOfferProviderFilters = {}, pagination: PaginationParams = { size: 50 }): Promise<PaginatedResult<Intent>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Intent>>(`${API_INTENTS_PATH}/with-offer`, {
    params: {
      ...serializeParams(filter),
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function getIntents(
  filter: IntentsProviderFilters = {},
  pagination: PaginationParams = {
    size: 50,
  },
): Promise<PaginatedResult<Intent>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Intent>>(API_INTENTS_PATH, {
    params: {
      ...serializeParams(filter),
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function getIntentEvents(
  intentId: string,
  filters: IntentEventsProviderFilters = {},
): Promise<EventV2[]> {
  const client = await getClient();
  const { data } = await client.get<EventV2[]>(`${API_INTENTS_PATH}/${intentId}/events`, {
    params: filters,
  });

  return data;
}

import * as Yup from 'yup';

const VIRTUAL_TOUR_REGEX = /^https:\/\/(players\.cupix\.com|viewer\.realisti\.co|my\.matterport\.com)\/[^\s"]+$/;
const VIDEO_REGEX = /^https:\/\/(www\.)?youtube\.com\/embed\/[a-zA-Z0-9_-]+(\?[^\s"]*)?$/;

const VIRTUAL_TOUR_ERROR_MESSAGES: { [key: string]: string } = {
  url: 'Virtual tour url non valido',
};
const VIDEO_ERROR_MESSAGES: { [key: string]: string } = {
  url: 'Inserisci un link di youtube, nella forma https://www.youtube.com/embed/...',
};

export const virtualTourSchema = Yup.object().shape({
  url: Yup.string().required(VIRTUAL_TOUR_ERROR_MESSAGES.url).matches(VIRTUAL_TOUR_REGEX, VIRTUAL_TOUR_ERROR_MESSAGES.url),
});

export const videoSchema = Yup.object().shape({
  url: Yup.string().required(VIDEO_ERROR_MESSAGES.url).matches(VIDEO_REGEX, VIDEO_ERROR_MESSAGES.url),
});

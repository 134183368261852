import {
  Card,
  DetailStack,
  FontWeight,
  Grid,
  HStack,
  Text,
  UserPreviewSkeleton,
} from '@doveit/bricks';
import React from 'react';
import { AgentPreview } from '../../../agent/components';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { LeadStatus } from '../../../domain/types';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { evaluationTypeLabels } from '../../../labels';
import { EditLeadAction } from '../../../lead/containers/edit-lead-action/EditLeadAction';
import UpdateLeadStatus from '../../../lead/containers/update-lead-status/UpdateLeadStatus';
import ViewLeadPopoverPreview from '../../../lead/containers/view-lead-popover-preview/ViewLeadPopoverPreview';
import { useLeadAssignedAgent } from '../../../lead/hooks/use-lead-assigned-agent/useLeadAssignedAgent';
import useSearchProspects from '../../../prospect/hooks/use-search-prospects/useSearchProspects';
import { Agent, Lead } from '../../../providers/api/dtos';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import PropertyAvatar from '../../../property/components/property-avatar/PropertyAvatar';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';

type ComputedAgent = {
  agent?: Agent,
  error?: {
    label: string,
  }
  details?: string,
};

const errorMapping: Record<number, string> = {
  404: 'Non coperto',
  412: 'Non assegnabile',
};

export interface ViewContactLeadCardProps extends React.AriaAttributes {
  lead: Lead,
  onLeadUpdate?: (updatedLead: Lead) => void,
}

const ViewContactLeadCard: React.FC<ViewContactLeadCardProps> = ({
  lead,
  onLeadUpdate,
  ...rest
}) => {
  const { userIsAdmin, userIsCallCenter } = useRBAC();
  const isAppointmentScheduled = React.useMemo(
    () => lead.status === LeadStatus.APPOINTMENT_SCHEDULED,
    [lead.status],
  );

  const canEditLead = React.useMemo(() => userIsAdmin || userIsCallCenter, [userIsAdmin, userIsCallCenter]);

  const showAsBadge = React.useMemo(() => !(userIsAdmin || userIsCallCenter), [userIsAdmin, userIsCallCenter]);

  const {
    data: leadAssignedAgent,
    error: leadAssignedAgentError,
    isLoading: isLeadAssignedAgentLoading,
    mutate: mutateLeadAssignedAgent,
  } = useLeadAssignedAgent(isAppointmentScheduled ? undefined : lead.id);

  const {
    data: prospects,
    error: prospectsError,
  } = useSearchProspects(isAppointmentScheduled ? { leadId: [lead.id!] } : undefined);

  const leadProspect = React.useMemo(() => prospects?.content[0], [prospects]);

  const {
    data: agent,
    isLoading: isAgentLoading,
    error: agentError,
  } = useAgent(leadProspect?.agentId);

  /**
   * @todo evaluate if we can/should put all this stuff together with
   * the one inside `ViewContactLeadRow`.
   */
  const computedAgentPreview: ComputedAgent = React.useMemo(() => {
    if (leadAssignedAgentError || agentError || prospectsError) {
      const statusCode = leadAssignedAgentError?.response?.status;

      return {
        error: {
          label: statusCode && statusCode in errorMapping ? errorMapping[statusCode] : 'Errore agente',
        },
      };
    }

    if (lead.status !== LeadStatus.APPOINTMENT_SCHEDULED && leadAssignedAgent) {
      const { agent: assignedAgent, evaluationType } = leadAssignedAgent;

      return {
        agent: assignedAgent,
        details: lead.originAgentId ? 'Personal' : evaluationTypeLabels[evaluationType],
      };
    }

    if (agent) {
      return {
        agent,
        details: lead.originAgentId === agent!.id! ? 'Personal' : undefined,
      };
    }

    return {};
  }, [agent, agentError, lead, leadAssignedAgent, leadAssignedAgentError, prospectsError]);

  const onInternalSuccess = React.useCallback((leadUpdated: Lead) => async () => {
    await mutateLeadAssignedAgent();
    onLeadUpdate?.(leadUpdated);
  }, [mutateLeadAssignedAgent, onLeadUpdate]);

  return (
    <Card aria-label="Informazioni lead" {...rest}>
      <Card.Header
        primaryActions={!isAppointmentScheduled && canEditLead
          ? [(
            <EditLeadAction
              lead={lead}
              size="S"
              onSuccess={onInternalSuccess(lead)}
            />
          )]
          : undefined}
      >
        <ViewLeadPopoverPreview
          leadId={lead.id!}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <PropertyAvatar size="S" />
              <Text.BodySmall fontWeight={FontWeight.MEDIUM}>
                {lead.propertyAddress}
              </Text.BodySmall>
            </HStack>
          )}
        />
      </Card.Header>

      <Card.Content>
        <Grid gutter={150}>
          <Grid.Unit>
            <UpdateLeadStatus
              lead={lead}
              size="XS"
              showAsBadge={showAsBadge}
              onSuccess={onLeadUpdate}
            />
          </Grid.Unit>

          <Grid.Unit>
            {(isLeadAssignedAgentLoading || isAgentLoading) && (
              <div style={{ width: '10rem' }}>
                <UserPreviewSkeleton
                  size="S"
                  aria-label="Informazioni sull'agente in caricamento"
                />
              </div>
            )}
            {computedAgentPreview.error && (
              <AgentPreview
                size="S"
                name={computedAgentPreview.error.label}
              />
            )}
            {computedAgentPreview.agent && (
              <ViewAgentPopoverPreview
                agent={computedAgentPreview.agent}
                trigger={(
                  <AgentPreview
                    {...toAgentPreviewProps(computedAgentPreview.agent)}
                    details={computedAgentPreview.details}
                    size="S"
                  />
                )}
              />
            )}
          </Grid.Unit>

          <Grid.Unit size={1 / 2}>
            <DetailStack label="Aggiunta">
              <DateViewer
                stringDate={lead.createdAt!}
                checkIfToday
              />
            </DetailStack>
          </Grid.Unit>

          <Grid.Unit size={1 / 2}>
            <DetailStack label="Aggiornata">
              <DateViewer
                stringDate={lead.updatedAt!}
                checkIfToday
              />
            </DetailStack>
          </Grid.Unit>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default ViewContactLeadCard;

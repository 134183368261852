import { useCallback, useMemo } from 'react';
import { capitalizeWords } from '@doveit/hammer';
import { useCurrentAgentSync } from '../use-agent/useAgent';
import useRBAC from '../use-rbac/useRBAC';
import { UserRole } from '../../types';
import useFilters, { Filters, FiltersMapper } from '../use-filters/useFilters';
import { Agent } from '../../providers/api/dtos';
import getShortAgentNameFromEmail from '../../utils/agent/getShortAgentNameFromEmail';
import { getFormattedName } from '../../agent/utils/utils';

export interface AgentFilterValue {
  email: string,
  name?: string,
}

export interface UseAgentFilterResult {
  agentEmails: AgentFilterValue[],
  isExplicit: boolean,
  addFilter: (agent: AgentFilterValue | AgentFilterValue[]) => () => void,
  removeFilter: (agent: AgentFilterValue | AgentFilterValue[]) => () => void,
  replaceFilter: (agent: AgentFilterValue | AgentFilterValue[]) => () => void,
  isLoading: boolean,
}

export interface AgentFilters extends Filters {
  // workaround to serialise as a custom string: reading is always an array, writing is always a string
  agentEmail?: AgentFilterValue[] | string,
}

type PartialUseAgentFilterResultV3 = Omit<UseAgentFilterResult, 'addFilter' | 'removeFilter' | 'replaceFilter' | 'isLoading'>;

export const filtersMapper: FiltersMapper<AgentFilters> = {
  agentEmail: (value) => (value
    ? value
      .split(',')
      .map((it) => it.trim())
      .filter((it) => it !== '')
      .map((it) => {
        const agentStrings = it.split('|');
        return {
          email: agentStrings[0],
          name: agentStrings[1]
            ? capitalizeWords(agentStrings[1].trim().replaceAll('_', ' '))
            : getShortAgentNameFromEmail(agentStrings[0].trim()),
        } as AgentFilterValue;
      })
    : []),
};

function getAgentFilter(
  agent: Agent,
  isCoordinator: boolean,
  formatAgentName: (name: Agent['name']) => string,
  parsedQueryParam?: AgentFilterValue[] | undefined,
): PartialUseAgentFilterResultV3 {
  if (agent.isManager || isCoordinator) {
    if (parsedQueryParam) {
      const agentParam = parsedQueryParam;
      const filteredAgents = [...(agent.canManage || []), agent]
        .filter((currentAgent) => agentParam.map((it) => it.email).includes(currentAgent.email.work!))
        .map((it) => ({
          email: it.email.work!,
          name: formatAgentName(it.name),
        }));

      if (filteredAgents && filteredAgents.length > 0) {
        return {
          agentEmails: filteredAgents,
          isExplicit: true,
        };
      }
    }

    return {
      agentEmails: agent.canManage!
        .map((currentAgent) => ({
          email: currentAgent.email.work!,
          name: formatAgentName(currentAgent.name),
        }))
        .concat([{
          email: agent.email.work!,
          name: formatAgentName(agent.name),
        }]),
      isExplicit: false,
    };
  }

  return {
    agentEmails: [{
      email: agent.email.work!,
      name: formatAgentName(agent.name),
    }],
    isExplicit: false,
  };
}

function notIncluded(a: AgentFilterValue[], b: AgentFilterValue[]) {
  const setB = new Set(b.map((x) => x.email));
  const elements = [...a].filter((x) => !setB.has(x.email));
  return Array.from(elements);
}

export function useAgentFilter(
  paginationQueryParam: string = 'page',
  formatAgentName: (name: Agent['name']) => string = getFormattedName,
): UseAgentFilterResult {
  const { userHasAnyRole, userIsCoordinator } = useRBAC();
  const { data: currentAgent, isLoading: isCurrentAgentLoading } = useCurrentAgentSync();
  const {
    filters: { agentEmail },
    addFilters: addBaseFilters,
    removeFilter: removeBaseFilter,
  } = useFilters<AgentFilters>(filtersMapper);
  const agentsFilter = useMemo(() => agentEmail as AgentFilterValue[] | undefined, [agentEmail]);

  const addFilter = useCallback((agentParam: AgentFilterValue | AgentFilterValue[]) => () => {
    if (paginationQueryParam) {
      removeBaseFilter(paginationQueryParam);
    }

    const agentsToAdd = Array.isArray(agentParam) ? agentParam : [agentParam];
    const elements = notIncluded(agentsToAdd, agentsFilter ?? []);

    if (elements.length > 0) {
      const agentsJoint = [...(agentsFilter ?? []), ...elements].map((it) => `${it.email}|${it.name?.toLowerCase().replaceAll(' ', '_')}`).join(',');
      addBaseFilters({ agentEmail: agentsJoint });
    }
  }, [paginationQueryParam, agentsFilter, removeBaseFilter, addBaseFilters]);

  const removeFilter = useCallback((agentParam: AgentFilterValue | AgentFilterValue[]) => () => {
    if (paginationQueryParam) {
      removeBaseFilter(paginationQueryParam);
    }

    const agentsToRemove = Array.isArray(agentParam) ? agentParam : [agentParam];
    const elements = agentsToRemove.length > 0 ? notIncluded(agentsFilter ?? [], agentsToRemove) : [];

    if (elements.length > 0) {
      addBaseFilters({ agentEmail: elements.map((it) => `${it.email}|${it.name?.toLowerCase().replaceAll(' ', '_')}`).join(',') });
    } else {
      removeBaseFilter('agentEmail');
    }
  }, [paginationQueryParam, agentsFilter, removeBaseFilter, addBaseFilters]);

  const replaceFilter = useCallback((agentParam: AgentFilterValue | AgentFilterValue[]) => () => {
    if (paginationQueryParam) {
      removeBaseFilter(paginationQueryParam);
    }

    const agents = (Array.isArray(agentParam) ? agentParam : [agentParam]);
    if (agents.length > 0) {
      addBaseFilters({ agentEmail: agents.map((it) => `${it.email}|${it.name?.toLowerCase().replaceAll(' ', '_')}`).join(',') });
    } else {
      removeBaseFilter('agentEmail');
    }
  }, [addBaseFilters, paginationQueryParam, removeBaseFilter]);

  if (userHasAnyRole([UserRole.ADMIN, UserRole.CONTENT_EDITOR])) {
    return {
      agentEmails: agentsFilter ?? [],
      isExplicit: (agentsFilter?.length ?? 0) > 0,
      addFilter,
      removeFilter,
      replaceFilter,
      isLoading: isCurrentAgentLoading,
    };
  }

  if (currentAgent) {
    return {
      ...getAgentFilter(currentAgent, userIsCoordinator, formatAgentName, agentsFilter),
      addFilter,
      removeFilter,
      replaceFilter,
      isLoading: isCurrentAgentLoading,
    };
  }

  return {
    agentEmails: [],
    isExplicit: false,
    addFilter,
    removeFilter,
    replaceFilter,
    isLoading: isCurrentAgentLoading,
  };
}

import {
  Action, FormHandlers, ModalSize, Portal, useModal,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import CreateIntent from '../create-intent/CreateIntent';
import { Contact, Intent, Property } from '../../../providers/api/dtos';

type ChildFnProps = {
  createIntent: VoidFunction,
};

export interface CreateIntentActionProps {
  contactId?: Contact['id'],
  propertyId?: Property['id'],
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (intent: Intent) => void,
}

const CreateIntentAction: React.FC<CreateIntentActionProps> = ({
  contactId,
  propertyId,
  children,
  onSuccess,
}) => {
  const modal = useModal();
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;

  const openModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const onClose = React.useCallback(() => {
    modal.close();
  }, [modal]);

  const onInternalSuccess = React.useCallback((intent: Intent) => {
    modal.close();
    onSuccess?.(intent);
  }, [modal, onSuccess]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  return (
    <>
      {children
        ? children({ createIntent: openModal })
        : (
          <Action
            label="Aggiungi"
            aria-label="Aggiungi un nuovo interesse d'acquisto"
            size="S"
            onClick={openModal}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Aggiungi interesse"
          size={ModalSize.MEDIUM}
          aria-label="Modale per aggiungere un nuovo interesse d'acquisto"
          close={onClose}
          footer={(
            <Action
              label="Aggiungi interesse"
              color="primary"
              emphasis="high"
              aria-label="Pulsante per aggiungere un nuovo interesse d'acquisto"
              onClick={submitForm}
            />
          )}
        >
          <CreateIntent
            contactId={contactId}
            propertyId={propertyId}
            onSuccess={onInternalSuccess}
            formRef={formRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default CreateIntentAction;

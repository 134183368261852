import {
  ActionIcon, Card, DetailStack, FontWeight, Grid, HStack, ICON_EYE_OUTLINE, Text, UserPreviewSkeleton,
} from '@doveit/bricks';
import React from 'react';
import { AgentPreview } from '../../../agent/components';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { ReferenceType } from '../../../domain/types';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { Property } from '../../../providers/api/dtos';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { setVerticalEllipsis } from '../../../utils/styles/verticalEllipsis.style';
import PropertyAvatar from '../../components/property-avatar/PropertyAvatar';
import UpdatePropertyStatus from '../update-property-status/UpdatePropertyStatus';
import ViewPropertyPopoverPreview from '../view-property-popover-preview/ViewPropertyPopoverPreview';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';

export interface ViewPropertyPreviewCardProps {
  property: Property,
  onPropertyUpdate?: (updatedProperty: Property) => void,
}

const ViewPropertyPreviewCard: React.FC<ViewPropertyPreviewCardProps> = ({
  property,
  onPropertyUpdate,
}) => {
  const {
    data: agent,
    isLoading: isAgentLoading,
    error: agentError,
  } = useAgent(property.agentEmail);

  const goToPropertyPage = React.useCallback(() => {
    goToDetailPage(property.id!, ReferenceType.PROPERTY, true);
  }, [property.id]);

  return (
    <Card aria-label="Card dell'immobile">
      <Card.Header
        primaryActions={[
          <ActionIcon
            label="Visualizza"
            icon={{ path: ICON_EYE_OUTLINE }}
            size="XS"
            onClick={goToPropertyPage}
            aria-label="Visualizza immobile"
          />,
        ]}
      >
        <ViewPropertyPopoverPreview
          property={property}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <PropertyAvatar size="S" propertyType={property.propertyType} />
              <Text.BodySmall
                fontWeight={FontWeight.MEDIUM}
                css={setVerticalEllipsis(2)}
              >
                {property.geo?.normalizedAddress}
              </Text.BodySmall>
            </HStack>
          )}
        />
      </Card.Header>
      <Card.Content>
        <Grid gutter={150}>
          <Grid.Unit>
            <UpdatePropertyStatus
              property={property}
              size="XS"
              onSuccess={onPropertyUpdate}
            />
          </Grid.Unit>

          <Grid.Unit size={1 / 2}>
            {isAgentLoading && (
              <UserPreviewSkeleton
                aria-label="Agente in caricamento"
                size="S"
              />
            )}
            {agentError && (
              <AgentPreview
                name="Non disponibile"
                size="S"
              />
            )}
            {agent && (
              <ViewAgentPopoverPreview
                agent={agent}
                trigger={(
                  <AgentPreview
                    {...toAgentPreviewProps(agent)}
                    size="S"
                  />
                )}
              />
            )}
          </Grid.Unit>

          <Grid.Unit size={1 / 2}>
            <DetailStack label="Aggiunto">
              <DateViewer
                stringDate={property.createdAt!}
                checkIfToday
              />
            </DetailStack>
          </Grid.Unit>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default ViewPropertyPreviewCard;

import React from 'react';
import {
  ActionIcon, DetailStack, FontWeight, HStack, ICON_EYE_OUTLINE, Skeleton, Stack, Text,
} from '@doveit/bricks';
import { pluralizeWord } from '@doveit/hammer';
import { SearchCriteria } from '../../../providers/api/dtos';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import ViewSearchCriteriaPopoverPreview from '../view-search-criteria-popover-preview/ViewSearchCriteriaPopoverPreview';
import UpdateSearchCriteriaStatusAction from '../update-search-criteria-status-action/UpdateSearchCriteriaStatusAction';
import useMatchProperties from '../../../property/hooks/use-match-properties/useMatchProperties';
import SearchCriteriaAvatar from '../../component/search-criteria-avatar/SearchCriteriaAvatar';
import { setVerticalEllipsis } from '../../../utils/styles/verticalEllipsis.style';
import { getGeoCriterionLabel } from '../../utils/getGeoCriterionLabel';
import { residentialPropertyTypeLabels } from '../../../labels';
import useRBAC from '../../../hooks/use-rbac/useRBAC';

export interface ViewSearchCriteriaPreviewTableRowProps {
  searchCriteria: SearchCriteria,
  onSearchCriteriaUpdate?: (updatedSearchCriteria: SearchCriteria) => void,
}

const ViewSearchCriteriaPreviewTableRow: React.FC<ViewSearchCriteriaPreviewTableRowProps> = ({
  searchCriteria,
  onSearchCriteriaUpdate,
}) => {
  const {
    data: matchingProperties,
    isLoading: areMatchingPropertiesLoading,
  } = useMatchProperties(searchCriteria, {
    size: 0,
  });

  const {
    userIsAdmin, userIsAgent, userIsContentEditor, userIsCallCenter,
  } = useRBAC();

  const propertyTypes = React.useMemo(() => searchCriteria?.propertyTypes
    .map((propertyType) => residentialPropertyTypeLabels[propertyType])
    .join(', '), [searchCriteria?.propertyTypes]);

  const goToSearchCriteriaPage = React.useCallback(() => {
    goToDetailPage(searchCriteria.id!, ReferenceType.SEARCH_CRITERIA);
  }, [searchCriteria.id]);

  const showAsBadge = React.useMemo(() => !(userIsAdmin || userIsAgent || userIsContentEditor || userIsCallCenter), [userIsAdmin, userIsAgent, userIsCallCenter, userIsContentEditor]);

  return (
    <SimpleTable.Row
      data-testid={`search-criteria-preview-table-row-${searchCriteria.id}`}
      aria-label="Informazioni ricerca"
    >
      <SimpleTable.Cell
        aria-label="Tipologia e geo"
        width="15rem"
      >
        <ViewSearchCriteriaPopoverPreview
          searchCriteria={searchCriteria}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <SearchCriteriaAvatar size="S" />
              <Stack gap={0}>
                <Text.BodySmall css={setVerticalEllipsis(1)}>
                  {propertyTypes}
                </Text.BodySmall>
                <Text.BodySmall
                  fontWeight={FontWeight.MEDIUM}
                  css={setVerticalEllipsis(1)}
                >
                  {searchCriteria.geo.map(getGeoCriterionLabel).join(', ')}
                </Text.BodySmall>
              </Stack>
            </HStack>
          )}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Stato">
        <UpdateSearchCriteriaStatusAction
          searchCriteria={searchCriteria}
          showAsBadge={showAsBadge}
          onSuccess={onSearchCriteriaUpdate}
          size="XS"
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Risultati matching">
        {areMatchingPropertiesLoading && (
          <Skeleton
            aria-label="Risultati matching in caricamento"
            width="3rem"
          />
        )}
        {!areMatchingPropertiesLoading && (
          <DetailStack label="Risultati">
            {matchingProperties?.totalElements
              ? `
                  ${matchingProperties.totalElements}
                  ${pluralizeWord('immobil', 'e', 'i', matchingProperties.totalElements)}
                  ${pluralizeWord('trovat', 'o', 'i', matchingProperties.totalElements)}
                  `
              : NO_VALUE_SYMBOL}
          </DetailStack>
        )}
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Data di creazione">
        <DetailStack label="Aggiunta">
          <DateViewer
            stringDate={searchCriteria.createdAt!}
            checkIfToday
          />
        </DetailStack>
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Azioni"
        align="right"
      >
        <ActionIcon
          aria-label="Visualizza ricerca"
          label="Visualizza"
          size="S"
          icon={{ path: ICON_EYE_OUTLINE }}
          onClick={goToSearchCriteriaPage}
        />
      </SimpleTable.Cell>
    </SimpleTable.Row>
  );
};

export default ViewSearchCriteriaPreviewTableRow;

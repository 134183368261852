import React from 'react';
import {
  Action, ConfirmModal, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Rumor } from '../../../providers/api/dtos';
import { RumorStatus } from '../../../domain/types';
import { updateRumor } from '../../../providers/api/rumor/rumorProvider';
import { useComputedRumorStatus } from '../../hooks/use-computed-rumor-status/useComputedRumorStatus';
import { rumorStatusLabels } from '../../constants';

export const RESTORE_RUMOR_SUCCESS_MESSAGE = 'La notizia è stata ripristinata con successo';
export const RESTORE_RUMOR_ERROR_MESSAGE = 'Non è stato possibile ripristinare la notizia';

interface ChildFnProps {
  restoreRumor: VoidFunction,
  loading: boolean,
}

export interface RestoreRumorActionProps {
  rumor: Rumor,
  onSuccess?: (restoredRumor: Rumor) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const RestoreRumorAction: React.FC<RestoreRumorActionProps> = ({
  rumor,
  onSuccess,
  children,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const { canBeUpdatedTo } = useComputedRumorStatus(rumor);
  const modal = useModal();

  const openRestoreRumorModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const onConfirm = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const rumorToRestore: Rumor = {
        ...rumor,
        status: RumorStatus.IN_PROGRESS,
      };

      const restoredRumor = await updateRumor(rumor.id!, rumorToRestore);

      setIsSaving(false);
      modal.close();
      addSuccess(RESTORE_RUMOR_SUCCESS_MESSAGE);

      onSuccess?.(restoredRumor);
    } catch (error) {
      setIsSaving(false);
      addError(RESTORE_RUMOR_ERROR_MESSAGE);
    }
  }, [rumor, modal, addSuccess, onSuccess, addError]);

  if (!canBeUpdatedTo(RumorStatus.IN_PROGRESS)) {
    return null;
  }

  return (
    <>
      {
        children?.({
          restoreRumor: openRestoreRumorModal,
          loading: isSaving,
        }) ?? (
          <Action
            label="Ripristina"
            aria-label="Ripristina la notizia"
            title="Ripristina la notizia"
            loading={isSaving}
            size="S"
            onClick={openRestoreRumorModal}
          />
        )
      }
      <Portal>
        <ConfirmModal
          isOpen={modal.isOpen}
          aria-label="Conferma il ripristino della notizia"
          title="Ripristina notizia"
          saving={isSaving}
          onConfirm={onConfirm}
          onAbort={modal.close}
        >
          Confermando l&apos;azione la notizia sarà riportata nello stato {rumorStatusLabels[RumorStatus.IN_PROGRESS]}.
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default RestoreRumorAction;

/* eslint-disable react/no-unknown-property */
import { Popover, PopoverProps } from '@doveit/bricks';
import React from 'react';
import { Lead, Prospect } from '../../../providers/api/dtos';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import * as styles from './ViewProspectPopoverPreview.style';
import PopoverPreview from '../../../components/popover-preview/PopoverPreview';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import ProspectAvatar from '../../components/prospect-avatar/ProspectAvatar';
import ViewProspectPopoverContent from './ViewProspectPopoverContent';

interface BaseViewProspectPopoverPreviewProps extends React.AriaAttributes {
  propertyAddress: Lead['propertyAddress'],
  trigger: PopoverProps['trigger'],
  hideAction?: boolean,
}

export type ViewProspectPopoverPreviewProps = BaseViewProspectPopoverPreviewProps & ({
  prospectId: NonNullable<Prospect['id']>,
  prospect?: undefined,
} | {
  prospect: Prospect,
  prospectId?: undefined,
});

const ViewProspectPopoverPreview: React.FC<ViewProspectPopoverPreviewProps> = ({
  propertyAddress,
  prospectId,
  prospect: externalProspect,
  trigger,
  hideAction = false,
  ...rest
}) => {
  const internalProspectId = externalProspect?.id ?? prospectId!;
  const goToProspectPage = React.useCallback(
    () => goToDetailPage(internalProspectId, ReferenceType.PROSPECT),
    [internalProspectId],
  );

  return (
    <Popover
      trigger={trigger}
      hasArrow
      {...rest}
    >
      <div css={styles.wrapper}>
        <PopoverPreview
          aria-label="Informazioni valutazione"
          title={truncateTextWithSuffix(propertyAddress, 60)}
          avatar={<ProspectAvatar size="L" />}
          action={!hideAction ? {
            label: 'Visualizza valutazione',
            'aria-label': 'Vai alla pagina della valutazione',
            onClick: goToProspectPage,
          } : undefined}
        >
          <ViewProspectPopoverContent prospectId={internalProspectId} prospect={externalProspect} />
        </PopoverPreview>
      </div>
    </Popover>
  );
};

export default ViewProspectPopoverPreview;

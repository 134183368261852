import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const publishedOnsPopoverWrapper: ThemedFunctor = ({ theme }) => css`
  min-width: 12.5rem;
  padding: ${theme.remSize[150]};
  display: flex;
  flex-direction: column;
  gap: ${theme.remSize[150]};
`;

export const notePopoverWrapper: ThemedFunctor = ({ theme }) => css`
  min-width: 25rem;
  padding: ${theme.remSize[150]};
`;

export const shootingStatusWrapper = () => css`
  width: 6.75rem;
`;

import React from 'react';
import {
  Badge, Card, CardSkeleton, DetailItemList, Dropdown, HStack, ICON_CHECK, ICON_HANDSHAKE_OUTLINE, Message,
} from '@doveit/bricks';
import { formatDate } from '@doveit/hammer';
import { useNavigate } from 'react-router-dom';
import useAssignmentByPropertyId from '../../../hooks/use-assignment-by-property-id/useAssignmentByPropertyId';
import { extraPackageLabels } from '../../../../labels/extraPackageLabels';
import EditPropertyAssignmentAction from '../../containers/edit-property-assignment-action/EditPropertyAssignmentAction';
import useRBAC from '../../../../hooks/use-rbac/useRBAC';
import { useCurrentAgentIsSameAgentOrManager } from '../../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import { Status } from '../../../../domain/types';
import EditAssignmentExtraPackagesAction from '../../../containers/edit-assignment-extra-packages-action/EditAssignmentExtraPackagesAction';
import useAssignmentSignatureStatus from '../../../../prospect/hooks/use-assignment-signature-status/useAssignmentSignatureStatus';
import { SignatureStatus } from '../../../../providers/api/dtos';
import useAssignmentWizardData from '../../../../prospect/hooks/use-assignment-wizard-data/useAssignmentWizardData';
import { AssignmentAction } from '../../../../assignment/types';
import CancelAssignmentSignatureRequestAction from '../../../../assignment/containers/cancel-assignment-signature-request-action/CancelAssignmentSignatureRequestAction';
import DeleteAssignmentSignatureRequestAction from '../../../../assignment/containers/delete-assignment-signature-request-action/DeleteAssignmentSignatureRequestAction';

export const PROPERTY_ASSIGNMENT_LOADING_ERROR_MESSAGE = 'Non è stato possibile recuperare i dati del mandato.';
export const SIGNATURE_ONGOING_PROCESS_MESSAGE = 'È in corso una procedura di rinnovo del mandato con firma digitale';

export interface PropertyAssignmentWidgetProps {
  propertyId: number
  propertyStatus: Status,
}

const PropertyAssignmentWidget: React.FC<PropertyAssignmentWidgetProps> = ({
  propertyId,
  propertyStatus,
}) => {
  const navigate = useNavigate();

  const {
    userIsAdmin, userIsCallCenter, userIsContentEditor, userIsAgent,
  } = useRBAC();

  const {
    data: assignment, isLoading: isAssignmentLoading, error: assignmentError, mutate: mutateAssignment,
  } = useAssignmentByPropertyId(propertyId);

  const { data: signatureStatus, mutate: mutateAssignmentSignatureStatus } = useAssignmentSignatureStatus(assignment?.prospectId);

  const isOwnerOrManager = useCurrentAgentIsSameAgentOrManager(assignment?.agentId);

  const { data: assignmentWizardData } = useAssignmentWizardData(assignment?.prospectId);

  const canViewWidget = React.useMemo(() => {
    if ((propertyStatus === Status.RITIRATO && userIsCallCenter) || (userIsAgent && !isOwnerOrManager)) {
      return false;
    }

    return true;
  }, [isOwnerOrManager, propertyStatus, userIsAgent, userIsCallCenter]);

  const canEditAssignment = React.useMemo(() => {
    if (userIsAdmin || ((isOwnerOrManager || userIsContentEditor) && [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE, Status.PROPOSTA].includes(propertyStatus))) {
      return true;
    }

    return false;
  }, [isOwnerOrManager, propertyStatus, userIsAdmin, userIsContentEditor]);

  const userIsAdminAgentOrManager = React.useMemo(() => userIsAdmin || isOwnerOrManager, [userIsAdmin, isOwnerOrManager]);

  const canViewExtraPackage = React.useMemo(() => (!(propertyStatus === Status.RITIRATO && (userIsContentEditor || userIsCallCenter))), [propertyStatus, userIsCallCenter, userIsContentEditor]);

  const canAddExtraPackage = React.useMemo(() => (userIsAdminAgentOrManager || userIsContentEditor) && [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE].includes(propertyStatus),
    [userIsAdminAgentOrManager, userIsContentEditor, propertyStatus]);

  const canEditExtraPackages = React.useMemo(() => (userIsAdmin || userIsContentEditor) || (isOwnerOrManager && [Status.ANTEPRIMA, Status.DRAFT].includes(propertyStatus)),
    [isOwnerOrManager, propertyStatus, userIsAdmin, userIsContentEditor]);

  const canCreateNewAssignment = React.useMemo(() => (userIsAdminAgentOrManager || userIsContentEditor) && [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE, Status.RITIRATO].includes(propertyStatus) && signatureStatus?.status !== SignatureStatus.ONGOING, [userIsAdminAgentOrManager, userIsContentEditor, propertyStatus, signatureStatus?.status]);

  const canRenewAssignment = React.useMemo(() => (userIsAdminAgentOrManager || userIsContentEditor) && [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE].includes(propertyStatus) && assignmentWizardData && signatureStatus?.status !== SignatureStatus.ONGOING, [assignmentWizardData, userIsAdminAgentOrManager, userIsContentEditor, propertyStatus, signatureStatus?.status]);

  const canCancelAssignmentSignatureProcess = React.useMemo(() => userIsAdminAgentOrManager && assignmentWizardData && signatureStatus?.status === SignatureStatus.ONGOING, [assignmentWizardData, userIsAdminAgentOrManager, signatureStatus?.status]);

  const canDeleteAssignmentSignatureProcess = React.useMemo(() => userIsAdminAgentOrManager && assignmentWizardData && signatureStatus?.status === SignatureStatus.EXPIRED, [assignmentWizardData, userIsAdminAgentOrManager, signatureStatus?.status]);

  const goToAssignmentModePage = React.useCallback((action: AssignmentAction) => () => {
    navigate(`/prospects/${assignment?.prospectId}/assignment/${action}`);
  }, [assignment?.prospectId, navigate]);

  const editAssignmentIsLimited = React.useMemo(
    () => (userIsAgent && isOwnerOrManager) && !userIsContentEditor && !userIsAdmin && propertyStatus !== Status.DRAFT,
    [isOwnerOrManager, propertyStatus, userIsAdmin, userIsAgent, userIsContentEditor],
  );

  if (!canViewWidget) {
    return null;
  }

  if (isAssignmentLoading) {
    return (
      <CardSkeleton
        aria-label="Sezione del mandato in caricamento"
      />
    );
  }

  if (assignmentError) {
    return (
      <Card>
        <Card.Header
          title="Mandato"
          color="critical"
        />
        <Card.Content>
          <Message
            message={PROPERTY_ASSIGNMENT_LOADING_ERROR_MESSAGE}
          />
        </Card.Content>
      </Card>
    );
  }

  return assignment && (
    <Card aria-label="Sezione del mandato">
      <Card.Header
        title="Mandato"
        icon={{ path: ICON_HANDSHAKE_OUTLINE }}
        primaryActions={[
          canEditAssignment && (
            <EditPropertyAssignmentAction
              propertyId={propertyId}
              limitEdit={editAssignmentIsLimited}
              onEditAssignmentSuccess={mutateAssignment}
            />
          ),
        ]}
        secondaryActions={[
          <EditAssignmentExtraPackagesAction
            propertyId={propertyId}
            canAdd={canAddExtraPackage}
            canEdit={canEditExtraPackages}
            onSuccess={mutateAssignment}
          >
            {({ openEditExtraPackagesModal }) => (
              <Dropdown.Option
                label="Visualizza pacchetti"
                onClick={openEditExtraPackagesModal}
              />
            )}
          </EditAssignmentExtraPackagesAction>,
          (canCreateNewAssignment && (
            <Dropdown.Option
              label="Nuovo mandato"
              aria-label="Avvia procedura di creazione di un nuovo mandato"
              onClick={goToAssignmentModePage('edit')}
            />
          )),
          (canRenewAssignment && (
            <Dropdown.Option
              label="Rinnovo o ribasso"
              aria-label="Avvia procedura per rinnovare o ribassare il mandato"
              onClick={goToAssignmentModePage('renew')}
            />
          )),
          (canCancelAssignmentSignatureProcess && (
            <CancelAssignmentSignatureRequestAction
              assignmentWizardData={assignmentWizardData!}
              onSuccess={() => mutateAssignmentSignatureStatus()}
            />
          )),
          (canDeleteAssignmentSignatureProcess && (
            <DeleteAssignmentSignatureRequestAction
              assignmentWizardData={assignmentWizardData!}
              onSuccess={() => mutateAssignmentSignatureStatus()}
            />
          )),
        ]}
      />
      {signatureStatus?.status === SignatureStatus.ONGOING && (
        <Card.Content>
          <Message message={SIGNATURE_ONGOING_PROCESS_MESSAGE} />
        </Card.Content>
      )}

      <Card.Content>
        <DetailItemList>
          {assignment.endDate && (
            <DetailItemList.Item
              label="Scadenza"
            >
              {formatDate(new Date(assignment.endDate!))}
            </DetailItemList.Item>
          )}

          <DetailItemList.BooleanItem
            label="Esclusiva"
            value={assignment.exclusive}
          />

          <DetailItemList.BooleanItem
            label="Rinnovo automatico"
            value={assignment.autoRenew}
          />

          <DetailItemList.BooleanItem
            label="Chiavi"
            value={assignment.keysHanded}
          />
        </DetailItemList>
      </Card.Content>

      {canViewExtraPackage && assignment.extraPackages.length > 0 && (
        <>
          <Card.Divider />
          <Card.Content>
            <HStack
              gap={75}
              aria-label="Sezione dei pacchetti extra"
            >
              {assignment.extraPackages.map(({ id, name }) => (
                <Badge
                  key={id}
                  label={extraPackageLabels[name]}
                  color="primary"
                  icon={ICON_CHECK}
                  size="XS"
                  emphasis="low"
                />
              ))}
            </HStack>
          </Card.Content>
        </>
      )}
    </Card>
  );
};

export default PropertyAssignmentWidget;

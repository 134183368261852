import React from 'react';
import {
  ICON_ACCOUNT_OUTLINE,
  ICON_COG_OUTLINE,
  ICON_BULLHORN_OUTLINE,
  ICON_PLUS,
  ICON_FORMAT_LIST_BULLETED_SQUARE,
  ICON_MESSAGE_PROCESSING_OUTLINE,
  ICON_MAGNIFY,
  ICON_HOME_PLUS_OUTLINE,
  ICON_LINK,
  ICON_FILE_DOCUMENT_OUTLINE,
  ICON_ACCOUNT_MULTIPLE_OUTLINE,
  ICON_PHONE_OUTLINE,
  ICON_VIEW_GRID_OUTLINE,
  ICON_HEART_OUTLINE,
  ICON_ARROW_UP,
  ICON_DOMAIN,
  ICON_MAP_CHECK_OUTLINE,
  ICON_FILE_DOCUMENT_MULTIPLE_OUTLINE,
  ICON_CASH_SYNC,
  ICON_ACCOUNT_COG_OUTLINE,
  ICON_FILE_DOCUMENT_ARROW_RIGHT_OUTLINE,
  ICON_FILE_SIGN,
  ICON_HANDSHAKE_OUTLINE,
  ICON_SCHOOL_OUTLINE,
  ICON_ACCOUNT_SEARCH_OUTLINE,
  ICON_MAP_SEARCH_OUTLINE,
} from '@doveit/bricks';
import { NavigationItem } from '../../types';
import useRBAC from '../use-rbac/useRBAC';
import { useCurrentAgent } from '../use-agent/useAgent';
import { isTrialAgent } from '../../utils/agent/isTrialAgent';
import { ResourceSection } from '../../resources/types';

export function findNavigationItem(data: NavigationItem[], pathname: string): NavigationItem | undefined {
  if (data.length === 0) return undefined;

  return data.find((item) => item.path && item.path === pathname)
    || findNavigationItem(data.flatMap((item) => item.items || []), pathname)
    || undefined;
}

export default function useMainNavigation(): NavigationItem[] {
  const {
    userIsAdmin,
    userIsAgent,
    userIsCallCenter,
    userIsContentEditor,
    userIsFinance,
    userIsHR,
    userIsAreaManager,
  } = useRBAC();
  const currentAgent = useCurrentAgent();
  const franchisingAgency = React.useMemo(
    () => (currentAgent?.franchising ? currentAgent.agencies?.find((agency) => agency.franchising) : undefined),
    [currentAgent],
  );
  const navigation: NavigationItem[] = [{
    id: 'dashboard',
    label: 'Dashboard',
    path: '/',
    icon: ICON_VIEW_GRID_OUTLINE,
  }];

  const isATrialAgent = isTrialAgent(currentAgent);

  const showContactMenu = !isATrialAgent;
  const showCallCenterMenu = userIsAdmin || userIsCallCenter;
  const showProspectsMenu = userIsAdmin || userIsAgent;
  const showRumorMenu = userIsAdmin || (userIsAgent && !isATrialAgent);
  const showGeographyMenu = userIsAdmin || userIsContentEditor || userIsCallCenter;
  const showMarketingMenu = userIsAdmin || (userIsAgent && !isATrialAgent) || userIsContentEditor;
  const showAdministrationMenu = userIsAdmin || userIsFinance || userIsCallCenter || userIsHR || userIsContentEditor;
  const showBaseHrMenu = !userIsAgent || !isATrialAgent;
  const showAllHRMenuItems = userIsAdmin || userIsHR || userIsAreaManager;
  const enableResourcesMenuItem = userIsAdmin || userIsContentEditor || userIsAgent || userIsHR;

  if (showCallCenterMenu) {
    navigation.push({
      id: 'call-center',
      label: 'Call Center',
      title: 'Call center',
      icon: ICON_PHONE_OUTLINE,
      items: [
        {
          id: 'call-center-add',
          path: '/call-center/contacts/create',
          label: 'Aggiungi lead',
          icon: ICON_PLUS,
        },
        {
          id: 'call-center-process',
          path: '/call-center/contacts/process',
          label: 'Gestisci lead',
          icon: ICON_FORMAT_LIST_BULLETED_SQUARE,
        },
        {
          id: 'call-center-messaging',
          path: '/call-center/messaging',
          label: 'Centro messaggi',
          icon: ICON_MESSAGE_PROCESSING_OUTLINE, // @TODO: Sostiture icon dopo avere aggiunto su Bricks
        },
      ],
    });
  }

  if (showContactMenu) {
    navigation.push({
      id: 'contacts',
      label: 'Contatti',
      icon: ICON_ACCOUNT_OUTLINE,
      items: [
        {
          id: 'contacts-search',
          path: '/contacts/search',
          label: 'Cerca contatto',
          icon: ICON_MAGNIFY,
        },
      ],
    });
  }

  navigation.push(
    {
      id: 'intents-all',
      label: 'Interessi',
      icon: ICON_HEART_OUTLINE,
      items: [
        {
          id: 'intents-add',
          path: '/intents/create',
          label: 'Aggiungi interesse',
          icon: ICON_PLUS,
        },
        {
          id: 'intents-process',
          path: '/intents/explore',
          label: 'Gestisci interessi',
          icon: ICON_FORMAT_LIST_BULLETED_SQUARE,
        },
        {
          id: 'search-criteria-add',
          path: '/search-criteria/create',
          label: 'Aggiungi ricerca',
          icon: ICON_HOME_PLUS_OUTLINE,
        },
      ],
    },
  );

  if (showProspectsMenu) {
    const prospectMenu: NavigationItem = {
      id: 'handle-prospects',
      label: 'Acquisizione',
      icon: ICON_ARROW_UP,
      items: [{
        id: 'handle-prospects',
        path: '/prospects/explore',
        label: 'Gestisci valutazioni',
        icon: ICON_FORMAT_LIST_BULLETED_SQUARE,
      }],
    };

    if (showRumorMenu) {
      prospectMenu.items = [
        {
          id: 'rumor-add',
          path: '/rumors/create',
          label: 'Aggiungi notizia',
          icon: ICON_PLUS,
        },
        {
          id: 'handle-rumors',
          path: '/rumors/explore',
          label: 'Gestisci notizie',
          icon: ICON_FORMAT_LIST_BULLETED_SQUARE,
        },
        ...prospectMenu.items!,
      ];
    }

    navigation.push(prospectMenu);
  }

  const propertyItems: NavigationItem = {
    id: 'properties-all',
    label: 'Immobili',
    icon: ICON_DOMAIN,
    items: [
      {
        id: 'handle-properties',
        path: '/properties/explore',
        label: 'Gestisci immobili',
        icon: ICON_FORMAT_LIST_BULLETED_SQUARE,
      },
    ],
  };

  navigation.push(propertyItems);

  if (showGeographyMenu) {
    navigation.push(
      {
        id: 'areas-search',
        label: 'Geografie',
        icon: ICON_MAP_CHECK_OUTLINE,
        items: [
          {
            id: 'search-agent-by-cap',
            label: 'Cerca CAP',
            icon: ICON_MAGNIFY,
            path: '/agent/search/zipcode',
          },
          {
            id: 'search-agent-by-locality',
            label: 'Cerca città',
            icon: ICON_MAP_SEARCH_OUTLINE,
            path: '/agent/search/locality',
          },
          {
            id: 'search-agent-by-areas',
            label: 'Cerca agente',
            icon: ICON_ACCOUNT_SEARCH_OUTLINE,
            path: '/agent/search/areas',
          },
        ],
      },
    );
  }

  if (showMarketingMenu) {
    navigation.push(
      {
        id: 'marketing',
        label: 'Marketing',
        icon: ICON_BULLHORN_OUTLINE,
        items: [
          {
            id: 'tracked-links-create',
            path: '/marketing',
            label: 'Crea link tracciato',
            icon: ICON_LINK,
          },
          {
            id: 'catalogue',
            path: '/marketing/catalogues',
            label: 'Materiali promozionali',
            icon: ICON_FILE_DOCUMENT_OUTLINE,
          },
          {
            id: 'generate-materials',
            path: '/marketing/generate',
            label: 'Genera materiali',
            icon: ICON_FILE_DOCUMENT_ARROW_RIGHT_OUTLINE,
          },
        ],
      },
    );
  }

  const adminItems: NavigationItem[] = [];

  if (userIsAdmin || userIsContentEditor) {
    adminItems.push({
      id: 'agent-manage',
      path: '/admin/agents',
      label: 'Gestisci agenti',
      icon: ICON_ACCOUNT_COG_OUTLINE,
    });
  }

  if (userIsAdmin || userIsCallCenter || userIsHR || userIsContentEditor) {
    adminItems.push({
      id: 'agent-search',
      path: '/admin/agents/search',
      label: 'Cerca agente',
      icon: ICON_MAGNIFY,
    });
  }

  if (userIsAdmin || userIsFinance) {
    adminItems.push({
      id: 'invoices',
      path: '/admin/invoices/todo',
      label: 'Gestisci fatturazione',
      icon: ICON_CASH_SYNC,
    });
  }

  if (showAdministrationMenu) {
    navigation.push(
      {
        id: 'administration',
        label: 'Amministrazione',
        icon: ICON_COG_OUTLINE,
        items: adminItems,
      },
    );
  }

  if (enableResourcesMenuItem) {
    navigation.push(
      {
        id: 'resources',
        label: 'Risorse',
        icon: ICON_FILE_DOCUMENT_MULTIPLE_OUTLINE,
        items: isATrialAgent
          ? [
            {
              id: 'documentation',
              label: 'Documentazione',
              path: franchisingAgency ? `/resources/${franchisingAgency.id}/${ResourceSection.DOCUMENTATION}` : `/resources/${ResourceSection.DOCUMENTATION}`,
              icon: ICON_FILE_SIGN,
            },
          ]
          : [{
            id: 'documentation',
            label: 'Documentazione',
            path: franchisingAgency ? `/resources/${franchisingAgency.id}/${ResourceSection.DOCUMENTATION}` : `/resources/${ResourceSection.DOCUMENTATION}`,
            icon: ICON_FILE_SIGN,
          },
          {
            id: 'partners',
            label: 'Partner',
            path: franchisingAgency ? `/resources/${franchisingAgency.id}/${ResourceSection.PARTNERS}` : `/resources/${ResourceSection.PARTNERS}`,
            icon: ICON_HANDSHAKE_OUTLINE,
          },
          {
            id: 'training',
            label: 'Formazione',
            path: franchisingAgency ? `/resources/${franchisingAgency.id}/${ResourceSection.TRAINING}` : `/resources/${ResourceSection.TRAINING}`,
            icon: ICON_SCHOOL_OUTLINE,
          }],
      },
    );
  }

  if (showBaseHrMenu) {
    const hrMenu: NavigationItem = {
      id: 'hr',
      label: 'HR',
      icon: ICON_ACCOUNT_MULTIPLE_OUTLINE,
      items: [
        {
          id: 'job-application-add',
          path: '/job-applications/create',
          label: 'Aggiungi candidatura',
          icon: ICON_PLUS,
        },
      ],
    };

    if (showAllHRMenuItems) {
      hrMenu.items!.push(
        {
          id: 'job-application-manage',
          path: '/job-applications/process/todo',
          label: 'Gestisci candidature',
          icon: ICON_FORMAT_LIST_BULLETED_SQUARE,
        },
        {
          id: 'job-application-search',
          path: '/job-applications/search',
          label: 'Cerca candidatura',
          icon: ICON_MAGNIFY,
        },
      );
    }

    navigation.push(hrMenu);
  }

  return navigation;
}

import React from 'react';
import {
  ActionIcon,
  DetailStack,
  FontWeight, HStack, ICON_EYE_OUTLINE, Skeleton, Text,
  theme,
  UserPreviewSkeleton,
} from '@doveit/bricks';
import { Agent, Lead } from '../../../providers/api/dtos';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import PropertyAvatar from '../../../property/components/property-avatar/PropertyAvatar';
import UpdateLeadStatus from '../../../lead/containers/update-lead-status/UpdateLeadStatus';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { EditLeadAction } from '../../../lead/containers/edit-lead-action/EditLeadAction';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { LeadStatus, ReferenceType } from '../../../domain/types';
import useSearchProspects from '../../../prospect/hooks/use-search-prospects/useSearchProspects';
import { useLeadAssignedAgent } from '../../../lead/hooks/use-lead-assigned-agent/useLeadAssignedAgent';
import { AgentPreview } from '../../../agent/components';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { evaluationTypeLabels } from '../../../labels';
import ViewLeadPopoverPreview from '../../../lead/containers/view-lead-popover-preview/ViewLeadPopoverPreview';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import { setVerticalEllipsis } from '../../../utils/styles/verticalEllipsis.style';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';

type ComputedAgent = {
  agent?: Agent,
  error?: {
    label: string,
  }
  details?: string,
};

const errorMapping: Record<number, string> = {
  404: 'Non coperto',
  412: 'Non assegnabile',
};

export interface ViewContactLeadRowProps {
  lead: Lead,
  onLeadUpdate?: (leadUpdated: Lead) => void,
}

const ViewContactLeadRow: React.FC<ViewContactLeadRowProps> = ({
  lead,
  onLeadUpdate,
}) => {
  const { userIsAdmin, userIsCallCenter } = useRBAC();

  const isAppointmentScheduled = React.useMemo(() => lead.status === LeadStatus.APPOINTMENT_SCHEDULED, [lead.status]);
  const canEditLead = React.useMemo(() => userIsAdmin || userIsCallCenter, [userIsAdmin, userIsCallCenter]);

  const showAsBadge = React.useMemo(() => !(userIsAdmin || userIsCallCenter), [userIsAdmin, userIsCallCenter]);

  const {
    data: leadAssignedAgent, error: leadAssignedAgentError, isLoading: isLeadAssignedAgentLoading, mutate: mutateLeadAssignedAgent,
  } = useLeadAssignedAgent(lead.status !== LeadStatus.APPOINTMENT_SCHEDULED ? lead.id! : undefined);
  const { data: prospects, isLoading: isProspectsLoading, error: prospectsError } = useSearchProspects(isAppointmentScheduled ? { leadId: [lead.id!] } : undefined);
  const { data: agent, error: agentError, isLoading: isAgentLoading } = useAgent(prospects?.content?.[0]?.agentId);

  const computedAgentPreview: ComputedAgent = React.useMemo(() => {
    if (leadAssignedAgentError || agentError || prospectsError) {
      const statusCode = leadAssignedAgentError?.response?.status;

      return {
        error: {
          label: statusCode && statusCode in errorMapping ? errorMapping[statusCode] : 'Errore agente',
        },
      };
    }

    if (lead.status !== LeadStatus.APPOINTMENT_SCHEDULED && leadAssignedAgent) {
      const { agent: assignedAgent, evaluationType } = leadAssignedAgent;

      return {
        agent: assignedAgent,
        details: lead.originAgentId ? 'Personal' : evaluationTypeLabels[evaluationType],
      };
    }

    if (agent) {
      return {
        agent,
        details: lead.originAgentId === agent!.id! ? 'Personal' : undefined,
      };
    }

    return {};
  }, [agent, agentError, lead.originAgentId, lead.status, leadAssignedAgent, leadAssignedAgentError, prospectsError]);

  const leadProspect = React.useMemo(() => prospects?.content[0], [prospects]);

  const onInternalSuccess = React.useCallback((leadUpdated: Lead) => () => {
    mutateLeadAssignedAgent();
    onLeadUpdate?.(leadUpdated);
  }, [mutateLeadAssignedAgent, onLeadUpdate]);

  const goToProspectPage = React.useCallback(() => {
    goToDetailPage(leadProspect!.id!, ReferenceType.PROSPECT);
  }, [leadProspect]);

  return (
    <SimpleTable.Row>
      <SimpleTable.Cell>
        <ViewLeadPopoverPreview
          leadId={lead.id!}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <PropertyAvatar size="S" />
              <Text.BodySmall fontWeight={FontWeight.MEDIUM} css={setVerticalEllipsis()}>
                {lead.propertyAddress}
              </Text.BodySmall>
            </HStack>
          )}
        />
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <UpdateLeadStatus
          lead={lead}
          showAsBadge={showAsBadge}
          size="XS"
          onSuccess={onLeadUpdate}
        />
      </SimpleTable.Cell>
      <SimpleTable.Cell width="10rem">
        {(isLeadAssignedAgentLoading || isAgentLoading) && (
          <UserPreviewSkeleton size="S" aria-label="Informazioni sull'agente in caricamento" />
        )}
        {computedAgentPreview.error && (
          <AgentPreview
            size="S"
            name={computedAgentPreview.error.label}
          />
        )}
        {computedAgentPreview.agent && (
          <ViewAgentPopoverPreview
            agent={computedAgentPreview.agent}
            trigger={(
              <AgentPreview
                {...toAgentPreviewProps(computedAgentPreview.agent)}
                details={computedAgentPreview.details}
                size="S"
              />
            )}
          />
        )}
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <DetailStack label="Aggiunta">
          <DateViewer
            checkIfToday
            stringDate={lead.createdAt!}
          />
        </DetailStack>
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <DetailStack label="Aggiornata">
          <DateViewer
            checkIfToday
            stringDate={lead.updatedAt!}
          />
        </DetailStack>
      </SimpleTable.Cell>
      <SimpleTable.Cell align="right">
        {!isAppointmentScheduled && canEditLead && (
          <EditLeadAction
            lead={lead}
            size="S"
            onSuccess={onInternalSuccess(lead)}
          />
        )}
        {isProspectsLoading && (
          <Skeleton
            aria-label="lead-action-skeleton"
            width={theme.remHeight.S}
            height={theme.remHeight.S}
          />
        )}
        {leadProspect && (
          <ActionIcon
            aria-label="Visualizza valutazione"
            label="Visualizza"
            size="S"
            icon={{ path: ICON_EYE_OUTLINE }}
            onClick={goToProspectPage}
          />
        )}
      </SimpleTable.Cell>
    </SimpleTable.Row>
  );
};

export default ViewContactLeadRow;

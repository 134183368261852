import {
  ActionIcon, ICON_NOTE_PLUS_OUTLINE, ICON_PENCIL_OUTLINE, Portal, useModal,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback } from 'react';
import { Note } from '../../../providers/api/dtos';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import NoteForm, { NoteFormModel } from '../../components/note-form/NoteForm';
import SimpleModal from '../../../components/simple-modal/SimpleModal';

interface ChildFnProps {
  upsert: VoidFunction,
}

export interface UpsertNotesActionProps {
  note?: Note,
  showNudgeCheckbox?: boolean,
  upsertNote?: (updatedNote: Note) => Promise<void>,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const UpsertNoteAction: FunctionComponent<UpsertNotesActionProps> = ({
  note,
  showNudgeCheckbox = false,
  upsertNote,
  children,
}) => {
  const { user, userIsCallCenter, mainUserRole } = useRBAC();
  const [isSaving, setIsSaving] = React.useState(false);
  const modal = useModal<{ note?: Note }>();

  const openModal = useCallback(
    () => modal.open(),
    [modal],
  );

  const handleUpdateNote = React.useCallback(async (updatedNote: NoteFormModel) => {
    if (!note) return;

    setIsSaving(true);
    note.nudge = updatedNote.nudge;
    note.text = updatedNote.text!;

    await upsertNote?.(note!);
    setIsSaving(false);
    modal.close();
  }, [note, upsertNote, modal]);

  const handleNewNote = React.useCallback(async ({ nudge, text }: Partial<Note>) => {
    setIsSaving(true);

    const newNote: Note = {
      date: new Date().toISOString(),
      role: mainUserRole,
      author: user.name,
      text: text?.trim() || '',
      nudge: nudge || false,
    };

    await upsertNote?.(newNote);
    setIsSaving(false);
    modal.close();
  }, [mainUserRole, modal, upsertNote, user.name]);

  return (
    <>
      {children
        ? children({
          upsert: openModal,
        })
        : (
          <ActionIcon
            icon={note ? { path: ICON_PENCIL_OUTLINE } : { path: ICON_NOTE_PLUS_OUTLINE }}
            label={note ? 'Modifica' : 'Aggiungi'}
            aria-label={note ? 'Pulsante per modificare la nota' : 'Pulsante per aggiungere una nota'}
            size="S"
            onClick={openModal}
          />
        )}
      <Portal>
        <SimpleModal
          isOpen={modal.isOpen}
          close={modal.close}
          title={note ? 'Modifica nota' : 'Aggiungi nota'}
          aria-label={note ? 'Modale per modificare la nota' : 'Modale per aggiungere una nota'}
        >
          <NoteForm
            initialValues={note}
            loading={isSaving}
            showNudgeCheckbox={showNudgeCheckbox && userIsCallCenter && !note?.date}
            onSubmit={note ? handleUpdateNote : handleNewNote}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default UpsertNoteAction;

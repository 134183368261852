import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import hash from 'object-hash';
import { AxiosError } from 'axios';
import React from 'react';
import { getAgentByPropertyId, getAgent, GetAgentOptions } from '../../providers/api/agent/agentProvider';
import useRBAC from '../use-rbac/useRBAC';
import { EntityType } from '../../domain/types/entityType';
import { Agent } from '../../providers/api/dtos';
import { DescendantsStrategy } from '../../domain/types';
import useAgents from '../use-agents/useAgents';
import { MAX_PAGED_SIZE } from '../../constants';

export const AGENTS_RID = '/v5/agents';
export const AGENTS_BY_PROPERTY_ID_RID = 'property/:id/agent';

export function useAgentByPropertyId(
  propertyId?: number,
  options?: SWRConfiguration<Agent, AxiosError>,
): SWRResponse<Agent, AxiosError> {
  const key = propertyId ? [AGENTS_BY_PROPERTY_ID_RID, propertyId.toString()] : null;
  return useSWR(key, () => getAgentByPropertyId(propertyId!), options);
}

export function useAgent(idOrEmail?: number | string, options: GetAgentOptions = {}): SWRResponse<Agent, Error> {
  const key = idOrEmail ? [AGENTS_RID, idOrEmail.toString(), hash(options)] : null;
  return useSWR(key, () => getAgent(idOrEmail!, options));
}

export type UseCurrentAgentSyncResult = {
  data?: Agent | undefined,
  isLoading: boolean,
  errors?: Error[] | undefined,
};

// TODO Let's replace useCurrentAgent with this one (and after the replacement, rename it!) - see https://doveit.atlassian.net/browse/DOVE-13775
export function useCurrentAgentSync(): UseCurrentAgentSyncResult {
  const { user, userIsAgent, userIsCoordinator } = useRBAC();

  const { data: agent, isLoading: isAgentLoading, error: agentError } = useAgent(
    userIsAgent ? user.email : undefined,
    { descendantsStrategy: DescendantsStrategy.ALL },
  );

  const { data: coordinatedAgents, isLoading: isAgentsLoading, error: agentsError } = useAgents(
    !isAgentLoading && (agent && userIsCoordinator) ? { coordinatedBy: user.email } : undefined,
    { size: MAX_PAGED_SIZE },
  );

  const managedAgents = new Set([
    ...(agent?.canManage || []),
    ...(coordinatedAgents?.content || []),
  ]);

  const hookErrors = React.useMemo(() => [agentError, agentsError].filter((item) => item !== undefined), [agentError, agentsError]);

  return {
    data: agent ? {
      ...agent,
      canManage: Array.from(managedAgents),
    } : undefined,
    isLoading: !!isAgentLoading || !!isAgentsLoading,
    errors: hookErrors.length > 0 ? hookErrors : undefined,
  };
}

/**
 * @deprecated User useCurrentAgentSync instead
 */
export function useCurrentAgent(): Agent | undefined {
  const { user, userIsAgent, userIsCoordinator } = useRBAC();

  const { data: agent } = useAgent(
    userIsAgent ? user.email : undefined,
    { descendantsStrategy: DescendantsStrategy.ALL },
  );

  const { data: coordinatedAgents } = useAgents(
    (agent && userIsCoordinator) ? { coordinatedBy: user.email } : undefined,
    { size: MAX_PAGED_SIZE },
  );

  if (!agent) return undefined;

  const managedAgents = new Set([
    ...(agent?.canManage || []),
    ...(coordinatedAgents?.content || []),
  ]);

  return {
    ...agent,
    canManage: Array.from(managedAgents),
  };
}

export function useAgentByEntity(entityId: string | number, entityType: EntityType): SWRResponse<Agent, Error> {
  let fetcher: any;
  let key: string[];

  if (entityType === EntityType.AGENT) {
    key = [AGENTS_RID, entityId.toString()];
    fetcher = () => getAgent(entityId);
  } else {
    key = [AGENTS_BY_PROPERTY_ID_RID, entityId.toString()];
    fetcher = () => getAgentByPropertyId(entityId as number);
  }

  return useSWR(key, fetcher);
}

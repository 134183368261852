import React from 'react';
import {
  BrowserRouter, Navigate, Routes, Route,
} from 'react-router-dom';
import { NotificationsProvider } from '@doveit/bricks';
import { SWRConfig } from 'swr';
import { Auth0Provider } from '@auth0/auth0-react';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import AppLayout from './layouts/app-layout/AppLayout';
import AgentsSearchPage from './agent/pages/search-by-geo/AgentsSearchPage';
import CallCenterContactProcessPage from './call-center/pages/process/CallCenterContactProcessPage';
import CreateLeadPage from './lead/pages/create/CreateLeadPage';
import SPBPage from './pages/spb/SPBPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import CreateIntentPage from './intent/pages/create/CreateIntentPage';
import ViewProspectPage from './prospect/pages/view/ViewProspectPage';
import ViewIntentPage from './intent/pages/view/ViewIntentPage';
import BrowseAgentsPage from './agent/pages/browse/BrowseAgentsPage';
import AssignmentModePage from './prospect/pages/assignment-mode/AssignmentModePage';
import SearchAgentPage from './agent/pages/search/SearchAgentPage';
import InvoiceProcessPage from './invoice/pages/process/InvoiceProcessPage';
import ViewContactPage from './contact/pages/view/ViewContactPage';
import SearchContactPage from './contact/pages/search/SearchContactPage';
import ViewSearchCriteriaPage from './search-criteria/pages/view/ViewSearchCriteriaPage';
import CreateSearchCriteriaPage from './search-criteria/pages/create/CreateSearchCriteriaPage';
import LoadGoogleMapsAPI from './components/load-google-maps-api/LoadGoogleMapsAPI';
import CreateRumorPage from './rumor/pages/create/CreateRumorPage';
import ViewJobApplicationPage from './job-application/pages/view/ViewJobApplicationPage';
import JobApplicationProcessPage from './job-application/pages/process/JobApplicationProcessPage';
import CreateJobApplicationWithApplicantPage from './job-application/pages/create/CreateJobApplicationWithApplicantPage';
import useRBAC from './hooks/use-rbac/useRBAC';
import { UserRole } from './types';
import SearchJobApplicationPage from './job-application/pages/search/SearchJobApplicationPage';
import ViewApplicationNotificationsPage from './application-notification/pages/view/ViewApplicationNotificationsPage';
import CreateTrackedLinksPage from './marketing/pages/tracked-links/CreateTrackedLinksPage';
import CataloguePage from './marketing/pages/catalogue/CataloguePage';
import { ApplicationNotificationsProvider } from './application-notification/containers/application-notifications-provider/ApplicationNotificationsProvider';
import ViewResourcesPage from './resources/pages/view/ViewResourcesPage';
import { swrOnErrorRetry } from './config/swr-config';
import { useCurrentAgent } from './hooks/use-agent/useAgent';
import { DOVE_LOGO_ASCII, NotFoundPage } from './constants';
import RequireAuth from './containers/require-auth/RequireAuth';
import { InvoiceSection } from './invoice/types';
import { JobApplicationSection } from './job-application/types';
import CallCenterMessageCenterPage from './call-center/pages/message-center/CallCenterMessageCenterPage';
import ManageAssignmentPage from './prospect/pages/manage-assignment/ManageAssignmentPage';
import ViewAgentPage from './agent/pages/view/ViewAgentPage';
import ViewAgencyPage from './agency/pages/view/ViewAgencyPage';
import OfferModePage from './offer/pages/offer-mode/OfferModePage';
import ManageOfferPage from './offer/pages/manage-offer/ManageOfferPage';
import { isTrialAgent } from './utils/agent/isTrialAgent';
import { CallCenterContactDetailPage, CallCenterContactProcessDetailPage } from './call-center/pages/view/CallCenterView';
import ViewPropertyMatchingPage from './property/pages/matching/ViewPropertyMatchingPage';
import ViewPropertyPage from './property/pages/view/ViewPropertyPage';
import IntentSerpPage from './intent/pages/serp/IntentSerpPage';
import ProspectSerpPage from './prospect/pages/serp/ProspectSerpPage';
import { AUTH0_CLIENT_DEFAULT_OPTIONS } from './providers/clients/auth0Client';
import GenerateShowcasePage from './marketing/pages/generate-showcase/GenerateShowcasePage';
import ViewProspectMatchingPage from './prospect/pages/matching/ViewProspectMatchingPage';
import ViewRumorPage from './rumor/pages/view/ViewRumorPage';
import GenerateAgentBrochurePage from './marketing/pages/generate-agent-brochure/GenerateAgentBrochurePage';
import GenerateMaterialsPage from './marketing/pages/generate-materials/GenerateMaterialsPage';
import ViewRumorMatchingPage from './rumor/pages/matching/ViewRumorMatchingPage';
import RumorSerpPage from './rumor/pages/serp/RumorSerpPage';
import PropertySerpPage from './property/pages/serp/PropertySerpPage';

const AppRoutes: React.FC = () => {
  const { userHasAnyRole } = useRBAC();
  const currentAgent = useCurrentAgent();

  return (
    <Routes>
      {/** SPB */}
      <Route
        path="/spb"
        element={(
          <RequireAuth>
            <SPBPage />
          </RequireAuth>
        )}
      />

      {/** Dashboard */}
      <Route
        index
        element={(
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        )}
      />

      {/** Call-center - Leads */}
      <Route
        path="/call-center/contacts/process"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.CALL_CENTER, UserRole.ADMIN])}>
            <CallCenterContactProcessPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/call-center/contacts/create"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.CALL_CENTER, UserRole.ADMIN])}>
            <CreateLeadPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/call-center/contacts/:id"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.CALL_CENTER, UserRole.ADMIN, UserRole.CONTENT_EDITOR])}>
            <CallCenterContactDetailPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/call-center/contacts/process/:id"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.CALL_CENTER, UserRole.ADMIN])}>
            <CallCenterContactProcessDetailPage />
          </RequireAuth>
        )}
      />

      {/** Call center - Message center */}
      <Route
        path="/call-center/messaging"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.CALL_CENTER, UserRole.ADMIN])}>
            <CallCenterMessageCenterPage />
          </RequireAuth>
        )}
      />

      {/** Contacts */}
      <Route
        path="/contacts/search"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.CONTENT_EDITOR, UserRole.CALL_CENTER, UserRole.ADMIN, UserRole.AGENT]) && !isTrialAgent(currentAgent)}>
            <SearchContactPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/contacts/:id"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.CONTENT_EDITOR, UserRole.CALL_CENTER, UserRole.ADMIN, UserRole.AGENT, UserRole.FINANCE])}>
            <ViewContactPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/contacts/:id/intents/:intentId"
        element={<Navigate to="/intents/:intentId" />}
      />

      {/** Intents */}
      <Route
        path="/intents/create"
        element={(
          <RequireAuth>
            <CreateIntentPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/intents/explore"
        element={(
          <RequireAuth>
            <IntentSerpPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/intents/:id"
        element={(
          <RequireAuth>
            <ViewIntentPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/intents/:id/offer"
        element={(
          <RequireAuth>
            <OfferModePage />
          </RequireAuth>
        )}
      />
      <Route
        path="/intents/:id/offer/:mode"
        element={(
          <RequireAuth>
            <ManageOfferPage />
          </RequireAuth>
        )}
      />

      {/** Search criteria */}
      <Route
        path="/search-criteria/create"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.CONTENT_EDITOR, UserRole.CALL_CENTER, UserRole.ADMIN, UserRole.AGENT])}>
            <CreateSearchCriteriaPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/search-criteria/:id"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.CONTENT_EDITOR, UserRole.CALL_CENTER, UserRole.ADMIN, UserRole.AGENT])}>
            <ViewSearchCriteriaPage />
          </RequireAuth>
        )}
      />

      {/** Rumors */}
      <Route
        path="/rumors/explore"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.ADMIN, UserRole.AGENT])}>
            <RumorSerpPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/rumors/create"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.CALL_CENTER, UserRole.ADMIN, UserRole.AGENT])}>
            <CreateRumorPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/rumors/:id"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.CALL_CENTER, UserRole.ADMIN, UserRole.AGENT])}>
            <ViewRumorPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/rumors/:id/matching"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.ADMIN, UserRole.AGENT])}>
            <ViewRumorMatchingPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/prospects/explore"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.ADMIN, UserRole.AGENT])}>
            <ProspectSerpPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/prospects/:id"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.ADMIN, UserRole.AREA_MANAGER, UserRole.AGENT, UserRole.CALL_CENTER, UserRole.CONTENT_EDITOR, UserRole.COORDINATOR, UserRole.FINANCE])}>
            <ViewProspectPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/prospects/:id/matching"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.ADMIN, UserRole.AGENT, UserRole.COORDINATOR])}>
            <ViewProspectMatchingPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/prospects/:id/assignment/:action"
        element={(
          <RequireAuth>
            <AssignmentModePage />
          </RequireAuth>
        )}
      />
      <Route
        path="/prospects/:id/assignment/:action/:mode"
        element={(
          <RequireAuth>
            <ManageAssignmentPage />
          </RequireAuth>
        )}
      />

      {/** Properties */}
      <Route
        path="/properties/explore"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.ADMIN, UserRole.AGENT, UserRole.CALL_CENTER, UserRole.CONTENT_EDITOR, UserRole.FINANCE])}>
            <PropertySerpPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/properties/:id"
        element={(
          <RequireAuth>
            <ViewPropertyPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/properties/:id/matching"
        element={(
          <RequireAuth>
            <ViewPropertyMatchingPage />
          </RequireAuth>
        )}
      />

      {/** Agents/Geo */}
      <Route
        path="/agent/search/*"
        element={(
          <RequireAuth>
            <AgentsSearchPage />
          </RequireAuth>
        )}
      />

      {/** Marketing */}
      <Route
        path="/marketing"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.AGENT, UserRole.ADMIN])}>
            <CreateTrackedLinksPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/marketing/catalogues"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.AGENT, UserRole.ADMIN])}>
            <CataloguePage />
          </RequireAuth>
        )}
      />
      <Route
        path="/marketing/generate"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.AGENT, UserRole.ADMIN, UserRole.CONTENT_EDITOR, UserRole.COORDINATOR])}>
            <GenerateMaterialsPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/marketing/generate/showcase"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.AGENT, UserRole.ADMIN, UserRole.CONTENT_EDITOR, UserRole.COORDINATOR])}>
            <GenerateShowcasePage />
          </RequireAuth>
        )}
      />
      <Route
        path="/marketing/generate/agent-brochure"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.AGENT, UserRole.ADMIN, UserRole.CONTENT_EDITOR, UserRole.COORDINATOR])}>
            <GenerateAgentBrochurePage />
          </RequireAuth>
        )}
      />

      {/** Admin - Agents */}
      <Route
        path="/admin/agents"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.ADMIN, UserRole.CONTENT_EDITOR])}>
            <BrowseAgentsPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/admin/agents/search"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.ADMIN, UserRole.CALL_CENTER, UserRole.HR, UserRole.CONTENT_EDITOR])}>
            <SearchAgentPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/admin/agents/:id"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.ADMIN, UserRole.HR, UserRole.FINANCE, UserRole.CONTENT_EDITOR, UserRole.CALL_CENTER])}>
            <ViewAgentPage />
          </RequireAuth>
        )}
      />

      {/** Admin - Agency */}
      <Route
        path="/admin/agencies/:id"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.ADMIN, UserRole.HR, UserRole.FINANCE, UserRole.CONTENT_EDITOR, UserRole.CALL_CENTER])}>
            <ViewAgencyPage />
          </RequireAuth>
        )}
      />

      {/** Admin - Invoices */}
      <Route
        path="/admin/invoices/:section"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.ADMIN, UserRole.FINANCE])}>
            <InvoiceProcessPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/admin/invoices"
        element={<Navigate to={`/admin/invoices/${InvoiceSection.TODO}`} />}
      />

      {/** Job Applications */}
      <Route
        path="/job-applications/create"
        element={(
          <RequireAuth>
            <CreateJobApplicationWithApplicantPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/job-applications/search"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.AREA_MANAGER, UserRole.ADMIN, UserRole.HR])}>
            <SearchJobApplicationPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/job-applications/process/:section"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.AREA_MANAGER, UserRole.ADMIN, UserRole.HR])}>
            <JobApplicationProcessPage />
          </RequireAuth>
        )}
      />
      <Route
        path="/job-applications/process"
        element={<Navigate to={`/job-applications/process/${JobApplicationSection.TODO}`} />}
      />
      <Route
        path="/job-applications/:id"
        element={(
          <RequireAuth>
            <ViewJobApplicationPage />
          </RequireAuth>
        )}
      />

      {/** Resources */}
      <Route
        path="/resources/:agencyId?/:section"
        element={(
          <RequireAuth renderConditions={userHasAnyRole([UserRole.ADMIN, UserRole.AGENT, UserRole.CONTENT_EDITOR, UserRole.HR])}>
            <ViewResourcesPage />
          </RequireAuth>
        )}
      />

      {/** Notifications */}
      <Route
        path="/notifications"
        element={(
          <RequireAuth>
            <ViewApplicationNotificationsPage />
          </RequireAuth>
        )}
      />

      {/** Callback */}
      <Route
        path="/callback"
        element={null}
      />

      {/** No match */}
      <Route
        path="*"
        element={<NotFoundPage />}
      />
    </Routes>
  );
};

const App: React.FC = () => {
  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`%c${DOVE_LOGO_ASCII}`, 'color: #E31D93;');
  }, []);

  return (
    <Auth0Provider
      domain={AUTH0_CLIENT_DEFAULT_OPTIONS.domain}
      clientId={AUTH0_CLIENT_DEFAULT_OPTIONS.clientId}
      authorizationParams={AUTH0_CLIENT_DEFAULT_OPTIONS.authorizationParams}
      onRedirectCallback={(appState) => {
        window.location.replace(appState?.returnTo || window.location.href);
      }}
    >
      <SWRConfig value={{
        revalidateOnFocus: false,
        revalidateIfStale: false,
        onErrorRetry: swrOnErrorRetry,
      }}
      >
        <BrowserRouter>
          <ScrollToTop>
            <NotificationsProvider>
              <ApplicationNotificationsProvider>
                <AppLayout>
                  <LoadGoogleMapsAPI>
                    <AppRoutes />
                  </LoadGoogleMapsAPI>
                </AppLayout>
              </ApplicationNotificationsProvider>
            </NotificationsProvider>
          </ScrollToTop>
        </BrowserRouter>
      </SWRConfig>
    </Auth0Provider>

  );
};

export default App;

import {
  Avatar, AvatarProps, ICON_DOMAIN, ICON_FOREST_OUTLINE,
  ICON_MAP_MARKER_OUTLINE,
} from '@doveit/bricks';
import React from 'react';
import { Property } from '../../../providers/api/dtos';
import {
  NonResidentialPropertyType, NonResidentialPropertyTypeValue,
} from '../../../domain/types/propertyType';

export type PropertyAvatarProps = Pick<AvatarProps, 'size' | 'placeholder'> & {
  propertyType?: Property['propertyType'],
  hasOverriddenGeo?: boolean,
};

export const PropertyAvatar: React.FC<PropertyAvatarProps> = ({
  propertyType,
  hasOverriddenGeo,
  ...rest
}) => {
  const placeholder = React.useMemo(() => {
    if (!propertyType) return ICON_DOMAIN;

    if (Object.values(NonResidentialPropertyTypeValue).includes(propertyType as NonResidentialPropertyType)) {
      return ICON_FOREST_OUTLINE;
    }

    return ICON_DOMAIN;
  }, [propertyType]);

  return (
    <Avatar
      {...rest}
      icon={hasOverriddenGeo ? ICON_MAP_MARKER_OUTLINE : undefined}
      placeholder={placeholder}
    />
  );
};

export default PropertyAvatar;

/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  ActionIcon, FontWeight, HStack, ICON_TRASH_CAN_OUTLINE, Popover, Text, UserPreview,
} from '@doveit/bricks';
import { formatDate } from '@doveit/hammer';
import {
  Intent, Note, NoteRole, Property, Prospect, Rumor,
} from '../../../providers/api/dtos';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import {
  ReferenceEntityWithNotes, ReferenceWithNotes, UserRole,
} from '../../../types';
import { AgentPreview } from '../../../agent/components';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import * as styles from './ViewNoteTableRow.style';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import UpsertNoteAction from '../upsert-note-action-v2/UpsertNoteAction';
import { ReferenceType } from '../../../domain/types';

const MAX_NOTE_LENGTH = 125;

export interface ViewNoteTableRowBaseProps {
  reference: Property | Intent | Rumor | Prospect,
  referenceType: ReferenceType,
  note: Note,
  canEdit?: boolean,
  canDelete?: boolean,
  onNoteUpdate?: (updatedReference: ReferenceEntityWithNotes) => void,
  onDeleteClick?: (note: Note) => VoidFunction,
}

export type ViewNoteTableRowProps = ViewNoteTableRowBaseProps & ReferenceWithNotes;

export const NoteAuthor: React.FC<Pick<ViewNoteTableRowProps, 'note'>> = ({ note }) => {
  if (note.role === UserRole.CUSTOMER) {
    return (
      <UserPreview
        name="Contatto"
        size="XS"
        aria-label="Preview del contatto"
      />
    );
  }

  if (([UserRole.AGENT, UserRole.AREA_MANAGER] as NoteRole[]).includes(note.role)) {
    // @TODO: Enrich preview with agent data
    return (
      <AgentPreview
        name={note.author}
        size="XS"
        aria-label="Preview dell'agente"
      />
    );
  }

  return (
    <UserPreview
      name={note.author}
      size="XS"
      aria-label="Preview del'autore"
    />
  );
};

const ViewNoteTableRow: React.FC<ViewNoteTableRowProps> = ({
  reference,
  referenceType,
  note,
  canEdit = true,
  canDelete = true,
  onNoteUpdate,
  onDeleteClick, // @TODO: Create un componente DeleteNoteAction e utilizzarlo qui (rimuovendo l'implementazione su NotesWidget)
}) => {
  const {
    text: noteText, date, author, role,
  } = note;
  const withPopover = React.useMemo(() => (noteText.length ?? 0) > MAX_NOTE_LENGTH, [noteText.length]);
  const { userIsAdmin, mainUserRole, user } = useRBAC();

  const showNoteActions = React.useMemo(() => (canEdit || canDelete) && ((author === user.name && role === mainUserRole) || userIsAdmin),
    [canEdit, canDelete, author, user.name, role, mainUserRole, userIsAdmin]);

  return (
    <SimpleTable.Row>
      <SimpleTable.Cell width="8rem">
        <NoteAuthor note={note} />
      </SimpleTable.Cell>
      <SimpleTable.Cell width="22rem">
        {withPopover ? (
          <Popover
            aria-label="notes-popover"
            trigger={(<Text.Caption>{truncateTextWithSuffix(noteText, MAX_NOTE_LENGTH)}</Text.Caption>)}
            hasArrow
          >
            <div css={styles.notesPopover}>
              <Text.Caption aria-label="notes-popover-text">{noteText}</Text.Caption>
            </div>
          </Popover>
        ) : <Text.Caption aria-label="notes-text">{noteText}</Text.Caption>}
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <Text.Caption fontWeight={FontWeight.MEDIUM}>
          {formatDate(new Date(date!))}
        </Text.Caption>
      </SimpleTable.Cell>
      {showNoteActions && (
        <SimpleTable.Cell align="right">
          <HStack gap={75} aria-label="Azioni delle note">
            {canEdit && (
              <UpsertNoteAction
                reference={reference}
                referenceType={referenceType}
                note={note}
                onSuccess={onNoteUpdate}
              />
            )}
            {canDelete && (
              <ActionIcon
                label="Elimina"
                icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                onClick={onDeleteClick?.(note)}
                size="S"
                aria-label="Pulsante per eliminare una nota"
              />
            )}
          </HStack>
        </SimpleTable.Cell>
      )}
    </SimpleTable.Row>
  );
};

export default ViewNoteTableRow;

import { ExtraPackageType, ShootingStatus, Status } from '../../../domain/types';
import { UserRole } from '../../../types';
import { PaginatedResult, PaginationParams, serializePagination } from '../../pagination';
import { API_PROPERTIES_SERP_PATH, getClient } from '../api';
import { SerpProperty } from '../dtos';
import { serializeParams } from '../utils/serializer';

export interface SerpPropertiesFilters {
  q?: string,
  status?: Status | Status[],
  assignmentExpirationGt?: Date,
  assignmentExpirationLt?: Date,
  agentEmail?: string | string[],
  toBeManagedBy?: UserRole,
  shootingStatus?: ShootingStatus | ShootingStatus[],
  withInReviewMandatoryDocuments?: boolean,
  extraPackages?: ExtraPackageType | ExtraPackageType[],
}

export async function getSerpProperties(
  filters: SerpPropertiesFilters = {},
  pagination: PaginationParams = { size: 20 },
): Promise<PaginatedResult<SerpProperty>> {
  const client = await getClient();

  const { data } = await client.get(API_PROPERTIES_SERP_PATH, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });

  return data;
}

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unknown-property */
import React, { PropsWithChildren } from 'react';
import {
  Action,
  Icon,
  ICON_CLOSE,
  ICON_MENU,
  Logo,
  Pictogram, UIConfigurator, ViewNotifications,
} from '@doveit/bricks';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalStyle } from '../../theme/styles/GlobalStyles';
import * as styles from './AppLayout.style';
import ViewMainNavigation from '../../containers/view-main-navigation/ViewMainNavigation';
import UserStatus from '../../components/user-status/UserStatus';
import useMainNavigation, { findNavigationItem } from '../../hooks/use-main-navigation/useMainNavigation';
import SideNavigation from '../../components/side-navigation/SideNavigation';

function getPageType(urlPath: string): string {
  const routeToTitleMapping = {
    '': 'Dashboard',
    '/contacts/search': 'Cerca contatto',
    '/intents/explore': 'Gestisci interessi',
    '/rumors/': 'Pagina notizia',
    '/rumors/explore': 'Gestisci notizie',
    '/prospects/': 'Pagina valutazione',
    '/prospects/explore': 'Gestisci valutazioni',
    '/properties/': 'Pagina immobile',
    '/properties/explore': 'Gestisci immobili',
    '/agent/search/': 'Cerca geografie',
    '/marketing/generate': 'Genera materiali',
    '/resources/': 'Risorse',
    '/admin/agents/search': 'Cerca agente',
    '/job-applications/': 'Pagina candidatura',
    '/job-applications/search': 'Cerca candidatura',
    '/notifications': 'Notifiche',
  };

  let result = '';
  Object.entries(routeToTitleMapping).forEach(([path, pageType]) => {
    if (urlPath.startsWith(path)) {
      result = pageType;
    }
  });

  return result;
}

const AppLayout: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const items = useMainNavigation();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [navigationOpen, setNavigationOpen] = React.useState(false);
  const {
    loginWithRedirect, isLoading, isAuthenticated, logout,
  } = useAuth0();

  const activeItem = React.useMemo(
    () => findNavigationItem(items, pathname + search),
    [items, pathname, search],
  );

  const pageTitle = React.useMemo(() => {
    if (activeItem) {
      return activeItem.label || activeItem.title;
    }

    return getPageType(pathname);
  }, [activeItem, pathname]);

  React.useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { returnTo: window.location.pathname + window.location.search + window.location.hash } });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  /**
   * @todo avoid all this bs and use a proper solution
   * @see https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
   */
  const onResize = React.useCallback(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  React.useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize, loginWithRedirect, isAuthenticated]);

  const goToDashboardPage = React.useCallback(
    () => navigate('/'),
    [navigate],
  );

  const exit = React.useCallback(
    () => logout(),
    [logout],
  );

  const toggleNavigation = React.useCallback(() => setNavigationOpen((value) => {
    document.body.style.overflow = value ? 'hidden' : 'auto';
    return !value;
  }), []);

  const closeNavigation = React.useCallback(() => setNavigationOpen(() => {
    document.body.style.overflow = 'auto';
    return false;
  }), []);

  const internalOnItemClick = React.useCallback(
    (_id: string, hasChildren: boolean, path?: string) => {
      if (!hasChildren) {
        closeNavigation();

        if (path) {
          navigate(path);
        }
        document.body.style.overflow = 'auto';
      }
    },
    [closeNavigation, navigate],
  );

  return (
    <UIConfigurator>
      <GlobalStyle />
      {isLoading && (
        <div css={styles.loadingContainer}>
          <div css={styles.loading}>
            <Pictogram />
          </div>
        </div>
      )}
      {(!isLoading && isAuthenticated) && (
        <div css={styles.base}>
          <button
            css={styles.sideMenuOverlay}
            $open={navigationOpen}
            onClick={closeNavigation}
            aria-label="Chiudi il menù di navigazione"
          />
          <div
            css={styles.side}
            $open={navigationOpen}
          >
            <div css={styles.sideLogo}>
              <button onClick={goToDashboardPage}>
                <Logo />
              </button>
            </div>
            <div css={styles.userBox}>
              <UserStatus />
              <Action
                aria-label="logout-button"
                label="Esci"
                onClick={exit}
                emphasis="high"
                size="S"
                title="Abbandona il back office"
              />
            </div>
            <div css={styles.items}>
              <SideNavigation
                activeItemPath={activeItem?.path}
                onCommonClick={({ id, hasChildren, path }) => internalOnItemClick(id, hasChildren, path)}
              >
                {items.map((item) => {
                  const activeSubitem = (item.items || []).some((i) => i.path === activeItem?.path);

                  return (
                    <SideNavigation.Item
                      key={item.id}
                      id={item.id}
                      label={item.label}
                      aria-label="side-navigation-primary-item"
                      icon={item.icon}
                      path={item.path}
                      alwaysOpen={activeSubitem}
                    >
                      {item.items && item.items.map((subitem) => (
                        <SideNavigation.Item
                          key={subitem.id}
                          id={subitem.id}
                          label={subitem.label}
                          aria-label="side-navigation-secondary-item"
                          icon={subitem.icon}
                          path={subitem.path}
                        />
                      ))}
                    </SideNavigation.Item>
                  );
                })}
              </SideNavigation>
            </div>
          </div>
          <div css={styles.main}>
            <div css={styles.navigation}>
              <button
                css={styles.navigationControl}
                onClick={toggleNavigation}
                aria-label="side-navigation-control"
              >
                <Icon path={navigationOpen ? ICON_CLOSE : ICON_MENU} />
              </button>
              <ViewMainNavigation
                title={pageTitle}
                onLinkClick={closeNavigation}
              />
            </div>
            <div css={styles.page}>
              {children}
            </div>
            <div css={styles.notifications}>
              <div css={styles.notificationsBox}>
                <ViewNotifications />
              </div>
            </div>
          </div>
        </div>
      )}
    </UIConfigurator>
  );
};

export default AppLayout;

import {
  Message, ICON_TEXT_BOX_OUTLINE, Action, Portal, useModal, ConfirmModal, BreakpointQueryName, Card, useToggle, Dropdown, Spacing, Text, Stack,
  useNotifications,
} from '@doveit/bricks';
import React from 'react';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import objectHash from 'object-hash';
import { formatDate } from '@doveit/hammer';
import { Note } from '../../../providers/api/dtos';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import MultilineContent from '../../../prospect/components/multiline-content/MultilineContent';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import ViewNoteTableRow, { NoteAuthor } from './ViewNoteTableRow';
import UpsertNoteAction from '../upsert-note-action-v2/UpsertNoteAction';
import { ReferenceType } from '../../../domain/types';
import { ReferenceEntityWithNotes, ReferenceWithNotes } from '../../../types';
import { UpdateProviderFunction } from '../../../providers/api/types';
import { mapUpdateFunctionToReferenceType } from '../../../constants';

export const MAX_SHOWN_NOTES = 3;

export interface NotesWidgetPropsBase extends React.AriaAttributes {
  reference: ReferenceEntityWithNotes,
  referenceType: ReferenceType,
  canCreate?: boolean,
  canEdit?: boolean,
  canDelete?: boolean,
  showNudgeCheckbox?: boolean,
  onNotesUpdate?: (updatedReference: ReferenceEntityWithNotes) => void,
}

export type NotesWidgetProps = NotesWidgetPropsBase & ReferenceWithNotes;

type RenderedNotes = {
  notes: Note[],
  hasMore: boolean,
};

export const DELETE_NOTE_SUCCESS_MESSAGE = 'La nota è stata eliminata con successo.';
export const DELETE_NOTE_ERROR_MESSAGE = 'Non è stato possibile eliminare la nota.';

const NotesWidget: React.FC<NotesWidgetProps> = ({
  reference,
  referenceType,
  canCreate = true,
  canEdit = true,
  canDelete = true,
  showNudgeCheckbox = false,
  onNotesUpdate,
}) => {
  const { user, mainUserRole, userIsAdmin } = useRBAC();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { opened: areAllNotesShown, toggle: showAllNotes } = useToggle(false);
  const { addSuccess, addError } = useNotifications();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BreakpointQueryName.MD));

  const deleteNoteModal = useModal<{ note: Note }>();

  const onDeleteClick = React.useCallback((noteToDelete: Note) => () => {
    deleteNoteModal.open({ note: noteToDelete });
  }, [deleteNoteModal]);

  const handleDelete = React.useCallback((noteToDelete: Note) => async () => {
    setIsDeleting(true);

    try {
      const updatedReference = await (mapUpdateFunctionToReferenceType[referenceType] as UpdateProviderFunction<typeof reference>)(reference.id!, {
        ...reference,
        notes: (reference.notes || []).filter((note) => note.date !== noteToDelete.date),
      });

      setIsDeleting(false);
      deleteNoteModal.close();

      addSuccess(DELETE_NOTE_SUCCESS_MESSAGE);
      onNotesUpdate?.(updatedReference);
    } catch (error) {
      setIsDeleting(false);
      addError(DELETE_NOTE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, deleteNoteModal, onNotesUpdate, reference, referenceType]);

  const renderedNotes: RenderedNotes = React.useMemo(() => {
    const sortedNotes = (reference.notes || []).sort((b, a) => Date.parse(a.date!) - Date.parse(b.date!));

    if (!isMobile || areAllNotesShown) {
      return {
        notes: sortedNotes,
        hasMore: sortedNotes.length > MAX_SHOWN_NOTES,
      };
    }

    return {
      notes: sortedNotes.slice(0, MAX_SHOWN_NOTES),
      hasMore: sortedNotes.length > MAX_SHOWN_NOTES,
    };
  }, [areAllNotesShown, isMobile, reference.notes]);

  return (
    <>
      <Card>
        <Card.Header
          icon={{ path: ICON_TEXT_BOX_OUTLINE }}
          title="Note"
          primaryActions={[
            canCreate && !isMobile && (
              <UpsertNoteAction
                reference={reference}
                referenceType={referenceType}
                showNudgeCheckbox={showNudgeCheckbox}
                onSuccess={onNotesUpdate}
              >
                {({ upsert }) => (
                  <Action
                    label="Aggiungi"
                    size="S"
                    aria-label="Aggiungi nuova nota"
                    onClick={upsert}
                  />
                )}
              </UpsertNoteAction>
            ),
          ]}
          secondaryActions={[
            canCreate && isMobile && (
              <UpsertNoteAction
                reference={reference}
                referenceType={referenceType}
                showNudgeCheckbox={showNudgeCheckbox}
                onSuccess={onNotesUpdate}
              >
                {({ upsert }) => (
                  <Action
                    label="Aggiungi"
                    size="S"
                    aria-label="Aggiungi nuova nota"
                    onClick={upsert}
                  />
                )}
              </UpsertNoteAction>
            ),
          ]}
        />

        <Card.Content>
          {isMobile && renderedNotes.notes.length === 0 && (
            <Message
              type="neutral"
              message="Non sono presenti note"
            />
          )}

          {isMobile && renderedNotes.notes.length > 0 && (
            <Stack>
              {renderedNotes.notes.map((note, i) => (
                <Card
                  key={objectHash({ note, i })}
                  aria-label="Informazioni nota (Mobile)"
                >
                  <Card.Header
                    secondaryActions={((note.author === user.name && note.role === mainUserRole) || userIsAdmin)
                      ? [
                        canEdit && (
                          <UpsertNoteAction
                            reference={reference}
                            referenceType={referenceType}
                            showNudgeCheckbox={showNudgeCheckbox}
                            onSuccess={onNotesUpdate}
                            note={note}
                          >
                            {({ upsert }) => (
                              <Dropdown.Option
                                label="Modifica"
                                aria-label="Pulsante per modificare una nota"
                                onClick={upsert}
                              />
                            )}
                          </UpsertNoteAction>
                        ),
                        canDelete && (
                          <Dropdown.Option
                            label="Elimina"
                            loading={isDeleting}
                            aria-label="Pulsante per eliminare una nota"
                            onClick={onDeleteClick(note)}
                          />
                        ),
                      ] : undefined}
                  >
                    <Stack>
                      <Text.Mini transform="uppercase">
                        Nota
                      </Text.Mini>
                      <Text.H4>
                        {note.date ? formatDate(new Date(note.date)) : NO_VALUE_SYMBOL}
                      </Text.H4>
                    </Stack>
                  </Card.Header>

                  <Card.Content>
                    <NoteAuthor note={note} />
                    <Spacing margin={[150, 0, 0]} />
                    <Text.BodySmall>
                      <MultilineContent maxLines={2}>
                        {note.text}
                      </MultilineContent>
                    </Text.BodySmall>
                  </Card.Content>
                </Card>
              ))}
            </Stack>
          )}
          {!isMobile && (
            <>
              {renderedNotes.notes.length === 0 && (
                <Message
                  type="neutral"
                  message="Non sono presenti note"
                />
              )}
              {renderedNotes.notes.length > 0 && (
                <SimpleTable aria-label="Tabella delle note">
                  <SimpleTable.Body>
                    {renderedNotes.notes.map((note, i) => (
                      <SimpleTable.Row
                        key={objectHash({ note, i })}
                        asChild
                      >
                        <ViewNoteTableRow
                          reference={reference}
                          referenceType={referenceType}
                          note={note}
                          canEdit={canEdit}
                          canDelete={canDelete}
                          onDeleteClick={onDeleteClick}
                          onNoteUpdate={onNotesUpdate}
                        />
                      </SimpleTable.Row>
                    ))}
                  </SimpleTable.Body>
                </SimpleTable>
              )}
            </>
          )}
        </Card.Content>
        {isMobile && renderedNotes.hasMore && (
          <Card.Content>
            <Action
              size="S"
              label={areAllNotesShown ? 'Vedi meno' : 'Vedi tutte'}
              onClick={showAllNotes}
            />
          </Card.Content>
        )}
      </Card>
      <Portal>
        {deleteNoteModal.data && (
          <ConfirmModal
            {...deleteNoteModal}
            title="Conferma eliminazione nota"
            variant="critical"
            dismissable={false}
            onConfirm={handleDelete(deleteNoteModal.data.note)}
            onAbort={() => deleteNoteModal.close()}
            aria-label="Modale per eliminare una nota"
          >
            <Text.Body>
              Confermando l&apos;azione, la nota sarà eliminata e i dati non potranno essere recuperati.
            </Text.Body>
          </ConfirmModal>
        )}
      </Portal>
    </>
  );
};

export default NotesWidget;

import hash from 'object-hash';
import useSWR from 'swr';
import { SWRCustomResponse } from '../../../hooks/types';
import { getSerpProspects, SerpProspectProviderFilters } from '../../../providers/api/serp-prospects/getSerpProspects';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SerpProspect } from '../../../providers/api/dtos';

export const PROSPECTS_SERP_RID = '/api/v1/serp/prospects';

export function useSerpProspects(
  filters?: SerpProspectProviderFilters,
  pagination: PaginationParams = { size: 20 },
): SWRCustomResponse<PaginatedResult<SerpProspect>, Error> {
  const matchMutate = useMatchMutate();
  const key = filters ? [PROSPECTS_SERP_RID, hash({ ...filters, ...pagination })] : null;
  const result = useSWR(key, () => getSerpProspects(filters, pagination));

  return {
    ...result,
    mutate: () => matchMutate(PROSPECTS_SERP_RID),
  };
}

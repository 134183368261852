import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Text, Card, FontWeight,
  Action, ActionIconDropdown, Dropdown, HStack,
  DetailItemList,
  Skeleton,
  Message,
  Spacing,
} from '@doveit/bricks';
import { formatDate, pluralizeWord } from '@doveit/hammer';
import { useTheme } from 'styled-components';
import { raise } from '../../../utils';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import RightColumnPageLayout from '../../../layouts/right-column-page-layout/RightColumnPageLayout';
import useProspect from '../../hooks/use-prospect/useProspect';
import CalendarWidget from '../../../components/calendar-widget/CalendarWidget';
import { ReferenceType } from '../../../domain/types';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import LeadWidget from '../../../lead/containers/lead-widget/LeadWidget';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { ProspectStatus } from '../../../domain/types/prospectStatus';
import useAssignmentWizardData from '../../hooks/use-assignment-wizard-data/useAssignmentWizardData';
import ProspectDigitalAssignmentWidget from '../../containers/prospect-digital-assignment-widget/ProspectDigitalAssignmentWidget';
import RightColumnPageSkeleton from '../../../components/page-skeleton/RightColumnPageSkeleton';
import NotesWidget from '../../../notes/containers/notes-widget/NotesWidget';
import PropertyAvatar from '../../../property/components/property-avatar/PropertyAvatar';
import useLead from '../../../lead/hooks/use-lead/useLead';
import useContact from '../../../contact/hooks/use-contact/useContact';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';
import { prospectMessagesProvider } from '../../../containers/send-whatsapp-action/messages/prospectMessagesProvider';
import { useCurrentAgent, useAgent } from '../../../hooks/use-agent/useAgent';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import useAssignmentByProspectId from '../../hooks/use-assignment-by-prospect-id/useAssignmentByProspectId';
import { goToDetailPage } from '../../../utils/navigate/utils';
import ViewNeighborhoodsAction from '../../../components/view-neighborhoods-action/ViewNeighborhoodsAction';
import ProspectDocumentsWidget from '../../containers/prospect-documents-widget/ProspectDocumentsWidget';
import ViewPropertyPopoverPreview from '../../../property/containers/view-property-popover-preview/ViewPropertyPopoverPreview';
import ProspectAvatar from '../../components/prospect-avatar/ProspectAvatar';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import AgentPreview from '../../../agent/components/agent-preview/AgentPreview';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import UpdateProspectStatus from '../../containers/update-prospect-status/UpdateProspectStatus';
import ProspectWidget from '../../containers/prospect-widget/ProspectWidget';
import { useProspectNoticesData } from '../../hooks/use-prospect-notices-data/useProspectNoticesData';
import { WithRequiredKeys } from '../../../types';
import { Prospect } from '../../../providers/api/dtos';
import { buildProspectNotice } from '../../../utils/notice/buildProspectNotice';
import NoticeBoard from '../../../notice/components/notice-board/NoticeBoard';
import ContactWidget from '../../../contact/containers/contact-widget/ContactWidget';
import { useProspectMatchCount } from '../../hooks/use-prospect-match-count/useProspectMatchCount';
import { ForbiddenErrorPage } from '../../../containers/require-auth/RequireAuth';
import EditAssignedAgentAction from '../../../agent/containers/edit-assigned-agent-action/EditAssignedAgentAction';
import { getAgentName } from '../../../agent/utils/utils';

export const LOAD_PROSPECT_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni della valutazione';

const ViewProspectPage: React.FC = () => {
  const theme = useTheme();
  const params = useParams();
  if (!params.id) raise('missing prospect id');

  const prospectId = React.useMemo(() => params.id!, [params.id]);
  const {
    data: prospect,
    isLoading: isProspectLoading,
    error: prospectError,
    mutate: mutateProspect,
  } = useProspect(prospectId);
  const { data: assignmentWizard } = useAssignmentWizardData(prospect?.id || undefined);
  const {
    data: lead, isLoading: isLeadLoading, error: leadError,
  } = useLead(prospect?.leadId);
  const {
    data: contact, isLoading: isContactLoading, error: contactError, mutate: contactMutate,
  } = useContact(lead?.contactId);
  const {
    data: assignment, isLoading: isAssignmentLoading, error: assignmentError,
  } = useAssignmentByProspectId(prospectId);
  const { data: prospectAgent, isLoading: isAgentLoading } = useAgent(prospect?.agentId);
  const {
    userIsAdmin,
    userIsFinance,
    userIsCallCenter,
    userIsContentEditor,
  } = useRBAC();
  const isMobile = useIsDevice('mobile');
  const userIsProspectAgentOrManager = useCurrentAgentIsSameAgentOrManager(prospect?.agentId);
  const currentAgent = useCurrentAgent();

  const { data: noticesData, mutate: mutateProspectNotices } = useProspectNoticesData(prospect as WithRequiredKeys<Prospect, 'id'>);

  const { data: matchingCounters, isLoading: isMatchingCountersLoading } = useProspectMatchCount(prospectId);

  const matchingSearchCriteriaCount = React.useMemo(
    () => matchingCounters?.counters.SEARCH_CRITERIA || 0,
    [matchingCounters],
  );

  const canSendWhatsapp = React.useMemo(
    () => contact?.phoneNumber && ((currentAgent && userIsProspectAgentOrManager) || userIsAdmin),
    [currentAgent, contact?.phoneNumber, userIsAdmin, userIsProspectAgentOrManager],
  );

  const { data: personalAgent } = useAgent(lead?.originAgentId);

  const canViewPage = React.useMemo(
    () => userIsAdmin || userIsProspectAgentOrManager || userIsCallCenter || userIsContentEditor || userIsFinance,
    [userIsAdmin, userIsCallCenter, userIsContentEditor, userIsFinance, userIsProspectAgentOrManager],
  );

  const showLeadAttributionMessage = React.useMemo(
    () => (userIsAdmin || userIsFinance) && lead?.originAgentId !== prospect?.agentId && personalAgent,
    [userIsAdmin, userIsFinance, personalAgent, lead?.originAgentId, prospect?.agentId],
  );

  const canChangeAssignedAgent = React.useMemo(
    () => userIsAdmin || userIsProspectAgentOrManager || userIsCallCenter,
    [userIsAdmin, userIsProspectAgentOrManager, userIsCallCenter],
  );

  const showProspectDigitalAssignmentWidget = React.useMemo(
    () => (userIsAdmin || userIsProspectAgentOrManager || userIsContentEditor)
      && prospect
      && assignmentWizard
      && prospect.status === ProspectStatus.IN_PROGRESS
      && assignmentWizard.isDigitallySigned,
    [assignmentWizard, prospect, userIsAdmin, userIsContentEditor, userIsProspectAgentOrManager],
  );

  const showNeighborhoodsSection = React.useMemo(
    () => userIsProspectAgentOrManager || userIsAdmin,
    [userIsAdmin, userIsProspectAgentOrManager],
  );

  const showProspectDocumentsWidget = React.useMemo(
    () => userIsAdmin || userIsProspectAgentOrManager,
    [userIsAdmin, userIsProspectAgentOrManager],
  );

  const canManageReminders = React.useMemo(
    () => userIsProspectAgentOrManager || userIsAdmin,
    [userIsAdmin, userIsProspectAgentOrManager],
  );

  const canManageAppointments = React.useMemo(
    () => userIsProspectAgentOrManager || userIsCallCenter || userIsAdmin,
    [userIsAdmin, userIsCallCenter, userIsProspectAgentOrManager],
  );

  const canManageNotes = React.useMemo(
    () => userIsProspectAgentOrManager || userIsCallCenter || userIsAdmin,
    [userIsAdmin, userIsCallCenter, userIsProspectAgentOrManager],
  );

  const onNotesUpdate = React.useCallback(async () => {
    await mutateProspect();
  }, [mutateProspect]);

  const canManageContactAdditionalServices = React.useMemo(
    () => userIsAdmin || userIsCallCenter || userIsProspectAgentOrManager,
    [userIsProspectAgentOrManager, userIsAdmin, userIsCallCenter],
  );

  const canManageContactConcessions = React.useMemo(
    () => userIsAdmin || userIsCallCenter || userIsProspectAgentOrManager || userIsFinance,
    [userIsAdmin, userIsCallCenter, userIsProspectAgentOrManager, userIsFinance],
  );

  const canManageContactOptIns = React.useMemo(
    () => userIsAdmin || userIsCallCenter,
    [userIsAdmin, userIsCallCenter],
  );

  const canViewMatchingSearchCriteria = React.useMemo(
    () => userIsAdmin || userIsProspectAgentOrManager,
    [userIsAdmin, userIsProspectAgentOrManager],
  );

  const goToPropertyPage = React.useCallback(() => {
    goToDetailPage(assignment?.propertyId!, ReferenceType.PROPERTY);
  }, [assignment]);

  const notices = React.useMemo(() => {
    if (!prospect) {
      return [];
    }

    return (noticesData || []).map((noticeData) => buildProspectNotice(noticeData, {
      onReminderCreate: mutateProspectNotices,
      onAppointmentCreate: mutateProspectNotices,
      onProspectNudgeSent: mutateProspectNotices,
      mutateProspect,
    }));
  }, [mutateProspect, mutateProspectNotices, noticesData, prospect]);

  const goToMatchingSearchCriteriaPage = React.useCallback(() => {
    const matchingPage = `/prospects/${prospectId}/matching`;
    if (isMobile) {
      window.location.href = matchingPage;
    } else {
      window.open(matchingPage, '_blank');
    }
  }, [prospectId, isMobile]);

  if (!canViewPage) {
    return <ForbiddenErrorPage />;
  }

  if (prospectError || leadError || contactError) {
    return <GenericErrorPage title={LOAD_PROSPECT_ERROR_MESSAGE} />;
  }

  if (!prospect || isProspectLoading || !lead || isLeadLoading || !contact || isContactLoading) {
    return <RightColumnPageSkeleton />;
  }

  return (
    <RightColumnPageLayout
      aria-label="Dettagli valutazione"
      title={(
        <HStack
          aria-label="prospect-preview"
          gap={200}
          wrap="nowrap"
        >
          {assignment && (
            <ViewPropertyPopoverPreview
              propertyId={assignment.propertyId}
              trigger={(
                <PropertyAvatar
                  aria-label="Avatar immobile"
                  size="M"
                />
              )}
            />
          )}
          {!assignment && (
            <ProspectAvatar
              aria-label="Avatar valutazione"
              size="M"
            />
          )}
          <div>
            <div>
              <Text.H4
                fontWeight={FontWeight.REGULAR}
                as="div"
              >
                {lead.propertyAddress}
              </Text.H4>
            </div>
            <Text.Body as="div">
              {contact.name}
            </Text.Body>
          </div>
        </HStack>
      )}
      actions={(
        <>
          {(canSendWhatsapp && !isMobile) && (
            <SendWhatsappAction
              phoneNumber={contact.phoneNumber!}
              name={contact.name}
              fetchMessages={prospectMessagesProvider(prospect, currentAgent)}
            >
              {({ onClick }) => (
                <Action
                  label="Invia whatsapp"
                  aria-label="Pulsante per inviare un messaggio Whatsapp"
                  onClick={onClick}
                  emphasis="high"
                />
              )}
            </SendWhatsappAction>
          )}
          {((isMobile && canSendWhatsapp) || prospect.status === ProspectStatus.ASSIGNMENT_CREATED) && (
            <ActionIconDropdown
              label="Azioni sulla valutazione"
              aria-label="Azioni sulla valutazione"
            >
              {(isMobile && canSendWhatsapp) && (
                <SendWhatsappAction
                  phoneNumber={contact.phoneNumber!}
                  name={contact.name}
                  fetchMessages={prospectMessagesProvider(prospect, currentAgent)}
                >
                  {({ onClick }) => (
                    <Dropdown.Option
                      label="Invia whatsapp"
                      aria-label="Pulsante per inviare un messaggio Whatsapp"
                      onClick={onClick}
                    />
                  )}
                </SendWhatsappAction>
              )}
              {prospect.status === ProspectStatus.ASSIGNMENT_CREATED && (
                <Dropdown.Option
                  label="Visualizza immobile"
                  aria-label="Pulsante per visualizzare l'immobile"
                  onClick={goToPropertyPage}
                  title={assignmentError && 'Non disponibile'}
                  loading={isAssignmentLoading}
                />
              )}
            </ActionIconDropdown>
          )}
        </>
      )}
      topContent={
        <NoticeBoard notices={notices} />
      }
      primarySide={(
        <Card>
          <Card.Header isSecondary>
            <UpdateProspectStatus
              prospect={prospect}
              onSuccess={mutateProspect}
              emphasis="high"
              showIcon
            />
          </Card.Header>
          <Card.Content aria-label="Sezione stato valutazione">
            <DetailItemList>
              {prospect.createdAt && (
                <DetailItemList.Item label="Data creazione">
                  {formatDate(new Date(prospect.createdAt))}
                </DetailItemList.Item>
              )}
              <DetailItemList.Item
                label="Agente"
                withoutVPadding
                withoutHPadding
                fullWidth
                loading={isAgentLoading}
                skeleton={(
                  <Skeleton
                    aria-label="skeleton-agent-loading"
                    width="12rem"
                    height={theme.remHeight.S}
                  />
                )}
              >
                {canChangeAssignedAgent && prospectAgent && (
                  <EditAssignedAgentAction
                    reference={prospect}
                    referenceType={ReferenceType.PROSPECT}
                    onSuccess={() => mutateProspect()}
                  >
                    {({ openEditModal }) => (
                      <AgentPreview
                        {...toAgentPreviewProps(prospectAgent)}
                        size="S"
                        onClick={openEditModal}
                        expanded
                      />
                    )}
                  </EditAssignedAgentAction>
                )}
                {!canChangeAssignedAgent && prospectAgent && (
                  <ViewAgentPopoverPreview
                    agent={prospectAgent}
                    trigger={(
                      <AgentPreview
                        {...toAgentPreviewProps(prospectAgent)}
                        size="S"
                        expanded
                      />
                    )}
                  />
                )}
              </DetailItemList.Item>
            </DetailItemList>
          </Card.Content>

          <Card.Divider />

          <ContactWidget
            contactId={contact.id!}
            aria-label="Sezione contatto"
            asNestedComponent
            canEdit
            canAddAdditionalServices={canManageContactAdditionalServices}
            canDeleteAdditionalServices={canManageContactAdditionalServices}
            canAddOptIns={canManageContactOptIns}
            canDeleteOptIns={canManageContactOptIns}
            canAddConcessions={canManageContactConcessions}
            canDeleteConcessions={canManageContactConcessions}
            onContactUpdated={contactMutate}
          />

          <Card.Divider />

          <ProspectWidget
            prospectId={prospectId}
            asNestedComponent
            canEdit={userIsAdmin || userIsProspectAgentOrManager}
          />

          {canViewMatchingSearchCriteria && (matchingSearchCriteriaCount > 0 || isMatchingCountersLoading) && (
            <>
              <Card.Divider />

              <Card.Content aria-label="Sezione matching">

                <DetailItemList>
                  <DetailItemList.Item
                    label="Matching"
                    aria-label="Matching"
                    withoutVPadding
                    loading={isMatchingCountersLoading}
                    skeleton={(
                      <Skeleton
                        aria-label="skeleton-matching-search-criteria-loading"
                        width="10rem"
                        height={theme.remHeight.S}
                      />
                    )}
                  >
                    {matchingSearchCriteriaCount > 0 && (
                      <Action
                        label={`${matchingSearchCriteriaCount} ${pluralizeWord('potenzial', 'e', 'i', matchingSearchCriteriaCount)} ${pluralizeWord('acquirent', 'e', 'i', matchingSearchCriteriaCount)}`}
                        aria-label="Visualizza i criteri di ricerca che matchano la valutazione"
                        onClick={goToMatchingSearchCriteriaPage}
                        size="S"
                      />
                    )}
                  </DetailItemList.Item>
                </DetailItemList>
              </Card.Content>
            </>
          )}
        </Card>
      )}
      secondarySide={showNeighborhoodsSection && lead.latitude && lead.longitude && (
        <Card aria-label="Sezione nelle vicinanze">
          <Card.Header title="Nelle vicinanze" />
          <Card.Content>
            <Spacing padding={[0, 150]}>
              <HStack
                alignItems="center"
                justifyContent="space-between"
              >
                <Text.Body fontWeight={FontWeight.MEDIUM}>Immobiliare.it</Text.Body>
                <ViewNeighborhoodsAction latitude={lead.latitude} longitude={lead.longitude} />
              </HStack>
            </Spacing>
          </Card.Content>
        </Card>
      )}
    >
      {showLeadAttributionMessage && (
        <Message
          aria-label="Messaggio di attribuzione lead"
          boxed
          type="neutral"
          message={`La lead è stata attribuita all'agente ${getAgentName(personalAgent)}`}
        />
      )}
      {showProspectDigitalAssignmentWidget && (
        <ProspectDigitalAssignmentWidget prospectId={prospectId!} />
      )}
      <LeadWidget
        leadId={prospect.leadId}
        canSeeSource={userIsAdmin || userIsFinance || userIsCallCenter}
      />
      <CalendarWidget
        agentId={prospect.agentId}
        contactId={prospect.contactId}
        filters={{
          referenceId: prospectId,
          referenceType: ReferenceType.PROSPECT,
        }}
        canAddReminder={canManageReminders}
        canEditReminder={canManageReminders}
        canDeleteReminder={canManageReminders}
        canAddAppointment={canManageAppointments}
        canEditAppointment={canManageAppointments}
      />
      <NotesWidget
        reference={prospect}
        referenceType={ReferenceType.PROSPECT}
        canCreate={canManageNotes}
        canEdit={canManageNotes}
        canDelete={canManageNotes}
        onNotesUpdate={onNotesUpdate}
      />
      {showProspectDocumentsWidget && (
        <ProspectDocumentsWidget prospectId={prospectId} />
      )}
    </RightColumnPageLayout>
  );
};

export default ViewProspectPage;

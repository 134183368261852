import React from 'react';
import {
  Badge,
  Card, CardSkeleton, Dropdown, HStack, ICON_ALERT_CIRCLE_OUTLINE, ICON_BULLHORN_OUTLINE, Message,
  Spacing,
  Stack,
} from '@doveit/bricks';
import { Contact } from '../../../providers/api/dtos';
import useContactRumors from '../../hooks/use-contact-rumors/useContactRumors';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import ViewContactRumorTableRow from '../view-contact-rumor-table-row/ViewContactRumorTableRow';
import ViewContactRumorCard from '../view-contact-rumor-card/ViewContactRumorCard';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import { RumorStatus } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import CreateRumorAction from '../../../rumor/containers/create-rumor-action/CreateRumorAction';
import { useCurrentAgent } from '../../../hooks/use-agent/useAgent';

const LOAD_RUMORS_ERROR_MESSAGE = 'Non è stato possibile caricare le notizie associate al contatto.';
export const NO_CONTACT_RUMORS_MESSAGE = 'Non sono presenti notizie associate al contatto.';

const FILTERS = ['all', 'active', 'inactive'] as const;

type Filter = typeof FILTERS[number];

const filterLabels: Record<Filter, string> = {
  all: 'Tutte',
  active: 'Attive',
  inactive: 'Non attive',
};

export interface ContactRumorsWidgetProps {
  contactId: NonNullable<Contact['id']>,
  excludeRumorStatus?: RumorStatus | RumorStatus[],
}

const ContactRumorsWidget: React.FC<ContactRumorsWidgetProps> = ({
  contactId,
  excludeRumorStatus,
}) => {
  const { userIsAdmin, userIsAgent } = useRBAC();

  const isMobile = useIsDevice('mobile');
  const {
    data: rumors = [],
    isLoading: areRumorsLoading,
    error: rumorsError,
    mutate: mutateContactRumors,
  } = useContactRumors(contactId, { excludeStatus: excludeRumorStatus });

  const currentAgent = useCurrentAgent();

  const [activeFilter, setActiveFilter] = React.useState<Filter>('all');

  const filteredRumors = React.useMemo(() => {
    let includeStatusFilter = Object.values(RumorStatus);

    switch (activeFilter) {
      case 'active':
        includeStatusFilter = [RumorStatus.IN_PROGRESS, RumorStatus.APPOINTMENT_SCHEDULED];
        break;
      case 'inactive':
        includeStatusFilter = [RumorStatus.KO_NOT_INTERESTED, RumorStatus.KO_NOT_CONFIRMED, RumorStatus.KO_DUPLICATE_LEAD];
        break;
      default:
        break;
    }

    return rumors.filter(({ status }) => includeStatusFilter.includes(status));
  }, [activeFilter, rumors]);

  const filteredSortedRumors = React.useMemo(
    () => {
      const sortedRumors = filteredRumors.sort((a, b) => Date.parse(b.updatedAt!) - Date.parse(a.updatedAt!));

      if (currentAgent) {
        return sortedRumors.filter((rumor) => currentAgent.id === rumor.agentId || currentAgent.canManage?.some((agentManaged) => agentManaged.id === rumor.agentId));
      }

      return sortedRumors;
    },
    [currentAgent, filteredRumors],
  );

  const handleContactRumorsUpdate = React.useCallback(async () => {
    await mutateContactRumors();
  }, [mutateContactRumors]);

  if (areRumorsLoading) {
    return (
      <CardSkeleton aria-label="Notizie in caricamento">
        {!isMobile && <SimpleTableSkeleton />}
      </CardSkeleton>
    );
  }

  if (rumorsError) {
    return (
      <Card>
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Notizie"
          color="critical"
        />
        <Card.Content>
          <Message message={LOAD_RUMORS_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  return filteredSortedRumors && (
    <Card aria-label="Sezione notizie">
      <Card.Header
        title="Notizie"
        icon={{ path: ICON_BULLHORN_OUTLINE }}
        primaryActions={[
          (!isMobile && (userIsAdmin || userIsAgent) && (
            <CreateRumorAction
              aria-label="Pulsante per aggiungere una nuova notizia"
              contactId={contactId}
              onSuccess={() => mutateContactRumors()}
            />
          )),
        ]}
        secondaryActions={[
          (isMobile && (userIsAdmin || userIsAgent) && (
            <CreateRumorAction
              aria-label="Pulsante per aggiungere una nuova notizia"
              contactId={contactId}
              onSuccess={() => mutateContactRumors()}
            >
              {({ createRumor }) => (
                <Dropdown.Option
                  aria-label="Pulsante per aggiungere una nuova notizia"
                  label="Aggiungi"
                  onClick={createRumor}
                />
              )}
            </CreateRumorAction>
          )),
        ]}
      />
      <Card.Content>
        <Stack aria-label="Lista notizie">
          {(isMobile && filteredSortedRumors.length > 0) && (
            filteredSortedRumors.map((rumor) => (
              <ViewContactRumorCard
                key={rumor.id}
                rumor={rumor}
              />
            ))
          )}
          {(!isMobile && filteredSortedRumors.length > 0) && (
            <>
              <Spacing margin={[0, 0, 150]}>
                <HStack aria-label="Filtri">
                  {FILTERS.map(((filter) => (
                    <Badge
                      label={filterLabels[filter]}
                      key={filter}
                      onClick={filter !== activeFilter ? () => setActiveFilter(filter) : undefined}
                      color={activeFilter === filter ? 'primary' : 'neutral'}
                      emphasis="low"
                    />
                  )))}
                </HStack>
              </Spacing>
              <SimpleTable>
                <SimpleTable.Body>
                  {filteredSortedRumors.map((rumor) => (
                    <SimpleTable.Row
                      asChild
                      key={rumor.id}
                    >
                      <ViewContactRumorTableRow rumor={rumor} onRumorUpdate={handleContactRumorsUpdate} />
                    </SimpleTable.Row>
                  ))}
                </SimpleTable.Body>
              </SimpleTable>
            </>
          )}
          {filteredSortedRumors.length === 0 && (
            <Message message={NO_CONTACT_RUMORS_MESSAGE} />
          )}
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default ContactRumorsWidget;

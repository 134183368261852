import { ActionIconProps, ActionProps } from '@doveit/bricks';
import type { SetRequired } from 'type-fest';
import { User } from '@auth0/auth0-react';
import { Content } from './providers/api/dtos/content';
import { ReferenceType } from './domain/types';
import type {
  Intent, Property, Prospect, Rumor,
} from './providers/api/dtos';

export type ExtraAction = Pick<ActionProps, 'href' | 'onClick' | 'title' | 'disabled' | 'color' | 'label' | 'iconLeft' | 'iconRight'> & {
  id: string | number,
};

export type ExtraActionIcon = Pick<ActionIconProps, 'href' | 'onClick' | 'title' | 'disabled' | 'color' | 'label' | 'icon'> & {
  id: string | number,
};

export interface NavigationItem {
  id: string,
  label: string,
  title?: string
  path?: string,
  icon?: string,
  disabled?: boolean,
  items?: NavigationItem[],
  data?: any,
  hasShortcut?: boolean,
}

export interface Anchor {
  label: string,
  id: string,
}

export type UploadContent = Omit<Content, 'id'> & {
  id: number | null,
  fileName: string,
};

export interface Upload {
  loading: boolean,
  error: boolean,
  success: boolean,
  preview: string,
  content: UploadContent,
}

export interface Media {
  id: number | string,
  url: string,
  fileName: string | null,
  about: string | null,
}

export enum PayloadStatus {
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR',
}

export interface Payload<T> {
  status: PayloadStatus,
  errorMessage: string | null,
  data: T | null,
}

export enum UserRole {
  CUSTOMER = 'customer',
  AGENT = 'agent',
  CONTENT_EDITOR = 'content',
  DEVELOPER = 'developer',
  ADMIN = 'admin',
  CALL_CENTER = 'callCenter',
  FINANCE = 'finance',
  HR = 'hr',
  AREA_MANAGER = 'areaManager',
  COORDINATOR = 'coordinator',
}

export interface CustomUser extends User {
  name: string,
  email: string,
  'https://backoffice.dove.it/roles': UserRole[],
  can_impersonate?: boolean,
}

export type AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken;

export enum ReminderStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  HIGHLIGHTED = 'HIGHLIGHTED',
}

export enum ResolvableReminderStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  RESOLVED = 'RESOLVED',
}

export enum OptionalBoolean {
  YES = 'Sì',
  NO = 'No',
  UNKNOWN = 'Non so',
}

export enum VerticalAlignment {
  TOP = 'TOP',
  CENTER = 'CENTER',
  BOTTOM = 'BOTTOM',
}

export enum HorizontalAlignment {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

// We can add new file ext when they need
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
export enum FileMimeType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  WEBP = 'image/webp',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export interface ColoredString {
  value: string,
  color?: string,
}

export type Language = 'it' | 'en';

export interface ModalControl {
  allowModalControl?: boolean,
}

/**
 * Makes the given keys of an object required.
 */
export type WithRequiredKeys<TObject extends object, TKeys extends keyof TObject> = SetRequired<TObject, TKeys> & {
  [TKey in keyof TObject]: TKey extends TKeys ? NonNullable<TObject[TKey]> : TObject[TKey];
};

/**
 * Makes the given keys of an object required except for the specified keys
 */
export type RequiredExceptFor<TObject, TKeys extends keyof TObject> = Required<Pick<TObject, Exclude<keyof TObject, TKeys>>> & Partial<Pick<TObject, TKeys>>;

/**
 * Stringifies all the fields of an object.
 */
export type Stringify<TObject extends object> = {
  [K in keyof TObject]: TObject[K] extends object ? Stringify<TObject[K]> : string
};

export interface WithStatus<TStatus extends string = string> {
  status: TStatus;
}

export enum LocationSellabilityRank {
  LOWEST = 0,
  LOWER = 1,
  LOW = 2,
  MEDIUM = 3,
  HIGH = 4,
  HIGHEST = 5,
}

export enum BlacklistAction {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export enum WhatsAppTemplate {
  RECONTACT_PROPERTY_EVALUATION = 'recontact_property_evaluation',
  MISSED_CALL = 'missed_call',
  SEARCH_CRITERIA = 'search_criteria_result',
}

export interface Identifiable {
  id: string | number,
}

export interface GenericSerpSortOption<T extends string> {
  key: T,
  label: string,
  value?: any,
}

export type ReferenceWithNotesMap = {
  [ReferenceType.PROPERTY]: Property;
  [ReferenceType.PROSPECT]: Prospect;
  [ReferenceType.RUMOR]: Rumor;
  [ReferenceType.INTENT]: Intent;
};

export type ReferenceEntityWithNotes = ReferenceWithNotesMap[keyof ReferenceWithNotesMap];

export type ReferenceWithNotes<T extends keyof ReferenceWithNotesMap = keyof ReferenceWithNotesMap> = {
  referenceType: T;
  reference: ReferenceWithNotesMap[T];
};

export type ReferenceWithNotesAndId<T extends keyof ReferenceWithNotesMap> = ReferenceWithNotesMap[T] & { id: NonNullable<ReferenceWithNotesMap[T]['id']> };

export interface Label {
  color: string,
  label?: string,
  value?: string
}

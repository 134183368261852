import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const userWrapper = () => css`
  width: 10rem;
`;

export const noteAndReminderPopoverWrapper: ThemedFunctor = ({ theme }) => css`
  min-width: 25rem;
  padding: ${theme.remSize[150]};
`;

import {
  Grid, Icon, Typography, Badge, ExpandableContent, Spacing, useModal, Portal, ModalSize, ICON_CHECK_CIRCLE_OUTLINE, ICON_EMAIL_OUTLINE, ICON_PHONE_OUTLINE, Message, Action, Dropdown, BreakpointQueryName, theme, ICON_ACCOUNT_OUTLINE, ActionIconDropdown, ICON_ACCOUNT_CANCEL_OUTLINE, ICON_ACCOUNT_CIRCLE_OUTLINE, ICON_BRIEFCASE_EDIT_OUTLINE, HStack, Stack, DetailItemList, ICON_ACCOUNT_MULTIPLE_PLUS_OUTLINE,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '@doveit/hammer';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import useApplicant from '../../../applicant/hooks/use-applicant/useApplicant';
import Card from '../../../components/card/Card';
import Divider from '../../../components/divider/Divider';
import NotesWidget from '../../../notes/components/notes-widget/NotesWidget';
import { jobApplicationStatusLabels } from '../../../labels';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import MultilineContent from '../../../prospect/components/multiline-content/MultilineContent';
import { Note } from '../../../providers/api/dtos';
import { JobApplicationFilters, updateJobApplication } from '../../../providers/api/job-application/jobApplicationProvider';
import JobApplicationStatusBadge from '../../components/job-application-status-badge/JobApplicationStatusBadge';
import ArchiveJobApplication from '../../containers/archive-job-application/ArchiveJobApplication';
import HireJobApplication from '../../containers/hire-job-application/HireJobApplication';
import RestoreJobApplication from '../../containers/restore-job-application/RestoreJobApplication';
import useJobApplication from '../../hooks/use-job-application/useJobApplication';
import OptInDetailsList from '../../../opt-ins/component/OptInDetailsList';
import {
  Department, JobApplicationStatus, OptInType, ReferenceType,
} from '../../../domain/types';
import { isArchived, isAssignable, isFinal } from '../../../utils/job-application/getJobApplicationProgress';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import EditApplicant from '../../../applicant/container/edit-applicant/EditApplicant';
import useJobPosition from '../../../job-position/hooks/use-job-position/useJobPosition';
import EditJobApplication from '../../containers/edit-job-application/EditJobApplication';
import AssignJobApplication from '../../containers/assign-job-application/AssignJobApplication';
import UserActions from '../../../components/user-actions/UserActions';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { template } from '../../../utils/text/text';
import ViewJobApplicationCard from '../../containers/view-job-application-card/ViewJobApplicationCard';
import useSimilarApplicants from '../../../applicant/hooks/use-similar-applicants/useSimilarApplicants';
import useJobApplications from '../../hooks/use-job-applications/useJobApplications';
import { MAX_PAGED_SIZE } from '../../../constants';
import RemindersWidgetV3 from '../../../reminders/containers/reminders-widget/RemindersWidgetV3';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import OfferJobApplication from '../../containers/offer-job-application/OfferJobApplication';
import useUserIsOnlyAreaManager from '../../../hooks/use-user-is-only-area-manager/useUserIsOnlyAreaManager';
import { UserRole } from '../../../types';
import HireApplicantAction from '../../../agent/containers/hire-applicant-action/HireApplicantAction';
import { getAgentName } from '../../../agent/utils/utils';

export const JOB_APPLICATION_LOAD_ERROR_MESSAGE = 'Impossibile caricare i dati della candidatura';
export const APPLICANT_LOAD_ERROR_MESSAGE = 'Impossibile caricare i dati del candidato';
export const ASSIGNMENT_NOT_PRESENT_MESSAGE = 'Candidato non ancora assegnato';
export const SIMILAR_JOB_APPLICATIONS_LOAD_ERROR_MESSAGE = 'Impossibile caricare le candidature associate a questo candidato';
export const SIMILAR_JOB_APPLICATIONS_INFO_MESSAGE = 'Ci sono altre candidature (<strong>{JOB_APPLICATIONS_COUNT}</strong>) che potrebbero riferirsi allo stesso candidato';
export const NOT_SALES_JOB_POSITION_WARNING_MESSAGE = 'Le candidature per ruoli non commerciali, come ad esempio {JOB_POSITION_NAME}, non sono assegnabili. Qualora la candidatura sia relativa ad un ruolo commerciale (es. consulente o agente), modificare la posizione.';

const ViewJobApplicationPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { id: jobApplicationId } = useParams();

  const isMobile = useMediaQuery(theme.breakpoints.down(BreakpointQueryName.MD));

  const { data: jobApplication, error: jobApplicationError, mutate: mutateJobApplication } = useJobApplication(jobApplicationId);
  const { data: applicant, error: applicantError, mutate: mutateApplicant } = useApplicant(jobApplication?.applicantId);
  const { data: similarApplicants, error: similarApplicantsError } = useSimilarApplicants(jobApplication?.applicantId);
  const { user, userHasAnyRole } = useRBAC();

  const isOnlyAreaManager = useUserIsOnlyAreaManager();
  const userIsHrOrAdmin = React.useMemo(() => userHasAnyRole([UserRole.HR, UserRole.ADMIN]), [userHasAnyRole]);

  const filters: JobApplicationFilters | null = useMemo(() => (similarApplicants && similarApplicants.length > 0
    ? { applicantId: similarApplicants.map((similarApplicant) => similarApplicant.id!) }
    : null), [similarApplicants]);

  const { data: similarJobApplications, error: similarJobApplicationsError } = useJobApplications(filters, {
    size: MAX_PAGED_SIZE,
  });
  const { data: jobPosition, error: jobPositionError } = useJobPosition(jobApplication?.jobPositionId);
  const { data: agent } = useAgent(jobApplication?.assignment?.agentId);

  const editApplicantModal = useModal();
  const editJobApplicationModal = useModal();
  const similarJobApplicationsModal = useModal();

  const isPrivilegedUser = useMemo(() => userHasAnyRole([UserRole.ADMIN, UserRole.AREA_MANAGER, UserRole.HR]), [userHasAnyRole]);

  const isSalesJobApplication = useMemo(
    () => jobPosition?.department === Department.SALES,
    [jobPosition?.department],
  );

  const isArchivedJobApplication = useMemo(
    () => (jobApplication ? isArchived(jobApplication) : undefined),
    [jobApplication],
  );

  const isFinalJobApplication = useMemo(
    () => (jobApplication ? isFinal(jobApplication) : undefined),
    [jobApplication],
  );

  const isAssignableJobApplication = useMemo(
    () => (jobApplication ? isAssignable(jobApplication) : undefined),
    [jobApplication],
  );

  const onJobApplicationStatusUpdateSuccess = useCallback(() => {
    mutateJobApplication();
  }, [mutateJobApplication]);

  const onJobApplicationUpdateSuccess = useCallback(() => {
    mutateJobApplication();
    editJobApplicationModal.close();
  }, [editJobApplicationModal, mutateJobApplication]);

  const onApplicantUpdateSuccess = useCallback(() => {
    mutateApplicant();
    editApplicantModal.close();
  }, [editApplicantModal, mutateApplicant]);

  const onAssignmentUpdateSuccess = useCallback(() => {
    mutateJobApplication();
  }, [mutateJobApplication]);

  const onNotesSubmit = useCallback(async (notes: Note[]) => {
    if (jobApplication && jobApplicationId) {
      await updateJobApplication(jobApplicationId, {
        ...jobApplication,
        notes,
      });
      mutateJobApplication();
    }
  }, [jobApplication, jobApplicationId, mutateJobApplication]);

  const showJobApplicationsList = useCallback(() => {
    similarJobApplicationsModal.open();
  }, [similarJobApplicationsModal]);

  const onViewActionClick = useCallback((applicationId: string) => () => {
    similarJobApplicationsModal.close();
    navigate(`/job-applications/${applicationId}`);
  }, [navigate, similarJobApplicationsModal]);

  if (jobApplicationError || applicantError || jobPositionError) {
    return (
      <GenericErrorPage title={
        jobApplicationError || jobPositionError
          ? JOB_APPLICATION_LOAD_ERROR_MESSAGE
          : APPLICANT_LOAD_ERROR_MESSAGE
      }
      />
    );
  }

  if (!jobApplication || !applicant || !jobPosition) {
    return null;
  }

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content
        title={(
          <HStack>
            {applicant.name}
            <JobApplicationStatusBadge status={jobApplication.status} />
          </HStack>
        )}
        subtitle={(
          <Typography.BODY>
            Candidatura del <strong>{formatDate(new Date(jobApplication.createdAt!))}</strong> - Ultimo aggiornamento <strong>{formatDate(new Date(jobApplication.updatedAt!))}</strong>
          </Typography.BODY>
        )}
        headerSlot={(
          <HStack>
            {!isMobile && isPrivilegedUser && jobApplication.status === JobApplicationStatus.ASSIGNED && !isOnlyAreaManager && (
              <OfferJobApplication
                jobApplication={jobApplication}
                onSuccess={mutateJobApplication}
              >
                {({ loading, offer }) => (
                  <Action
                    label="Manda proposta"
                    onClick={offer}
                    emphasis="high"
                    loading={loading}
                  />
                )}
              </OfferJobApplication>
            )}
            <ActionIconDropdown
              label="Azioni sulla candidatura"
              aria-label="Azioni sulla candidatura"
            >
              {isPrivilegedUser && jobApplication.status === JobApplicationStatus.IN_PROGRESS && !isOnlyAreaManager && (
                <AssignJobApplication
                  jobApplication={jobApplication}
                  disabled={!isSalesJobApplication}
                  onSuccess={mutateJobApplication}
                >
                  {({ disabled, openModal }) => (
                    <Dropdown.Option
                      disabled={disabled}
                      label={jobApplication.status !== JobApplicationStatus.ASSIGNED ? 'Assegna' : 'Riassegna'}
                      onClick={openModal}
                    />
                  )}
                </AssignJobApplication>
              )}
              {isMobile && isPrivilegedUser && jobApplication.status === JobApplicationStatus.ASSIGNED && !isOnlyAreaManager && (
                <OfferJobApplication
                  jobApplication={jobApplication}
                  onSuccess={mutateJobApplication}
                >
                  {({ loading, offer }) => (
                    <Dropdown.Option
                      label="Manda proposta"
                      onClick={offer}
                      loading={loading}
                    />
                  )}
                </OfferJobApplication>
              )}
              {isPrivilegedUser && jobApplication.status === JobApplicationStatus.OFFER && !isOnlyAreaManager && !isSalesJobApplication && (
                <HireJobApplication
                  jobApplication={jobApplication}
                  onSuccess={mutateJobApplication}
                >
                  {({ loading, hire }) => (
                    <Dropdown.Option
                      label="Assumi"
                      onClick={hire}
                      loading={loading}
                    />
                  )}
                </HireJobApplication>
              )}
              {isPrivilegedUser && jobApplication.status === JobApplicationStatus.OFFER && !isOnlyAreaManager && isSalesJobApplication && (
                <HireApplicantAction
                  jobApplication={jobApplication}
                  applicant={applicant}
                >
                  {({ isLoading, openModal }) => (
                    <Dropdown.Option
                      aria-label="Assumi il candidato"
                      label="Assumi"
                      onClick={openModal}
                      loading={isLoading}
                    />
                  )}
                </HireApplicantAction>
              )}
              {isPrivilegedUser && isFinalJobApplication && (
                <RestoreJobApplication
                  jobApplication={jobApplication}
                  onSuccess={onJobApplicationStatusUpdateSuccess}
                >
                  {({ loading, restore }) => (
                    <Dropdown.Option
                      label="Ripristina"
                      onClick={restore}
                      loading={loading}
                    />
                  )}
                </RestoreJobApplication>
              )}
              {isPrivilegedUser && !isFinalJobApplication && (
                <ArchiveJobApplication
                  jobApplication={jobApplication}
                  onSuccess={onJobApplicationStatusUpdateSuccess}
                >
                  {({ archive, loading }) => (
                    <Dropdown.Option
                      label="Archivia"
                      loading={loading}
                      onClick={archive}
                    />
                  )}
                </ArchiveJobApplication>
              )}
            </ActionIconDropdown>
          </HStack>
        )}
      >
        <Stack gap={150}>
          {!isSalesJobApplication && (
            <Message
              boxed
              type="warning"
              message={template(NOT_SALES_JOB_POSITION_WARNING_MESSAGE, { JOB_POSITION_NAME: jobPosition.name.toLowerCase() })}
              actions={[<Action
                label="Modifica posizione"
                size="S"
                color="warning"
                emphasis="high"
                onClick={editJobApplicationModal.open}
              />]}
            />
          )}
          <Grid gutter={150}>
            <Grid.Unit size={{ MD: 2 / 3 }}>
              <Card data-ref="job-application-section">
                <Card.Header
                  primaryActions={[
                    <Action
                      label="Modifica"
                      size="S"
                      onClick={editJobApplicationModal.open}
                    />,
                  ]}
                >
                  <Card.Title>
                    <Icon path={ICON_BRIEFCASE_EDIT_OUTLINE} />
                    <Typography.HEADING_3 color="brand.primary">
                      Candidatura
                    </Typography.HEADING_3>
                  </Card.Title>
                </Card.Header>
                <Card.Box>
                  <DetailItemList columns={2}>
                    <DetailItemList.Group>
                      <DetailItemList.Item label="Ruolo">
                        {jobPosition.name}
                      </DetailItemList.Item>
                      <DetailItemList.Item label="Esperienza">
                        {jobApplication.experience}
                      </DetailItemList.Item>
                      <DetailItemList.Item label="Sorgente">
                        {jobApplication.source ? jobApplication.source.toLowerCase() : undefined}
                      </DetailItemList.Item>
                    </DetailItemList.Group>
                    {isSalesJobApplication && (
                    <DetailItemList.Group>
                      <DetailItemList.Item label="Provincia">
                        {jobApplication.province}
                      </DetailItemList.Item>
                      <DetailItemList.BooleanItem
                        label="Patentino"
                        value={jobApplication.license}
                      />
                      <DetailItemList.BooleanItem
                        label="Attualmente lavora"
                        value={jobApplication.agency}
                      />
                    </DetailItemList.Group>
                    )}
                  </DetailItemList>
                  {jobApplication.message && (
                  <>
                    <Divider>Messaggio</Divider>
                    <MultilineContent openLabel="Mostra tutto">
                      {jobApplication.message}
                    </MultilineContent>
                  </>
                  )}
                </Card.Box>
                <Portal>
                  <SimpleModal
                    {...editJobApplicationModal}
                    title="Modifica candidatura"
                  >
                    <EditJobApplication
                      jobApplication={jobApplication}
                      onSuccess={onJobApplicationUpdateSuccess}
                    />
                  </SimpleModal>
                </Portal>
              </Card>
            </Grid.Unit>
            <Grid.Unit size={{ MD: 1 / 3 }}>
              <Card data-ref="applicant-section">
                <Card.Header
                  primaryActions={[
                    <Action
                      label="Modifica"
                      size="S"
                      onClick={editApplicantModal.open}
                    />,
                  ]}
                >
                  <Card.Title>
                    <Icon path={ICON_ACCOUNT_CIRCLE_OUTLINE} />
                    <Typography.HEADING_3 color="brand.primary">
                      Candidato
                    </Typography.HEADING_3>
                  </Card.Title>
                </Card.Header>
                <Card.Box>
                  <DetailItemList>
                    <DetailItemList.Item icon={ICON_ACCOUNT_OUTLINE}>
                      {applicant.name}
                    </DetailItemList.Item>
                    <DetailItemList.Item icon={ICON_PHONE_OUTLINE}>
                      {applicant.phoneNumber}
                    </DetailItemList.Item>
                    {applicant.email && (
                    <DetailItemList.Item icon={ICON_EMAIL_OUTLINE}>
                      {applicant.email}
                    </DetailItemList.Item>
                    )}
                  </DetailItemList>
                  <Spacing margin={[100, 0]}>
                    <UserActions
                      user={applicant}
                      hideLinkedInAction={false}
                    />
                  </Spacing>
                  <ExpandableContent>
                    <Divider />
                    <Card.Header unpadded>
                      <Card.Title>
                        <Icon path={ICON_CHECK_CIRCLE_OUTLINE} />
                        <Typography.HEADING_3 color="brand.primary">
                          Consensi
                        </Typography.HEADING_3>
                      </Card.Title>
                    </Card.Header>
                    <Spacing margin={[400, 0, 0]}>
                      <OptInDetailsList
                        optIns={applicant.optIns}
                        excludedOptInTypes={[OptInType.THIRD_PARTIES]}
                      />
                    </Spacing>
                  </ExpandableContent>
                </Card.Box>
                <Portal>
                  <SimpleModal
                    {...editApplicantModal}
                    title="Modifica candidato"
                    data-ref="applicant-modal"
                  >
                    <EditApplicant
                      applicant={applicant}
                      onSuccess={onApplicantUpdateSuccess}
                    />
                  </SimpleModal>
                </Portal>
              </Card>
            </Grid.Unit>
            {isPrivilegedUser && isArchivedJobApplication && (
            <Grid.Unit>
              <Card data-ref="archiving-reason-section">
                <Card.Header>
                  <Card.Title>
                    <Icon path={ICON_ACCOUNT_CANCEL_OUTLINE} />
                    <Typography.HEADING_3 color="brand.primary">
                      Motivazione archiviazione
                    </Typography.HEADING_3>
                  </Card.Title>
                  <ArchiveJobApplication
                    label="Modifica"
                    jobApplication={jobApplication}
                    onSuccess={onJobApplicationStatusUpdateSuccess}
                  />
                </Card.Header>
                <Card.Box>
                  <DetailItemList>
                    <DetailItemList.Item>
                      <Badge
                        label={jobApplicationStatusLabels[jobApplication.status]}
                        color="critical"
                      />
                    </DetailItemList.Item>
                    <DetailItemList.Item>
                      {jobApplication.archivingReason}
                    </DetailItemList.Item>
                  </DetailItemList>
                </Card.Box>
              </Card>
            </Grid.Unit>
            )}
            {!isFinalJobApplication && (
            <Grid.Unit size={{ MD: 1 / 2 }}>
              <RemindersWidgetV3
                referenceType={ReferenceType.JOB_APPLICATION}
                referenceId={jobApplication.id!}
                userEmail={user.email}
                canCreate={userIsHrOrAdmin}
                canDelete={userIsHrOrAdmin}
                canEdit={userIsHrOrAdmin}
              />
            </Grid.Unit>
            )}
            {isAssignableJobApplication && isSalesJobApplication && (
            <Grid.Unit size={{ MD: 1 / 2 }}>
              <Card data-ref="assignee-section">
                <Card.Header
                  primaryActions={[
                    userHasAnyRole([UserRole.HR, UserRole.ADMIN]) && (
                      <AssignJobApplication
                        jobApplication={jobApplication}
                        onSuccess={onAssignmentUpdateSuccess}
                      >
                        {({ openModal }) => (
                          <Action
                            label={jobApplication.assignment ? 'Modifica' : 'Aggiungi'}
                            size="S"
                            onClick={openModal}
                          />
                        )}
                      </AssignJobApplication>
                    ),
                  ]}
                >
                  <Card.Title>
                    <Icon path={ICON_ACCOUNT_MULTIPLE_PLUS_OUTLINE} />
                    <Typography.HEADING_3 color="brand.primary">
                      Assegnatario
                    </Typography.HEADING_3>
                  </Card.Title>
                </Card.Header>
                {jobApplication.assignment
                  ? (
                    <Card.Box>
                      <DetailItemList>
                        <DetailItemList.Item icon={ICON_ACCOUNT_OUTLINE}>
                          <strong>{getAgentName(agent)}</strong>
                        </DetailItemList.Item>
                        {jobApplication.assignment.note && (
                          <DetailItemList.Item
                            label="Nota per l'agente"
                            inline={false}
                          >
                            <MultilineContent>
                              {jobApplication.assignment.note}
                            </MultilineContent>
                          </DetailItemList.Item>
                        )}
                      </DetailItemList>
                    </Card.Box>
                  )
                  : (
                    <Card.Box>
                      <Message message={ASSIGNMENT_NOT_PRESENT_MESSAGE} />
                    </Card.Box>
                  )}
              </Card>
            </Grid.Unit>
            )}
            <Grid.Unit data-ref="notes-section">
              <NotesWidget
                notes={jobApplication.notes}
                canCreate={isPrivilegedUser}
                onSubmit={onNotesSubmit}
              />
            </Grid.Unit>
            <Grid.Unit data-ref="similar-job-applications-section">
              {(similarApplicantsError || similarJobApplicationsError) && (
              <Message
                type="critical"
                boxed
                message={SIMILAR_JOB_APPLICATIONS_LOAD_ERROR_MESSAGE}
              />
              )}
              {isPrivilegedUser && similarJobApplications && similarJobApplications.numberOfElements > 0 && !isOnlyAreaManager && (
              <>
                <Message
                  type="info"
                  boxed
                  message={template(SIMILAR_JOB_APPLICATIONS_INFO_MESSAGE, { JOB_APPLICATIONS_COUNT: similarJobApplications.numberOfElements })}
                  actions={[
                    <Action
                      label="Vedi candidature"
                      color="info"
                      emphasis="high"
                      size="S"
                      onClick={showJobApplicationsList}
                    />,
                  ]}
                />
                <Portal>
                  <SimpleModal
                    {...similarJobApplicationsModal}
                    title="Altre candidature"
                    data-ref="similar-job-applications-modal"
                    size={ModalSize.LARGE}
                  >
                    <Stack>
                      {similarJobApplications.content
                        .map((similarJobApplication) => (
                          <ViewJobApplicationCard
                            key={similarJobApplication.id}
                            jobApplication={similarJobApplication}
                            onView={onViewActionClick(similarJobApplication.id!)}
                            compact
                          />
                        ))}
                    </Stack>
                  </SimpleModal>
                </Portal>
              </>
              )}
            </Grid.Unit>
          </Grid>
        </Stack>

      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default ViewJobApplicationPage;

import { ICON_ALERT_CIRCLE_OUTLINE, ICON_CHECK_CIRCLE_OUTLINE, ICON_PROGRESS_CHECK } from '@doveit/bricks';
import { BadgeColor } from '@doveit/bricks/dist/typings/components/badge/types';
import { PropertyStatus, Status } from '../domain/types';

export const statusLabels: { [key in Status]: string } = {
  DRAFT: 'Bozza',
  ANTEPRIMA: 'Anteprima',
  LIVE: 'Pubblicato',
  VENDUTO: 'Venduto',
  RITIRATO: 'Ritirato',
  PROPOSTA: 'Trattativa',
};

export const statusActionIcon: Record<Status, string> = {
  [Status.LIVE]: ICON_PROGRESS_CHECK,
  [Status.DRAFT]: ICON_PROGRESS_CHECK,
  [Status.ANTEPRIMA]: ICON_PROGRESS_CHECK,
  [Status.VENDUTO]: ICON_CHECK_CIRCLE_OUTLINE,
  [Status.RITIRATO]: ICON_ALERT_CIRCLE_OUTLINE,
  [Status.PROPOSTA]: ICON_PROGRESS_CHECK,
};

export const statusActionColor: Record<Status, BadgeColor> = {
  [Status.LIVE]: 'info',
  [Status.DRAFT]: 'warning',
  [Status.ANTEPRIMA]: 'warning',
  [Status.VENDUTO]: 'success',
  [Status.RITIRATO]: 'critical',
  [Status.PROPOSTA]: 'success',
};

export const propertyStatusLabels: {
  [key in PropertyStatus]: string
} = {
  NUOVO: 'Nuova costruzione',
  ABITABILE: 'In buono stato / abitabile',
  DA_RISTRUTTURARE: 'Da ristrutturare',
  IN_COSTRUZIONE: 'In costruzione',
  RISTRUTTURATO: 'Ristrutturato',
};

import React from 'react';
import {
  Badge,
  Card, CardSkeleton, HStack, ICON_ACCOUNT_ARROW_RIGHT_OUTLINE, ICON_ALERT_CIRCLE_OUTLINE, Message,
  Stack,
} from '@doveit/bricks';
import { Contact, Prospect } from '../../../providers/api/dtos';
import useContactProspects from '../../hooks/use-contact-prospects/useContactProspects';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import { ProspectStatus } from '../../../domain/types';
import ViewContactProspectCard from '../view-contact-prospect-card/ViewContactProspectCard';
import { PROSPECT_ACTIVE_STATUSES, PROSPECT_KO_STATUSES } from '../view-contact-activities-summary/prospects/constants';
import ViewContactProspectTableRow from '../view-contact-prospect-table-row/ViewContactProspectTableRow';

export const CONTACT_PROSPECTS_ERROR_MESSAGE = 'Non è stato possibile recuperare le valutazioni del contatto.';
export const EMPTY_CONTACT_PROSPECTS = 'Non sono presenti valutazioni associate al contatto.';

export interface ContactProspectsWidgetProps {
  contactId: NonNullable<Contact['id']>,
  excludeProspectStatus?: ProspectStatus | ProspectStatus[],
  onProspectUpdate?: (prospect: Prospect) => void,
}

const FILTERS = ['all', 'active', 'inactive'] as const;

type Filter = typeof FILTERS[number];

const filterLabels: Record<Filter, string> = {
  all: 'Tutte',
  active: 'Attive',
  inactive: 'Non attive',
};

const ContactProspectsWidget: React.FC<ContactProspectsWidgetProps> = ({
  contactId,
  excludeProspectStatus,
  onProspectUpdate,
}) => {
  const [activeFilter, setActiveFilter] = React.useState<Filter>('all');

  const {
    data: contactProspects = [],
    isLoading: isContactProspectsLoading,
    error: contactProspectsError,
    mutate: mutateContactProspects,
  } = useContactProspects(contactId); // select all to be able to show filters properly

  const onInternalProspectUpdate = React.useCallback(async (prospect: Prospect) => {
    await mutateContactProspects();
    onProspectUpdate?.(prospect);
  }, [mutateContactProspects, onProspectUpdate]);

  const isMobile = useIsDevice('mobile');

  const filteredContactProspects = React.useMemo(() => {
    const explicitExcludeFilter = excludeProspectStatus ?? [];
    let implicitExcludeFilter: ProspectStatus[] = [];
    switch (activeFilter) {
      case 'active':
        implicitExcludeFilter = Object.values(ProspectStatus)
          .filter((value) => !PROSPECT_ACTIVE_STATUSES.includes(value));
        break;
      case 'inactive':
        implicitExcludeFilter = Object.values(ProspectStatus)
          .filter((value) => !PROSPECT_KO_STATUSES.includes(value));
        break;
      default:
        break;
    }

    const explicitFilterArray = Array.isArray(explicitExcludeFilter) ? explicitExcludeFilter : [explicitExcludeFilter];
    const uniqueExcludeValues = new Set([...implicitExcludeFilter, ...explicitFilterArray]);

    return contactProspects.filter(({ status }) => !uniqueExcludeValues.has(status));
  }, [activeFilter, contactProspects, excludeProspectStatus]);

  const sortedContactProspects = React.useMemo(
    () => filteredContactProspects && filteredContactProspects.sort((a, b) => Date.parse(b.createdAt!) - Date.parse(a.createdAt!)),
    [filteredContactProspects],
  );

  if (isContactProspectsLoading) {
    return (
      <CardSkeleton aria-label="Sezione valutazioni in caricamento">
        {!isMobile && <SimpleTableSkeleton />}
      </CardSkeleton>
    );
  }

  if (contactProspectsError) {
    return (
      <Card>
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Valutazioni"
          color="critical"
        />
        <Card.Content>
          <Message
            message={CONTACT_PROSPECTS_ERROR_MESSAGE}
          />
        </Card.Content>
      </Card>
    );
  }

  return sortedContactProspects && (
    <Card aria-label="Sezione valutazioni">
      <Card.Header
        title="Valutazioni"
        icon={{ path: ICON_ACCOUNT_ARROW_RIGHT_OUTLINE }}
      />
      <Card.Content>
        <Stack gap={150} aria-label="Lista valutazioni">
          {isMobile && sortedContactProspects.length > 0 && (
            sortedContactProspects.map((prospect) => (
              <ViewContactProspectCard
                key={prospect.id}
                prospect={prospect}
                onProspectUpdate={onInternalProspectUpdate}
              />
            ))
          )}
          {(!isMobile && sortedContactProspects.length > 0) && contactProspects.length > 0 && (
            <>
              <HStack gap={75} aria-label="Filtri">
                {FILTERS.map((filter) => (
                  <Badge
                    key={filter}
                    label={filterLabels[filter]}
                    color={filter === activeFilter ? 'primary' : 'neutral'}
                    emphasis="low"
                    onClick={filter !== activeFilter ? () => setActiveFilter(filter) : undefined}
                  />
                ))}
              </HStack>
              <SimpleTable
                aria-label="Tabella delle valutazioni di un contatto"
              >
                <SimpleTable.Body>
                  {sortedContactProspects.map((contactProspect) => (
                    <SimpleTable.Row
                      key={contactProspect.id}
                      asChild
                    >
                      <ViewContactProspectTableRow
                        prospect={contactProspect}
                        onProspectUpdate={onInternalProspectUpdate}
                      />
                    </SimpleTable.Row>
                  ))}
                </SimpleTable.Body>
              </SimpleTable>
            </>
          )}

          {sortedContactProspects.length === 0 && (
            <Message message={EMPTY_CONTACT_PROSPECTS} />
          )}
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default ContactProspectsWidget;

import React from 'react';
import {
  Text, FontWeight, HStack,
} from '@doveit/bricks';
import ViewPropertyPopoverPreview from '../../containers/view-property-popover-preview/ViewPropertyPopoverPreview';
import { Property, PropertyPreview as PropertyPreviewDTO } from '../../../providers/api/dtos';
import { setVerticalEllipsis } from '../../../utils/styles/verticalEllipsis.style';
import PropertyAvatar from '../property-avatar/PropertyAvatar';

export interface PropertyPreviewProps extends React.AriaAttributes {
  property: Property | PropertyPreviewDTO;
  showMatchingAction?: boolean;
}

const PropertyPreview: React.FC<PropertyPreviewProps> = ({
  property,
  showMatchingAction = false,
  'aria-label': ariaLabel = 'property-preview',
  ...rest
}) => (
  <ViewPropertyPopoverPreview
    {...rest}
    aria-label={ariaLabel}
    property={property}
    trigger={(
      <HStack gap={100} wrap="nowrap">
        <PropertyAvatar
          size="S"
          hasOverriddenGeo={!!property.overriddenGeo}
          propertyType={property.propertyType}
        />
        <Text.BodySmall
          aria-label="Indirizzo immobile"
          fontWeight={FontWeight.MEDIUM}
          css={setVerticalEllipsis()}
        >
          {property.overriddenGeo?.normalizedAddress || property.geo?.normalizedAddress}
        </Text.BodySmall>
      </HStack>
    )}
    showMatchingAction={showMatchingAction}
  />
);

export default PropertyPreview;

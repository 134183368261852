/* eslint-disable import/no-duplicates */
import { SelectOption, TextProps } from '@doveit/bricks';
import {
  endOfDay, format, isBefore,
  isPast,
  isToday,
} from 'date-fns';
import { it } from 'date-fns/locale';
import { capitalize } from 'lodash';
import { OfferDeedStatus } from '../../domain/types';
import { OfferStatus } from '../../domain/types/offerStatus';
import { offerStatusLabels } from '../../labels';
import { Offer } from '../../providers/api/dtos/offer';
import { NO_VALUE_SYMBOL } from '../../property/utils';

export function isPendingOffer(offer: Offer) {
  return [
    OfferStatus.IN_PROGRESS,
    OfferStatus.ACCEPTED,
  ].includes(offer.status);
}

export function isOfferArchived(offer: Offer): boolean {
  return [
    OfferStatus.REFUSED,
    OfferStatus.WITHDRAWN,
    OfferStatus.MORTGAGE_REFUSED,
    OfferStatus.OTHER_CONSTRAINTS,
  ].includes(offer.status);
}

export function isRefusedOffer(offer: Offer) {
  return [
    OfferStatus.REFUSED,
    OfferStatus.MORTGAGE_REFUSED,
    OfferStatus.OTHER_CONSTRAINTS,
  ].includes(offer.status);
}

export function hasPendingOffers(offers: Offer[]) {
  return offers.some((offer) => isPendingOffer(offer));
}

export function hasRefusedOffers(offers: Offer[]) {
  return offers.some((offer) => isRefusedOffer(offer));
}

export function hasWithdrawnOffers(offers: Offer[]) {
  return offers.some((offer) => offer.status === OfferStatus.WITHDRAWN);
}

export function getStatusOptions(currentState: OfferStatus): SelectOption[] {
  const options: SelectOption[] = [
    {
      value: currentState,
      label: offerStatusLabels[currentState],
    },
  ];

  switch (currentState) {
    case OfferStatus.IN_PROGRESS:
      options.push(
        {
          value: OfferStatus.ACCEPTED,
          label: offerStatusLabels[OfferStatus.ACCEPTED],
        },
        {
          value: OfferStatus.REFUSED,
          label: offerStatusLabels[OfferStatus.REFUSED],
        },
        {
          value: OfferStatus.WITHDRAWN,
          label: offerStatusLabels[OfferStatus.WITHDRAWN],
        },
      );
      break;
    case OfferStatus.ACCEPTED:
      options.push(
        {
          value: OfferStatus.CONFIRMED,
          label: offerStatusLabels[OfferStatus.CONFIRMED],
        },
        {
          value: OfferStatus.MORTGAGE_REFUSED,
          label: offerStatusLabels[OfferStatus.MORTGAGE_REFUSED],
        },
        {
          value: OfferStatus.OTHER_CONSTRAINTS,
          label: offerStatusLabels[OfferStatus.OTHER_CONSTRAINTS],
        },
        {
          value: OfferStatus.WITHDRAWN,
          label: offerStatusLabels[OfferStatus.WITHDRAWN],
        },
      );
      break;
    default:
  }

  return options;
}

export interface OfferDeedDateLabel {
  color: string,
  value: string
}

export function buildOfferDeedDate(offer: Offer): OfferDeedDateLabel {
  if (offer.deed) {
    const deedDate = new Date(offer.deed.date);
    const deedStatus = offer.deed.status;

    const deedDateLabel = Object.keys(OfferDeedStatus).filter((status) => status !== OfferDeedStatus.VAGUE).includes(offer.deed.status)
      ? format(deedDate, 'dd/MM/yyyy')
      : capitalize(format(deedDate, 'MMMM yyyy', { locale: it }));

    if (deedStatus === OfferDeedStatus.CONFIRMED) {
      return {
        value: deedDateLabel,
        color: 'neutrals.darkest',
      };
    }

    if (isBefore(deedDate, endOfDay(new Date()))) {
      return {
        value: deedDateLabel,
        color: 'status.alert',
      };
    }

    return {
      value: deedDateLabel,
      color: 'status.warning',
    };
  }

  return {
    value: NO_VALUE_SYMBOL,
    color: 'neutrals.darkest',
  };
}

export interface OfferDateToDisplayLabel {
  color: TextProps['color'],
  label: string,
  value: string
}

export const buildOfferDateToDisplay = (offer: Offer): OfferDateToDisplayLabel => {
  const {
    status, validUntil, mortgage, createdAt,
  } = offer;

  if (status === OfferStatus.IN_PROGRESS && validUntil) {
    return {
      label: 'Scad. accett.',
      value: validUntil,
      color: (isPast(validUntil) && !isToday(validUntil))
        ? 'critical.default.low'
        : 'neutral.default.low',
    } as const;
  }

  if (status === OfferStatus.ACCEPTED && mortgage?.maxAcceptanceDate) {
    return {
      label: 'Scad. sospen.',
      value: mortgage.maxAcceptanceDate,
      color: (isPast(mortgage.maxAcceptanceDate) && !isToday(mortgage.maxAcceptanceDate))
        ? 'critical.default.low'
        : 'neutral.default.low',
    } as const;
  }

  return {
    label: 'Creata',
    value: createdAt!,
    color: 'neutral.default.low',
  } as const;
};

import React from 'react';
import {
  Action,
  ActionDropDown,
  ActionIconDropdown,
  CardSkeleton,
  HStack, ICON_CHECK, ICON_CUSTOM_SORT_PLUS, ICON_SORT, Message, Select, Spacing, Stack, Text,
} from '@doveit/bricks';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { useSerpProperties } from '../../hooks/use-serp-properties/useSerpProperties';
import AgentFilterAction from '../../../components/agent-filter-action/AgentFilterAction';
import usePagination from '../../../hooks/use-pagination/usePagination';
import { useAgentFilter } from '../../../hooks/agent-filter/useAgentFilter';
import getShortAgentName from '../../../utils/agent/getShortAgentName';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import {
  ALL_PROPERTIES_FILTER_OPTION,
  DEFAULT_SORT_OPTION,
  FILTER_OPTIONS,
  FILTERS_MAPPER,
  GROUPED_FILTER_OPTIONS,
  LOAD_SERP_PROPERTIES_ERROR_MESSAGE,
  MOST_RECENT_CREATION_SORT_OPTION,
  NO_SERP_PROPERTIES,
  PORTFOLIO_FILTER_OPTION,
  SORT_OPTIONS,
  SORT_OPTIONS_PER_FILTER,
  TO_BE_MANAGED_FILTER_OPTION,
} from './constants';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import ViewSerpPropertyCard from '../../containers/view-serp-property-card/ViewSerpPropertyCard';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import ViewSerpPropertyTableRow from '../../containers/view-serp-property-table-row/ViewSerpPropertyTableRow';
import useFilters from '../../../hooks/use-filters/useFilters';
import { PropertySerpPageFilterKey } from './types';
import SearchByQueryAction from '../../../components/search-by-query-action/SearchByQueryAction';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import SerpFiltersSummary from '../../../components/serp-filters-summary/SerpFiltersSummary';
import { useCurrentAgentSync } from '../../../hooks/use-agent/useAgent';

const PropertySerpPage: React.FC = () => {
  const isDesktop = useIsDevice('desktop');
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const useRBACResult = useRBAC();
  const { page, paginationQueryParam, goToPage } = usePagination('page', scrollRef);

  const { data: currentAgent, isLoading: isCurrentAgentLoading } = useCurrentAgentSync();

  const {
    agentEmails: agents,
    replaceFilter: replaceAgentFilter,
    isLoading: isAgentFilterLoading,
    isExplicit: isAgentFilterExplicit,
  } = useAgentFilter(paginationQueryParam, getShortAgentName);
  const agentEmail = React.useMemo(() => agents.map((it) => it.email || ''), [agents]);

  const filterContext = React.useMemo(() => ({ ...useRBACResult, isTrial: currentAgent?.inTrial }), [currentAgent?.inTrial, useRBACResult]);

  const filtersOptions = React.useMemo(
    () => FILTER_OPTIONS(filterContext),
    [filterContext],
  );

  const TO_BE_MANAGED_COMPUTED_FILTER_OPTION = React.useMemo(() => TO_BE_MANAGED_FILTER_OPTION(filterContext), [filterContext]);

  const {
    filters,
    addFilters,
    removeFilter,
    removeFilters,
  } = useFilters(FILTERS_MAPPER(filterContext));

  const sortOption = React.useMemo(() => {
    if (filters.sort && filters.sort !== DEFAULT_SORT_OPTION.key) {
      return SORT_OPTIONS.find(({ key }) => key === filters.sort);
    }
    if (filters.filter) {
      return SORT_OPTIONS_PER_FILTER[filters.filter!];
    }

    return MOST_RECENT_CREATION_SORT_OPTION;
  }, [filters.filter, filters.sort]);

  const filterLabel = React.useMemo(() => {
    if (filters.filter === ALL_PROPERTIES_FILTER_OPTION.key) {
      return '';
    }

    const filterObject = filtersOptions.find((option) => option.key === filters.filter);

    return filterObject?.label.long.toLowerCase();
  }, [filters.filter, filtersOptions]);

  const {
    data: serpProperties,
    isLoading: areSerpPropertiesLoading,
    error: serpPropertiesError,
    mutate: mutateSerpProperties,
  } = useSerpProperties(isAgentFilterLoading || isCurrentAgentLoading
    ? undefined
    : {
      q: filters.q,
      agentEmail: isAgentFilterExplicit || filters.filter !== 'all' ? agentEmail : undefined,
      ...(filtersOptions.find(({
        key,
      }) => key === filters.filter)?.value),
    }, {
    page,
    sort: sortOption?.value,
  });

  const handleSearch = React.useCallback((query: string) => {
    if (query) {
      addFilters({ q: query });
      removeFilter(paginationQueryParam);
    } else {
      removeFilters(['q', paginationQueryParam]);
    }
  }, [addFilters, paginationQueryParam, removeFilter, removeFilters]);

  const mutateProperties = React.useCallback(async () => {
    await mutateSerpProperties();
  }, [mutateSerpProperties]);

  const handleBadgeClick = React.useCallback((badgeFilter: PropertySerpPageFilterKey) => () => {
    removeFilter(paginationQueryParam);
    addFilters({ filter: badgeFilter });
  }, [addFilters, paginationQueryParam, removeFilter]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Bar>
        <HStack
          alignItems="center"
          justifyContent="space-between"
          gap={100}
        >
          <div style={{ flexGrow: '1' }}>
            {isDesktop && (
              <HStack aria-label="Filtri (desktop)" gap={150}>
                {(filterContext.userIsAdmin || filterContext.userIsAgent) && (
                  <Action
                    label={TO_BE_MANAGED_COMPUTED_FILTER_OPTION.label.short}
                    aria-label="Da gestire"
                    onClick={filters.filter !== TO_BE_MANAGED_COMPUTED_FILTER_OPTION.key
                      ? handleBadgeClick(TO_BE_MANAGED_COMPUTED_FILTER_OPTION.key)
                      : undefined}
                    color={filters.filter !== TO_BE_MANAGED_COMPUTED_FILTER_OPTION.key
                      ? 'neutral'
                      : 'primary'}
                  />
                )}

                {GROUPED_FILTER_OPTIONS(filterContext).map(({ label, options }) => (
                  <ActionDropDown
                    key={label}
                    label={label}
                    aria-label={`Filtra immobili per ${label}`}
                    emphasis="low"
                    color={options(filterContext).some(({ key }) => key === filters.filter) ? 'primary' : 'neutral'}
                    options={options(filterContext).map(({ key, label: optionLabel }) => ({
                      key,
                      label: optionLabel.short,
                      color: filters.filter !== key ? 'neutral' : 'primary',
                      iconRight: filters.filter !== key ? undefined : { path: ICON_CHECK },
                      onClick: filters.filter !== key ? handleBadgeClick(key) : undefined,
                    }))}
                  />
                ))}

                {!filterContext.userIsAgent && (
                  <Action
                    label={ALL_PROPERTIES_FILTER_OPTION.label.short}
                    aria-label="Tutti gli immobili"
                    onClick={filters.filter !== ALL_PROPERTIES_FILTER_OPTION.key
                      ? handleBadgeClick(ALL_PROPERTIES_FILTER_OPTION.key)
                      : undefined}
                    color={filters.filter !== ALL_PROPERTIES_FILTER_OPTION.key
                      ? 'neutral'
                      : 'primary'}
                  />
                )}

                {filterContext.userIsAgent && filterContext.isTrial && (
                  <Action
                    label={PORTFOLIO_FILTER_OPTION.label.short}
                    aria-label="Filtra immobili per portafoglio"
                    onClick={filters.filter !== PORTFOLIO_FILTER_OPTION.key
                      ? handleBadgeClick(PORTFOLIO_FILTER_OPTION.key)
                      : undefined}
                    color={filters.filter !== PORTFOLIO_FILTER_OPTION.key
                      ? 'neutral'
                      : 'primary'}
                  />
                )}
              </HStack>
            )}
            {!isDesktop && (
              <Select
                value={filters.filter}
                aria-label="Seleziona un filtro dell'immobile (mobile)"
                options={filtersOptions.map(({ label, key }) => ({
                  label: label.long,
                  value: key,
                }))}
                size="S"
                onChange={({ target }) => handleBadgeClick(target.value as PropertySerpPageFilterKey)()}
              />
            )}
          </div>

          <HStack
            alignItems="center"
            gap={100}
          >
            <SearchByQueryAction
              initialQuery={filters.q}
              onSearch={handleSearch}
              placeholder="Cerca per immobile o testo"
              size={isDesktop ? 'M' : 'S'}
              variant={isDesktop ? 'input' : 'action'}
            />
            <AgentFilterAction
              size={isDesktop ? 'M' : 'S'}
              onSubmit={(emails) => replaceAgentFilter(emails)()}
            />
            <ActionIconDropdown
              label="Ordina"
              aria-label="Pulsante per selezionare l'ordinamento degli immobili"
              placement="bottom-end"
              icon={{ path: !filters.sort || filters.sort !== DEFAULT_SORT_OPTION.key ? ICON_CUSTOM_SORT_PLUS : ICON_SORT }}
              size={isDesktop ? 'M' : 'S'}
              color={filters.sort && filters.sort !== DEFAULT_SORT_OPTION.key ? 'primary' : 'neutral'}
              emphasis="low"
              options={SORT_OPTIONS.map(({ key, label }) => ({
                key,
                label,
                iconRight: (!filters.sort && key === DEFAULT_SORT_OPTION.key) || filters.sort === key ? { path: ICON_CHECK } : undefined,
                color: (key !== DEFAULT_SORT_OPTION.key && filters.sort === key)
                  ? 'primary'
                  : 'neutral',
                onClick: () => addFilters({ sort: key }),
              }))}
            />
          </HStack>
        </HStack>
      </GenericPageLayout.Bar>
      <GenericPageLayout.Content>
        <HStack justifyContent="space-between" alignItems="center">
          <Stack gap={100}>
            <Text.H4 aria-label="Titolo pagina">
              {`Immobili${filterLabel ? ` ${filterLabel}` : ''}${(serpProperties && serpProperties.totalElements > 0) ? ` (${serpProperties.totalElements})` : ''}`}
            </Text.H4>
            <SerpFiltersSummary
              aria-label="search-filters-summary"
              filters={{ ...filters, agentFilters: { values: agents, isExplicit: isAgentFilterExplicit } }}
              sortOptions={SORT_OPTIONS}
            />
          </Stack>
        </HStack>
        <Spacing
          margin={[300, 0, 0]}
          aria-label="Lista immobili"
        >
          {areSerpPropertiesLoading && isDesktop && (
            <SimpleTableSkeleton aria-label="Immobili in caricamento" />
          )}
          {areSerpPropertiesLoading && !isDesktop && (
            <Stack aria-label="Immobili in caricamento">
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </Stack>
          )}
          {serpPropertiesError && !areSerpPropertiesLoading && (
            <Message
              message={LOAD_SERP_PROPERTIES_ERROR_MESSAGE}
              type="critical"
              boxed
            />
          )}
          {(!areSerpPropertiesLoading && (serpProperties && serpProperties.totalElements > 0)) && (
            <PaginatedList
              {...serpProperties}
              hideResultCount
              goToPage={goToPage}
            >
              {!isDesktop && serpProperties.content.map((serpProperty) => (
                <ViewSerpPropertyCard
                  key={serpProperty.property.id}
                  serpProperty={serpProperty}
                  onIntentCreate={mutateProperties}
                  onOfferUpdate={mutateProperties}
                  onPropertyUpdate={mutateProperties}
                  onShootingUpdate={mutateProperties}
                />
              ))}
              {isDesktop && (
                <SimpleTable>
                  <SimpleTable.Body>
                    {serpProperties.content.map((serpProperty) => (
                      <SimpleTable.Row
                        asChild
                        key={serpProperty.property.id}
                      >
                        <ViewSerpPropertyTableRow
                          serpProperty={serpProperty}
                          onIntentCreate={mutateProperties}
                          onOfferUpdate={mutateProperties}
                          onPropertyUpdate={mutateProperties}
                          onShootingUpdate={mutateProperties}
                        />
                      </SimpleTable.Row>
                    ))}
                  </SimpleTable.Body>
                </SimpleTable>
              )}
            </PaginatedList>
          )}
          {(serpProperties && serpProperties.totalElements === 0) && (
            <Message message={NO_SERP_PROPERTIES} boxed />
          )}
        </Spacing>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default PropertySerpPage;

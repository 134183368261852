import React from 'react';
import {
  Action, ActionIcon, ICON_BROADCAST, Message, ModalSize, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Property, PublishedOn, PublishedOnBatch } from '../../../../providers/api/dtos';
import SimpleModal from '../../../../components/simple-modal/SimpleModal';
import PublishedOnForm, { PublishedOnChangeEvent } from '../../components/published-on-form/PublishedOnForm';
import { toPublishedOnModel } from '../../mappers/publishedOnMapper';
import { getDisabledAggregators } from './utils';
import { LUXURY_AGGREGATORS } from '../../../../domain/types';
import { raise } from '../../../../utils';
import { updatePublishedOns } from '../../../../providers/api/publishedOn/publishedOnProvider';

export const UPDATE_PUBLISHED_ONS_SUCCESS_MESSAGE = "Lo stato di pubblicazione dell'immobile è stato aggiornato con successo";
export const UPDATE_PUBLISHED_ONS_ERROR_MESSAGE = "Non è stato possibile aggiornare lo stato di pubblicazione dell'immobile";
const MISSING_ENGLISH_DESCRIPTION_WARNING_MESSAGE = 'Per poter pubblicare l\'annuncio sui portali Luxury è necessario compilare la descrizione anche in lingua inglese.';

interface ChildFnProps {
  disabled: boolean,
  openPublishedOnsModal: VoidFunction,
}

export interface ManagePublishedOnsActionProps {
  property: Property,
  disabled?: boolean,
  onSuccess?: (updatedPublishedOns: PublishedOn[]) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const ManagePublishedOnsAction: React.FC<ManagePublishedOnsActionProps> = ({
  property,
  disabled = false,
  onSuccess,
  children,
}) => {
  const { id, publishedOn, descriptionEn } = property;
  const propertyId = id ?? raise('missing property id');

  const [isSaving, setIsSaving] = React.useState(false);
  const { addError, addSuccess } = useNotifications();
  const [publishedOns, setPublishedOns] = React.useState(new Set(publishedOn));
  const publishedOnsModal = useModal();

  const disabledAggregators = React.useMemo(() => getDisabledAggregators(property), [property]);

  const missingEnglishDescription: boolean = React.useMemo(() => disabledAggregators.some(
    (aggregator) => LUXURY_AGGREGATORS.includes(aggregator),
  ) && !descriptionEn, [disabledAggregators, descriptionEn]);

  const openPublishedOnsModal = React.useCallback(() => {
    publishedOnsModal.open();
  }, [publishedOnsModal]);

  const onChange = React.useCallback((updatedPublishedOnEvent: PublishedOnChangeEvent) => {
    const { checked, aggregator } = updatedPublishedOnEvent;

    const updatedPublishedOn: PublishedOn = {
      propertyId,
      aggregator,
    };

    if (checked) {
      setPublishedOns((prev) => new Set([...prev, updatedPublishedOn]));
    } else {
      setPublishedOns((prev) => new Set([...prev].filter((p) => p.aggregator !== aggregator)));
    }
  }, [propertyId]);

  const onConfirm = React.useCallback(async () => {
    try {
      setIsSaving(true);

      const publishedOnBatch: PublishedOnBatch = {
        propertyId,
        publishedOns: [...publishedOns],
      };

      const updatedPublishedOnBatch = await updatePublishedOns(publishedOnBatch);

      setIsSaving(false);
      addSuccess(UPDATE_PUBLISHED_ONS_SUCCESS_MESSAGE);
      publishedOnsModal.close();

      if (onSuccess) {
        onSuccess(updatedPublishedOnBatch.publishedOns);
      }
    } catch (_) {
      setIsSaving(false);
      addError(UPDATE_PUBLISHED_ONS_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, onSuccess, propertyId, publishedOns, publishedOnsModal]);

  return (
    <>
      {children?.({ disabled, openPublishedOnsModal }) ?? (
        <ActionIcon
          label="Modifica portali"
          aria-label="Modifica portali"
          disabled={disabled}
          icon={{ path: ICON_BROADCAST }}
          onClick={openPublishedOnsModal}
        />
      )}
      <Portal>
        <SimpleModal
          {...publishedOnsModal}
          aria-label="Modifica i portali su cui è pubblicato l'immobile"
          title="Modifica portali"
          size={ModalSize.LARGE}
          footer={(
            <Action
              label="Modifica"
              color="primary"
              emphasis="high"
              aria-label="Conferma modifiche"
              onClick={onConfirm}
            />
          )}
        >
          <PublishedOnForm
            values={toPublishedOnModel(publishedOn)}
            onChange={onChange}
            disabled={isSaving}
            disabledAggregators={disabledAggregators}
            luxuryWarning={missingEnglishDescription ? (
              <Message
                type="warning"
                message={MISSING_ENGLISH_DESCRIPTION_WARNING_MESSAGE}
              />
            ) : undefined}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ManagePublishedOnsAction;

import React from 'react';
import {
  DetailItemList, Grid, HStack, Icon, ICON_ASPECT_RATIO, ICON_BALCONY, ICON_BEACH_UMBRELLA, ICON_CAR_OUTLINE, ICON_ELEVATOR, ICON_LAND_PLOTS, ICON_SHELF, ICON_STAIRS, Spacing, Stack, Text, UserPreview, UserPreviewSkeleton,
} from '@doveit/bricks';
import { formatDate, formatSquareMeters, pluralizeWord } from '@doveit/hammer';
import { Property } from '../../../providers/api/dtos';
import ViewPropertyCover from '../view-property-cover/ViewPropertyCover';
import UpdatePropertyStatus from '../update-property-status/UpdatePropertyStatus';
import { AgentPreview } from '../../../agent/components';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import {
  countRooms, formatFloor, isApartmentAlike, NO_VALUE_SYMBOL,
} from '../../utils';
import { FeatureType } from '../../../domain/types';
import useRooms from '../../hooks/use-rooms/useRooms';
import useBalconies from '../../hooks/use-balconies/useBalconies';
import useTerraces from '../../hooks/use-terraces/useTerraces';
import useGarages from '../../hooks/use-garages/useGarages';
import useCellars from '../../hooks/use-cellars/useCellars';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';

export const LOAD_PROPERTY_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni dell\'immobile.';

export interface ViewPropertySummaryProps extends React.AriaAttributes {
  property: Property,
  onPropertyUpdate?: (updatedProperty: Property) => void,
  canSort?: boolean,
  canEdit?: boolean,
  canAdd?: boolean,
  canDelete?: boolean,
  canSeeMissingAboutError?: boolean,
}

const ViewPropertySummary: React.FC<ViewPropertySummaryProps> = ({
  property,
  onPropertyUpdate,
  canSort = false,
  canEdit = false,
  canAdd = false,
  canDelete = false,
  canSeeMissingAboutError = false,
  ...rest
}) => {
  const {
    data: agent,
    isLoading: isAgentLoading,
    error: agentError,
  } = useAgent(property?.agentEmail);

  const { data: rooms } = useRooms(property?.id);
  const { data: balconies } = useBalconies(property.id);
  const { data: terraces } = useTerraces(property.id);
  const { data: garages } = useGarages(property.id);
  const { data: cellars } = useCellars(property.id);

  const numberOfRooms = React.useMemo(() => (rooms ? countRooms(rooms) : 0), [rooms]);

  return (
    <Stack gap={150} {...rest}>
      <ViewPropertyCover
        propertyId={property.id!}
        propertyStatus={property.status}
        canAdd={canAdd}
        canDelete={canDelete}
        canEdit={canEdit}
        canSort={canSort}
        canSeeMissingAboutError={canSeeMissingAboutError}
      />
      <Grid gutter={50}>
        <Grid.Unit size={1 / 3}>
          <HStack
            aria-label="Superficie"
            wrap="nowrap"
          >
            <Icon
              path={ICON_ASPECT_RATIO}
              color="neutral.hover.low"
            />
            <Text.BodySmall color="neutral.hover.low">
              {property.propertySize && formatSquareMeters(property.propertySize)}
            </Text.BodySmall>
          </HStack>
        </Grid.Unit>

        {isApartmentAlike(property.propertyType) && (
          <Grid.Unit size={1 / 3}>
            <HStack
              aria-label="Piano"
              wrap="nowrap"
            >
              <Icon
                path={ICON_STAIRS}
                color="neutral.hover.low"
              />
              <Text.BodySmall color="neutral.hover.low">
                {property?.mainFloor !== undefined ? formatFloor(property?.mainFloor, true) : NO_VALUE_SYMBOL} di {property.buildingFloors ?? NO_VALUE_SYMBOL}
              </Text.BodySmall>
            </HStack>
          </Grid.Unit>
        )}

        <Grid.Unit size={1 / 3}>
          <HStack
            aria-label="Ascensore"
            wrap="nowrap"
          >
            <Icon
              path={ICON_ELEVATOR}
              color="neutral.hover.low"
            />
            <Text.BodySmall color="neutral.hover.low">
              {property.features.some(({ type }) => type === FeatureType.ASCENSORE) ? 'Ascens.' : 'No ascens.'}
            </Text.BodySmall>
          </HStack>
        </Grid.Unit>

        <Grid.Unit size={1 / 3}>
          <HStack
            aria-label="Locali"
            wrap="nowrap"
          >
            <Icon
              path={ICON_LAND_PLOTS}
              color="neutral.hover.low"
            />
            <Text.BodySmall color="neutral.hover.low">
              {numberOfRooms} {pluralizeWord('local', 'e', 'i', numberOfRooms)}
            </Text.BodySmall>
          </HStack>
        </Grid.Unit>

        {!!terraces?.length && (
          <Grid.Unit size={1 / 3}>
            <HStack
              aria-label="Terrazze"
              wrap="nowrap"
            >
              <Icon
                path={ICON_BEACH_UMBRELLA}
                color="neutral.hover.low"
              />
              <Text.BodySmall color="neutral.hover.low">
                {`${terraces.length} ${pluralizeWord('terrazz', 'a', 'e', terraces.length)}`}
              </Text.BodySmall>
            </HStack>
          </Grid.Unit>
        )}

        {!!balconies?.length && (
          <Grid.Unit size={1 / 3}>
            <HStack
              aria-label="Balconi"
              wrap="nowrap"
            >
              <Icon
                path={ICON_BALCONY}
                color="neutral.hover.low"
              />
              <Text.BodySmall color="neutral.hover.low">
                {`${balconies.length} ${pluralizeWord('balcon', 'e', 'i', balconies.length)}`}
              </Text.BodySmall>
            </HStack>
          </Grid.Unit>
        )}

        {!!cellars?.length && (
          <Grid.Unit size={1 / 3}>
            <HStack
              aria-label="Cantine"
              wrap="nowrap"
            >
              <Icon
                path={ICON_SHELF}
                color="neutral.hover.low"
              />
              <Text.BodySmall color="neutral.hover.low">
                {`${cellars.length} ${pluralizeWord('cantin', 'a', 'e', cellars.length)}`}
              </Text.BodySmall>
            </HStack>
          </Grid.Unit>
        )}

        {!!garages?.length && (
          <Grid.Unit size={1 / 3}>
            <HStack
              aria-label="Posti auto"
              wrap="nowrap"
            >
              <Icon
                path={ICON_CAR_OUTLINE}
                color="neutral.hover.low"
              />
              <Text.BodySmall color="neutral.hover.low">
                {`${garages.length} ${pluralizeWord('post', 'o', 'i', garages.length)} auto`}
              </Text.BodySmall>
            </HStack>
          </Grid.Unit>
        )}
      </Grid>

      <Spacing />

      <div>
        <UpdatePropertyStatus
          property={property}
          emphasis="high"
          onSuccess={onPropertyUpdate}
          showIcon
        />
      </div>

      <DetailItemList>
        <DetailItemList.Item label="Data creazione">
          {property.createdAt && formatDate(new Date(property.createdAt))}
        </DetailItemList.Item>

        <DetailItemList.Item
          label="Agente"
          withoutVPadding
        >
          {isAgentLoading && <UserPreviewSkeleton size="XS" />}
          {agent && (
            <ViewAgentPopoverPreview
              agent={agent}
              trigger={(
                <AgentPreview
                  {...toAgentPreviewProps(agent)}
                  size="S"
                />
              )}
            />
          )}
          {agentError && (
            <UserPreview
              size="XS"
              name="Errore agente"
            />
          )}
        </DetailItemList.Item>
      </DetailItemList>
    </Stack>
  );
};

export default ViewPropertySummary;

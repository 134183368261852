/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  ActionIcon, Card, DetailStack, Dropdown, FontWeight, HStack, ICON_EYE_OUTLINE, Popover, Stack,
  Text,
} from '@doveit/bricks';
import { formatEuro, pluralizeWord } from '@doveit/hammer';
import { isPast, isToday } from 'date-fns';
import {
  Intent, Property, SerpProperty, Shooting,
} from '../../../providers/api/dtos';
import { goToDetailPage } from '../../../utils/navigate/utils';
import {
  Aggregator, ReferenceType, Status,
} from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import CreateIntentAction from '../../../intent/containers/create-intent-action/CreateIntentAction';
import UpsertNoteAction from '../../../notes/containers/upsert-note-action-v2/UpsertNoteAction';
import EntityReferenceBadge from '../../../components/entity-reference-badge/EntityReferenceBadge';
import UpdatePropertyStatus from '../update-property-status/UpdatePropertyStatus';
import DateViewer from '../../../components/date-viewer/DateViewer';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import UpdateShootingStatus from '../../../shooting/containers/update-shooting-status/UpdateShootingStatus';
import UpdateOfferStatus from '../../../offer/containers/update-offer-status/UpdateOfferStatus';
import { ReferenceEntityWithNotes } from '../../../types';
import { buildOfferDateToDisplay } from '../../../offer/utils';
import PropertyPreview from '../../components/property-preview/PropertyPreview';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import * as styles from './ViewSerpPropertyCard.style';

export interface ViewSerpPropertyCardProps extends React.AriaAttributes {
  serpProperty: SerpProperty,
  onPropertyUpdate?: (property: Property) => void,
  onShootingUpdate?: (shooting: Shooting) => void,
  onIntentCreate?: (intent: Intent) => void,
  onOfferUpdate?: VoidFunction,
}

const MAX_NOTE_LENGTH = 100;
export const UPSERT_NOTE_ERROR_MESSAGE = 'Non è stato possibile aggiungere la nota';
export const UPSERT_NOTE_SUCCESS_MESSAGE = 'Nota aggiunta con successo';

const ViewSerpPropertyCard: React.FC<ViewSerpPropertyCardProps> = ({
  serpProperty,
  onPropertyUpdate,
  onShootingUpdate,
  onIntentCreate,
  onOfferUpdate,
  ...rest
}) => {
  const {
    property, agent, assignment, offer, shooting,
  } = serpProperty;
  const { userIsAdmin, userIsCallCenter } = useRBAC();
  const userIsPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(agent.id);

  const goToPropertyPage = React.useCallback(() => {
    goToDetailPage(property.id!, ReferenceType.PROPERTY);
  }, [property.id]);

  const canCreateIntent = React.useMemo(
    () => userIsAdmin || userIsCallCenter || userIsPropertyAgentOrManager,
    [userIsAdmin, userIsCallCenter, userIsPropertyAgentOrManager],
  );

  const showUpdatedAt = React.useMemo(
    () => [Status.ANTEPRIMA, Status.LIVE].includes(property.status),
    [property.status],
  );

  const showPublishedAt = React.useMemo(
    () => property.status === Status.LIVE && property.publishedOn.some(({ aggregator, publishedAt }) => aggregator === Aggregator.DOVE_IT && !!publishedAt),
    [property.publishedOn, property.status],
  );

  const showRetiredAt = React.useMemo(
    () => property.status === Status.RITIRATO && !!property.retiredAt,
    [property.retiredAt, property.status],
  );

  const showAssignmentEndDate = React.useMemo(
    () => [Status.ANTEPRIMA, Status.LIVE].includes(property.status) && !!assignment.endDate,
    [assignment.endDate, property.status],
  );

  const showShootingInfo = React.useMemo(
    () => !!shooting && property.status === Status.DRAFT,
    [property.status, shooting],
  );

  const showMinPrice = React.useMemo(
    () => property.status !== Status.RITIRATO && !!property.minimumPrice,
    [property.minimumPrice, property.status],
  );

  const showPrice = React.useMemo(
    () => [Status.ANTEPRIMA, Status.LIVE].includes(property.status) && !!property.price,
    [property.price, property.status],
  );

  const showSaleInfo = React.useMemo(
    () => property.status === Status.VENDUTO && !!property.soldAt && !!property.soldPrice,
    [property.soldAt, property.soldPrice, property.status],
  );

  const showOfferInfo = React.useMemo(
    () => property.status === Status.PROPOSTA && !!offer,
    [offer, property.status],
  );

  const noteToDisplay = React.useMemo(
    () => property.notes?.[0],
    [property.notes],
  );

  const showNote = React.useMemo(
    () => noteToDisplay && [Status.DRAFT, Status.ANTEPRIMA].includes(property.status),
    [noteToDisplay, property.status],
  );

  const dateToDisplayWithOffer = React.useMemo(() => {
    if (!offer) return undefined;

    return buildOfferDateToDisplay(offer);
  }, [offer]);

  const showPublishedOns = React.useMemo(
    () => property.status === Status.LIVE,
    [property.status],
  );

  const onNoteCreated = React.useCallback((updatedProperty: ReferenceEntityWithNotes) => {
    onPropertyUpdate?.(updatedProperty as Property);
  }, [onPropertyUpdate]);

  return (
    <Card {...rest}>
      <Card.Header
        primaryActions={[
          <ActionIcon
            aria-label="Visualizza immobile"
            label="Visualizza"
            size="S"
            icon={{ path: ICON_EYE_OUTLINE }}
            onClick={goToPropertyPage}
          />,
        ]}
        secondaryActions={[
          canCreateIntent && (
            <CreateIntentAction
              propertyId={property.id}
              onSuccess={onIntentCreate}
            >
              {({ createIntent }) => (
                <Dropdown.Option
                  label="Aggiungi interesse"
                  aria-label="Aggiungi interesse"
                  onClick={createIntent}
                />
              )}
            </CreateIntentAction>
          ),
          <UpsertNoteAction
            reference={property}
            referenceType={ReferenceType.PROPERTY}
            aria-label="Pulsante per aggiungere una nota"
            onSuccess={onNoteCreated}
          >
            {({ upsert }) => (
              <Dropdown.Option
                label="Aggiungi nota"
                aria-label="Aggiungi nota"
                onClick={upsert}
              />
            )}
          </UpsertNoteAction>,
        ]}
      >
        <PropertyPreview property={property} />
      </Card.Header>

      <Card.Content aria-label="Informazioni immobile">
        <Stack gap={150}>
          {/** First row */}
          <HStack gap={150}>
            <EntityReferenceBadge
              entity={ReferenceType.PROPERTY}
              id={property.id!}
              label={property.referenceId}
            />

            <UpdatePropertyStatus
              property={property}
              size="XS"
              onSuccess={onPropertyUpdate}
            />
          </HStack>

          {/** Second row */}
          <HStack gap={150} justifyContent="space-between">
            <HStack gap={150}>
              <DetailStack
                label="Aggiunto"
                aria-label="Data di aggiunta"
              >
                <DateViewer
                  stringDate={property.createdAt!}
                  checkIfToday
                />
              </DetailStack>

              {showUpdatedAt && (
                <DetailStack
                  label="Aggiornato"
                  aria-label="Data di aggiornamento"
                >
                  <DateViewer
                    stringDate={property.updatedAt!}
                    checkIfToday
                  />
                </DetailStack>
              )}

              {showPublishedAt && (
                <DetailStack
                  label="Pubblicato"
                  aria-label="Data di pubblicazione"
                >
                  <DateViewer
                    stringDate={property.publishedOn.find(({ aggregator }) => aggregator === Aggregator.DOVE_IT)!.publishedAt!}
                    checkIfToday
                  />
                </DetailStack>
              )}
            </HStack>

            <ViewAgentPopoverPreview
              agent={agent}
              aria-label="Preview agente"
              trigger={(
                <AgentPreview
                  {...toAgentPreviewProps(agent, { omit: { name: true } })}
                  size="S"
                />
              )}
            />
          </HStack>

          <Card.Divider />

          <HStack gap={150}>
            {showMinPrice && (
              <DetailStack
                label="Richiesta"
                aria-label="Richiesta"
              >
                <strong>
                  {formatEuro(property.minimumPrice!)}
                </strong>
              </DetailStack>
            )}

            {showOfferInfo && (
              <>
                <DetailStack
                  label={dateToDisplayWithOffer!.label}
                  aria-label={dateToDisplayWithOffer!.label}
                  color={dateToDisplayWithOffer!.color}
                >
                  <DateViewer
                    stringDate={dateToDisplayWithOffer!.value}
                    checkIfToday
                    checkIfTomorrow
                  />
                </DetailStack>

                <DetailStack
                  label="Proposta"
                  aria-label="Proposta"
                >
                  <strong>
                    {formatEuro(offer!.value)}
                  </strong>
                </DetailStack>

                <UpdateOfferStatus
                  aria-label="Stato proposta"
                  intentId={offer!.intentId}
                  lastOffer={offer}
                  size="XS"
                  onSuccess={onOfferUpdate}
                />
              </>
            )}

            {showAssignmentEndDate && (
              <DetailStack
                label="Scad. mandato"
                aria-label="Scadenza mandato"
                color={(isPast(assignment.endDate!) && !isToday(assignment.endDate!))
                  ? 'critical.default.low'
                  : 'neutral.default.low'}
              >
                <DateViewer
                  stringDate={assignment.endDate!}
                  checkIfToday
                  checkIfTomorrow
                />
              </DetailStack>
            )}

            {showSaleInfo && (
              <>
                <DetailStack
                  label="Vendita"
                  aria-label="Data di vendita"
                >
                  <DateViewer
                    stringDate={property.soldAt!}
                    checkIfToday
                  />
                </DetailStack>
                <DetailStack
                  label="Venduto a"
                  aria-label="Prezzo di vendita"
                >
                  <strong>
                    {formatEuro(property.soldPrice!)}
                  </strong>
                </DetailStack>
              </>
            )}

            {showShootingInfo && (
              <>
                <DetailStack
                  label="Shooting"
                  aria-label="Data di shooting"
                >
                  <DateViewer
                    stringDate={shooting!.date}
                    checkIfToday
                    checkIfTomorrow
                  />
                </DetailStack>
                <UpdateShootingStatus
                  aria-label="Stato shooting"
                  shooting={shooting!}
                  size="XS"
                  onSuccess={onShootingUpdate}
                />
              </>
            )}

            {showPublishedOns && (
              <DetailStack
                label="Visibile su"
                aria-label="Numero di portali"
                color={showPublishedAt ? 'neutral.default.low' : 'critical.default.low'}
              >
                {property.publishedOn.length} {pluralizeWord('portal', 'e', 'i', property.publishedOn.length)}
              </DetailStack>
            )}

            {showPrice && (
              <DetailStack
                label="In vendita a"
                aria-label="Prezzo in vendita"
              >
                <strong>
                  {formatEuro(property.price!)}
                </strong>
              </DetailStack>
            )}

            {showRetiredAt && (
              <DetailStack
                label="Ritiro"
                aria-label="Data di ritiro"
              >
                <DateViewer
                  stringDate={property.retiredAt!}
                  checkIfToday
                />
              </DetailStack>
            )}
          </HStack>
          {showNote && (
            <Popover
              aria-label="Visualizza dettagli note"
              trigger={(
                <Text.Caption>
                  {truncateTextWithSuffix(noteToDisplay!.text, MAX_NOTE_LENGTH)}
                </Text.Caption>
              )}
              hasArrow
            >
              <div css={styles.notePopoverWrapper}>
                <Text.Body fontWeight={FontWeight.REGULAR}>{noteToDisplay?.author}</Text.Body>
                <Text.BodySmall>{noteToDisplay!.text}</Text.BodySmall>
              </div>
            </Popover>
          )}
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default ViewSerpPropertyCard;

import React from 'react';
import { isAfter, isFuture } from 'date-fns';
import { AxiosError } from 'axios';
import { DigitalOfferStatus, OfferDeedStatus, OfferStatus } from '../../../domain/types';
import { Intent, Offer, SignatureStatus } from '../../../providers/api/dtos';
import useOfferWizardData from '../use-offer-wizard-data/useOfferWizardData';
import useOfferSignatureStatus from '../use-offer-signature-status/useOfferSignatureStatus';

type ComputedOfferStatusCanBeUpdatedTo = ComputedOfferStatus | 'SIGNATURE_REQUEST_CANCELLED' | 'SIGNATURE_REQUEST_DELETED';
export type ComputedOfferStatus = OfferStatus | DigitalOfferStatus | 'DEED_DONE' | 'NO_STATUS';

export type UseComputedOfferStatusData = {
  status: ComputedOfferStatus,
  canBeUpdated: boolean,
  canBeUpdatedTo: ComputedOfferStatusCanBeUpdatedTo[],
};

type UseComputedOfferStatusResult = {
  data?: UseComputedOfferStatusData | undefined,
  isLoading: boolean,
  errors?: Error[] | undefined,
  mutate: () => Promise<void>,
};

export const computedOfferStatusCanBeUpdatedTo: (signatureStatus: SignatureStatus | undefined) => Record<ComputedOfferStatus, ComputedOfferStatusCanBeUpdatedTo[]> = (signatureStatus) => ({
  NO_STATUS: [],
  IN_PROGRESS: [OfferStatus.REFUSED, OfferStatus.WITHDRAWN],
  WAITING_FOR_ACCEPTANCE: [OfferStatus.REFUSED, OfferStatus.WITHDRAWN],
  ACCEPTED: [OfferStatus.CONFIRMED, OfferStatus.MORTGAGE_REFUSED, OfferStatus.OTHER_CONSTRAINTS],
  CONFIRMED: ['DEED_DONE'],
  DEED_DONE: [],
  MORTGAGE_REFUSED: [],
  OTHER_CONSTRAINTS: [],
  REFUSED: [],
  WITHDRAWN: [],
  WAITING_FOR_SIGNATURE: [signatureStatus === SignatureStatus.ONGOING ? 'SIGNATURE_REQUEST_CANCELLED' : 'SIGNATURE_REQUEST_DELETED'],
});

export function useComputedOfferStatus(
  intentId: NonNullable<Intent['id']>,
  offer?: Offer,
): UseComputedOfferStatusResult {
  const [errors, setErrors] = React.useState(
    offer && offer.intentId !== intentId
      ? [new Error(`Offer must be related to the intent with id ${intentId}`)]
      : undefined,
  );

  const handleNewError = React.useCallback((err: AxiosError) => {
    if (err.response?.status && [400, 404].includes(err.response.status)) return;

    setErrors((prev) => {
      if (!prev) return [err];
      return [...prev, err];
    });
  }, []);

  const {
    data: wizardData,
    isLoading: isWizardDataLoading,
    mutate: mutateWizardData,
  } = useOfferWizardData(intentId, { onError: handleNewError });

  const {
    data: signatureData,
    isLoading: isSignatureStatusLoading,
    mutate: mutateSignatureStatus,
  } = useOfferSignatureStatus(intentId, { onError: handleNewError });

  /**
   * @todo handle WAITING_FOR_ACCEPTANCE status when we will introduce acceptance flow
   */
  const computedStatus: ComputedOfferStatus = React.useMemo(() => {
    if (offer && (!wizardData?.isDigitallySigned || isAfter(new Date(offer.updatedAt!), new Date(wizardData.updatedAt!)))
    ) {
      if (offer.deed?.status === OfferDeedStatus.CONFIRMED && !isFuture(offer.deed.date)) {
        return 'DEED_DONE';
      }

      return offer.status;
    }

    if (wizardData?.isDigitallySigned) {
      return DigitalOfferStatus.WAITING_FOR_SIGNATURE;
    }

    return 'NO_STATUS';
  }, [offer, wizardData]);

  const mutate = React.useCallback(async () => {
    await mutateWizardData();
    await mutateSignatureStatus();
  }, [mutateSignatureStatus, mutateWizardData]);

  const isLoading = React.useMemo(
    () => isWizardDataLoading || isSignatureStatusLoading,
    [isSignatureStatusLoading, isWizardDataLoading],
  );

  const data: UseComputedOfferStatusData = React.useMemo(() => {
    const canBeUpdatedTo = computedOfferStatusCanBeUpdatedTo(signatureData?.status)[computedStatus];

    return {
      status: computedStatus,
      canBeUpdated: canBeUpdatedTo.length > 0,
      canBeUpdatedTo,
    };
  }, [computedStatus, signatureData?.status]);

  return React.useMemo(() => ({
    data: (!errors && !isLoading) ? data : undefined,
    isLoading,
    errors,
    mutate,
  }), [data, errors, isLoading, mutate]);
}

import React from 'react';
import {
  Card, ConfirmModal, ICON_ACCOUNT_CIRCLE_OUTLINE, Message, useModal,
  useNotifications,
} from '@doveit/bricks';
import { Contact, Rumor } from '../../../providers/api/dtos';
import ContactWidget, { ContactWidgetProps } from '../../../contact/containers/contact-widget/ContactWidget';
import AssociateContactToRumorAction from '../associate-contact-to-rumor-action/AssociateContactToRumorAction';
import { updateRumor } from '../../../providers/api/rumor/rumorProvider';

export const CONTACT_DISASSOCIATE_OPERATION_ERROR_MESSAGE = 'Non è stato possibile dissociare il contatto dalla notizia.';
export const CONTACT_DISASSOCIATE_OPERATION_SUCCESS_MESSAGE = 'Contatto dissociato con successo.';

export interface AssociateContactToRumorWidgetProps extends Omit<ContactWidgetProps, 'contactId'> {
  rumor: Rumor,
  asNestedComponent?: boolean,
  onDisassociateSuccess?: (rumor: Rumor) => void
  onAssociateSuccess?: (rumor: Rumor, contact: Contact) => void
}

const AssociateContactToRumorWidget: React.FC<AssociateContactToRumorWidgetProps> = ({
  rumor,
  asNestedComponent = false,
  onDisassociateSuccess,
  onAssociateSuccess,
  ...rest
}) => {
  const { addSuccess, addError } = useNotifications();
  const unlinkContactModal = useModal();

  const unlinkContact = React.useCallback(
    () => unlinkContactModal.open(),
    [unlinkContactModal],
  );

  const onDisassociateContact = React.useCallback(
    async () => {
      try {
        const updatedRumor = await updateRumor(rumor.id!, { ...rumor!, contactId: undefined });
        unlinkContactModal.close();
        addSuccess(CONTACT_DISASSOCIATE_OPERATION_SUCCESS_MESSAGE);
        onDisassociateSuccess?.(updatedRumor);
      } catch (error) {
        addError(CONTACT_DISASSOCIATE_OPERATION_ERROR_MESSAGE);
      }
    },
    [rumor, unlinkContactModal, addSuccess, onDisassociateSuccess, addError],
  );

  if (rumor.contactId) {
    return (
      <>
        <ContactWidget
          {...rest}
          asNestedComponent={asNestedComponent}
          contactId={rumor.contactId}
          extraActions={[{
            label: 'Dissocia',
            onClick: unlinkContact,
          }]}
        />
        <ConfirmModal
          title="Vuoi dissociare il contatto dalla notizia?"
          isOpen={unlinkContactModal.isOpen}
          onAbort={unlinkContactModal.close}
          onConfirm={onDisassociateContact}
        >
          Questa operazione non elimina il contatto dal database. In futuro potrai riassegnarlo alla notizia o ad altre attività.
        </ConfirmModal>
      </>
    );
  }

  return (
    <Card aria-label="Sezione del contatto" asContent={asNestedComponent}>
      <Card.Header
        title="Contatto"
        icon={{ path: ICON_ACCOUNT_CIRCLE_OUTLINE }}
        primaryActions={[<AssociateContactToRumorAction rumor={rumor} onSuccess={onAssociateSuccess} />]}
      />
      <Card.Content>
        <Message message="Nessun contatto associato alla notizia." />
      </Card.Content>
    </Card>
  );
};

export default AssociateContactToRumorWidget;

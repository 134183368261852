import React from 'react';
import { AxiosError } from 'axios';
import { RumorNoticeData } from '../../../providers/api/dtos/notice';
import { Rumor } from '../../../providers/api/dtos/rumor';
import { useAgent, useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import {
  RumorStatus,
} from '../../../domain/types';
import { isReminderExpired } from '../../../utils/reminder/reminder';
import { WithRequiredKeys } from '../../../types';
import { useRumorReminders } from '../use-rumor-reminders/useRumorReminders';
import useRBAC from '../../../hooks/use-rbac/useRBAC';

const hasMissingInfo = ({
  propertyAddress,
  propertyType,
  propertySize,
  propertyStatus,
  propertyFloor,
  isGeoComplete,
}: Rumor): boolean => !propertyAddress
|| !propertyType
|| propertySize === undefined
|| !propertyStatus
|| propertyFloor === undefined
  || !isGeoComplete;

export type UseRumorNoticesDataResult = {
  data?: RumorNoticeData[],
  isLoading: boolean,
  errors?: Error[] | undefined,
  mutate: () => Promise<void>
};

export const useRumorNoticesData = (rumor?: WithRequiredKeys<Rumor, 'id'>): UseRumorNoticesDataResult => {
  const [errors, setErrors] = React.useState<AxiosError[] | undefined>();

  const handleApiError = React.useCallback((err: AxiosError) => {
    if (err.response?.status === 404) { return; }

    setErrors((prev) => {
      if (!prev) return [err];
      return [...prev, err];
    });
  }, []);

  const validRumor = rumor?.status === RumorStatus.IN_PROGRESS || rumor?.status === RumorStatus.APPOINTMENT_SCHEDULED ? rumor : undefined;

  const { userIsAdmin } = useRBAC();
  const currentAgent = useCurrentAgent();

  const { data: rumorAgent, isLoading: isRumorAgentLoading } = useAgent(validRumor?.agentId);

  const {
    data: reminders,
    mutate: mutateRumorReminders,
    isLoading: isRemindersLoading,
  } = useRumorReminders(validRumor?.id, undefined, { onError: handleApiError });

  const isLoading = React.useMemo(() => (
    isRumorAgentLoading
    || isRemindersLoading
  ), [isRumorAgentLoading, isRemindersLoading]);

  const mutate = React.useCallback(async () => {
    Promise.all([
      mutateRumorReminders(),
    ]);
  }, [mutateRumorReminders]);

  const data = React.useMemo(() => {
    if (!validRumor) return [];

    const noticesData: RumorNoticeData[] = [];

    const allRemindersExpired = !!reminders && reminders.content.every((reminder) => isReminderExpired(reminder));

    const nextActionHasNotBeenDefined = validRumor.status === RumorStatus.IN_PROGRESS
      && allRemindersExpired;

    /** RUMOR_NO_NEXT_ACTION_DEFINED_SUPERVISOR */
    if (rumorAgent
      && currentAgent
      && currentAgent.isSupervisor
      && (
        currentAgent.id === rumorAgent.supervisorId
        || (!currentAgent?.needsSupervisor && currentAgent?.hasLicense && currentAgent.canManage?.some(({ id }) => id === validRumor.id))
      ) && nextActionHasNotBeenDefined
      && nextActionHasNotBeenDefined
    ) {
      noticesData.push({
        type: 'RUMOR_NO_NEXT_ACTION_DEFINED_SUPERVISOR',
        referenceId: validRumor.id.toString(),
        data: {
          rumor: validRumor,
        },
      });
    }

    /** RUMOR_NO_NEXT_ACTION_DEFINED_AGENT */
    if (rumorAgent
      && currentAgent
      && currentAgent.id === rumorAgent.id
      && validRumor.contactId
      && nextActionHasNotBeenDefined
    ) {
      noticesData.push({
        type: 'RUMOR_NO_NEXT_ACTION_DEFINED_AGENT',
        referenceId: validRumor.id.toString(),
        data: {
          rumor: validRumor,
        },
      });
    }

    /** RUMOR_MISSING_INFO */
    if ((userIsAdmin || (currentAgent && currentAgent.id === rumorAgent?.id)) && hasMissingInfo(validRumor)) {
      noticesData.push({
        type: 'RUMOR_MISSING_INFO',
        referenceId: validRumor.id.toString(),
        data: {
          rumor: validRumor,
        },
      });
    }

    /** RUMOR_NO_CONTACT_ASSOCIATED */
    if ((userIsAdmin || (currentAgent && currentAgent.id === rumorAgent?.id)) && !validRumor.contactId) {
      noticesData.push({
        type: 'RUMOR_NO_CONTACT_ASSOCIATED',
        referenceId: validRumor.id.toString(),
        data: {
          rumor: validRumor,
        },
      });
    }

    return noticesData;
  }, [validRumor, reminders, rumorAgent, currentAgent, userIsAdmin]);

  return {
    data,
    isLoading,
    errors,
    mutate,
  };
};

import React from 'react';
import {
  Action, ConfirmModal, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { AssignmentWizardData } from '../../../providers/api/dtos';
import { deleteAssignmentSignatureRequest } from '../../../providers/api/assignment/assignmentProvider';

export const DELETE_ASSIGNMENT_SIGNATURE_REQUEST_SUCCESS_MESSAGE = "La richiesta di firma digitale dell'incarico è stata cancellata con successo";
export const DELETE_ASSIGNMENT_SIGNATURE_REQUEST_ERROR_MESSAGE = "Non è stato possibile cancellare la richiesta di firma digitale dell'incarico";

interface ChildFnProps {
  deleteSignatureRequest: VoidFunction,
  isLoading: boolean,
}

export interface DeleteAssignmentSignatureRequestActionProps {
  assignmentWizardData: AssignmentWizardData,
  onSuccess?: (assignmentWizardData: AssignmentWizardData) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const DeleteAssignmentSignatureRequestAction: React.FC<DeleteAssignmentSignatureRequestActionProps> = (props) => {
  const { assignmentWizardData, onSuccess, children } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const confirmDeleteSignatureRequestModal = useModal();

  const openConfirmModal = React.useCallback(() => {
    confirmDeleteSignatureRequestModal.open();
  }, [confirmDeleteSignatureRequestModal]);

  const deleteSignatureRequest = React.useCallback(async () => {
    try {
      setIsLoading(true);

      await deleteAssignmentSignatureRequest(assignmentWizardData.prospectId);

      setIsLoading(false);
      confirmDeleteSignatureRequestModal.close();

      addSuccess(DELETE_ASSIGNMENT_SIGNATURE_REQUEST_SUCCESS_MESSAGE);
      onSuccess?.(assignmentWizardData);
    } catch (_) {
      setIsLoading(false);
      addError(DELETE_ASSIGNMENT_SIGNATURE_REQUEST_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, assignmentWizardData, confirmDeleteSignatureRequestModal, onSuccess]);

  if (!assignmentWizardData.isDigitallySigned) {
    return null;
  }

  return (
    <>
      {
      children
        ? children({ deleteSignatureRequest: openConfirmModal, isLoading })
        : (
          <Action
            aria-label="Cancella la richiesta di firma digitale dell'incarico"
            title="Cancella la richiesta di firma digitale dell'incarico"
            label="Cancella"
            onClick={openConfirmModal}
            size="S"
            loading={isLoading}
          />
        )
    }
      <Portal>
        <ConfirmModal
          isOpen={confirmDeleteSignatureRequestModal.isOpen}
          title="Conferma cancellazione richiesta di firma"
          aria-label="Conferma cancellazione richiesta di firma"
          onConfirm={deleteSignatureRequest}
          onAbort={confirmDeleteSignatureRequestModal.close}
        >
          Cliccando sul pulsante OK cancellerai la richiesta di firma digitale del mandato.
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default DeleteAssignmentSignatureRequestAction;

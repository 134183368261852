/* eslint-disable react/no-unknown-property */
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Action, ActionIconDropdown, Avatar, Dropdown, FontWeight, HStack, ICON_CELLPHONE, ICON_PHONE_OFF_OUTLINE,
  Text,
  Tooltip,
  useNotifications,
} from '@doveit/bricks';
import { formatDate, pluralizeWord } from '@doveit/hammer';
import { PageType } from '../../types';
import RightColumnPageLayout from '../../../layouts/right-column-page-layout/RightColumnPageLayout';
import useContact from '../../../contact/hooks/use-contact/useContact';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import ContactWidget from '../../../contact/components/contact-widget/ContactWidget';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import AircallButton from '../../../containers/aircall-button/AircallButton';
import useContactLeads from '../../../contact/hooks/use-contact-leads/useContactLeads';
import { ReferenceType } from '../../../domain/types';
import useTimer from '../../../hooks/use-timer/useTimer';
import { getFormattedSeconds } from '../../../utils';
import {
  Call, CallContextType, Contact, Note, ProcessableContact,
} from '../../../providers/api/dtos';
import buildMobileCall from '../../../utils/call/call';
import NextContactAction from '../../containers/next-contact-action/NextContactAction';
import { createCall } from '../../../providers/api/call/callProvider';
import useFilters, { Filters } from '../../../hooks/use-filters/useFilters';
import ViewContactToProcessCount from '../../containers/view-contact-to-process-count/ViewContactToProcessCount';
import ExitSession from '../../../components/exit-session/ExitSession';
import CallBackButton from '../../components/call-back-button/CallBackButton';
import NoAnswerAction from '../../containers/no-answer-action/NoAnswerAction';
import EventRegistryWidget from '../../containers/event-registry-widget/EventRegistryWidget';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import { goToSearchPage } from '../../../utils/navigate/utils';
import ContactIntentsWidget from '../../../contact/containers/contact-intents-widget/ContactIntentsWidget';
import RightColumnPageSkeleton from '../../../components/page-skeleton/RightColumnPageSkeleton';
import ContactRumorsWidget from '../../../contact/containers/contact-rumors-widget/ContactRumorsWidget';
import ContactLeadsWidget from '../../../contact/containers/contact-leads-widget/ContactLeadsWidget';
import { updateContact } from '../../../providers/api/contact/contactProvider';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import { useContactToProcessNoticesData } from '../../../contact/hooks/use-contact-to-process-notices-data/useContactToProcessNoticesData';
import { buildContactToProcessNotice } from '../../../utils/notice/buildContactNotice';
import NoticeBoard from '../../../notice/components/notice-board/NoticeBoard';
import NotesSection from '../../../notes/components/notes-section/NotesSection';

export const LOAD_PAGE_DATA_ERROR_MESSAGE = 'Non è stato possibile caricare i dati della pagina.';
export const UPDATE_CONTACT_SUCCESS_MESSAGE = 'Il contatto è stato modificato con successo.';
export const UPDATE_CONTACT_ERROR_MESSAGE = 'Non è stato possibile modificare il contatto.';
export const NEXT_CONTACT_ERROR_MESSAGE = 'Impossibile caricare il prossimo contatto da processare.';
export const CALL_EVENT_ERROR_MESSAGE = 'Impossibile salvare le informazioni della chiamata.';

interface CreateIntentPageFilters extends Filters {
  correlationId?: string;
}

export interface CallCenterContactPageProps {
  type: PageType.PROCESS_CONTACT | PageType.DETAIL,
}

const CallCenterView: React.FC<CallCenterContactPageProps> = ({
  type,
}) => {
  const { id: contactId } = useParams();
  const { filters: { correlationId } } = useFilters<CreateIntentPageFilters>();
  const { user } = useRBAC();
  const navigate = useNavigate();
  const [showCallControls, setShowCallControls] = React.useState<boolean>(true);
  const [disableNextContact, setDisableNextContact] = React.useState<boolean>(true);
  const [disableControls, setDisableControls] = React.useState<boolean>(false);
  const [showControls, setShowControls] = React.useState<boolean>(false);
  const [showStopCall, setShowStopCall] = React.useState<boolean>(false);
  const [callEvent, setCallEvent] = React.useState<Call | undefined>();
  const { addSuccess, addError } = useNotifications();
  const callTimer = useTimer();
  const isMobile = useIsDevice('mobile');

  React.useEffect(() => {
    setDisableNextContact(true);
    setShowCallControls(true);
    setShowControls(false);
    setShowStopCall(false);
  }, [contactId]);

  const {
    data: contact,
    isLoading: isContactLoading,
    error: contactError,
    mutate: mutateContact,
  } = useContact(contactId);
  const { data: contactLeads, error: contactLeadsError } = useContactLeads(contactId);

  const { data: contactToProcessNoticesData = [], mutate: mutateContactToProcessNoticesData } = useContactToProcessNoticesData(contactId);
  const contactToProcessNotices = React.useMemo(() => contactToProcessNoticesData.map((noticeData) => buildContactToProcessNotice(noticeData, {
    isMobile,
    onLeadUpdate: mutateContactToProcessNoticesData,
  })), [contactToProcessNoticesData, isMobile, mutateContactToProcessNoticesData]);

  const onContactUpdate = React.useCallback(async () => {
    await mutateContact();
  }, [mutateContact]);

  const onLeadUpdate = React.useCallback(async () => {
    setDisableNextContact(false);
  }, []);

  const onCallStarted = React.useCallback(() => {
    setShowCallControls(false);
    setShowControls(true);
  }, []);

  const onCallMobileStarted = React.useCallback(() => {
    setShowCallControls(false);
    setShowStopCall(true);
    callTimer.start();
  }, [callTimer]);

  const stopCall = React.useCallback(() => {
    if (callTimer.isActive) {
      callTimer.stop();

      const event = buildMobileCall({
        outboundNumber: contact!.phoneNumber!,
        duration: callTimer.seconds,
        leadTransformationSpecialist: user!.email!,
        context: {
          type: CallContextType.LEAD,
          id: contact!.id!,
        },
      });

      callTimer.reset();
      setCallEvent(event);

      return event;
    }

    return callEvent;
  }, [callTimer, contact, user, callEvent]);

  const sendCallEvent = React.useCallback(async (event: Call | undefined) => {
    if (event) {
      try {
        await createCall({
          ...event,
          correlationId,
        });

        setCallEvent(undefined);
      } catch (err) {
        addError(CALL_EVENT_ERROR_MESSAGE);
      }
    }
  }, [addError, correlationId]);

  const onNoAnswerSuccess = React.useCallback(async () => {
    if (callEvent) {
      const updatedCallEvent = { ...callEvent!, answered: false };
      setCallEvent(updatedCallEvent);

      await sendCallEvent(updatedCallEvent);
    }

    if (type === PageType.DETAIL) {
      setShowControls(false);
      setShowCallControls(true);
    }

    setDisableNextContact(false);
  }, [sendCallEvent, callEvent, type]);

  const onCallFinished = React.useCallback(() => {
    stopCall();
    setShowStopCall(false);
    setShowControls(true);
  }, [stopCall]);

  const onCallBackSuccess = React.useCallback(async (dateTime: string) => {
    setDisableNextContact(false);
    addSuccess(`Il contatto verrà richiamato il giorno ${formatDate(new Date(dateTime), {
      time: true,
    })}`);

    await sendCallEvent(callEvent ? { ...callEvent, answered: true } : undefined);

    if (type === PageType.DETAIL) {
      setShowControls(false);
      setShowCallControls(true);
    }
  }, [addSuccess, sendCallEvent, callEvent, type]);

  const onCallBackError = React.useCallback(() => {
    setDisableNextContact(false);
    sendCallEvent(callEvent);
  }, [callEvent, sendCallEvent]);

  const onNextContactSuccess = React.useCallback(async (processableContact?: ProcessableContact) => {
    await sendCallEvent(stopCall());

    if (!processableContact) {
      navigate('/call-center/contacts/process', { replace: true });
      return;
    }

    navigate(`/call-center/contacts/process/${processableContact.id}?correlation_id=${processableContact.correlationId}`, { replace: true });
  }, [sendCallEvent, stopCall, navigate]);

  const onNextContactError = React.useCallback(async () => {
    await sendCallEvent(stopCall());

    setDisableControls(false);
    addError(NEXT_CONTACT_ERROR_MESSAGE);
  }, [addError, stopCall, sendCallEvent]);

  const onExit = React.useCallback(() => {
    sendCallEvent(stopCall());
    navigate('/call-center/contacts/process');
  }, [navigate, sendCallEvent, stopCall]);

  const openSearchContactPage = React.useCallback(() => {
    goToSearchPage(ReferenceType.CONTACT);
  }, []);

  const handleCallCenterNotesUpdate = React.useCallback(async (callCenterNotes: Note[]) => {
    if (!contact) return;

    try {
      const contactToUpdate: Contact = {
        ...contact,
        callCenterNotes,
      };

      await updateContact(contact.id!, contactToUpdate);

      addSuccess(UPDATE_CONTACT_SUCCESS_MESSAGE);

      await mutateContact();
    } catch (err) {
      addError(UPDATE_CONTACT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, contact, mutateContact]);

  if (contactError || contactLeadsError) {
    return <GenericErrorPage title={LOAD_PAGE_DATA_ERROR_MESSAGE} />;
  }

  if (isContactLoading) {
    return <RightColumnPageSkeleton />;
  }

  return contact! && (
    <RightColumnPageLayout
      title={(
        <HStack gap={200}>
          <Avatar />
          <div>
            <div>
              <Text.H4
                fontWeight={FontWeight.REGULAR}
                as="div"
              >
                {contact.name || NO_VALUE_SYMBOL}
              </Text.H4>
            </div>
            <Text.Body as="div">
              {contact.phoneNumber}
            </Text.Body>
          </div>
        </HStack>
      )}
      actions={(
        <ActionIconDropdown
          label="Azioni pagina contatto"
          aria-label="Azioni pagina contatto"
        >
          <Dropdown.Option
            label="Cerca contatto"
            aria-label="Apri la pagina di ricerca contatto"
            onClick={openSearchContactPage}
          />
        </ActionIconDropdown>
      )}
      topContent={<NoticeBoard notices={contactToProcessNotices} />}
      primarySide={(
        <ContactWidget
          contact={contact}
          onUpdate={onContactUpdate}
        />
      )}
      secondarySide={
        contact?.phoneNumber && (<EventRegistryWidget phoneNumber={contact.phoneNumber} />)
      }
      footer={(
        <HStack alignItems="center" justifyContent="space-between">
          <HStack>
            {contactLeads && showCallControls && (
              <>
                <AircallButton
                  phoneNumber={contact.phoneNumber!}
                  onSuccess={onCallStarted}
                />
                <Action
                  label="Mobile"
                  aria-label="Azione per avviare la chiamata con mobile"
                  iconLeft={{ path: ICON_CELLPHONE }}
                  color="primary"
                  onClick={onCallMobileStarted}
                />
              </>
            )}
            {showStopCall && (
              <Action
                label="Chiamata terminata"
                aria-label="Azione di fine chiamata con mobile"
                color="critical"
                emphasis="high"
                iconLeft={{ path: ICON_PHONE_OFF_OUTLINE }}
                badgeLabel={getFormattedSeconds(callTimer.seconds)}
                onClick={onCallFinished}
              />
            )}
            {showControls && (
              <>
                <CallBackButton
                  contactId={contactId!}
                  onSuccess={onCallBackSuccess}
                  onError={onCallBackError}
                  disabled={disableControls}
                />
                <NoAnswerAction
                  contactId={contactId!}
                  onSuccess={onNoAnswerSuccess}
                  onError={onNoAnswerSuccess}
                  disabled={disableControls}
                />
              </>
            )}
          </HStack>

          {type === PageType.PROCESS_CONTACT && (
            <HStack>
              <NextContactAction
                disabled={disableNextContact}
                onSuccess={onNextContactSuccess}
                onError={onNextContactError}
              />
              <ViewContactToProcessCount>
                {(count) => (
                  <Tooltip content={`${count} ${pluralizeWord('contatt', 'o', 'i', count)} da processare`}>
                    <div>
                      <ExitSession
                        badgeLabel={count}
                        onExit={onExit}
                      />
                    </div>
                  </Tooltip>
                )}
              </ViewContactToProcessCount>
            </HStack>
          )}
        </HStack>
      )}
    >
      <ContactLeadsWidget
        contactId={contact.id!}
        onLeadUpdate={onLeadUpdate}
      />
      <NotesSection
        notes={contact.callCenterNotes}
        onNotesUpdate={handleCallCenterNotesUpdate}
      />
      <ContactRumorsWidget contactId={contact.id!} />
      <ContactIntentsWidget contactId={contact.id!} />
    </RightColumnPageLayout>
  );
};

export function CallCenterContactProcessDetailPage() {
  return (
    <CallCenterView type={PageType.PROCESS_CONTACT} />
  );
}

export function CallCenterContactDetailPage() {
  return (
    <CallCenterView type={PageType.DETAIL} />
  );
}

export default CallCenterView;

import { AssignmentWizardData, Lead, Prospect } from '../../../../../providers/api/dtos';
import { boolToBooleanString, booleanStringToBool } from '../../../../../utils/boolean-string/booleanString';
import { CreateAssignmentFormModel } from '../../create-assignment-form/CreateAssignmentForm';

export const toAssignmentWizardDetails = (
  initialData: AssignmentWizardData,
  prospect: Prospect,
  lead?: Lead,
): CreateAssignmentFormModel => ({
  autoRenew: typeof initialData?.assignment?.autoRenew !== 'undefined'
    ? boolToBooleanString(initialData?.assignment?.autoRenew)
    : '',
  keysHanded: typeof initialData?.assignment?.keysHanded !== 'undefined'
    ? boolToBooleanString(initialData?.assignment?.keysHanded)
    : '',
  exclusive: typeof initialData?.assignment?.exclusive !== 'undefined'
    ? boolToBooleanString(initialData?.assignment?.exclusive)
    : '',
  isMortgageInProgress: (typeof initialData?.property?.isMortgageInProgress !== 'undefined'
    ? boolToBooleanString(initialData?.property?.isMortgageInProgress)
    : boolToBooleanString(lead?.residualMortgage)) ?? '',
  residualMortgage: initialData?.property?.residualMortgage ? `${initialData.property.residualMortgage}` : '',
  minimumPrice: initialData?.property?.minimumPrice ? `${initialData.property.minimumPrice}` : '',
  price: initialData?.property?.price ? `${initialData.property.price}` : '',
  endDate: initialData?.assignment?.endDate ?? '',
  evaluation: (initialData?.property
    ? initialData.property.evaluation?.toString()
    : prospect.agentEvaluation?.toString()
  ) ?? '',
});

export const toAssignmentWizardData = (
  existingData: AssignmentWizardData,
  values: CreateAssignmentFormModel,
): AssignmentWizardData => {
  const {
    autoRenew,
    keysHanded,
    exclusive,
    endDate,
    isMortgageInProgress,
    residualMortgage,
    minimumPrice,
    price,
    evaluation,
  } = values;

  const newAssignmentValues: Partial<AssignmentWizardData['assignment']> = {
    autoRenew: booleanStringToBool(autoRenew),
    exclusive: booleanStringToBool(exclusive),
    keysHanded: booleanStringToBool(keysHanded),
    endDate: endDate || undefined,
  };

  const newPropertyValues: Partial<AssignmentWizardData['property']> = {
    isMortgageInProgress: booleanStringToBool(isMortgageInProgress),
    residualMortgage: residualMortgage ? parseFloat(residualMortgage) : undefined,
    minimumPrice: minimumPrice ? parseInt(minimumPrice, 10) : undefined,
    price: price ? parseInt(price, 10) : undefined,
    evaluation: evaluation ? parseInt(evaluation, 10) : undefined,
  };

  const newValues: AssignmentWizardData = {
    ...existingData,
    isDigitallySigned: false,
    assignment: {
      ...existingData.assignment,
      ...newAssignmentValues,
    },
    property: {
      ...existingData.property,
      ...newPropertyValues,
    },
  };

  return newValues;
};

import React from 'react';
import {
  Action,
} from '@doveit/bricks';
import { Notice, RumorNoticeData, Severity } from '../../providers/api/dtos/notice';
import { BuildRumorNoticeCtx } from './types';
import EditRumorAction from '../../rumor/containers/edit-rumor-action/EditRumorAction';
import SendNudgeAction from '../../containers/send-nudge-action/SendNudgeAction';
import { ReferenceType } from '../../domain/types';
import AssociateContactToRumorAction from '../../rumor/containers/associate-contact-to-rumor-action/AssociateContactToRumorAction';
import { Reminder } from '../../providers/api/dtos';
import UpsertReminderAction from '../../reminders/containers/upsert-reminder-action/UpsertReminderAction';

export function buildRumorNoNextActionDefinedSupervisorNotice(noticeData: RumorNoticeData, ctx: BuildRumorNoticeCtx): Notice {
  const { data: { rumor } } = noticeData;
  const { onRumorNudgeSent } = ctx;
  return {
    type: 'RUMOR_NO_NEXT_ACTION_DEFINED_SUPERVISOR',
    severity: Severity.WARNING,
    priority: 2,
    message: 'Non è stata definita una prossima azione',
    children: "Sollecita l'agente con una notifica al fine di definire la prossima azione sulla notizia.",
    actions: [
      <SendNudgeAction
        reference={rumor}
        referenceType={ReferenceType.RUMOR}
        referenceLabel="notizia"
        onSuccess={onRumorNudgeSent}
      >
        {({ sendNudge, disabled, loading }) => (
          <Action
            label="Sollecita"
            aria-label="Bottone per sollecitare un'azione"
            color="warning"
            size="S"
            emphasis="high"
            onClick={sendNudge}
            loading={loading}
            disabled={disabled}
          />
        )}
      </SendNudgeAction>],
  };
}

export function buildRumorNoNextActionDefinedAgentNotice(noticeData: RumorNoticeData, ctx: BuildRumorNoticeCtx): Notice {
  const { data: { rumor } } = noticeData;
  const { onReminderCreate } = ctx;

  const reminderToCreate: Reminder = {
    agentId: rumor.agentId,
    referenceId: rumor.id,
    referenceType: ReferenceType.RUMOR,
    resolved: false,
  } as Reminder;

  return {
    type: 'RUMOR_NO_NEXT_ACTION_DEFINED_AGENT',
    severity: Severity.WARNING,
    priority: 1,
    message: 'Non è stata definita una prossima azione',
    children: 'Definisci la prossima azione sulla notizia per mantenere alta la probabilità di conversione in valutazione.',
    actions: [
      <UpsertReminderAction
        reminder={reminderToCreate}
        onSuccess={onReminderCreate}
      >
        {({ upsert }) => (
          <Action
            label="Aggiungi promemoria"
            color="warning"
            size="S"
            emphasis="high"
            onClick={upsert}
          />
        )}
      </UpsertReminderAction>,
    ],
  };
}

export function buildRumorMissingInfoNotice(noticeData: RumorNoticeData, ctx: BuildRumorNoticeCtx): Notice {
  const { data: { rumor } } = noticeData;
  const { onRumorEdit } = ctx;

  return {
    type: 'RUMOR_MISSING_INFO',
    severity: Severity.WARNING,
    priority: 3,
    message: "Mancano alcune informazioni dell'immobile",
    children: "Modifica la notizia inserendo le informazioni mancanti sull'immobile per trasformarla in valutazione.",
    actions: [
      <EditRumorAction
        rumor={rumor}
        onSuccess={onRumorEdit}
      >
        {({ onEditRumorClick }) => (
          <Action
            label="Modifica notifica"
            color="warning"
            size="S"
            emphasis="high"
            onClick={onEditRumorClick}
          />
        )}
      </EditRumorAction>],
  };
}

export function buildRumorNoContactAssociatedNotice(noticeData: RumorNoticeData, ctx: BuildRumorNoticeCtx): Notice {
  const { data: { rumor } } = noticeData;
  const { onContactAssociated } = ctx;

  return {
    type: 'RUMOR_NO_CONTACT_ASSOCIATED',
    severity: Severity.WARNING,
    priority: 2,
    message: 'Non è stato associato un contatto',
    children: 'Associa un contatto alla notizia per trasformarla in valutazione.',
    actions: [
      <AssociateContactToRumorAction
        rumor={rumor}
        onSuccess={onContactAssociated}
      >
        {({ associateContact }) => (
          <Action
            label="Associa contatto"
            color="warning"
            size="S"
            emphasis="high"
            onClick={associateContact}
          />
        )}
      </AssociateContactToRumorAction>],
  };
}

export function buildRumorNotice(noticeData: RumorNoticeData, ctx: BuildRumorNoticeCtx): Notice {
  switch (noticeData.type) {
    case 'RUMOR_NO_NEXT_ACTION_DEFINED_SUPERVISOR':
      return buildRumorNoNextActionDefinedSupervisorNotice(noticeData, ctx);
    case 'RUMOR_NO_NEXT_ACTION_DEFINED_AGENT':
      return buildRumorNoNextActionDefinedAgentNotice(noticeData, ctx);
    case 'RUMOR_MISSING_INFO':
      return buildRumorMissingInfoNotice(noticeData, ctx);
    case 'RUMOR_NO_CONTACT_ASSOCIATED':
      return buildRumorNoContactAssociatedNotice(noticeData, ctx);
    default:
      throw new Error('Unknown rumor notice type');
  }
}

import React from 'react';
import { Action, useNotifications } from '@doveit/bricks';
import { Contact, Rumor } from '../../../providers/api/dtos';
import SelectContact from '../../../contact/containers/select-contact/SelectContact';
import { SearchContactFormModel } from '../../../contact/components/search-contact-form/SearchContactForm';
import createOrUpdateContact from '../../../contact/utils/createOrUpdateContact';
import { updateRumor } from '../../../providers/api/rumor/rumorProvider';

export interface AssociateContactToRumorActionProps {
  rumor: Rumor,
  onSuccess?: (rumor: Rumor, contact: Contact) => void,
  children?: ({ associateContact }: { associateContact: VoidFunction }) => React.ReactNode
}

export const ASSOCIATE_CONTACT_TO_RUMOR_ACTION_SUCCESS_MESSAGE = 'Contatto associato alla notizia con successo.';
export const ASSOCIATE_CONTACT_TO_RUMOR_ACTION_ERROR_MESSAGE = 'Non é stato possibile associare il contatto alla notizia.';

const AssociateContactToRumorAction: React.FC<AssociateContactToRumorActionProps> = ({
  rumor,
  onSuccess,
  children,
}) => {
  const { addSuccess, addError } = useNotifications();

  const onContactChange = React.useCallback(async (existingContact?: Contact, formValues?: SearchContactFormModel) => {
    try {
      const contactResult = await createOrUpdateContact(formValues, existingContact);
      const updatedRumor = await updateRumor(rumor.id!, { ...rumor!, contactId: contactResult.id! });

      addSuccess(ASSOCIATE_CONTACT_TO_RUMOR_ACTION_SUCCESS_MESSAGE);
      onSuccess?.(updatedRumor, contactResult);
    } catch (error) {
      addError(ASSOCIATE_CONTACT_TO_RUMOR_ACTION_ERROR_MESSAGE);
    }
  }, [rumor, addSuccess, onSuccess, addError]);

  return (
    <SelectContact
      onContactChange={onContactChange}
      render={{
        noContactView: ({ openContactFinder }) => (children ? children({ associateContact: openContactFinder }) : (
          <Action
            label="Associa contatto"
            aria-label="Pulsante per associare un contatto alla notizia"
            size="S"
            onClick={openContactFinder}
          />
        )),
        // eslint-disable-next-line react/jsx-no-useless-fragment
        contactSelectedView: () => (<></>),
      }}
    />
  );
};

export default AssociateContactToRumorAction;
